<template>
  <chargers-header
    :loading="data.loading"
    @on-add-charger="methods.handleAddCharger"
    @update:model-value="methods.handlerFilter"
  />

  <wb-cards-loader :loading="data.loading" data-test-id="chargers-loader">
    <template v-if="state.charger.getChargers.length">
      <enable-auto-refresh-component
        v-if="data.showActivateInterval"
        class="mb-8"
        @enable-auto-refresh="methods.enableAutoRefresh"
      />

      <update-chargers-notification />

      <chargers-table
        data-test-id="groupList"
        @on-bulk-action="methods.handleBulkAction"
      />
    </template>
    <chargers-empty-state v-else />
  </wb-cards-loader>

  <add-chargers-modal
    v-if="data.isModalAddChargersOpen"
    @on-update-chargers="methods.loadChargers('network')"
    @on-configure-payments="methods.handleConfigurePayments"
    @close="data.isModalAddChargersOpen = false"
  />

  <chargers-remote-actions-modal
    v-if="data.isModalRemoteActionsOpen && data.actionSelected"
    :action="data.actionSelected"
    :group="state.groups.groupRelatedToOrganizationWithChargers"
    :force-all-chargers="data.remoteActionAllChargers"
    @close="data.isModalRemoteActionsOpen = false"
  />

  <chargers-assign-rates-modal
    v-if="data.isModalAssignRatesOpen"
    @close="data.isModalAssignRatesOpen = false"
  />

  <chargers-unassign-rates-modal
    v-if="data.isModalUnassignRatesOpen"
    @close="data.isModalUnassignRatesOpen = false"
  />
</template>

<script setup lang="ts">
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import UpdateChargersNotification from '@/components/UpdateChargersNotification.vue'
import ChargersTable from '@/components/tables/ChargersTable.vue'

import ChargersHeader from '@/components/headers/ChargersHeader.vue'
import ChargersRemoteActionsModal from '@/components/modals/ChargersRemoteActionsModal.vue'
import ChargersAssignRatesModal from '@/components/modals/ChargersAssignRatesModal.vue'
import ChargersUnassignRatesModal from '@/components/modals/ChargersUnassignRatesModal.vue'
import AddChargersModal from '@/components/modals/AddChargersModal.vue'
import ChargersEmptyState from '@/components/emptyStates/ChargersEmptyState.vue'
import state from '@/state'
import api from '@/api'

import { useRoute, useRouter } from 'vue-router'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive, onBeforeUnmount, watchEffect } from 'vue'
import type { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'

const route = useRoute()
const router = useRouter()

interface Data {
  actionSelected?: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
  loading: boolean
  remoteActionAllChargers: boolean
  isModalRemoteActionsOpen: boolean
  isModalAssignRatesOpen: boolean
  isModalUnassignRatesOpen: boolean
  isModalAddChargersOpen: boolean
  showActivateInterval: boolean
  unmounted: boolean
  reloadChargersInterval?: ReturnType<typeof setInterval>
}
const data = reactive<Data>({
  loading: false,
  actionSelected: undefined,
  remoteActionAllChargers: false,
  isModalRemoteActionsOpen: false,
  isModalAssignRatesOpen: false,
  isModalUnassignRatesOpen: false,
  isModalAddChargersOpen: false,
  showActivateInterval: false,
  unmounted: false,
  reloadChargersInterval: undefined
})

type action = 'assign-rates' | 'unassign-rates' | REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
const methods = {
  handleBulkAction (action: action, { allChargers = false } = {}) {
    switch (action) {
      case 'assign-rates':
        data.isModalAssignRatesOpen = true
        break
      case 'unassign-rates':
        data.isModalUnassignRatesOpen = true
        break
      default:
        data.actionSelected = action
        data.isModalRemoteActionsOpen = true
        break
    }

    data.remoteActionAllChargers = allChargers
  },

  handleAddCharger () {
    trackDataAction('add-charger', { location_id: state.organizations.getCurrentOrganization.group_id })
    data.isModalAddChargersOpen = true
  },

  handleConfigurePayments () {
    data.isModalAddChargersOpen = false
    data.isModalAssignRatesOpen = true
  },

  handlerFilter () {
    state.charger.resetCheckedChargers()
  },

  async loadChargers (cacheType = 'stale') {
    const { result } = await api.groups.getAllDataOrganizations({ cacheType })
    state.groups.set('groups', result.groups)
  },

  async enableAutoRefresh () {
    data.showActivateInterval = false

    await methods.loadChargers()

    if (!data.unmounted) {
      data.reloadChargersInterval = setInterval(() => {
        location.href.includes('chargers') && methods.loadChargers()
      }, 10000)

      setTimeout(() => {
        clearInterval(data.reloadChargersInterval)
        data.showActivateInterval = true
      }, 60000)
    }
  }
}

onBeforeUnmount(() => {
  data.unmounted = true
  clearInterval(data.reloadChargersInterval)
})

async function created () {
  data.loading = true

  await methods.enableAutoRefresh()

  data.loading = false
}

created()

watchEffect(() => {
  if (route.query['add-chargers']) {
    data.isModalAddChargersOpen = true
    router.push({ query: {} }).catch(() => {})
  }
})
</script>
