<template>
  <wb-modal
    width="45"
    @close="methods.close"
  >
    <template #title>
      {{ i18n.t('mywb.common.change-timezone') }}
    </template>

    <timezone-select-form
      v-model:timezoneId="data.timezoneId"
      data-test-id="timezoneSelect"
    />

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="timezoneChangeTimezoneBtn"
          size="block"
          type="primary"
          :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
          :loading="data.loading"
          @click="methods.saveTimezone"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import TimezoneSelectForm from '@/components/forms/TimezoneSelectForm.vue'
import api from '@/api'
import { useNotify } from '@wallbox/toolkit-ui'
import state from '@/state'

const i18n = useI18n()
const notify = useNotify()

interface Events {
  (e: 'close'): void,
}

const emit = defineEmits<Events>()

interface Data {
  loading: boolean
  timezoneId: string
}

const data: Data = reactive({
  loading: false,
  timezoneId: ''
})

const methods = {
  async saveTimezone () {
    try {
      data.loading = true
      await api.chargers.updateChargerConfig({
        id: state.charger.getCurrentCharger.chargerData.id,
        config: { timezone: data.timezoneId }
      })
      const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
      state.charger.setCurrentCharger(charger)
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
      methods.close()
    }
  },

  close () {
    emit('close')
  }
}

function created () {
  data.timezoneId = state.charger.getCurrentCharger.config.timezone
}

created()
</script>
