<template>
  <div v-if="permissions.hasAuthToRestartAndUpdate" class="charger-actions g-8">
    <wb-button
      type="white"
      outlined
      data-test-id="updateChargerButton"
      icon="send_to_charger"
      :loading="!!compute.isUpdating"
      :disabled="props.loading || compute.isRemoteActionDisabled || compute.isUpdating || !compute.softwareUpdate"
      :label="mq.current === 'desktop' ? i18n.t('mywb.common.update') : ''"
      @click="methods.handleRemoteActions(REMOTE_ACTIONS.UPDATE)"
    />
    <wb-button
      type="white"
      outlined
      icon="refresh"
      :label="mq.current === 'desktop' ? i18n.t('mywb.common.restart') : ''"
      :loading="!!compute.isRestarting"
      :disabled="props.loading || compute.isRemoteActionDisabled || compute.isRestarting"
      data-test-id="restartChargerButton"
      @click="methods.handleRemoteActions(REMOTE_ACTIONS.RESTART)"
    />

    <chargers-remote-actions-modal
      v-if="data.isModalRemoteActionsOpen"
      :charger="state.charger.getCurrentCharger.chargerData"
      :software="state.charger.getCurrentCharger.config.software"
      :action="data.actionSelected"
      @close="data.isModalRemoteActionsOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'

import { isChargerRemoteAction, REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import { permissions } from '@/engine/clients'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargersRemoteActionsModal from '@/components/modals/ChargersRemoteActionsModal.vue'
import { useMq } from 'vue3-mq'
import state from '@/state'

const i18n = useI18n()
const mq = useMq()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  actionSelected: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
  isModalRemoteActionsOpen: boolean
}
const data = reactive<Data>({
  actionSelected: REMOTE_ACTIONS.UPDATE,
  isModalRemoteActionsOpen: false
})

const compute = reactive({
  isUpdating: computed(() => isChargerRemoteAction(state.charger.getCurrentCharger.config, REMOTE_ACTIONS.UPDATE)),

  isRestarting: computed(() => isChargerRemoteAction(state.charger.getCurrentCharger.config, REMOTE_ACTIONS.RESTART)),

  isRemoteActionDisabled: computed(() => {
    if (!state.charger.getCurrentCharger.chargerData) return true

    return getChargerStatus(state.charger.getCurrentCharger.chargerData)?.code === STATUSES.DISCONNECTED ||
    getChargerStatus(state.charger.getCurrentCharger.chargerData)?.code === STATUSES.CHARGING ||
    getChargerStatus(state.charger.getCurrentCharger.chargerData)?.code === STATUSES.DISCHARGING
  }),

  softwareUpdate: computed(() => isUpdatesAvailable(
    state.charger.getCurrentCharger.chargerData,
    state.charger.getCurrentCharger.config.software
  ))
})

const methods = {
  handleRemoteActions (action: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART) {
    data.actionSelected = action
    data.isModalRemoteActionsOpen = true
  }
}
</script>

<style lang="postcss" scoped>
.charger-actions {
  justify-self: end;
  display: grid;
  grid-template-columns: min-content min-content;
  max-height: 42px;
}
</style>
