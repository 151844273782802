<template>
  <wb-input
    v-model="compute.filteredText"
    data-test-id="searchButton"
    :disabled="props.loading"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.users') })"
    icon="search"
    type="search"
  />

  <shared-filter
    v-model="compute.filteredUsers"
    multiple
    with-search
    label="email"
    icon="person"
    :name="i18n.t('mywb.common.users')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.users') })"
    :empty-text="i18n.t('mywb.users.empty')"
    data-test-id="usersFilter"
    :options="compute.users"
    :reduce="(item: typeof data.users[number]) => item.id"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'accessConfigFilters', key: 'users' })"
  >
    <template #option="{ option }">
      <div class="grid g-8">
        <wb-user-avatar
          data-test-id="userPicture"
          size="small"
          :src="option.avatar"
          :initials="userInitials(option)"
        />
        <div>
          <p
            v-if="option.name"
            class="is-font-weight-500"
          >
            {{ option.name }} {{ option.lastname }}
          </p>
          <p>{{ option.email }}</p>
        </div>
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-model="compute.filteredGroups"
    multiple
    with-search
    label="name"
    icon="folder"
    :name="i18n.t('mywb.common.access-group')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.group') })"
    :empty-text="i18n.t('mywb.common.no-access-groups-found')"
    :options="compute.groups"
    :reduce="(item: typeof compute.groups[number]) => item.id"
    :loading="props.loading"
    data-test-id="groupsFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'accessConfigFilters', key: 'groups' })"
  />

  <shared-filter
    v-model="compute.filteredRole"
    option-key="profile"
    :name="i18n.t('mywb.common.type')"
    :options="data.roleOptions"
    :reduce="(item: typeof data.roleOptions[number]) => item.profile"
    :loading="props.loading"
    data-test-id="profileFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'accessConfigFilters', key: 'role' })"
  />

  <shared-filter
    v-model="compute.filteredStatus"
    option-key="code"
    :name="i18n.t('mywb.common.status')"
    :options="data.statusOptions"
    :reduce="(item: typeof data.statusOptions[number]) => item.code"
    :loading="props.loading"
    data-test-id="statusFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'accessConfigFilters', key: 'status' })"
  />

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'accessConfigFilters' })"
  />
</template>

<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue'
import { dom } from '@wallbox/toolkit-ui'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { userInitials } from '@/utilities/users'
import { EnumRoles } from '@/utilities/user-roles'
import state from '@/state'
import { useRoute, useRouter } from 'vue-router'
import type { Group } from '@/types'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})

const data = reactive({
  roleOptions: [
    {
      label: i18n.t('mywb.common.admin'),
      profile: 1,
      dataTestId: 'adminFilterTag'
    },

    {
      label: i18n.t('mywb.common.user'),
      profile: 3,
      dataTestId: 'userFilterTag'
    }
  ],

  statusOptions: [
    {
      label: i18n.t('mywb.common.pending'),
      code: 'pending'
    },

    {
      label: i18n.t('mywb.common.active'),
      code: 'active'
    }
  ]
})

const compute = reactive({
  filteredText: computed({
    get () {
      return state.filters.accessConfigFilters?.text
    },

    set: dom.debounce(value => {
      state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'text', value })
    }, 300)
  }),

  filteredUsers: computed({
    get () {
      return state.filters.accessConfigFilters?.users ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'users', value })
    }
  }),

  filteredGroups: computed({
    get () {
      return state.filters.accessConfigFilters?.groups ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'groups', value })
    }
  }),

  filteredRole: computed({
    get () {
      return state.filters.accessConfigFilters?.role ?? undefined
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'role', value })
    }
  }),

  filteredStatus: computed({
    get () {
      return state.filters.accessConfigFilters?.status ?? undefined
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'status', value })
    }
  }),

  users: computed((): Array<{ id: number, name?: string }> => {
    if (!compute.rootGroup) return []

    return [
      ...(compute.rootGroup.users ?? []),
      ...(compute.rootGroup.accessConfigs ?? []).map(group => group.users)
    ].flat().filter(user => user.email && user.profile !== EnumRoles['super-admin'])
  }),

  groups: computed((): Array<{ id: number, name: string }> => {
    if (!compute.rootGroup) return []

    return [
      {
        id: compute.rootGroup.id,
        name: i18n.t('mywb.common.custom-access')
      },

      ...(compute.rootGroup.accessConfigs ?? []).map(accessConfig => ({
        id: accessConfig.id,
        name: accessConfig.name
      }))
    ]
  }),

  rootGroup: computed((): typeof state.user.getGroupsByAccessConfig[number] & Group.Group => {
    const group = state.user.getGroupsByAccessConfig && state.user.getGroupsByAccessConfig
      .find(group =>
        state.groups.groupRelatedToOrganization.id === group.id) as typeof state.user.getGroupsByAccessConfig[number]

    return {
      ...group,
      ...state.groups.groupRelatedToOrganization
    }
  })
})

onMounted(() => {
  if (route.query?.group) {
    state.filters.setFilterValue({ filter: 'accessConfigFilters', key: 'groups', value: [+route.query.group] })
    router.push({ query: {} }).catch(() => {})
  }
})
</script>

<style lang="postcss" scoped>
.charger-img {
  width: 32px;
  object-fit: cover;
}

.bullet {
  color: var(--bullet-color, var(--white));
  font-size: 12px;
}

.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}
</style>
