<template>
  <wb-card
    :headline="compute.selectedPlan"
    data-test-id="planSection"
    alignment="center"
  >
    <template #actions>
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.change')"
        data-test-id="changePlanBtn"
        :to="{ name: 'plans' }"
        @click="methods.changePlan"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { EnumPlans } from '@/utilities/plans'

const i18n = useI18n()

const compute = reactive({
  plan: computed(() => state.plans.getPlan(state.organizations.getCurrentOrganization.product_id ||
    state.organizations.getCurrentOrganization.plan_id)),

  selectedPlan: computed(() => {
    switch (state.organizations.getCurrentOrganization.product_id) {
      case (EnumPlans.OPERATOR): return i18n.t('mywb.common.plan-operator')
      case (EnumPlans.BUSINESS): return i18n.t('mywb.common.plan-business')
      case (EnumPlans.BASIC): return i18n.t('mywb.common.plan-basic')
      default: return ''
    }
  })
})

const methods = {
  changePlan () {
    trackDataAction('change-subscription-plan')
  }
}
</script>
