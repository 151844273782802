import { reactive, computed } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import groupState from '@/state/groups.state'
import type api from '@/api'
import type { Charger } from '@/types'

type ApiCharger = Awaited<ReturnType<typeof api.chargers.getCharger>>

export interface DefaultCharger {
  users: ApiCharger['charger']['data']['users']
  chargerData: ApiCharger['charger']['data']['chargerData']
  config: ApiCharger['chargerConfig']
  sessions: Record<string, unknown>
}

const DEFAULT_CURRENT_CHARGER: DefaultCharger = {
  users: [],
  chargerData: {
    accessType: 'guest',
    id: 0,
    uid: '',
    group: 0,
    groupUid: '',
    name: '',
    serialNumber: '',
    chargerType: '',
    protocolCommunication: '',
    softwareVersion: '',
    status: 0,
    ocppConnectionStatus: 0,
    ocppReady: '',
    locked: 0,
    lastConnection: 0,
    wifiSignal: 0,
    midStatus: 0,
    midSerialNumber: '',
    midEnabled: 0,
    powerSharingStatus: 0,
    maxAvailableCurrent: 0,
    maxChargingCurrent: 0,
    connectorType: '',
    connectionType: '',
    chargingType: '',
    uniqueIdentifier: '',
    softwareUpdatedAt: 0
  },
  sessions: {
    segments: {}
  },
  config: {
    charger_id: 0,
    uid: '',
    serial_number: '',
    name: '',
    locked: 0,
    auto_lock: 0,
    auto_lock_time: 60,
    multiuser: 0,
    max_charging_current: 0,
    energyCost: {
      value: 0,
      inheritedGroupId: null
    },
    currency: {
      id: 0,
      name: '',
      symbol: '',
      code: 'USD'
    },
    charger_load_type: '',
    country: {
      id: 0,
      code: '',
      iso2: 'US',
      name: '',
      phone_code: ''
    },
    state: {
      label: '',
      name: '',
      iso2: ''
    },
    part_number: '',
    software: {
      updateAvailable: false,
      currentVersion: '',
      latestVersion: '',
      fileName: ''
    },
    operation_mode: '',
    ocpp_ready: null,
    mid_enabled: 0,
    mid_status: 0,
    group_id: 0,
    user_socket_locking: false,
    ocpp_connection_status: 0,
    status: 0,
    power_sharing_config: 0,
    error: false,
    remote_action: 0,
    contract_charging_available: false,
    timezone: '',
    home_sharing: false,
    ecosmart: {
      enabled: false
    }
  }
}

const DEFAULT_CHARGER_ENERGY = {
  chargingPower: 0,
  chargingEnergy: 0,
  chargingTime: 0,
  chargingCost: 0
}

interface State {
  currentCharger: DefaultCharger
  checkedChargers: Array<Charger.Charger | Charger.ChargerData>
  chargers: any
  currentChargerEnergy: Charger.ChargerEnergy
}

const initialState = (): State => ({
  currentCharger: DEFAULT_CURRENT_CHARGER,
  checkedChargers: [],
  chargers: [],
  currentChargerEnergy: DEFAULT_CHARGER_ENERGY
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<State>) {
    return objects.getProperty(state, path)
  },

  getCurrentCharger: computed(() => state.currentCharger),

  getCheckedChargers: computed(() => state.checkedChargers),

  getChargers: computed(() => {
    type ChargerWithInfo = Charger.Charger & {
      groupId: number
      groupName: string
    }

    const groupId = groupState.groupRelatedToOrganization?.id

    return groupState.locations.reduce<ChargerWithInfo[]>((chargers, group) => {
      if (group.id === groupId || group.parent === groupId) {
        chargers = [...chargers, ...group.chargers.map(charger => ({
          ...charger,
          groupId: group.id,
          groupName: group.name
        }))]
      }
      return chargers
    }, [])
  }),

  currentChargerEnergy: computed(() => state.currentChargerEnergy)
}

const setters = {
  setCheckCharger (charger: Charger.Charger | Charger.ChargerData, checked: boolean) {
    state.checkedChargers = state.checkedChargers.filter(checkedCharger => checkedCharger.id !== charger.id)
    checked && state.checkedChargers.push(charger)
  },

  setCheckChargersGroup ({ status, chargers }:
  { status: boolean, chargers: Array<Charger.Charger | Charger.ChargerData> }) {
    if (status) {
      chargers.map(charger => state.checkedChargers
        .every(({ id }) => id !== charger.id) && state.checkedChargers.push(charger))
    } else {
      chargers.map(charger => (state.checkedChargers.find((checkedCharger, index) =>
        (charger.id === checkedCharger.id) && state.checkedChargers.splice(index, 1)
      )))
    }
  },

  resetCheckedChargers () {
    state.checkedChargers = []
  },

  setInitialState () {
    state = reactive(initialState())
  },

  setCurrentCharger ({ charger, chargerConfig }: ApiCharger) {
    state.currentCharger = {
      ...state.currentCharger,
      ...charger.data
    }
    state.currentCharger.config = chargerConfig
  },

  resetCurrentCharger () {
    state.currentCharger = DEFAULT_CURRENT_CHARGER
    state.currentChargerEnergy = DEFAULT_CHARGER_ENERGY
  },

  set<T> (path: Paths<State>, value: T) {
    objects.setProperty(state, path, value)
  }
}

export default reactive({
  ...getters,
  ...setters
})
