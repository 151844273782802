<template>
  <shared-header class="location-header">
    <template #title>
      {{ i18n.t('mywb.common.locations') }}
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canSeeLocations"
        id="add-location"
        icon="add"
        data-test-id="addLocationBtn"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.charger.add-location') : ''"
        type="primary"
        @click="emit('on-add-group')"
      />
    </template>

    <template #subheader />

    <template #filters>
      <locations-filters
        :locations="props.locations"
      />
    </template>

    <template #secondary-actions>
      <div class="view-selector g-8">
        <p
          v-t="'mywb.locations.show-map'"
          class="is-size-300"
          @click="compute.showMap = !compute.showMap"
        />
        <wb-switch
          v-model="compute.showMap"
        />
      </div>
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import LocationsFilters from '@/components/filters/LocationsFilters.vue'
import type { Locations } from '@/types'

const i18n = useI18n()
const mq = useMq()

interface Events {
  (e: 'on-add-group'): void
  (e :'update:showMap', show: boolean): void
}

const emit = defineEmits<Events>()

interface PropsType {
  showMap: boolean
  locations: Locations.Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  showMap: true,
  locations: () => []
})

const compute = reactive({
  showMap: computed({
    get () {
      return props.showMap
    },
    set (value) {
      localStorage.setItem('wb-show-locations-map', value.toString())
      emit('update:showMap', value)
    }
  })
})
</script>

<style lang="postcss" scoped>
.view-selector {
  display: flex;
  cursor: pointer;
}
</style>
