import ENV from '@/engine/env/web.env'
import axios from 'axios'

export function useTimezonesGoogleMaps () {
  interface Data {
    googleUrl: string
    query: string
  }
  const data: Data = {
    googleUrl: 'https://maps.googleapis.com/maps/api/timezone/json',
    query: `?location={lat},{lng}&timestamp={timestamp}&key=${ENV.google.maps}`
  }

  const methods = {
    async getTimezoneId (lat: number, lng: number, date?: Date) {
      if (!lat || !lng) return null
      date = date ?? new Date()
      const formattedQuery = `${data.query}`
        .replace('{lat}', lat.toString())
        .replace('{lng}', lng.toString())
        .replace('{timestamp}', (Math.round(date.getTime() / 1000).toString()))
      const formedUrl = `${data.googleUrl}${formattedQuery}`
      try {
        return (await axios.get(formedUrl)).data.timeZoneId
      } catch (error) {
        return null
      }
    }
  }

  return {
    getTimezoneId: methods.getTimezoneId
  }
}
