<template>
  <div>
    <p v-if="permissions.canSeeLocations" class="is-size-400 mb-24">
      <i18n-t keypath="mywb.common.select-existing-location-or-add-new-one" tag="span">
        <template #element>
          <span
            class="is-primary-link"
            @click="methods.handleAddLocation"
          >
            {{ i18n.t('mywb.common.add-new-location') }}
          </span>
        </template>
      </i18n-t>
    </p>
    <wb-select
      v-model="compute.groupUid"
      option-label="name"
      uid="_group"
      :options="data.locations"
      :reduce="location => location.group_uid"
      data-test-id="selectLocation"
      placeholder="select location"
      :loading="data.loading"
      :disabled="props.loading || data.loading || props.disabled"
      :error="props.error"
      placeholder-icon="location"
      @change="emit('on-update')"
    >
      <template #selected-option="{ option }">
        <span
          class="wb-icons mr-12"
        > location</span>
        {{ i18n.t(option.name) }}
      </template>
    </wb-select>

    <location-create-modal
      v-if="data.isModalCreateLocationOpen"
      data-test-id="chargersGroupEditModal"
      @on-close="data.isModalCreateLocationOpen = false"
      @on-edit-finish="methods.handleCloseEditLocation"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import state from '@/state'
import api from '@/api'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import LocationCreateModal from '@/components/modals/LocationCreateModal.vue'
import type { Locations } from '@/types'

const i18n = useI18n()

interface Props {
  modelValue?: string,
  loading?: boolean
  disabled?: boolean
  error?: string
  filterGroup?: string
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', value?: string): void
  (e: 'on-update'): void
}
const emit = defineEmits<Events>()

interface Data {
  loading: boolean
  isModalCreateLocationOpen: boolean
  locations: Locations.Location[]
}

const data = reactive<Data>({
  loading: false,
  isModalCreateLocationOpen: false,
  locations: []
})

const compute = reactive({
  groupUid: computed({
    get () {
      return !data.loading ? props.modelValue : i18n.t('mywb.common.loading')
    },
    set (groupUid) {
      emit('update:modelValue', groupUid)
    }
  })
})

const methods = {
  handleAddLocation () {
    data.isModalCreateLocationOpen = true
  },

  async handleCloseEditLocation () {
    data.isModalCreateLocationOpen = false
    await methods.getLocations()
  },

  async getLocations () {
    data.loading = true
    data.locations = (await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid })).data
    data.loading = false
  }
}

methods.getLocations()
</script>

<style lang="postcss" scoped>
:deep(.wbSelect .v-select .vs__search) {
  padding-left: 34px;
}

.is-primary-link {
  color: var(--primary-500);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
