<template>
  <div>
    <div class="is-size-1000 mb-24">
      {{ i18n.t('mywb.common.where-are-the-chargers-installed') }}
    </div>

    <div class="grid g-24 mt-24">
      <wb-select
        v-model="compute.organizationSelected"
        :searchable="false"
        :label="i18n.t('mywb.common.organization')"
        :options="compute.organizationsParsed"
        data-test-id="organizationSelect"
        class="mb-24"
      >
        <template #selected-option="{ option }">
          <div>
            <p class="is-size-400 is-font-weight-500">
              {{ option.name }}
            </p>
          </div>
        </template>
        <template #option="{ option }">
          <div>
            <p class="is-size-300 has-text-grey-700 is-font-weight-500">
              {{ option.name }}
            </p>
          </div>
        </template>
      </wb-select>
    </div>

    <location-layout
      id="locationSelector"
      :ref="setRef('locationSelector')"
      v-model:groupId="data.groupId"
      :error="data.errors.groupId"
      :loading="props.loading"
      @on-change="methods.handleChangeLocation"
      @on-create-location="emit('on-create-location')"
    />
    <div v-if="!permissions.canSeeLocations" class="grid g-24 mt-24">
      <country-and-state-select-form
        :ref="setRef('country')"
        v-model:countryCode="compute.countryCode"
        is-state-hidden
      />
    </div>
    <div v-if="!permissions.canSeeLocations" class="timezone mt-24">
      <timezone-select-form
        v-model:timezoneId="compute.timezoneId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import LocationLayout from '@/components/locations/LocationLayout.vue'
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'
import TimezoneSelectForm from '@/components/forms/TimezoneSelectForm.vue'

import state from '@/state'
import api from '@/api'
import { computed, reactive, onMounted, watch } from 'vue'
import { useForm, useField } from 'vee-validate'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { getRoleById, EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import { useTimezonesGoogleMaps } from '@/hooks/useTimezonesGoogleMaps'
import { permissions } from '@/engine/clients'
import type { Currency, Group } from '@/types'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()

interface Props {
  locationId?: number,
  country?: Currency.CountryCodes,
  loading?: boolean,
  timezoneId?: string
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:locationId', locationId: number): void,
  (e: 'update:country', countryCode?: string): void,
  (e: 'update:timezoneId', timezoneId?: string): void,
  (e: 'update:loading', loading: boolean): void,
  (e: 'on-create-location'): void
}
const emit = defineEmits<Events>()

const { errors, validate } = useForm({
  validationSchema: reactive({
    groupId: computed(() => {
      return !state.organizations.isPlan()([EnumPlans.BASIC]) && 'required'
    })
  })
})

const { getTimezoneId } = useTimezonesGoogleMaps()

const { value: groupId } = useField<number>('groupId')

interface Data {
  groupId: typeof groupId,
  errors: typeof errors,
  countryCode?: string
  timezoneId?: string
}
const data = reactive<Data>({
  groupId,
  errors
})

interface Organization {
  id: string,
  name: string,
  role: string,
  plan: string,
  groupId: number
}

const compute = reactive({
  countryCode: computed({
    get (): string | undefined {
      return props.country
    },
    set (country) {
      emit('update:country', country)
    }
  }),
  timezoneId: computed({
    get (): string | undefined {
      return props.timezoneId
    },
    set (timezoneId) {
      emit('update:timezoneId', timezoneId)
    }
  }),

  hasOperatorPlanInRootGroup: computed((): boolean => state.organizations.allOrganizations
    .find(organization => organization.profile_id === EnumRoles['super-admin'])?.product_id === EnumPlans.OPERATOR),

  organizationsParsed: computed((): Organization[] => {
    return state.organizations.allOrganizations
      .filter(organization => {
        return (
          (!compute.hasOperatorPlanInRootGroup
            ? getRoleById(organization.profile_id).id !== EnumRoles.operator
            : true) &&
          getRoleById(organization.profile_id).id !== EnumRoles.user
        )
      })
      .map(organization => ({
        id: organization.id,
        name: organization.name,
        role: i18n.t(`mywb.common.${getRoleById(organization.profile_id).name}`),
        plan: i18n.t(state.plans.getPlan(organization.product_id).label),
        groupId: organization.group_id
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }),

  organizationSelected: computed({
    get: (): Organization => compute.organizationsParsed
      .find(organization => organization.id === state.organizations.getCurrentOrganization.id) as Organization,

    set: organization => {
      state.organizations.setCurrentOrganization(organization.id)
      emit('update:loading', true)
      api.groups.getDataGroup(organization.groupId).then(info => {
        emit('update:loading', false)
        state.groups.set('groupRelatedToOrganization', info.result)
      })
    }
  })
})

watch(() => state.groups.groupRelatedToOrganizationWithChargers.id, (locations, oldLocations) => {
  if (JSON.stringify(locations) !== JSON.stringify(oldLocations)) {
    data.groupId = state.organizations.getCurrentOrganization.group_id
    methods.handleChangeLocation()
  }
}, { deep: true })

const methods = {
  async handleChangeLocation () {
    emit('update:locationId', data.groupId)
    if (!state.organizations.isPlan()([EnumPlans.BASIC])) {
      const country = await methods.getCountryFromLocation()
      const timezoneId = await methods.getTimezoneIdFromLocation()

      emit('update:country', country)
      emit('update:timezoneId', timezoneId ?? undefined)
    }
  },

  async getCountryFromLocation () {
    const group = state.groups.groupRelatedToOrganizationWithChargers.subgroups
      ?.find(location => location.id === data.groupId)
    if (!group) return
    const location = await methods.getLocation(group)
    if (!location) return
    return state.global.getCountries.find(country => country.iso2 === location.country)?.code || undefined
  },

  async getTimezoneIdFromLocation () {
    const group = state.groups.groupRelatedToOrganizationWithChargers.subgroups
      ?.find(location => location.id === data.groupId)
    if (!group) return
    const location = await methods.getLocation(group)
    if (!location) return
    const timezone = await getTimezoneId(location.latitude, location.longitude)
    if (!timezone) return
    return timezone
  },

  async getLocation (group: Group.GroupChargersTree) {
    const filters = [{
      field: 'group_uid',
      operator: 'eq',
      value: group.uid
    }]

    const locations = await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid, filters })
    return locations.data[0]
  },

  async isValidateForm () {
    emit('update:loading', true)
    const { valid } = await validate()
    const validCountry = state.organizations.isPlan()([EnumPlans.BASIC])
      ? await refs.country.isValidCountry()
      : true

    emit('update:loading', false)
    return valid && validCountry
  }
}

onMounted(() => {
  data.groupId = props.locationId || state.organizations.getCurrentOrganization.group_id
  methods.handleChangeLocation()
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 520px auto;
  }
}

.timezone {
  @media (--tablet) {
    max-width: 520px;
  }
}

.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  pointer-events: none;
}
</style>
