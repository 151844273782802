import state from '@/state'
import api from '@/api'

import {
  addPendingRequest,
  getIsRefreshing,
  getPendingRequests,
  setIsRefreshing,
  clearPendingRequest
} from './refreshRequests'
import type { AxiosError, AxiosInstance } from 'axios'
import type { ApiErrorResponse } from '@/types/api/clientApi.api.types'

export default async function handleUnauthenticated (client: AxiosInstance, error: AxiosError<ApiErrorResponse>) {
  const requestWithNewToken = new Promise(resolve => {
    addPendingRequest(() => {
      const { token } = state.global.getAuth
      const config = error.config

      if (config?.headers) {
        const headers = JSON.parse(JSON.stringify(config.headers))

        config.headers = {
          ...headers,
          Authorization: `Bearer ${token}`
        }

        return resolve(client.request(config))
      }
    })
  })

  if (!getIsRefreshing()) {
    setIsRefreshing(true)

    const { refresh_token } = state.global.getAuth

    const { data } = await api.auth.refreshToken({ refreshToken: refresh_token })
    state.global.setAuth(data.attributes)
    setIsRefreshing(false)
    await Promise.all(getPendingRequests().map(request => request()))
    clearPendingRequest()
  }

  return await requestWithNewToken
}
