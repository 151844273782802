<template>
  <wb-popover menu-position="bottom-end" :distance-offset="16">
    <template #activator>
      <wb-button
        data-test-id="assignRatesChargerButton"
        icon="card"
        type="white"
        outlined
        :label="mq.current !== 'mobile' ? i18n.t('mywb.charger.payments-settings') : ''"
        size="small"
        :disabled="props.disabled"
      />
    </template>

    <template #container>
      <wb-button
        data-test-id="assignRatesChargerButton"
        icon="add"
        type="white"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.charger.assign-new-rates')"
        @mousedown="emit('assign-rates')"
      />

      <wb-button
        data-test-id="assignRatesChargerButton"
        icon="delete"
        type="white"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.charger.reset-current-rates')"
        @mousedown="emit('unassign-rates')"
      />
    </template>
  </wb-popover>
</template>

<script setup lang="ts">
import { useMq } from 'vue3-mq'
import { useI18n } from '@/hooks/useI18n.hook'

const mq = useMq()
const i18n = useI18n()

interface Props {
  disabled?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'assign-rates'): void,
  (e: 'unassign-rates'): void
}
const emit = defineEmits<Events>()
</script>
