import i18n from '@/engine/lang'
import state from '@/state'
import type { Charger, Session } from '@/types'
import type { CountryCodes } from '@/types/data/currency.data.types'

export enum MID_STATUS {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ERROR = 'error'
}

export enum MID_ERROR_COMMUNICATION {
  STATUS_1 = 187,
  STATUS_2 = 188
}

const midStatuses = [
  {
    code: MID_STATUS.DISABLED,
    label: 'mywb.common.disabled',
    ids: [0]
  },
  {
    code: MID_STATUS.ENABLED,
    label: 'mywb.common.enabled',
    ids: [4]
  },
  {
    code: MID_STATUS.ERROR,
    label: 'mywb.common.error',
    ids: [1, 2, 3]
  }
]

const parseMidStatus = (charger: Charger.AnyTypeOfCharger) => {
  const disabledStatus = midStatuses.find(status => status.code === MID_STATUS.DISABLED)
  if (!disabledStatus) throw new TypeError('disabled status should not be undefined')

  if (!charger?.midEnabled) return disabledStatus
  return midStatuses.find(status => status.ids.includes(charger?.midStatus ?? 0)) ?? disabledStatus
}

export const getMidSession = (session: Session.Session) => {
  if (!session.mid_energy) return ''
  else if (session.mid_energy > 0 && session.energy > 0) return MID_STATUS.ERROR
  else if (session.mid_energy > 0 && session.energy === 0) return MID_STATUS.ENABLED
}

export const hasMidErrorCommunication = (statusId: number) => {
  return (statusId === MID_ERROR_COMMUNICATION.STATUS_1) || (statusId === MID_ERROR_COMMUNICATION.STATUS_2)
}

export const canAddEnergyRate = (charger: Charger.AnyTypeOfCharger, iso2?: CountryCodes) => {
  if (!iso2) return false
  return !state.config.countriesWithMidNeeded.includes(iso2) || parseMidStatus(charger).code === MID_STATUS.ENABLED
}

export const getMidStatus = (charger: Charger.ChargerData | Charger.Charger) => {
  const status = parseMidStatus(charger)

  if (status) {
    return {
      ...status,
      label: i18n.global.t(status.label)
    }
  }
}
