<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500">
        <span
          v-t="'mywb.auth.login-signup'"
          class="is-font-weight-700"
        />
        <span>&nbsp;</span>
        <span v-t="'mywb.auth.login-signup-explain'" />
      </p>
    </template>

    <wb-form class="auth-form">
      <wb-input
        ref="input"
        v-model="username"
        :label="i18n.t('mywb.common.email')"
        placeholder="example@email.com"
        :error="errors.username"
        data-test-id="emailInput"
        :disabled="data.loading"
        v-on="validationListenersUsername"
        @on-enter="methods.continueLogin"
      />

      <wb-button
        size="large"
        :label="i18n.t('mywb.common.continue')"
        data-test-id="emailCtaButton"
        :disabled="data.loading"
        @click.prevent="methods.continueLogin"
      />

      <div
        class="is-flex"
        :class="permissions.hasSocialSingin ? 'justify-between': 'center'"
      >
        <wb-link
          v-if="permissions.hasSocialSingin"
          data-test-id="backButton"
          class="is-size-400"
          type="text"
          @click="emit('back')"
        >
          <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
          <span>{{ i18n.t('mywb.common.back') }}</span>
        </wb-link>

        <wb-link
          class="is-size-400"
          data-test-id="forgotPasswordLink"
          @click="methods.handleForgotPassword"
        >
          {{ i18n.t('mywb.auth.forgotten-password') }}
        </wb-link>
      </div>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { ref, reactive } from 'vue'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import { useField, useWbInputAutofocus } from '@/hooks'
import { trackAction, trackScreen } from '@/engine/metrics/metricsManager'
import { useNotify } from '@wallbox/toolkit-ui'
import { getServerError } from '@/utilities/errorMessages'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import api from '@/api'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const i18n = useI18n()
const notify = useNotify()
const router = useRouter()

interface Events {
  (e: 'continue-password', name: string): void,
  (e: 'back'): void,
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const { errors, handleSubmit } = useForm({
  validationSchema: {
    username: 'required|email'
  }
})

const input = ref()
useWbInputAutofocus(input)

const { value: username, validationListeners: validationListenersUsername } = useField<string>('username')

const methods = {
  continueLogin: handleSubmit(async () => {
    data.loading = true
    try {
      const data = await api.auth.isUserEmailRegistered(username.value)
      const status = data.data.attributes.status
      if (status === 'confirmed') {
        emit('continue-password', username.value)
      } else if (status === 'not_registered') {
        trackScreen('register', { type: 'email' })
        router.push({ name: 'register', query: { email: username.value } })
      } else if (status === 'not_confirmed') {
        notify.error(getServerError({ code: status }))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
      } else {
        throw error
      }
    } finally {
      data.loading = false
    }
  }),

  handleForgotPassword () {
    trackAction('forgot-password')
    router.push({ name: 'forgot-password' })
  }
}
</script>

<style lang="postcss" scoped>
.justify-between,
.center {
  width: 100%;
}

.justify-between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}
</style>
