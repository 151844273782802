import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'

interface Image {
  name: string
  extension: string
  bytes: string
  size: number
}

interface Access {
  user: number
  type: string
  group: number
  authentication: string
}

export default {
  async updateUserImage (image: Image): Promise<void> {
    return await axios.put(endpoints.v1.users_rekognition_masterImage, image)
  },

  async updateRfidAccess (access: Access): Promise<void> {
    return await axios.post(endpoints.v3.access, access)
  },

  async deleteRfidAccess (access: Omit<Access, 'authentication'>): Promise<void> {
    return await axios.delete(endpoints.v3.access, { data: access })
  }
}
