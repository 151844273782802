import global from '@/state/global.state'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { addRequestCount } from './errorResponse/refreshRequests'

export const requestInterceptor = (config: AxiosRequestConfig) => {
  addRequestCount(1)
  const { token } = global.getAuth

  const isRefresh = config.url === '/users/refresh-token'

  if (token && !isRefresh && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

export default (client: AxiosInstance) => {
  client.interceptors.request.use(requestInterceptor)
}
