<template>
  <wb-cards-loader :loading="data.initialLoading">
    <container-component>
      <content-component>
        <shared-header>
          <template #title>
            {{ i18n.t('mywb.support.title') }}
          </template>
        </shared-header>

        <div class="grid desktop:g-24 widescreen:g-128">
          <wb-form>
            <wb-select
              v-model="data.model.country"
              uid="_country"
              :label="i18n.t('mywb.common.country')"
              :options="compute.availibleCountries"
              option-label="name"
              :placeholder="i18n.t('mywb.support.country-placeholder')"
              :reduce="country => country"
              :error="errors.country"
              data-test-id="countrySelect"
            />
            <wb-select
              v-model="data.model.chargers"
              :label="i18n.t('mywb.support.related-chargers')"
              uid="_chargers"
              :options="compute.chargers"
              option-label="name"
              :reduce="charger => charger.id"
              multiple
              :clearable="true"
              :close-on-select="false"
              :placeholder="i18n.t('mywb.support.charger-placeholder')"
              data-test-id="chargerSelect"
            />
            <wb-select
              v-model="data.model.topicId"
              :label="i18n.t('mywb.support.issue-topic')"
              uid="_topics"
              :options="data.issues"
              option-label="description"
              :reduce="issue => issue.id"
              :error="errors.topicId"
              :placeholder="i18n.t('mywb.support.issue-topic-placeholder')"
              data-test-id="issueTopicSelect"
            />
            <wb-input
              v-model="data.model.description"
              is-textarea
              :label="i18n.t('mywb.common.description')"
              :error="errors.description"
              data-test-id="descriptionTextArea"
            />
            <wb-file-uploader
              v-model="data.model.images"
              v-model:errors="data.filesErrors"
              class="file-uploader"
              :label="i18n.t('mywb.support.attached-files')"
              multiple
              accept="image/png, image/gif, image/jpeg"
              :title-label="i18n.t('mywb.common.browse-or-drag-and-drop-files')"
              :description-label="i18n.t('mywb.common.only-accept-image-file')"
              :error="errors.images || data.imagesFilesError"
              data-test-id="fileUploader"
            />
            <wb-button
              :loading="data.loading"
              :size="mq.current === 'touch' ? 'block' : 'normal'"
              :label="i18n.t('mywb.common.send')"
              data-test-id="sendButton"
              @click="methods.validateIssue"
            />
          </wb-form>
          <div class="support-info-wrapper">
            <div
              v-if="data.model.country"
              class="support-info p-24"
            >
              <p
                v-t="{ path: 'mywb.support.customer-service', args: [data.model.country.name] }"
                class="is-size-500 is-font-weight-500 mb-8"
                data-test-id="countryWrapper"
              />

              <p
                v-t="{ path: 'mywb.support.phone-service', args: [data.model.country.supportPhone] }"
                class="is-size-300"
              />
              <p
                v-if="data.model.country.from && data.model.country.to"
                v-t="compute.fromToLabel"
                class="is-size-300"
              />

              <p class="is-size-300">
                {{ compute.timeService }}
              </p>
            </div>
          </div>
        </div>

        <support-request-sent-modal
          v-if="data.supportModalSuccess"
          @on-close="data.supportModalSuccess = false"
        />
      </content-component>
    </container-component>
  </wb-cards-loader>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import ContentComponent from '@/components/ContentComponent.vue'
import SupportRequestSentModal from '@/components/modals/SupportRequestSentModal.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'

import api from '@/api'
import state from '@/state'
import lang from '@/engine/lang'
import { useField, useForm } from 'vee-validate'
import { clientConfig } from '@/engine/clients'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useMq } from 'vue3-mq'
import type { Support, Currency, Charger } from '@/types'

const i18n = useI18n()
const mq = useMq()
const notify = useNotify()

const { errors, handleSubmit, resetForm } = useForm({
  validationSchema: {
    country: 'required',
    topicId: 'required',
    description: 'required',
    images: 'size:4000'
  },
  initialValues: {
    country: null,
    topicId: '',
    description: '',
    images: []
  }
})

const { value: country } = useField<Support.Support & Currency.Country>('country')
const { value: topicId } = useField<string>('topicId')
const { value: description } = useField<string>('description')
const { value: images } = useField<File[]>('images')

interface Data {
  errors: typeof errors
  issues: Support.Topic[]
  loading:boolean
  supportModalSuccess: boolean
  initialLoading: boolean
  model: {
    country?: typeof country
    chargers: Charger.Charger[]
    topicId: typeof topicId
    description: typeof description
    images: typeof images
  },
  filesErrors: Array<{ code: string }>
  imagesFilesError: string
}

const data = reactive<Data>({
  errors,
  issues: [],
  loading: false,
  supportModalSuccess: false,
  initialLoading: true,
  model: {
    country,
    chargers: [],
    topicId,
    description,
    images
  },
  filesErrors: [],
  imagesFilesError: ''
})

watch(() => data.filesErrors, (error, oldError) => {
  if (JSON.stringify(error[0]) !== JSON.stringify(oldError[0])) {
    if (error[0]?.code === 'type-format-error') data.imagesFilesError = i18n.t('mywb.errors.files-not-accepted-format')
  }
}, { deep: true })

const compute = reactive({
  chargers: computed(() => ([
    ...(state.groups.groupRelatedToOrganizationWithChargers?.chargers ?? []),
    ...(state.groups.groupRelatedToOrganizationWithChargers?.subgroups ?? []).map(({ chargers }) => chargers).flat()
  ])),

  availibleCountries: computed(() => {
    return state.global.getCountries
      .reduce<Array<Support.Support & Currency.Country>>((parsedCountry, country) => {
        const supportCountry = clientConfig.supportInfo.find(({ countryIso2 }) => country.iso2 === countryIso2)
        return supportCountry ? [...parsedCountry, { ...country, ...supportCountry }] : parsedCountry
      }, [])
      .sort((item1, item2) => item1.name.localeCompare(item2.name))
  }),

  fromToLabel: computed(() => ({
    path: 'mywb.support.from-to',
    args: [data.model.country?.from, `${data.model.country?.to} ${data.model.country?.timeZone}`]
  })),

  timeService: computed(() => {
    if (data.model.country?.timeService === 'working-days') {
      return `${i18n.t('mywb.common.monday')} - ${i18n.t('mywb.common.friday')}`
    }
    return data.model.country?.timeService
  })
})

onMounted(() => {
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://widget-hosts.mavenoid.com/custom-embedding-scripts/wallbox.js'
  document.head.appendChild(script)

  onBeforeUnmount(() => {
    document.head.removeChild(script)
    document.querySelectorAll('[src="https://app.mavenoid.com/embedded/embedded.js"]').forEach(node => node.remove?.())
    document.getElementsByTagName('mavenoid-assistant')[0]?.remove?.()
  })
})

async function created () {
  data.initialLoading = true

  const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
  state.groups.set('groups', result.groups)

  api.groups.getAllDataOrganizations().then(({ result: { groups } = {} }) => state.groups.set('groups', groups))

  await methods.getTopics()

  data.model.country = methods.getUserLoggedCountry()
  data.initialLoading = false
}

const methods = {
  validateIssue: handleSubmit((): Promise<void> => methods.sendIssue()),

  getUserLoggedCountry () {
    return compute.availibleCountries.find(country => country.code === state.user.userLogged.country_code)
  },

  async getTopics (): Promise<void> {
    const { result } = await api.support.getTopics(lang.__rootLanguage)
    data.issues = result
  },

  async sendIssue (): Promise<void> {
    data.loading = true

    const payload = {
      email: state.user.userLogged.email,
      topicId: data.model.topicId,
      images: data.model.images.map((image: any) => ({
        name: image.name,
        bytes: /base64,(.+)/.exec(image.content)?.[1] ?? '0',
        size: `${image.size}`,
        extension: image.name.split('.').pop()
      }))
        .slice(0, 6),
      country: data.model.country?.id ?? 0,
      language: i18n.locale.value,
      description: `${data.model.description} chargers: ${data.model.chargers}`,
      platform: 'portal'
    }

    try {
      await api.support.postReport(payload)
      trackDataEvent('submit-support')
      data.supportModalSuccess = true
      trackDataScreen('support-success')
      methods.resetIssue()
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  },

  resetIssue (): void {
    resetForm()
    data.model.country = methods.getUserLoggedCountry()
    data.model.chargers = []
  }
}

created()
</script>

<style lang="postcss" scoped>
.support-info {
  border-radius: 0.4rem;
  border: solid 1px var(--grey-300);
}

.grid {
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr;

  @media (--desktop) {
    grid-template-columns: minmax(0, 2fr) 1fr;
  }

  @media (--widescreen) {
    grid-template-columns: minmax(0, 3fr) 2fr 1fr;
  }
}

.file-uploader {
  width: 100%;
}

.support-info-wrapper {
  grid-row-start: 1;
  margin-bottom: 24px;

  @media (--desktop) {
    grid-row-start: initial;
  }
}
</style>
