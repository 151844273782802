import i18n from '@/engine/lang'
import type { Charger } from '@/types'
import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'

export const connectivitySignal = (charger: Charger.ChargerData | Charger.Charger) => {
  const chargerStatus = getChargerStatus(charger)?.code
  return !chargerStatus || (chargerStatus === STATUSES.DISCONNECTED)
    ? i18n.global.t('mywb.common.disconnected')
    : i18n.global.t('mywb.common.connected')
}

const wifiSignal = (charger: Charger.ChargerData | Charger.Charger) => {
  const chargerStatus = getChargerStatus(charger)?.code

  if (!chargerStatus || chargerStatus === STATUSES.DISCONNECTED) return i18n.global.t('mywb.common.disconnected')
  const signal = charger.wifiSignal
  switch (true) {
    case (signal >= 80): return i18n.global.t('mywb.common.connected')
    case (signal >= 55): return i18n.global.t('mywb.common.high')
    case (signal >= 30): return i18n.global.t('mywb.common.normal')
    case (signal > 0): return i18n.global.t('mywb.common.low')
    default: return i18n.global.t('mywb.common.disconnected')
  }
}

export const connectivityByConnectionType = (charger: Charger.ChargerData | Charger.Charger) => {
  const connectionType = charger?.connectionType
  return connectionType === 'wifi' ? wifiSignal(charger) : connectivitySignal(charger)
}

export const connectivityTypeIcon = (charger: Charger.ChargerData | Charger.Charger) => {
  const connectionType = charger?.connectionType

  switch (connectionType) {
    case ('wifi'): return 'signal_wifi_4_bar'
    case ('mobile'): return 'signal_cellular_4_bar'
    case ('ethernet'): return 'ethernet'
    default: return 'bluetooth'
  }
}

export const connectivityTypeLabel = (charger: Charger.ChargerData | Charger.Charger) => {
  const connectionType = charger?.connectionType

  switch (connectionType) {
    case ('wifi'): return i18n.global.t('mywb.common.wifi')
    case ('mobile'): return i18n.global.t('mywb.common.mobile')
    case ('ethernet'): return i18n.global.t('mywb.common.ethernet')
    default: return i18n.global.t('mywb.common.bluetooth')
  }
}
