<template>
  <div class="rate-schedule-form">
    <h5
      v-if="props.label"
      class="is-size-300 is-font-weight-500 has-text-black"
    >
      {{ props.label }}
    </h5>
    <p
      v-if="props.label"
      class="is-size-200 has-text-grey-500"
    >
      {{ compute.readHours }}
    </p>

    <wb-button
      v-if="props.label"
      class="cta"
      type="white"
      data-test-id="activate-arrow"
      :icon="compute.activated ? 'expand_less': 'expand_more'"
      @click="compute.activated = !compute.activated"
    />

    <template v-if="compute.activated">
      <wb-date-picker
        v-model="compute.from"
        data-test-id="from-input"
        time-picker
        text-input
        :label="i18n.t('mywb.common.from')"
        :clearable="false"
      />

      <wb-date-picker
        v-model="compute.to"
        data-test-id="to-input"
        time-picker
        text-input
        :label="i18n.t('mywb.common.to')"
        :clearable="false"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'

const i18n = useI18n()

interface Props {
  label?: string,
  from?: number,
  to?: number,
  activated?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  from: 0,
  to: 23
})

interface Events {
  (e: 'update:from', from?: number): void,
  (e: 'update:to', to?: number): void,
  (e: 'update:activated', activated?: boolean): void
}

const emit = defineEmits<Events>()
const compute = reactive({
  from: computed({
    get () {
      return { hours: props.from, minutes: 0 }
    },

    set (value) {
      emit('update:from', value.hours)
    }
  }),

  to: computed({
    get () {
      return { hours: props.to, minutes: 59 }
    },

    set (value) {
      emit('update:to', value.hours)
    }
  }),

  activated: computed({
    get () {
      return props.activated
    },

    set (value) {
      emit('update:activated', value)
    }
  }),

  readHours: computed(() => {
    if ((!props.from && props.from !== 0) || !props.to) return ''

    const fromString = props.from.toString().padStart(2, '0')
    const toString = props.to.toString().padStart(2, '0')

    return `${fromString}:00 - ${toString}:00`
  })
})
</script>

<style lang="postcss">
.rate-schedule-form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 0 8px;
}

.cta {
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: end;
}
</style>
