<template>
  <div>
    <wb-form>
      <wb-input
        v-model="data.accessConfig.name"
        class="mb-24"
        name="name"
        type="text"
        data-test-id="userGroupNameInput"
        :label="i18n.t('mywb.common.access-group-name')"
        :placeholder="i18n.t('mywb.common.add-group-name')"
        :error="data.errors.name"
      />
    </wb-form>

    <p class="label">
      {{ i18n.t('mywb.common.select-chargers-to-access-group') }}
    </p>
    <locations-accordion
      v-model="data.accessConfig.chargers"
    />
  </div>
</template>

<script setup lang="ts">
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import { useField, useForm } from 'vee-validate'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import state from '@/state'
import { reactive, type Ref } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { AccessConfig } from '@/types'

const i18n = useI18n()

interface Props {
  groupId: number
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:accessConfigId', AccessConfigId: number | undefined): void
}
const emit = defineEmits<Events>()

const { errors, handleSubmit } = useForm({
  validationSchema: {
    name: 'required'
  }
})

const { value: name } = useField<string>('name')

interface Data {
  errors: typeof errors,
  accessConfig: Omit<AccessConfig.AccessConfig, 'name'> & { name: Ref<string> },
  isValid: boolean
}

const data = reactive<Data>({
  errors,
  accessConfig: {
    id: -1,
    owner: -1,
    group: -1,
    createdByUser: true,
    chargers: [],
    name
  },
  isValid: false
})

const methods = {
  updateAccessConfigId (): void {
    const accessConfigs = state.user.getGroupsByAccessConfig
      .find(group => group.id === props.groupId)?.accessConfigs

    emit('update:accessConfigId', accessConfigs?.at(-1)?.id ?? undefined)
  },

  async createAccessConfigGroup (): Promise<void> {
    try {
      data.accessConfig.owner = state.user.userLogged.id
      data.accessConfig.group = props.groupId
      await api.accessConfig.createAccessConfig(data.accessConfig)
      state.user.set('usersGroup', await api.users.getUserGroups())
      methods.updateAccessConfigId()
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
      trackDataEvent('add-group')
      data.isValid = true
    } catch (error) {
      data.isValid = false
    }
  },

  validateConfigGroup: handleSubmit((): Promise<void> => methods.createAccessConfigGroup())
}

defineExpose({
  validateForm: async () => {
    await methods.validateConfigGroup()
    return data.isValid
  }
})

async function created () {
  const { result } = await api.groups.getAllDataOrganizations()
  state.groups.set('groups', result.groups)
  trackDataScreen('users-add-group')
}

created()
</script>

<style lang="postcss" scoped>
.label {
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  margin-bottom: 6px;
}
</style>
