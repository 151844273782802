import state from '@/state'
import type { EnumRoles } from '@/utilities/user-roles'
import type { EnumPlans } from '@/utilities/plans'

export const or = (...items: Array<boolean | (() => boolean)>) => () => {
  return items.some(item => {
    if (typeof item === 'boolean') {
      return item
    }
    return item()
  })
}

export const and = (...items: Array<boolean | (() => boolean)>) => () => {
  return items.every(item => {
    if (typeof item === 'boolean') {
      return item
    }
    return item()
  })
}

export const hasPlan = (...plans: EnumPlans[]) => () => {
  const plan = state.organizations.getCurrentOrganization.product_id
  return plans.includes(plan)
}

export const hasRole = (...roles: EnumRoles[]) => () => {
  const role = state.organizations.getCurrentOrganization.profile_id

  return roles.includes(role)
}

export const hasFreeProduct = (check: boolean) => () => {
  return state.groups.hasFreeProduct === check
}

export const isOwner = () => () => {
  return state.organizations.getCurrentOrganization.owner_id === state.user.userLogged.id
}
