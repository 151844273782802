import type { Charger } from '@/types'
import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'
import { findChargerType } from '@/utilities/charger/chargerTypes'
import { getChargerVersion } from '@/utilities/charger/chargerSoftware'
import { compareVersionGreaterOrEqual } from '@/utilities/compare'

export enum REMOTE_ACTIONS {
  UPDATE = 5,
  RESTART = 3,
  CHARGE = 1,
  PAUSE = 2
}

export enum LOCK_STATUS {
  LOCK = 1,
  UNLOCK = 0
}

export const chargerRemoteActions = [
  {
    id: REMOTE_ACTIONS.CHARGE
  },
  {
    id: REMOTE_ACTIONS.PAUSE
  },
  {
    id: REMOTE_ACTIONS.RESTART,
    label: 'mywb.charger.status.restarting'
  },
  {
    id: REMOTE_ACTIONS.UPDATE,
    label: 'mywb.charger.status.updating'
  }
]

export const isChargerRemoteAction = (charger: Charger.ChargerConfig, actionId: REMOTE_ACTIONS) => {
  const remoteAction = charger.remote_action

  const action = chargerRemoteActions.find(action => action.id === actionId)
  const hasAction = action?.id === remoteAction

  return !!remoteAction && hasAction
}

export const hasActionsSupport = (charger: Charger.AnyTypeOfCharger) => {
  const chargerType = findChargerType(charger)
  const minVersion = chargerType?.actions?.minVersion
  return !minVersion ? false : compareVersionGreaterOrEqual(getChargerVersion(charger), minVersion)
}

export const hasActionsAvailable = (charger: Charger.Charger) => {
  const remoteActionRunning = charger.remoteAction === REMOTE_ACTIONS.UPDATE ||
    charger.remoteAction === REMOTE_ACTIONS.RESTART
  const chargerStatus = getChargerStatus(charger)?.code
  const forbiddenStatus =
    (chargerStatus === STATUSES.DISCONNECTED) ||
    (chargerStatus === STATUSES.CHARGING) ||
    (chargerStatus === STATUSES.DISCHARGING)
  return !remoteActionRunning && !forbiddenStatus && hasActionsSupport(charger)
}
