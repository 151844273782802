<template>
  <wb-table :options="data.options">
    <wb-table-row
      v-for="(charger, index) in props.accesibleChargers"
      :key="index"
    >
      <wb-table-row-item>
        {{ charger.name }}
      </wb-table-row-item>
      <wb-table-row-item>
        {{ charger.id }}
      </wb-table-row-item>
      <wb-table-row-item v-if="props.action === REMOTE_ACTIONS.UPDATE" class="old-version">
        {{ charger.software.currentVersion }}
      </wb-table-row-item>

      <wb-table-row-item v-if="props.action === REMOTE_ACTIONS.UPDATE" class="new-version">
        {{ charger.software.latestVersion }}
      </wb-table-row-item>
      <wb-table-row-item>
        <wb-button
          type="white"
          outlined
          icon="delete"
          :data-test-id="`remove-charger-${charger.id}`"
          @click="methods.deleteCharger(charger)"
        />
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { ExcludesNullish, TableProps } from '@wallbox/toolkit-ui'

const i18n = useI18n()

const emit = defineEmits(['on-delete-charger'])

interface ChargerAlike {
  name: string
  id: number,
  software: {
    currentVersion: string
    latestVersion: string
  }
}
interface Props {
  action: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
  accesibleChargers: ChargerAlike[]
}
const props = defineProps<Props>()

const data = reactive({
  options: {
    columns: [
      { name: i18n.t('mywb.common.charger'), align: 'left', minWidth: '100px', width: 'auto' },
      {
        name: i18n.t('mywb.charger.serial-number'),
        align: 'left',
        width: '80px',
        lineBehaviour: 'multiline'
      },
      props.action === REMOTE_ACTIONS.UPDATE && {
        name: i18n.t('mywb.common.current-version'),
        align: 'left',
        width: '80px',
        lineBehaviour: 'multiline'
      },
      props.action === REMOTE_ACTIONS.UPDATE && {
        name: i18n.t('mywb.common.new-update'),
        align: 'left',
        width: '80px',
        lineBehaviour: 'multiline'
      },
      { name: '', align: 'left', width: '70px' }
    ].filter(Boolean as unknown as ExcludesNullish) as TableProps['options']['columns'],
    empty: {
      title: i18n.t('mywb.charger.no-chargers-selected'),
      icon: ''
    }
  }
})

const methods = {
  deleteCharger (charger: ChargerAlike) {
    emit('on-delete-charger', charger)
  }
}
</script>
