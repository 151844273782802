<template>
  <div class="header">
    <breadcrumb-header
      v-if="!props.hideBreadcrumb"
      class="breadcrumb"
    />

    <div class="is-grid">
      <slot v-if="$slots['custom-title']" name="custom-title" />
      <div v-else-if="$slots['title']">
        <h2
          class="is-size-700 is-font-weight-500"
          :class="{'is-size-900': mq.current !== 'mobile'}"
          data-test-id="header-title"
        >
          <slot name="title" />
        </h2>
      </div>

      <div v-if="$slots['actions']" class="actions g-8">
        <slot name="actions" />
      </div>
    </div>

    <slot v-if="$slots['tabs']" name="tabs" />

    <div class="is-flex filters-wrapper g-16">
      <div v-if="$slots.filters" class="filters g-12">
        <slot name="filters" />
      </div>

      <div v-if="$slots['secondary-actions']" class="actions pl-8">
        <slot name="secondary-actions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BreadcrumbHeader from '@/components/headers/BreadcrumbHeader.vue'
import { useMq } from 'vue3-mq'

const mq = useMq()

const props = defineProps({
  hideBreadcrumb: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="postcss" scoped>
.header {
  display: grid;
  background: var(--white);
  color: var(--black);
  padding: 12px 0;
  position: sticky;
  top: var(--header-touch-size);
  z-index: 110;

  @media (--tablet) {
    top: 0;
  }
}

.actions {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  position: sticky;
  right: 0;
  background: var(--white);
}

.filters {
  background: var(--white);
  padding: 7px 0;
  height: auto;
  display: flex;
  margin-left: 1px;

  @media (--tablet) {
    height: var(--filters-size);
  }
}

.filters-wrapper {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--grey-300) var(--white);

  &::-webkit-scrollbar {
    height: 5px;
    background-color: var(--white);
    border-left: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-300);
  }
}

.breadcrumb {
  height: 2.1rem;
}

.is-grid {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
}

:deep(.input_element) {
  width: auto !important;
  grid-template-rows: 38px !important;
  min-width: 150px !important;
}
</style>
