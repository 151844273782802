<template>
  <wb-card
    :headline="i18n.t('mywb.charger.ocpp-socket')"
    :subhead="i18n.t('mywb.charger.configure-ocpp')"
    alignment="center"
  >
    <template v-if="data.isActivated" #content>
      <wb-card filled>
        <template #content>
          <div class="is-grid">
            <p
              v-t="'mywb.common.id'"
              class="is-font-weight-500"
            />
            <p data-test-id="ocppChargePointIdentity">
              {{ data.ocpp.chargePointIdentity }}
            </p>
          </div>

          <div class="is-grid">
            <p
              v-t="'mywb.common.url'"
              class="is-font-weight-500"
            />
            <div
              class="url-copy-button"
              @click="methods.copy"
            >
              <span class="wb-icons icon">file_copy</span>
              <p class="text has-text-overflow">
                {{ data.ocpp.address }}
              </p>
            </div>
          </div>

          <div v-if="data.ocpp.password" class="is-grid">
            <p
              v-t="'mywb.common.password'"
              class="is-font-weight-500"
            />

            <p>{{ data.ocpp.password }}</p>
          </div>
        </template>

        <template #actions>
          <wb-button
            icon="edit"
            data-test-id="charger-edit-ocpp-cta"
            :label="i18n.t('mywb.common.edit')"
            type="white"
            outlined
            :disabled="compute.cannotOperate"
            @click="data.showEditOcppModal = true"
          />
        </template>
      </wb-card>
    </template>

    <template #place-upper-right>
      <wb-switch
        id="type"
        v-model="data.isActivated"
        data-test-id="switchOcpp"
        name="type"
        @input="methods.switchOcpp"
      />
    </template>

    <template #modal>
      <charger-edit-ocpp-modal
        v-if="data.showEditOcppModal"
        :ocpp-settings="data.ocpp"
        :loading="data.savingLoading"
        @on-close="methods.handleClose"
        @on-save="methods.updateOcpp"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerEditOcppModal from '@/components/modals/ChargerEditOcppModal.vue'
import { reactive, computed, onMounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'

import api from '@/api'
import state from '@/state'
import type { Ocpp } from '@/types'

const notify = useNotify()
const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  showEditOcppModal: boolean
  savingLoading: boolean
  ocpp: Partial<Ocpp.OcppSettings>
  isActivated: boolean
}

const data = reactive<Data>({
  showEditOcppModal: false,
  savingLoading: false,
  ocpp: {
    type: 'wallbox'
  },
  isActivated: false
})

const compute = reactive({
  cannotOperate: computed(() => {
    return props.loading || data.savingLoading
  })
})

const methods = {
  copy () {
    data.ocpp.chargePointIdentity && navigator.clipboard.writeText(data.ocpp.chargePointIdentity)
      .then(() => notify.success(i18n.t('mywb.common.copied')))
      .catch(() => notify.error(i18n.t('mywb.error.unexpected-error')))
  },

  async updateOcpp (ocppSettings: Partial<Ocpp.OcppSettings>) {
    data.savingLoading = true
    try {
      const ocppConfig = {
        chargerId: state.charger.getCurrentCharger.chargerData.id,
        settings: {
          address: ocppSettings.address ?? '',
          chargePointIdentity: ocppSettings.chargePointIdentity ?? '',
          password: ocppSettings.password ?? '',
          type: ocppSettings.type ?? 'wallbox'
        }
      }

      await api.ocpp.postChargerOcppSettings(ocppConfig)
      const charger = await api.chargers.getCharger(ocppConfig.chargerId)
      state.charger.setCurrentCharger(charger)
      methods.updateOcppSettings()
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.savingLoading = false
    data.showEditOcppModal = false
  },

  handleClose () {
    data.showEditOcppModal = false
    if (data.ocpp.type !== 'ocpp') data.isActivated = false
  },

  async switchOcpp (checked: boolean) {
    if (checked) {
      data.showEditOcppModal = true
    } else {
      data.ocpp.type = 'wallbox'
      methods.updateOcpp(data.ocpp)
    }
  },

  async updateOcppSettings () {
    const ocppSettings = await api.ocpp.getChargerOcppSettings(state.charger.getCurrentCharger.chargerData.id)
    if (ocppSettings) data.ocpp = ocppSettings
    data.isActivated = !!data.ocpp.type && data.ocpp.type !== 'wallbox'
  }
}

onMounted(async () => {
  methods.updateOcppSettings()
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 16px;
}

.url-copy-button {
  background: white;
  display: grid;
  grid-template-columns: min-content auto;
  padding: 16px;
  margin: 8px 0;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid var(--grey-200);

  &:hover {
    color: var(--primary-500);
    border: 1px solid var(--primary-500);
  }

  & .icon {
    font-size: 20px;
    margin-right: 12px;
  }

  & .text {
    word-break: break-all;
  }
}
</style>
