<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.create-access-group') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.create-access-group.description') }}
    </div>

    <access-config-creation-form
      :ref="setRef('access-config-creation')"
      v-model:accessConfigId="data.accessConfigId"
      :group-id="props.group.id"
    />
  </div>
</template>

<script setup lang="ts">
import AccessConfigCreationForm from '@/components/forms/AccessConfigCreationForm.vue'

import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import type { User } from '@/types'

const i18n = useI18n()
const { refs, setRef } = useTemplateRef()

interface Props {
  group: User.UsersGroupWithUserExpanded
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:accessConfigId', id?: number): void
  (e: 'update:chargersSelected', chargers: number[]): void

}

const emit = defineEmits<Events>()

interface Data {
  accessConfigId?: number
}
const data = reactive<Data>({})

const compute = reactive({
  getChargersFromAccessConfig: computed(() => {
    return props.group.accessConfigs
      .find(accessConfig => accessConfig.id === data.accessConfigId)?.chargers || []
  })
})

const methods = {
  async isValidateForm () {
    if (await refs['access-config-creation'].validateForm()) {
      emit('update:accessConfigId', data.accessConfigId)
      emit('update:chargersSelected', compute.getChargersFromAccessConfig)
      return true
    }
    return false
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
