<template>
  <wb-table-row
    class="grid-table-row"
    :data-test-id="`user-${props.accessConfig.user.id}`"
    @click="emit('on-click')"
  >
    <wb-table-row-item>
      <div class="is-flex has-align-items">
        <wb-user-avatar
          class="mr-16"
          data-test-id="userPicture"
          size="small"
          :src="props.accessConfig.user.avatar"
          :initials="compute.initials"
        />
        <div class="is-name">
          <p
            v-if="compute.userName"
            class="is-font-weight-500"
            data-test-id="userName"
          >
            {{ compute.userName }}
          </p>
          <p data-test-id="userEmail">
            {{ props.accessConfig.user.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userGroup">
      {{ props.accessConfig.group?.name || '' }}
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userProfile">
      <user-role-item :profile="props.accessConfig.profile" />
    </wb-table-row-item>

    <wb-table-row-item
      v-if="permissions.showPayments"
      class="has-text-centered"
    >
      <wb-popover>
        <template #activator>
          <div :class="compute.contractStatus.class" data-test-id="stateSubscription">
            {{ compute.contractStatus.label }}
          </div>
        </template>
        <template v-if="compute.contractStatus.details" #tooltip>
          <p
            v-for="(detail, index) in compute.contractStatus.details"
            :key="index"
            class="contract-detail"
          >
            {{ detail.label }}
            <span
              class="item wb-icons"
              data-test-id="chargerTypeIcon"
            >
              {{ detail.status ? 'check' : 'close' }}
            </span>
          </p>
        </template>
      </wb-popover>
    </wb-table-row-item>

    <wb-table-row-item class="has-text-centered">
      <wb-label
        is-active
        disabled
        icon="lens_filled"
        color="success"
        data-test-id="userActivationStatus"
      >
        {{ i18n.t('mywb.common.active') }}
      </wb-label>
    </wb-table-row-item>

    <wb-table-row-item @click.stop>
      <wb-popover
        v-if="props.hasPermisionToActions"
        data-test-id="userActionsDropdown"
      >
        <template #activator>
          <wb-button
            data-test-id="userRemove"
            type="danger"
            size="small"
            icon="delete"
            outlined
            @click="methods.handleRemove"
          />
        </template>
        <template #tooltip>
          {{ i18n.t('mywb.common.delete') }}
        </template>
      </wb-popover>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import UserRoleItem from '@/components/users/UserRoleItem.vue'
import { permissions } from '@/engine/clients'
import { userInitials, getContractStatus } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { User } from '@/types'

const i18n = useI18n()

interface Props {
  accessConfig: User.UserList
  hasPermisionToActions?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-click'): void,
  (e: 'on-remove'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  contractStatus: computed(() => {
    return getContractStatus(props.accessConfig)
  }),

  userName: computed(() => {
    return props.accessConfig?.user?.name
      ? `${props.accessConfig.user.name} ${props.accessConfig.user.lastname}`
      : ''
  }),

  initials: computed(() => userInitials(props.accessConfig.user))
})

const methods = {
  handleRemove () {
    emit('on-remove')
  }
}
</script>

<style lang="postcss" scoped>
.has-align-items {
  align-items: center;
}

.grid-table-row {
  cursor: pointer;

  &:hover .is-name {
    text-decoration: underline;
  }
}

.contract-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
</style>
