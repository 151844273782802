<template>
  <wb-table-row-item>
    <wb-list-loader v-if="props.isCompatible?.isCompatible" :loading="props.loading">
      <template v-if="props.schedule?.status === 'frozen'">
        <wb-label
          is-active
          color="grey"
        >
          {{ i18n.t('mywb.common.disabled') }}
        </wb-label>
      </template>

      <template v-else-if="!props.schedule?.isSchedule && props.schedule.rate">
        <wb-link
          type="text"
          class="has-text-grey-700"
          :to="{ name: 'payments-new-rates-edit', params: { rateId: props.schedule.rate.id } }"
        >
          {{ props.schedule.rate.name }}
        </wb-link>
        <wb-label
          is-active
          color="success"
          icon="lens_filled"
          class="ml-8"
          data-test-id="locationRateRowEnabled"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-label>

        <p class="is-size-200 has-text-grey-500">
          <span v-if="props.schedule.rate.fixed_fee"> {{ compute.fixedFeePrice }}</span>
          <span v-if="props.schedule.rate.fixed_fee && props.schedule.rate.variable_fee_price">&nbsp;+&nbsp;</span>
          <span v-if="props.schedule.rate.variable_fee_price">
            {{ compute.variableFeePrice }} {{ compute.variableLabel }}
          </span>
        </p>
      </template>

      <template v-else>
        <span class="has-text-grey-700">Week schedule</span>
        <wb-label
          is-active
          color="success"
          icon="lens_filled"
          class="ml-8"
          data-test-id="locationRateRowEnabled"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-label>
      </template>
    </wb-list-loader>

    <div v-else>
      <wb-label
        is-active
        color="grey"
      >
        {{ i18n.t('mywb.error.not-compatible') }}
      </wb-label>
      <p class="mt-4 is-size-200 has-text-grey-500" data-test-id="locationRateRowReason">
        {{ props.isCompatible?.reason }}
      </p>
    </div>
  </wb-table-row-item>
</template>

<script setup lang="ts">
import { useRateInfoParser } from '@/utilities/charger/assignedRates'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Charger } from '@/types'

const i18n = useI18n()

interface Props {
  loading?: boolean,
  schedule: Charger.ChargerWithRates['payPerMonthSchedule']
  isCompatible?: {
    isCompatible: boolean
    reason: string
  }
}
const props = defineProps<Props>()

const { compute } = useRateInfoParser({
  rate: props.schedule.rate
})
</script>
