export default class GenericError extends Error {
  #error

  constructor (error: Error | string) {
    super(
      typeof error === 'string' ? error : 'unexpected error'
    )

    this.#error = error
  }

  get error () { return this.#error }
}
