<template>
  <wb-select
    v-model="compute.profileSelected"
    :label="i18n.t('mywb.common.type')"
    uid="_user_types"
    :options="compute.userTypeOptions"
    :searchable="false"
    :disabled="props.disabled"
    data-test-id="userTypeSelected"
  >
    <template #option="{ option }">
      <div :data-test-id="option.dataTestId" class="profile-option">
        <p class="is-font-weight-500 mb-8">
          {{ option.label }}
        </p>
        <p style="width: 100%;">
          {{ option.description }}
        </p>
      </div>
    </template>
    <template #selected-option="{ option }">
      {{ option.label }}
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import { EnumRoles } from '@/utilities/user-roles'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()

interface Props {
  profile: EnumRoles,
  disabled?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-change', role: EnumRoles): void
}
const emit = defineEmits<Events>()

interface Option {
  label: string
  description: string
  code: EnumRoles
  dataTestId: string
}

const compute = reactive({
  userTypeOptions: computed((): Option[] => {
    return [
      {
        label: i18n.t('mywb.common.user'),
        description: i18n.t('mywb.profile.user.description'),
        code: EnumRoles.user,
        dataTestId: 'userTypeUser'
      },
      {
        label: i18n.t('mywb.common.admin'),
        description: i18n.t('mywb.profile.admin.description'),
        code: EnumRoles.admin,
        dataTestId: 'userTypeAdmin'
      },

      (permissions.canAddOperators || props.disabled) && {
        label: i18n.t('mywb.common.operator'),
        description: i18n.t('mywb.profile.operator.description'),
        code: EnumRoles.operator,
        dataTestId: 'userOperatorAdmin'
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  }),

  profileSelected: computed({
    get (): Option {
      return compute.userTypeOptions.find(option => option.code === props.profile) as Option
    },
    set (profile) {
      emit('on-change', profile.code)
    }
  })
})
</script>

<style lang="postcss" scoped>
.profile-option {
  white-space: normal;
}
</style>
