<template>
  <wb-popover
    menu-position="bottom-end"
    :distance-offset="16"
    data-test-id="locationActionMenu"
  >
    <template #activator>
      <wb-button
        icon="more_horiz"
        type="white"
        outlined
      />
    </template>
    <template #container="{ close }">
      <wb-button
        data-test-id="editGroupCta"
        icon="edit"
        type="white"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.edit')"
        @mousedown="emit('on-edit', close)"
      />
      <wb-button
        data-test-id="deleteGroupCta"
        icon="delete"
        type="danger"
        size="block"
        content-position="left"
        unrounded
        inverted
        :label="i18n.t('mywb.common.delete')"
        @mousedown="emit('on-delete', close)"
      />
    </template>
  </wb-popover>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

type Events = {
  (e: 'on-edit', close: () => void): void,
  (e: 'on-delete', close: () => void): void,
}
const emit = defineEmits<Events>()
</script>
