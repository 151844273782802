import mixpanel from 'mixpanel-browser'
import Tracker from './tracker'

declare global {
  interface Window { mixpanel: any }
}

export default class MixpanelTracker extends Tracker {
  constructor (token: string) {
    super()

    mixpanel.init(token, { api_host: 'https://api-eu.mixpanel.com' })

    window.mixpanel = mixpanel
  }

  trackScreen (name: string, params: object) {
    mixpanel.track(name, params)
  }

  trackAction (name: string, params: object) {
    mixpanel.track(name, params)
  }

  trackEvent (name: string, params: object) {
    mixpanel.track(name, params)
  }

  trackPurchase (name: string, params: object) {
    mixpanel.track(name, params)
  }

  trackError (name: string, params: object) {
    mixpanel.track(name, params)
  }

  trackIdentify (user: { id: string }) {
    mixpanel.people.set(user)
    mixpanel.identify(user.id)
  }
}
