<template>
  <wb-popover
    tooltip-position="right"
    menu-position="right"
    :distance-offset="24"
    :tooltip-distance-offset="16"
    @click="methods.handleDropdown"
    @change="data.isActive = !data.isActive"
  >
    <template #activator>
      <slot name="activator" />
    </template>
    <template v-if="slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
    <template #container="{ close }">
      <wb-button
        v-if="compute.existExperience"
        type="white"
        icon="bulb"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.getting-starting')"
        data-test-id="getStartedButton"
        @mousedown="methods.initExperience(close)"
      />

      <wb-button
        type="white"
        icon="school"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.wallbox-academy')"
        data-test-id="wallboxAcademyButton"
        @mousedown="methods.goToAcademy(close)"
      />

      <wb-line />

      <wb-button
        type="white"
        icon="email"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.support.title')"
        data-test-id="supportButton"
        @mousedown="methods.clickOption('support', close)"
      />
    </template>
  </wb-popover>
</template>

<script setup lang="ts">
import { Userpilot } from 'userpilot'
import { clientConfig } from '@/engine/clients'
import { hasExperience, getExperienceToken } from '@/engine/userpilot'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, useSlots } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const slots = useSlots()

interface DataType {
  isActive: boolean
}

const data: DataType = reactive({
  isActive: false
})

const compute = reactive({
  existExperience: computed(() => hasExperience(route.name))
})

const methods = {
  initExperience (close: () => void) {
    close()
    const token = getExperienceToken(route.name)
    if (token) Userpilot.trigger(token)
  },

  handleDropdown (isActive: boolean) {
    trackDataAction('help-menu', { is_active: isActive })
  },

  goToAcademy (close: () => void) {
    close()
    trackDataAction('help-menu-option', { option: 'academy' })
    window.open(clientConfig.academy)
  },

  clickOption (name:string, close: () => void) {
    close()
    if (route.name !== name) {
      router.push({ name }).catch(() => {})
    }
    trackDataAction(`account-menu-${name}`, { name })
  }
}
</script>
