import envs from './env-list'
import packageJson from '../../../package.json'
const API_PROTOCOL = `${import.meta.env.VITE_API_PROTOCOL || 'https'}`
const currentEnv = getCurrentEnv()

export default {
  name: currentEnv.name,

  portal: {
    version: packageJson.version,
    baseURL: import.meta.env.BASE_URL,
    client: import.meta.env.VITE_CLIENT_ENV || currentEnv.client
  },

  api: {
    baseURL: `${API_PROTOCOL}://${import.meta.env.VITE_API_HOST ?? currentEnv.api}`,
    userApiBaseUrl: `${API_PROTOCOL}://${import.meta.env.VITE_USER_API_HOST ?? currentEnv.userApi}`,
    perseusApiBaseUrl: `${API_PROTOCOL}://${import.meta.env.VITE_PERSEUS_API_HOST ?? currentEnv.perseusApi}`
  },

  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL_TOKEN || currentEnv.mixpanel?.token,
    active: import.meta.env.VITE_MIXPANEL_ACTIVATE === 'true' || currentEnv.mixpanel?.active
  },

  userpilot: {
    token: import.meta.env.VITE_USERPILOT_TOKEN || currentEnv.userpilot?.token,
    active: import.meta.env.VITE_USERPILOT_ACTIVATE === 'true' || currentEnv.userpilot?.active
  },

  featureFlags: {
    baseURL: import.meta.env.VITE_FEATURE_FLAG_URL || currentEnv.featureFlags?.baseURL,
    active: import.meta.env.VITE_FEATURE_FLAG_ACTIVATE === 'true' || currentEnv.featureFlags?.active
  },

  oauth: {
    google: import.meta.env.GOOGLE_CLIENT_ID || currentEnv.oauth?.google,
    apple: import.meta.env.APPLE_CLIENT_ID || currentEnv.oauth?.apple
  },

  datadog: {
    active: currentEnv.datadog?.active,
    projectId: currentEnv.datadog?.applicationId,
    clientToken: currentEnv.datadog?.clientToken,
    env: currentEnv.datadog?.env,
    version: import.meta.env.VERCEL_GIT_COMMIT_SHA?.substring(0, 8)
  },
  google: {
    maps: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  },

  formspree: {
    form: currentEnv.formspree?.form
  },

  pwa: {
    active: currentEnv.pwa?.active
  },

  deepLinks: {
    publicCharge: currentEnv.deepLinks.publicCharge
  }
}

function getCurrentEnv () {
  let selectedEnv = envs.default

  const keys = Object.keys(envs)
  keys.forEach(env => {
    if (envs[env].web.includes(document.location.host)) {
      selectedEnv = envs[env]
    }
  })

  return selectedEnv
}
