import { EnumRoles } from '@/utilities/user-roles'
import state from '@/state'
import i18n from '@/engine/lang'
import type { User } from '@/types'

export const checkIfUserIsSameAs = (userId: number) => {
  if (!userId) return

  const organization = state.organizations.getCurrentOrganization
  const users = state.user.getCurrentUserGroupsMap[organization.group_id]?.users || []
  const userFound = users.find(user => user.id === userId)

  if (!userFound) return false

  return (userFound.id === state.user.userLogged.id)
}

export const hasRoleGreaterThan = (userId: number) => {
  if (!userId) return false

  const organization = state.organizations.getCurrentOrganization
  const currentUserGroupsMap = state.user.getCurrentUserGroupsMap

  const currentUserGroupSelected = currentUserGroupsMap[organization.group_id]
  const userFound = currentUserGroupSelected?.users.find(user => user.id === userId)

  const roleUserAccessConfigSelected = Object.values(EnumRoles).indexOf(userFound?.profile ?? '')
  const roleRootUserGroupSelected = Object.values(EnumRoles).indexOf(organization.profile_id)

  return roleRootUserGroupSelected > roleUserAccessConfigSelected
}

export const userInitials = (user: { name: string, surname?: string, lastname?: string }) => {
  if (user.surname) return user.name.charAt(0) + user.surname.charAt(0)
  if (user.lastname) return user.name.charAt(0) + user.lastname.charAt(0)

  return (user.name ?? '').substring(0, 2)
}

export const getContractStatus = (accessConfig: User.UserList) => {
  interface Contract {
    class: string
    label: string
    status: 'active' | 'unpaid' | 'error' | 'pending' | 'no'
    details?: Record<'paidInvoices' | 'signed' | 'billingInfo' | 'paymentMethod', {
      label: string
      status: boolean
    }>
  }

  let contract: Contract = {
    class: 'has-text-grey-500',
    label: i18n.global.t('mywb.common.no'),
    status: 'no'
  }

  if (accessConfig.contract) {
    const status = accessConfig.contract?.status ?? 'pending'
    const statusObject: Record<string, any> = {
      active: { status: 'active', class: 'has-text-primary-500', label: i18n.global.t('mywb.common.active') },
      unpaid: { status: 'unpaid', class: 'has-text-warning-500', label: i18n.global.t('mywb.common.unpaid') },
      error: { status: 'error', class: 'has-text-danger-500', label: i18n.global.t('mywb.common.error') },
      pending: { status: 'pending', class: 'has-text-grey-500', label: i18n.global.t('mywb.common.pending') },
      no: { status: 'no', class: 'has-text-grey-500', label: i18n.global.t('mywb.common.no') }
    }

    contract = statusObject[status] ?? statusObject.no
  }

  if (accessConfig.contractStatus?.user) {
    contract.details = {
      paidInvoices: {
        label: i18n.global.t('mywb.common.subscription-created'),
        status: accessConfig.contractStatus.user.paidInvoices
      },

      signed: {
        label: i18n.global.t('mywb.common.contract-signed'),
        status: accessConfig.contractStatus.user.signed
      },

      billingInfo: {
        label: i18n.global.t('mywb.common.billing-info-filled'),
        status: accessConfig.contractStatus.user.billingInfo
      },

      paymentMethod: {
        label: i18n.global.t('mywb.common.payment-method-filled'),
        status: accessConfig.contractStatus.user.paymentMethod
      }
    }
  }

  return contract
}
