import ENV from '@/engine/env/web.env'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import DatadogTracker from './datadogTracker'
import MixpanelTracker from './mixpanelTracker'
import type Tracker from './tracker'

let clientsTracker: Tracker[] = []

const defaultparams = {
  wallbox_version: ENV.portal.version,
  device_type: window.navigator.platform,
  user_locale: window.navigator.language
}

export const initClientTrackers = () => {
  clientsTracker = [
    ENV.mixpanel.active && ENV.mixpanel.token && new MixpanelTracker(ENV.mixpanel.token),
    ENV.datadog.active && new DatadogTracker()
  ].filter(Boolean as unknown as ExcludesNullish)

  const storage = JSON.parse(localStorage.getItem('wb-state') ?? '{}')

  const userUid: string = storage?.auth?.userId
  const organizations = storage?.organizations
  const currentOrganizationId = storage?.currentOrganizationId

  try {
    trackEvent(`page_init_${userUid}`, { userUid, organizations, currentOrganizationId })
  } catch {}
}

export const trackScreen = (name: string, params?: object) => {
  clientsTracker.forEach(client => {
    client.trackScreen(`screen_${name}`, { defaultparams, ...params })
  })
}

export const trackAction = (name: string, params?: object) => {
  clientsTracker.forEach(client => {
    client.trackAction(`action_${name}`, { defaultparams, ...params })
  })
}

export const trackEvent = (name: string, params?: object) => {
  clientsTracker.forEach(client => {
    client.trackEvent(`event_${name}`, { defaultparams, ...params })
  })
}

export const trackPurchase = (name: string, params?: object) => {
  clientsTracker.forEach(client => {
    client.trackPurchase(`purchase_${name}`, { defaultparams, ...params })
  })
}

export const trackError = (name: string, params?: object) => {
  clientsTracker.forEach(client => {
    client.trackError(`error_${name}`, { defaultparams, ...params })
  })
}

export const trackIdentify = (params?: object) => {
  clientsTracker.forEach(client => {
    client.trackIdentify(params)
  })
}
