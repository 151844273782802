<template>
  <div class="charger-schedule">
    <div class="schedule-wrapper">
      <div class="days">
        <div v-for="day in data.days" :key="day">
          {{ day }}
        </div>
      </div>

      <div class="hours">
        <div v-for="(hour, index) in data.hours" :key="index">
          {{ hour }}
        </div>
      </div>

      <div class="schedule">
        <template v-for="(day, index) in props.schedule" :key="index">
          <wb-popover
            v-for="(rate, rateIndex) in day"
            :key="rateIndex"
            tooltip-position="right"
            :tooltip-distance-offset="16"
            class="popover"
          >
            <template #activator>
              <div
                class="rate rate-cell"
                data-test-id="rate-cell"
                :data-test-value="rate"
                :class="{
                  decolorize: props.rateBeingAdded && rateBeingAdded?.id !== rate
                }"
                :style="state.rates.getStyleByRate(rate, props.type)"
              />
            </template>

            <template #tooltip>
              {{ `${rateIndex}`.padStart(2, '0') }}:00-{{ `${rateIndex}`.padStart(2, '0') }}:59
            </template>
          </wb-popover>
        </template>
      </div>
    </div>

    <div class="rates mt-32" data-test-id="assignedRates">
      <h4
        v-t="'mywb.rates.assigned-rates'"
        class="is-size-500 is-font-weight-500 mb-12"
      />

      <div
        v-for="(rate, index) in compute.rateParsed"
        :key="index"
        class="rate-item"
        data-test-id="rate-item"
        :data-test-value="rate.id"
        :class="{
          decolorize: props.rateBeingAdded && rateBeingAdded?.id !== rate.id
        }"
      >
        <div
          class="rate-list-color rate"
          :style="state.rates.getStyleByRate(rate.id, props.type)"
        />

        <div>
          <p class="is-size-300 is-font-weight-500">
            {{ rate.name }}
          </p>
          <p class="is-size-300">
            <span v-if="rate.fixed_fee"> {{ rate.fixedFeePrice }}</span>
            <span v-if="rate.fixed_fee && rate.variable_fee_price">&nbsp;+&nbsp;</span>
            <span v-if="rate.variable_fee_price">{{ rate.variableFeePrice }} {{ rate.variableLabel }} </span>
          </p>
        </div>

        <wb-button
          v-if="props.hasActions && props.rates.length > 1"
          type="danger"
          inverted
          @click="emit('delete-rate', rate)"
        >
          {{ i18n.t('mywb.common.delete') }}
        </wb-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import type { Rate } from '@/types'
import { getCurrencyCode } from '@/utilities/currency'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import state from '@/state'

const i18n = useI18n()

interface PropsType {
  schedule?: Rate.Schedule
  rates: Rate.Rate[]
  hasActions?: boolean,
  rateBeingAdded?: Rate.Rate
  type: 'pay_per_charge' | 'pay_per_month'
}

const props = withDefaults(defineProps<PropsType>(), {
  schedule: () => ({
    monday: Array.from(Array(24).keys()).map(() => ''),
    tuesday: Array.from(Array(24).keys()).map(() => ''),
    wednesday: Array.from(Array(24).keys()).map(() => ''),
    thursday: Array.from(Array(24).keys()).map(() => ''),
    friday: Array.from(Array(24).keys()).map(() => ''),
    saturday: Array.from(Array(24).keys()).map(() => ''),
    sunday: Array.from(Array(24).keys()).map(() => '')
  }),

  rateBeingAdded: undefined
})

interface Events {
  (e: 'edit-rate', rate: Rate.Rate): void
  (e: 'delete-rate', rate: Rate.Rate): void
}

const emit = defineEmits<Events>()

const data = reactive({
  days: [
    i18n.t('mywb.days.monday-short'),
    i18n.t('mywb.days.tuesday-short'),
    i18n.t('mywb.days.wednesday-short'),
    i18n.t('mywb.days.thursday-short'),
    i18n.t('mywb.days.friday-short'),
    i18n.t('mywb.days.saturday-short'),
    i18n.t('mywb.days.sunday-short')
  ],
  hours: ['00h', '', '', '', '04h', '', '', '', '08h', '', '', '',
    '12h', '', '', '', '16h', '', '', '', '20h', '', '', '', '24h']
})

const compute = reactive({
  hasToShowRateBeingAdded: computed((): boolean => {
    const rates: string[] = Object.values(props.schedule).flat()
    return rates.some(rate => rate === props.rateBeingAdded?.id)
  }),

  rateParsed: computed(():
  Array<Rate.Rate & { fixedFeePrice: string, variableFeePrice: string, variableLabel:string }> => {
    return props.rates
      .filter(rate => (rate.id !== props.rateBeingAdded?.id) || compute.hasToShowRateBeingAdded)
      .map(rate => ({
        ...rate,
        fixedFeePrice: numbers
          .toLocaleCurrencySymbol(rate.fixed_fee, getCurrencyCode(), i18n.locale.value),
        variableFeePrice: numbers
          .toLocaleCurrencySymbol(rate.variable_fee_price, getCurrencyCode(), i18n.locale.value),
        variableLabel: methods.getLabel(rate)
      }))
  })
})

const methods = {
  getLabel (rate: Rate.Rate) {
    if (rate.variable_fee_type === 'energy') {
      return i18n.t('mywb.common.cost-per-kwh')
    } else if (rate.variable_fee_type === 'time') {
      return i18n.t('mywb.common.cost-per-hour')
    }
    return ''
  }
}
</script>

<style lang="postcss">
  :root {
    --color-1-500: #009b86;
    --color-2-500: #518df7;
    --color-3-500: #fac54f;
    --color-4-500: #c55c5d;
    --color-5-500: #8665a9;
  }
</style>

<style lang="postcss" scoped>
.charger-schedule {
  display: grid;
  gap: 32px;
  padding: 32px;

  @media (--tablet) {
    grid-template-columns: 2fr 1fr;
  }
}

.schedule-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 50px auto;
  grid-template-rows: 30px auto;
  grid-template-areas:
    ". days"
    "hours schedule";
}

.days {
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.hours {
  grid-area: hours;
  display: grid;
  grid-template-rows: repeat(24, minmax(15px, min-content));
  line-height: 15px;
}

.schedule {
  grid-area: schedule;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(24, minmax(15px, min-content));
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}

.rate {
  background: var(--primary-500);
}

.rate-cell,
.popover :deep(> div:first-of-type) {
  height: 100%;
  width: 100%;
}

.popover {
  &:nth-of-type(24n - 23) .rate-cell {
    border-radius: 8px 8px 0 0;
  }

  &:nth-of-type(24n) .rate-cell {
    border-radius: 0 0 8px 8px;
  }
}

.rate-list-color {
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.rate-item {
  display: grid;
  grid-template-columns: auto 1fr max-content;
  gap: 0  8px;
  border-bottom: 1px solid var(--grey-100);
}

.rates {
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;
}

.decolorize {
  opacity: 0.3;
}
</style>
