<template>
  <div>
    <rates-header
      :loading="data.loading"
    />

    <rates-table
      :rates="compute.ratesFiltered"
      :loading="data.loading"
      @on-delete-rate="methods.handleDeleteRate"
    />

    <rate-delete-modal
      v-if="data.isModalPaymentsDeleteRateOpen && data.deleteRateCandidate"
      data-test-id="deleteRateModal"
      :rate="data.deleteRateCandidate"
      @close="data.isModalPaymentsDeleteRateOpen = false"
      @on-delete-rate="methods.removeRateFromList"
    />
  </div>
</template>

<script setup lang="ts">
import api from '@/api'
import state from '@/state'
import RateDeleteModal from '@/components/modals/RateDeleteModal.vue'
import RatesTable from '@/components/tables/RatesTable.vue'
import RatesHeader from '@/components/headers/RatesHeader.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import type { Rate } from '@/types'

interface Data {
  loading: boolean,
  rates: Rate.Rate[],
  assignedRates: Rate.AreAreAssigned,
  deleteRateCandidate?: Rate.RateWithAssigned,
  isModalPaymentsDeleteRateOpen: boolean
}
const data = reactive<Data>({
  loading: false,
  rates: [],
  assignedRates: {},
  isModalPaymentsDeleteRateOpen: false
})

const compute = reactive({
  ratesWithAssignation: computed((): Rate.RateWithAssigned[] => {
    return data.rates.map(rate => ({
      ...rate,
      assigned: !!data.assignedRates[rate.id]
    }))
  }),

  ratesFiltered: computed((): Rate.RateWithAssigned[] => {
    const reducers = {
      text: methods.filterByText,
      fixedFee: methods.filterByFixedFee,
      variableType: methods.filterByVariableType,
      assigned: methods.filterByAssigned
    }
    const entries = Object.entries(state.filters.ratesFilters) as
      Array<[keyof typeof reducers, string & string[] & boolean[]]>

    return entries.reduce((ratesFiltered, [type, value]) =>
      reducers[type](ratesFiltered, value), compute.ratesWithAssignation
    )
  })
})

const methods = {
  filterByText (rates: Rate.RateWithAssigned[], text: string) {
    if (!text) return rates

    return rates.filter(rate => {
      return (
        rate.name.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  filterByFixedFee (rates: Rate.RateWithAssigned[], fixedFee: boolean[]) {
    if (!fixedFee?.length) return rates

    return rates.filter(rate => fixedFee.includes(rate.fixed_fee > 0))
  },

  filterByVariableType (rates: Rate.RateWithAssigned[], variableTypes: string[]) {
    if (!variableTypes?.length) return rates

    return rates.filter(rate => variableTypes.includes(rate.variable_fee_type))
  },

  filterByAssigned (rates: Rate.RateWithAssigned[], assigned: boolean[]) {
    if (!assigned?.length) return rates

    return rates.filter(rate => assigned.includes(rate.assigned))
  },

  removeRateFromList (rate: Rate.RateWithAssigned) {
    data.rates = data.rates.filter(({ id }) => id !== rate.id)
    data.isModalPaymentsDeleteRateOpen = false
  },

  handleDeleteRate (rate: Rate.RateWithAssigned) {
    data.deleteRateCandidate = rate
    data.isModalPaymentsDeleteRateOpen = true
  }
}

async function created () {
  data.loading = true
  const result = await api.rates.getRates({
    groupId: state.groups.groupRelatedToOrganization.id
  })

  data.rates = result.data

  const ratesPromises = data.rates.map(rate =>
    api.rates.getIsRateAssigned(state.groups.groupRelatedToOrganization.uid, rate.id))

  const assigned = (await Promise.all(ratesPromises))
    .reduce((acum, current) => ({ ...acum, [current.rate]: current.result }), {})

  data.assignedRates = assigned
  data.loading = false

  trackDataScreen('rates')
}

created()
</script>
