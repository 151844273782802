import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import type { User, UsersApi } from '@/types'

interface UpdatePassword {
  current_password: string
  new_password: string
}

interface UpdateUser {
  name?: string
  surname?: string
  phone_code?: string
  phone?: string
  country_id?: number
  time_zone?: string
  reporting?: boolean
  pin?: string | null
  system_format?: string
}

export default {
  async getUser (userId: number): Promise<UsersApi.ApiGetUserResponse> {
    return await axios.get(endpoints.v2.user_userId.replace('{userId}', userId?.toString()))
  },

  async getUserDetail (userId: number): Promise<User.UserDetail> {
    return await axios.get(endpoints.v1.users_data_userId.replace('{userId}', userId?.toString()))
  },

  async getUserGroups (): Promise<User.UsersGroup[]> {
    return await axios.get<unknown, UsersApi.ApiGetUserGroupsResponse>(endpoints.v3.users_groups)
      .then(response => response.result.groups)
  },

  async updateUserPassword (params: UpdatePassword): Promise<void> {
    return await axios.put(endpoints.v1.updatePassword, params)
  },

  async updateUser ({ params, userId }: { params: UpdateUser, userId: number }): Promise<void> {
    return await axios.put(endpoints.v1.users_data_userId.replace('{userId}', userId.toString()), params)
  },

  async activeContracts ({ contractId, confirmToken }: { contractId: number, confirmToken: string }) {
    const endpoint = endpoints.v3.users_contracts_contractId_active
      .replace('{contractId}', contractId.toString())
    return await axios.post(endpoint, { confirmToken })
  },

  async deleteContract (contractId: number): Promise<void> {
    return await axios.delete(endpoints.v3.contracts_contractId.replace('{contractId}', contractId.toString()))
  },

  async getIdByUlid ({ ulid }: { ulid: string }): Promise<UsersApi.GetIdByUlidResponse> {
    return await axios.get(endpoints.v4.user_ulid_id.replace('{ulid}', ulid))
  }
}
