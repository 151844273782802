<template>
  <wb-table
    :options="data.options"
    :loading="props.loading"
    data-test-id="billing-section"
  >
    <wb-table-row
      v-for="invoice in compute.invoicesAndFees"
      :key="invoice.id"
      :data-test-id="invoice.isFee ? 'fee': 'invoice'"
    >
      <wb-table-row-item data-test-id="type">
        {{ invoice.type }}
      </wb-table-row-item>
      <wb-table-row-item data-test-id="amount">
        {{ invoice.amount }}
      </wb-table-row-item>
      <wb-table-row-item>
        <wb-label
          is-active
          disabled
          data-test-id="label"
          :color="invoice.retryPayment ? 'warning': 'success'"
          icon="lens_filled"
        >
          {{ invoice.retryPayment ? i18n.t('mywb.common.pending') : i18n.t('mywb.payments.paid') }}
        </wb-label>
      </wb-table-row-item>
      <wb-table-row-item data-test-id="paidAt">
        {{ invoice.date }}
      </wb-table-row-item>
      <wb-table-row-item>
        <wb-button-groups>
          <wb-popover v-if="invoice.url" class="group-item">
            <template #activator>
              <wb-button
                type="white"
                outlined
                size="small"
                icon="visibility"
                data-test-id="invoiceBtn"
                @click="methods.openUrl(invoice.url)"
              />
            </template>
            <template #tooltip>
              {{ i18n.t('mywb.common.view') }}
            </template>
          </wb-popover>
          <wb-popover v-if="invoice.retryPayment" class="group-item">
            <template #activator>
              <wb-button
                type="white"
                outlined
                size="small"
                data-test-id="payBtn"
                class="ml-16"
                @click="methods.payInvoice(invoice.id as number)"
              />
            </template>
            <template #tooltip>
              {{ i18n.t('mywb.payments.retry-pay') }}
            </template>
          </wb-popover>
        </wb-button-groups>
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { dates, numbers, useNotify } from '@wallbox/toolkit-ui'
import api from '@/api'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { Invoice } from '@/types'
import state from '@/state'

const i18n = useI18n()
const notify = useNotify()

interface Props {
  fees: Invoice.Fee[]
  invoices: Invoice.PlanInvoice[]
  loading?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-update-data'): void
}
const emit = defineEmits<Events>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: i18n.t('mywb.common.type'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.total-amount'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.status'), align: 'center', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.date'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: '200px', width: 'auto' }
    ],
    stickyHead: 'var(--header-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

interface TableData {
  id: string | number,
  isFee: boolean,
  type: string,
  amount: string
  retryPayment?: boolean
  date?: string
  url: string
  issueDate: number
}

const compute = reactive({
  feesParsed: computed((): TableData[] => {
    return props.fees.map(fee => {
      const feeAmount = fee.attributes?.total_fee_amount && fee.attributes?.total_fee_amount / 100

      return {
        id: fee.id,
        isFee: true,
        type: i18n.t('mywb.common.wallbox-fee'),
        amount: numbers.toLocaleCurrencySymbol(feeAmount, fee.attributes?.currency_code, i18n.locale.value),
        date: dates.toDate(fee.attributes?.issue_date, i18n.locale.value),
        url: api.invoices.feeInvoiceDetailsLink(state.organizations.getCurrentOrganization.group_uid, fee.id),
        issueDate: fee.attributes?.issue_date
      }
    })
  }),
  invoicesParsed: computed((): TableData[] => {
    return props.invoices.map(invoice => {
      const isNotPaid = invoice.status === 'uncollectible' || invoice.status === 'open'

      return {
        id: invoice.id,
        isFee: false,
        type: i18n.t(`mywb.common.plan-${invoice?.planName?.toLowerCase()}`),
        amount: numbers.toLocaleCurrencySymbol(invoice.amountDue, invoice.currency?.code, i18n.locale.value),
        retryPayment: isNotPaid,
        date: dates.toDate(invoice.issueDate, i18n.locale.value),
        url: invoice.invoicePdf,
        issueDate: invoice.issueDate
      }
    })
  }),

  invoicesAndFees: computed((): TableData[] => {
    return [...compute.feesParsed, ...compute.invoicesParsed]
      .sort((firstItem, secondItem) => (secondItem.issueDate - firstItem.issueDate))
  })
})

const methods = {
  async payInvoice (invoiceId: number) {
    try {
      await api.invoices.payInvoice(invoiceId)
      notify.success(i18n.t('mywb.payments.paid'))
      emit('on-update-data')
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  openUrl (url: string) {
    window.open(url, '_blank', 'noopener')
  }
}
</script>
