import type { ApiErrorResponse, ApiError } from '@/types/api/clientApi.api.types'

export default class HttpError extends Error {
  #msg
  #code
  #status
  #error

  constructor (ApiErrorResponse: ApiErrorResponse | ApiError, status: number) {
    function isComplexResponse (response: any): response is ApiErrorResponse {
      return response.errors || response.result
    }

    const error = isComplexResponse(ApiErrorResponse)
      ? ApiErrorResponse.errors?.[0] ?? ApiErrorResponse.result
      : ApiErrorResponse

    const msg = error?.msg ?? error?.detail ?? error?.message ?? 'unexpected error'
    super(msg)
    this.#msg = msg
    this.#code = isNaN(parseInt(error?.code ?? '0', 10)) ? error?.code ?? 0 : parseInt(error?.code ?? '0', 10)
    this.#status = error?.status ?? status
    this.#error = error
  }

  get msg () { return this.#msg }
  get code () { return this.#code }
  get status () { return this.#status }
  get error () { return this.#error }
}
