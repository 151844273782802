<template>
  <shared-header hide-breadcrumb>
    <template
      #secondary-actions
    >
      <wb-button
        type="primary"
        data-test-id="createRateButton"
        :label="mq.current === 'desktop' ? i18n.t('mywb.common.create-rate') : ''"
        icon="add"
        :to="{ name: 'payments-new-rates-create' }"
      />
    </template>

    <template
      #filters
    >
      <rates-filters :loading="props.loading" />
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import RatesFilters from '@/components/filters/RatesFilters.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useMq } from 'vue3-mq'

const mq = useMq()
const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

</script>
