<template>
  <div>
    <wb-modal
      width="80"
      hide-close-button
      blocked
      @close="emit('close')"
    >
      <wb-progress-bar
        class="mb-16"
        :val="currentStatesCount"
        :max="totalStatesCount"
        bar-color="var(--primary-500)"
      />
      <transition :name="data.stepDirection">
        <div v-if="isCurrentState('locationChargers')" class="content-step">
          <location-chargers
            :ref="setRef('locationChargers')"
            v-model:locationId="data.chargersToAdd.location.groupId"
            v-model:country="data.chargersToAdd.location.country"
            v-model:timezoneId="data.chargersToAdd.location.timezoneId"
            v-model:loading="data.actionsDisabled"
            @on-create-location="methods.handleCustomButton('editLocation')"
          />
        </div>

        <div v-else-if="isCurrentState('editLocation')" class="content-step">
          <edit-location
            :ref="setRef('editLocation')"
            :group-id="data.editLocationId"
            @on-edit="methods.assignLocation"
          />
        </div>

        <div v-else-if="isCurrentState('addChargers')" class="content-step">
          <add-chargers
            id="chargersSelectors"
            :ref="setRef('addChargers')"
            v-model="data.chargersToAdd.chargers"
            :location="data.chargersToAdd.location"
          />
        </div>

        <div v-else-if="isCurrentState('configurePayments')" class="content-step">
          <configure-payments
            :ref="setRef('configurePayments')"
            :chargers="data.chargersToAdd.chargers"
            @on-configure-payments="emit('on-configure-payments')"
          />
        </div>
      </transition>

      <template #actions>
        <div class="button-actions">
          <wb-button
            v-if="isCurrentState('locationChargers')"
            data-test-id="cancelButton"
            type="white"
            outlined
            :label="i18n.t('mywb.common.cancel')"
            @click="emit('close')"
          />
          <wb-button
            v-if="!isCurrentState('locationChargers') && !isCurrentState('configurePayments')"
            data-test-id="backButton"
            icon="arrow_back"
            type="white"
            outlined
            class="mr-24"
            :label="i18n.t('mywb.common.back')"
            @click="methods.handleBackButton"
          />
          <span v-else />
          <wb-button
            data-test-id="nextButton"
            :label="i18n.t('mywb.common.continue')"
            icon="arrow_forward"
            icon-position="right"
            :disabled="data.actionsDisabled"
            :loading="data.loading"
            @click="methods.handleNextButton"
          />
        </div>
      </template>
    </wb-modal>
  </div>
</template>

<script setup lang="ts">
import AddChargers from '@/components/addChargers/AddChargers.vue'
import LocationChargers from '@/components/addChargers/LocationChargers.vue'
import EditLocation from '@/components/addChargers/EditLocation.vue'
import ConfigurePayments from '@/components/addChargers/ConfigurePayments.vue'

import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { useTemplateRef, useNotify } from '@wallbox/toolkit-ui'
import { reactive, computed, watchEffect } from 'vue'
import { useStateMachine } from '@/hooks'
import { permissions } from '@/engine/clients'
import { useRouter, useRoute } from 'vue-router'
import type { Charger, Locations } from '@/types'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const notify = useNotify()

interface Props {
  locationId?: number
}
const props = defineProps<Props>()

interface Data {
  chargersToAdd: {
    chargers: Charger.ChargerToAdd[],
    location: Locations.LocationAddingChargers
  },
  stepDirection: 'slide-left' | 'slide-right',
  loading: boolean,
  editLocationId?: number,
  showToastSuccess: boolean,
  actionsDisabled: boolean
}

const data = reactive<Data>({
  chargersToAdd: {
    chargers: [
      {
        serial: {
          value: ''
        },
        puk: {
          value: ''
        },
        added: false
      }
    ],
    location: {
      groupId: undefined,
      country: undefined,
      timezoneId: undefined
    }
  },
  stepDirection: 'slide-left',
  loading: false,
  editLocationId: undefined,
  showToastSuccess: false,
  actionsDisabled: false
})

const compute = reactive({
  isLocationCompleted: computed(() => {
    return !!data.chargersToAdd.location.country && !!data.chargersToAdd.location.timezoneId
  })
})

const { currentStateKey, isCurrentState, send, currentStatesCount, totalStatesCount } = useStateMachine({
  initial: 'locationChargers',
  states: {
    locationChargers: {
      transitions: {
        editLocation: 'editLocation',
        next: 'addChargers'
      }
    },

    editLocation: {
      transitions: {
        next: 'addChargers',
        back: 'locationChargers'
      }
    },

    addChargers: {
      transitions: {
        next: 'configurePayments',
        back: 'locationChargers'
      }
    },

    configurePayments: {
      transitions: {
        back: 'addChargers',
        next: 'configurePayments'
      },
      on: {
        next: () => {
          notify.success(i18n.t('mywb.add-chargers.chargers-added'))
          emit('close')
        }
      }
    }
  }
})

const emit = defineEmits([
  'close',
  'on-configure-payments',
  'on-update-chargers'
])

const methods = {
  handleBackButton () {
    data.stepDirection = 'slide-left'
    send('back')
  },

  async handleNextButton () {
    data.loading = true
    if (refs[currentStateKey.value] && await refs[currentStateKey.value].validateForm()) {
      data.stepDirection = 'slide-right'

      if (isCurrentState('locationChargers') && !compute.isLocationCompleted) {
        data.editLocationId = data.chargersToAdd.location.groupId
        send('editLocation')
        data.loading = false
        return
      }

      if (isCurrentState('addChargers')) {
        emit('on-update-chargers')
        if (!permissions.showPaymentsEngage) {
          emit('close')
          data.loading = false
          return
        }
      }
      send('next')
    }
    data.loading = false
  },

  handleCustomButton (stateName: 'editLocation' | 'next' | 'back') {
    data.stepDirection = 'slide-right'
    send(stateName)
  },

  assignLocation (location: Locations.LocationAddingChargers) {
    data.chargersToAdd.location.groupId = location.groupId
    data.chargersToAdd.location.country = location.country
    data.chargersToAdd.location.timezoneId = location.timezoneId
  }
}

function created () {
  data.chargersToAdd.location.groupId = props.locationId
  trackDataScreen('add-chargers')
}

created()

watchEffect(() => {
  if (route.query['location-id']) {
    data.chargersToAdd.location.groupId = +route.query['location-id']
    router.push({ query: {} }).catch(() => {})
  }
})
</script>

<style lang="postcss" scoped>
:deep(.button-actions) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.modal-grid) {
  min-height: calc(100vh - 8rem);
}

:deep(.modal-content) {
  overflow-x: hidden;
  max-height: calc(100vh - 18rem) !important;
}

.content-step {
  @media (--tablet) {
    width: calc(80rem - 8rem);
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 360ms ease-out;
  position: absolute;
}

.slide-right-enter-from {
  transform: translateX(80rem);
}

.slide-right-leave-to {
  transform: translateX(-80rem);
}

.slide-left-enter-from {
  transform: translateX(-80rem);
}

.slide-left-leave-to {
  transform: translateX(80rem);
}
</style>
