<template>
  <wb-card
    :headline="i18n.t('mywb.common.resources')"
    :subhead="i18n.t('mywb.payments.resources')"
    filled
    actions-position="bottom"
    class="mt-8"
  >
    <template #actions>
      <wb-button
        type="white"
        outlined
        class="download-button"
        icon="qr_code"
        :label="i18n.t('mywb.common.qr')"
        :loading="data.loadingQR"
        @click="methods.downloadQR"
      />

      <wb-button
        type="white"
        outlined
        class="download-button"
        icon="picture_as_pdf"
        :label="i18n.t('mywb.payments.ppc-instructions')"
        :loading="data.loadingPDF"
        @click="methods.downloadInstructions"
      />
    </template>
    <template #place-upper-right>
      <canvas id="qr" @click="methods.downloadQR" />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { download } from '@wallbox/toolkit-ui'
import { onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import lang, { type Langs } from '@/engine/lang'
import { useI18n } from '@/hooks/useI18n.hook'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import ENV from '@/engine/env/web.env'
import QR from 'qrcode'
import { PDFDocument } from 'pdf-lib'

const route = useRoute()
const i18n = useI18n()

interface Data {
  loadingPDF: boolean
  loadingQR: boolean
  languages: Record<Langs, string>
}
const data = reactive<Data>({
  loadingPDF: false,
  loadingQR: false,
  languages: {
    ca: 'visitor-charge_ES.pdf',
    de: 'visitor-charge_DE.pdf',
    en: 'visitor-charge_EN.pdf',
    es: 'visitor-charge_ES.pdf',
    fr: 'visitor-charge_FR.pdf',
    it: 'visitor-charge_IT.pdf',
    nl: 'visitor-charge_NL.pdf',
    no: 'visitor-charge_NO.pdf',
    zh: 'visitor-charge_EN.pdf',
    pt: 'visitor-charge_PT.pdf',
    sv: 'visitor-charge_EN.pdf',
    enUS: 'visitor-charge_EN.pdf'
  }
})

onMounted(async () => {
  const canvas = document.getElementById('qr')
  QR.toCanvas(
    canvas,
      `${ENV.deepLinks.publicCharge}?serial_number=${route.params.chargerId}`,
      { margin: 0, width: 50 }
  )
})

const methods = {
  async downloadQR () {
    data.loadingQR = true
    await methods.downloadPDF({
      url: '/docs/pay-per-charge/scan-to-charge.pdf',
      name: 'scan-to-charge.pdf',
      qrFormName: 'qr',
      snFormName: 'sn'
    })
    data.loadingQR = false
    trackDataEvent('printQR')
  },

  async downloadInstructions () {
    data.loadingPDF = true
    await methods.downloadPDF({
      url: `/docs/pay-per-charge/visitor-charge/${data.languages[lang.__rootLanguage] ?? data.languages.en}`,
      name: 'visitor-charge.pdf',
      qrFormName: 'QR_code'
    })
    data.loadingPDF = false
    trackDataEvent('printInstructions')
  },

  async downloadPDF ({ url, name, qrFormName, snFormName = '' }:
{ url: string, name: string, qrFormName: string, snFormName?: string }) {
    const img = await QR.toDataURL(
        `${ENV.deepLinks.publicCharge}?serial_number=${route.params.chargerId}`,
        { width: 200, margin: 0, type: 'image/jpeg' }
    )

    const formPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(formPdfBytes)

    const form = pdfDoc.getForm()

    if (snFormName) {
      const snField = form.getTextField(snFormName)
      snField.setText(`SN: ${route.params.chargerId}`)
      snField.enableReadOnly()
    }

    const jpgImage = await pdfDoc.embedJpg(img)
    const qrImageField = form.getButton(qrFormName)
    qrImageField.setImage(jpgImage)
    qrImageField.enableReadOnly()

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'text/csv;charset=utf-8;' })
    download.downloadBlob(blob, name)
  }
}
</script>
