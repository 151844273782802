import { configure, defineRule } from 'vee-validate'

import {
  required,
  email,
  min_value,
  digits,
  confirmed,
  numeric,
  size,
  not_one_of,
  max_value
} from '@vee-validate/rules'
import i18n from '@/engine/lang'

import passwordValidator from './password.validator'
import rfidValidator from './rfid.validator'
import decimalValidator from './decimal.validator'
import objectValidator from './object.validator'
import { notExceedsPriceUpperLimit, priceAmountUpperLimit } from './price.validator'

const dictionary: Record<string, (field?: string, value?: unknown) => string> = {
  not_one_of: () => i18n.global.t('mywb.error.invalid-value'),
  required: () => i18n.global.t('mywb.error.field-required'),
  numeric: () => i18n.global.t('mywb.error.expected-number'),
  decimal: () => i18n.global.t('mywb.error.expected-number'),
  email: () => i18n.global.t('mywb.error.invalid-email'),
  confirmed: () => i18n.global.t('mywb.error.values-not-match'),
  oneRequired: () => i18n.global.t('mywb.error.one-required-field'),
  verifyRfid: () => i18n.global.t('mywb.error.invalid-format-rfid'),
  not_exceeds_price_upper_limit: () => {
    return i18n.global.t('mywb.error.rate-amount-upper-limit', { amount: priceAmountUpperLimit() })
  },
  verifyPassword: () => i18n.global.t('mywb.error.invalid-format-password'),
  min_value: (field) => {
    return field === 'commanderPin'
      ? i18n.global.t('mywb.error.invalid-charger-pin')
      : i18n.global.t('mywb.error.invalid-value')
  },
  max_value: () => i18n.global.t('mywb.error.invalid-value'),
  userInGroup: () => i18n.global.t('userInGroup'),
  size: (field, params = {}) => {
    const paramsTyped = params as Record<string, number>
    const MB = new Intl.NumberFormat(i18n.global.locale, {
      style: 'unit',
      unit: 'megabyte',
      maximumFractionDigits: 2
    }).format(paramsTyped.size ?? 0 / 1024)

    return i18n.global.t('mywb.error.file-size', [MB])
  },
  default: () => i18n.global.t('mywb.error.unexpected-error')
}

interface FieldValidationMetaInfo {
  field: string
  value: unknown
  form: Record<string, unknown>
  rule?: {
    name: string
    params?: Record<string, unknown> | unknown[]
  }
}

export const getMessage = ({ rule, field, value }: FieldValidationMetaInfo) => {
  if (!rule?.name) return ''

  return dictionary[rule.name] ? dictionary[rule.name](field, value) : dictionary.default()
}

export default function initValidator () {
  configure({
    generateMessage: getMessage
  })

  defineRule('verifyPassword', passwordValidator)
  defineRule('verifyRfid', rfidValidator)
  defineRule('decimal', decimalValidator)
  defineRule('nonEmptyObject', objectValidator)
  defineRule('required', required)
  defineRule('email', email)
  defineRule('min_value', min_value)
  defineRule('max_value', max_value)
  defineRule('digits', digits)
  defineRule('confirmed', confirmed)
  defineRule('numeric', numeric)
  defineRule('size', size)
  defineRule('not_one_of', not_one_of)
  defineRule('not_exceeds_price_upper_limit', notExceedsPriceUpperLimit)
}
