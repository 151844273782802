import state, { loadStates } from '@/state'
import type { ApiErrorResponse } from '@/types/api/clientApi.api.types'
import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import handleUnauthenticated from './errorResponse/handleUnauthenticated'
import { addRequestCount } from './errorResponse/refreshRequests'
import unhandledError from './errorResponse/unhandledError'

export const successInterceptor = (success: AxiosResponse) => {
  addRequestCount(-1)
  return success.data
}

export const errorInterceptor = (client: AxiosInstance) => async (error: AxiosError<ApiErrorResponse>) => {
  addRequestCount(-1)

  const isLogin = error.config?.url === '/users/signin'
  const isRefresh = error.config?.url === '/users/refresh-token'

  if (!isLogin && error.response && error.response.status === 401) {
    if (isRefresh) {
      state.global.removeLocalStorageSensitiveInfo()
      sessionStorage.removeItem('wb-state')
      window.location.href = '/login'
      loadStates()
    } else {
      return await handleUnauthenticated(client, error)
    }
  }

  return await unhandledError(error)
}

export default (client: AxiosInstance) => {
  client.interceptors.response.use(
    successInterceptor,
    errorInterceptor(client)
  )
}
