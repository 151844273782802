<template>
  <wb-modal
    width="40"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', {element: props.location?.name}) }}
    </template>

    <p class="is-size-400">
      {{ i18n.t('mywb.location.delete-location-description', {element: props.location?.name}) }}
    </p>

    <location-group
      v-model="data.locationId"
      :error="errors.locationId"
      :disabled="compute.hasTwoLocations"
      :filter-group="props.location?.group_uid"
      class="is-fullwidth mt-8"
    />

    <template #actions>
      <div class="is-flex">
        <wb-button
          data-test-id="cancelButton"
          class="mr-12"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          type="danger"
          size="block"
          :label="i18n.t('mywb.common.move-delete')"
          :loading="data.loading"
          @click="validate(methods.handleClick)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import LocationGroup from '@/components/locations/LocationGroup.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import state from '@/state'
import type { Locations } from '@/types'
import type { Charger } from '@/types/data/charger.data.types'
import { useValidator } from '@/hooks/useValidator.hook'

const notify = useNotify()
const i18n = useI18n()
const emit = defineEmits(['on-close', 'on-confirm'])
const { yup, errors, defineSchema, validate } = useValidator()

interface PropsType {
  location?: Locations.Location,
  chargers: Charger[]
}

const props = defineProps<PropsType>()

interface Data {
  loading: boolean,
  locationId: number | undefined
}

const data = reactive<Data>({
  loading: false,
  locationId: undefined
})

defineSchema(data, {
  locationId: yup.number().required()
})

const compute = reactive({
  hasTwoLocations: computed(() => {
    return state.groups.groupRelatedToOrganizationWithChargers?.subgroups?.length === 2
  })
})

const methods = {
  async handleClick () {
    if (!props.location) return
    data.loading = true

    try {
      await Promise.all(props.chargers.map(charger => {
        return api.chargers.updateChargerLocation({
          chargerId: charger.id,
          config: { group: data.locationId }
        })
      }))

      await api.locations.deleteLocation({ id: props.location.id })
      emit('on-confirm')
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }

    emit('on-close')
    data.loading = false
  }
}

function created () {
  trackDataScreen('chargers-delete-group-with-chargers')

  if (compute.hasTwoLocations) {
    const locationId = state.groups.groupRelatedToOrganizationWithChargers?.subgroups
      ?.find?.(group => group.uid !== props.location?.group_uid)?.id

    if (locationId) {
      data.locationId = locationId
    }
  }
}

created()
</script>
