import { numbers } from '@wallbox/toolkit-ui'
import i18n from '@/engine/lang'
import type { Session } from '@/types'

interface Unit {
  unit: string
  divider: number
}

const units: Record<string, Unit> = {
  kwh: {
    unit: 'mywb.common.kwh',
    divider: 1
  },

  mwh: {
    unit: 'mywb.common.mwh',
    divider: 1000
  }
}

export const getEnergyFromWatts = (value = 0): number => {
  const amount = value > 0 ? value / 1000 : 0

  return amount
}

export const getSessionEnergy = (session: Session.Session, locale = 'en-US'): string => {
  const sessionEnergy = getEnergyFromWatts(session.energy)
  const sessionMidEnergy = getEnergyFromWatts(session.mid_energy)
  const energy = sessionEnergy + sessionMidEnergy

  return energy >= 0
    ? `${numbers.toLocaleNumber(energy, locale, 3, 3)} ${i18n.global.t(units.kwh.unit)}`
    : '-'
}

export const getEnergyFromKiloWatts = (energy: number, locale = i18n.global.locale) => {
  const unit = units[energy >= 1000 ? 'mwh' : 'kwh']
  energy = energy / unit.divider
  const decilams = energy % 1 !== 0 ? 3 : 0

  return `${numbers.toDecimal(energy, locale, decilams, decilams)} ${i18n.global.t(unit.unit)}`
}
