<template>
  <div>
    <div
      class="grid"
      :class="{'grid-2-columns g-24': compute.hasStates && !props.isStateHidden}"
    >
      <wb-select
        v-model="compute.countryCode"
        name="country"
        uid="_country"
        data-test-id="countrySelect"
        :label="i18n.t('mywb.common.country')"
        option-label="name"
        :reduce="(country: Currency.Country) => country.code"
        :options="state.global.getCountries"
        :disabled="props.loading || !state.global.getCountries.length || props.isCountryDisabled"
        :loading="!state.global.getCountries.length"
        :error="data.errors.country"
      >
        <template #label>
          <span>{{ i18n.t('mywb.common.country') }}</span>
        </template>
      </wb-select>

      <wb-select
        v-if="compute.hasStates && !props.isStateHidden"
        v-model="compute.stateCode"
        uid="_state"
        name="state"
        data-test-id="stateSelect"
        :label="i18n.t('mywb.common.state')"
        option-label="label"
        :reduce="(state: Currency.State) => state.iso2"
        :disabled="props.loading || !compute.hasStates || props.isStateDisabled"
        :options="data.optionsState"
        :error="data.errors.state || props.stateError"
        @change="methods.onChangeState"
      />
    </div>
    <p v-if="compute.disableReason" class="is-size-300 has-text-grey-500 mt-8">
      {{ compute.disableReason }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'

import state from '@/state'
import { validate } from 'vee-validate'
import api from '@/api'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Currency } from '@/types'

const i18n = useI18n()

interface PropsType {
  countryId?: string | number
  countryCode?: string
  stateCode?: string
  loading?: boolean
  isCountryDisabled?: boolean
  isStateHidden?: boolean
  isStateDisabled?: boolean
  stateName?: string
  countryKey?: keyof Currency.Country
  stateError?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  countryId: undefined,
  countryCode: undefined,
  stateCode: undefined,
  stateName: undefined,
  countryKey: undefined,
  stateError: undefined
})

interface Events {
  (e: 'update:countryCode', code: string | number | undefined): void,
  (e: 'update:stateCode', code: string | number | undefined): void,
  (e: 'update:stateName', name: string | number | undefined): void,
  (e: 'update:countryId', id: string | number | undefined): void,
  (e: 'on-update', payload: { hasStates: boolean }): void
}

const emit = defineEmits<Events>()

interface Data {
  rules: {
    country: string,
    state: string
  }
  errors: {
    country?: string
    state?: string
  }
  optionsState: Currency.State[]
}

const data: Data = reactive({
  rules: {
    country: 'required',
    state: computed(() => compute.hasStates ? 'required' : '')
  },
  errors: {},
  optionsState: []
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.payments_account),

  hasStates: computed(() => !!data.optionsState.length),

  countryCode: computed({
    get () {
      if (props.countryId) {
        return methods.findCountryValue(props.countryId, 'id', 'code')
      }

      return methods.findCountryValue(props.countryCode, props.countryKey, 'code')
    },

    set (value: string | number | undefined) {
      if (value) {
        emit('update:countryCode', methods.findCountryValue(value, 'code', props.countryKey))
        emit('update:countryId', methods.findCountryValue(value, 'code', 'id'))
      }
    }
  }),

  stateCode: computed({
    get () {
      return props.stateCode
    },
    set (value: string | undefined) {
      if (value) {
        emit('update:stateCode', value)
        emit('update:stateName', methods.findState(value))
      }
    }
  }),

  disableReason: computed(() => {
    if (props.isCountryDisabled) {
      return i18n.t('mywb.common.country-field-cannot-be-change')
    }
    return ''
  })
})

const methods = {
  async isValidCountry () {
    const countryValidation = await validate(compute.countryCode, data.rules.country)
    data.errors.country = countryValidation.errors[0]
    return !!countryValidation.valid
  },

  async areValidCountryAndState () {
    const countryValidation = await validate(compute.countryCode, data.rules.country)
    const stateValidation = await validate(compute.stateCode, data.rules.state)
    data.errors.country = countryValidation.errors[0]
    data.errors.state = stateValidation.errors[0]
    return !!(countryValidation.valid && stateValidation.valid)
  },

  findCountryId (code: string) {
    return state.global.getCountries.find(country => country.code === code)?.id
  },

  findState (iso2: string) {
    return data.optionsState.find(state => state.iso2 === iso2)?.name || ''
  },

  findCountryValue (
    value: string | number,
    key: keyof Currency.Country = 'code',
    obtain: keyof Currency.Country = 'code'
  ) {
    return state.global.getCountries.find(country => country[key] === value)?.[obtain]
  },

  async onChangeCountry (countryCode: string) {
    await methods.getStates(countryCode)
    compute.stateCode = undefined
    emit('on-update', { hasStates: compute.hasStates })
  },

  onChangeState () {
    emit('on-update', { hasStates: compute.hasStates })
  },

  async getStates (countryCode: string) {
    data.optionsState = (await api.global.getStatesByCountry(countryCode) || [])
      .sort((a, b) => a.name.localeCompare(b.name))
  }
}

watch(() => compute.countryCode, async (value, oldValue) => {
  if (value) {
    await methods.getStates(value.toString())
    emit('on-update', { hasStates: compute.hasStates })
    if (oldValue && value !== oldValue) {
      compute.stateCode = undefined
    }
  }
}, { immediate: true })

defineExpose({
  isValidCountryState: methods.areValidCountryAndState,
  isValidCountry: methods.isValidCountry
})
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr auto;
  }
}

.grid-2-columns {
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.is-label-icon {
  font-size: 1.1rem;
  margin-left: 1rem;
  top: 2px;
  position: relative;
}
</style>
