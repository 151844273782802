<template>
  <wb-card
    :headline="i18n.t('mywb.user.personal-data')"
    data-test-id="personalDataSection"
  >
    <template #content>
      <wb-card filled alignment="center">
        <template #content>
          <p>
            {{ state.user.userLogged.name }} {{ state.user.userLogged.surname }}
          </p>
          <p>
            {{ state.user.userLogged.email }}
          </p>
          <p data-test-id="countryAndTimeZone">
            {{ compute.countryName }} {{ state.user.userLogged.time_zone.replace('_', ' ') }}
          </p>
        </template>

        <template #actions>
          <wb-button
            type="white"
            outlined
            :label="i18n.t('mywb.common.edit')"
            icon="edit"
            class="edit"
            data-test-id="editBtn"
            @click="data.openPersonalDataAccountModal = true"
          />
        </template>
      </wb-card>
    </template>

    <template #modal>
      <personal-data-account-modal
        v-if="data.openPersonalDataAccountModal"
        @close="data.openPersonalDataAccountModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import state from '@/state'
import PersonalDataAccountModal from '@/components/modals/PersonalDataAccountModal.vue'

const i18n = useI18n()

const data = reactive({
  openPersonalDataAccountModal: false
})

const compute = reactive({
  countryName: computed(() => {
    const country = state.global.getCountries.find(country => country.id === state.user.userLogged.country_id)
    return country?.name
  })
})
</script>
