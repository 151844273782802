<template>
  <div>
    <wb-notification
      v-if="data.hasPendingPayments"
      icon="info_filled"
      class="mb-24"
    >
      <span class="mr-8">
        {{ i18n.t('mywb.payments.configure-valid-payment') }}
      </span>
      <wb-link :to="{ name: 'account', hash:'#payment' }">
        {{ i18n.t('mywb.payments.add-payment-method') }}
      </wb-link>
    </wb-notification>

    <payments-history-table
      :invoices="data.invoices"
      :fees="data.fees"
      :loading="data.loading"
      @on-update-data="methods.loadData"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import PaymentsHistoryTable from '@/components/tables/PaymentsHistoryTable.vue'
import api from '@/api'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Invoice } from '@/types'

const i18n = useI18n()

interface Data {
  hasPendingPayments: boolean
  invoices: Invoice.PlanInvoice[]
  fees: Invoice.Fee[]
  loading: boolean
}
const data = reactive<Data>({
  hasPendingPayments: false,
  invoices: [],
  fees: [],
  loading: false
})

const methods = {
  async loadData () {
    data.loading = true

    data.fees = (await api.invoices.getFeesInvoices(state.organizations.getCurrentOrganization.group_uid)).data

    data.invoices = (await api.invoices.getPlanInvoices(state.organizations.getCurrentOrganization.group_id)).result
    data.loading = false

    data.hasPendingPayments = data.invoices.some(bill => bill.status === 'uncollectible' || bill.status === 'open')
  }
}

function created () {
  methods.loadData()
}

created()
</script>
