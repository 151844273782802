<template>
  <div class="locations-maps">
    <div
      :ref="setRef('googleMap')"
      class="google-map"
      :class="{'is-open': data.locationSelected?.name }"
    />

    <wb-button
      rounded
      icon="chevron_right"
      type="white"
      outlined
      class="button-collapse"
      :class="{'is-open': data.locationSelected?.name }"
      @click="data.locationSelected = undefined"
    />

    <location-settings-overview
      class="summary"
      :class="{'is-open': data.locationSelected?.name }"
      :location="data.locationSelected"
      :group="compute.groupRelated"
      is-title-a-link
      @on-edit="emit('on-edit', $event)"
      @on-delete="emit('on-delete', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef, objects } from '@wallbox/toolkit-ui'

import { useLoadGoogleMaps } from '@/hooks/useLoadGoogleMaps'
import { computed, onMounted, reactive, watch } from 'vue'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import LocationSettingsOverview from '@/components/locations/LocationSettingsOverview.vue'
import type { Group, Locations } from '@/types'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import state from '@/state'

const { refs, setRef } = useTemplateRef()

interface PropsType {
  locations: Locations.Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  locations: () => []
})

const { map, initMap, createCustomCluster, createCustomMarkerIcon } = useLoadGoogleMaps({
  center: { lat: props.locations[0].latitude, lng: props.locations[0].longitude }
})

type Events = {
  (e: 'on-edit', location?: Locations.Location): void,
  (e: 'on-delete', location?: Locations.Location): void,
}

const emit = defineEmits<Events>()

let markers: google.maps.Marker[] = []
const lines: Record<string, google.maps.Polyline> = {}

let clusterer: MarkerClusterer

interface DataType {
  map?: google.maps.Map,
  locationSelected?: Locations.Location
}

const data: DataType = reactive({
  map
})

type LocationParsed = Locations.Location & { latlng: string, realLatitude?: number, realLongitude?: number }

const compute = reactive({
  locationsParsed: computed((): LocationParsed[] => {
    const splittedLocations = methods.splitLocationsThatHasSamePosition(props.locations)
    const locationsParsed = splittedLocations.reduce((locations, locationChunk) => {
      let locationsMoved = locationChunk

      if (locationChunk.length > 1) {
        locationsMoved = methods.moveSameLocationsPosition(locationChunk)
      }

      return locations.concat(locationsMoved)
    }, [])

    return locationsParsed
  }),

  groupRelated: computed(() => {
    const groups = state.groups.get('groups') as Group.GroupChargersTree[]

    return groups.find((group: Group.GroupChargersTree) => data.locationSelected?.group_uid === group.uid)
  })
})

const methods = {
  moveSameLocationsPosition (locations: Array<Locations.Location & { latlng: string }>) {
    const lng_radius = 0.0004
    const lat_to_lng = 111.23 / 71.7
    const step = 2 * Math.PI / locations.length
    const lat_radius = lng_radius / lat_to_lng

    let angle = 0.5

    return locations.map(location => {
      const realLongitude = location.longitude
      const realLatitude = location.latitude

      const longitude = location.longitude + (Math.cos(angle) * lng_radius)
      const latitude = location.latitude + (Math.sin(angle) * lat_radius)

      angle += step

      return {
        ...location,
        longitude,
        latitude,
        realLongitude,
        realLatitude
      }
    }).filter(Boolean as unknown as ExcludesNullish)
  },

  splitLocationsThatHasSamePosition (locations: Locations.Location[]) {
    const locationsWithKey = locations.map(location => {
      if (location.latitude && location.longitude) {
        return {
          ...location,
          latlng: location.latitude.toFixed(4) + location.longitude.toFixed(4)
        }
      }

      return null
    }).filter(Boolean as unknown as ExcludesNullish)

    return Object.values(objects.groupBy(locationsWithKey, 'latlng'))
  },

  drawPolylinesToRealCenterOfLocation (location: LocationParsed) {
    if (!location.realLatitude || !location.realLongitude) {
      return
    }

    const path = [
      { lat: location.latitude, lng: location.longitude },
      { lat: location.realLatitude, lng: location.realLongitude }
    ]

    const line = new window.google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: '#7a7a7a',
      strokeOpacity: 1.0,
      strokeWeight: 1
    })

    lines[location.id] = line

    data.map && line.setMap(data.map)
  },

  async setMarkers () {
    markers = compute.locationsParsed.map((location) => {
      const marker = new window.google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        icon: createCustomMarkerIcon(),
        label: {
          text: location.name,
          color: '#000000',
          fontSize: '12px'
        },
        title: location.id.toString()
      })

      methods.drawPolylinesToRealCenterOfLocation(location)

      marker.addListener('click', (marker: { latLng: google.maps.LatLng }) => {
        data.map?.panTo(marker.latLng)
        data.locationSelected = location
      })

      return marker
    })

    clusterer = new MarkerClusterer({ markers, map: data.map, renderer: createCustomCluster() })
  }
}

watch(() => compute.locationsParsed, () => {
  if (markers.length > 0) {
    clusterer.removeMarkers(markers)

    Object.values(lines).forEach(line => line.setMap(null))
    const markersToDraw = markers.filter(marker => {
      return compute.locationsParsed.some(({ id }) => id.toString() === marker.getTitle())
    })

    clusterer.addMarkers(markersToDraw)

    markersToDraw.forEach(marker => data.map && lines[marker.getTitle() ?? '']?.setMap?.(data.map))
    clusterer.render()
  }
})

onMounted(async () => {
  await initMap({ el: refs.googleMap })
  methods.setMarkers()
})
</script>

<style lang="postcss" scoped>
.locations-maps {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  overflow: hidden;

  @media (--tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--desktop) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
}

.google-map {
  width: 100%;
  height: 100%;
  min-height: 580px;
  border-radius: 8px;
  grid-column: 1 / -1;
  grid-row: 1;

  &.is-open {
    grid-column: 1 / -2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.button-collapse {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
  height: 24px !important;
  width: 24px !important;
  right: 12px;
  top: 8px;
  transform: scale(0);
  transition: transform 100ms ease-in;

  @media (--tablet) {
    grid-column: 2;
  }

  &.is-open {
    transform: scale(1);
  }
}

.summary {
  grid-column: -1 / -2;
  grid-row: 1;
  z-index: 2;
  transform: translateX(1000px);
  transition: transform 300ms ease-in;

  @media (--tablet) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.is-open {
    transform: translateX(0);
  }
}
</style>
