import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import state from '@/state'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import ENV from '@/engine/env/web.env'

enum clients {
  WALLBOX = 'wallbox',
  IBERDROLA = 'iberdrola'
}

interface routeAccess {
  path?: string
  name?: string
  redirect: Partial<NavigationGuardNext>
  access: {
    roles?: EnumRoles[]
    clients?: clients[]
    plan?: EnumPlans[]
    stripe?: boolean
    freeProduct?: boolean
  }
}

const protectedRoutes: routeAccess[] = [
  {
    path: '/dashboard',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
    }
  },
  {
    path: '/users',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
    }
  },
  {
    path: '/payments-configuration',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX],
      plan: [EnumPlans.BUSINESS, EnumPlans.OPERATOR]
    }
  },
  {
    path: '/payments/rates',
    redirect: { name: 'payments-configuration' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX],
      plan: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
      stripe: true
    }
  },
  {
    path: '/payments/invoices',
    redirect: { name: 'payments-configuration' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX],
      plan: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
      stripe: true
    }
  },
  {
    path: '/rates/create/post-payment',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX],
      plan: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
      stripe: true
    }
  },
  {
    path: '/rates/create/pre-payment',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX],
      plan: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
      stripe: true
    }
  },
  {
    name: 'payment-history',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      clients: [clients.WALLBOX]
    }
  },
  {
    name: 'plans',
    redirect: { name: 'chargers' },
    access: {
      freeProduct: false,
      roles: [EnumRoles['super-admin']],
      plan: [EnumPlans.BUSINESS, EnumPlans.BASIC]
    }
  },
  {
    name: 'plan-business',
    redirect: { name: 'chargers' },
    access: {
      freeProduct: false
    }
  },
  {
    path: '/locations',
    redirect: { name: 'chargers' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin],
      clients: [clients.WALLBOX]
    }
  },
  {
    path: 'organizations/:organizationUid',
    redirect: { name: 'organizations' },
    access: {
      roles: [EnumRoles['super-admin'], EnumRoles.operator],
      plan: [EnumPlans.OPERATOR],
      clients: [clients.WALLBOX]
    }
  }
]

const validator: Record<string, (param: any) => boolean> = {
  roles (roles: EnumRoles[]): boolean {
    const isRole = state.organizations.isRole()

    return isRole(roles)
  },

  clients (clients: string) {
    return clients.includes(ENV.portal.client)
  },

  freeProduct (hasFreeProduct: boolean) {
    return state.groups.hasFreeProduct === hasFreeProduct
  },

  plan (plan: EnumPlans[]): boolean {
    const isPlan = state.organizations.isPlan()

    return isPlan(plan)
  },

  stripe (needConnection: boolean) {
    return !!state.organizations.getCurrentOrganization.payments_account === needConnection
  }
}

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const protectedRoute = protectedRoutes.find(route => {
    return route.path === to.path || route.name === to.name
  })

  if (protectedRoute != null) {
    const keys = Object.keys(protectedRoute.access) as Array<keyof typeof protectedRoute.access>

    keys.forEach(rule => {
      if (validator[rule] && !validator[rule](protectedRoute.access[rule])) {
        return next(protectedRoute.redirect)
      }
    })
  }

  next()
}
