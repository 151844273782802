<template>
  <div>
    <wb-modal
      width="80"
      hide-close-button
      blocked
      @close="emit('close')"
      @on-key-enter="methods.handleNextButton"
    >
      <wb-progress-bar
        class="mb-16"
        :val="currentStatesCount"
        :max="totalStatesCount"
        bar-color="var(--primary-500)"
      />

      <Transition :name="data.stepDirection">
        <div v-if="isCurrentState('inviteUsers')" class="content-step">
          <invite-users
            :ref="setRef('inviteUsers')"
            v-model="data.invitation.users"
            :existing-emails="compute.exisitingEmails"
          />
        </div>

        <div v-else-if="isCurrentState('usersRole')" class="content-step">
          <role-users
            :ref="setRef('usersRole')"
            v-model="data.invitation.profile"
          />
        </div>

        <div v-else-if="isCurrentState('usersAccess')" class="content-step">
          <access-users
            :ref="setRef('usersAccess')"
            v-model="data.invitation.invitationToAccessGroup"
            @on-click="methods.handleNextButton"
          />
        </div>

        <div v-else-if="isCurrentState('usersAssignChargers')" class="content-step">
          <assign-access-group-users
            v-if="data.invitation.invitationToAccessGroup"
            :ref="setRef('usersAssignChargers')"
            v-model:accessConfigId="data.invitation.accessConfigId"
            v-model:chargersSelected="data.invitation.chargers"
            :group="props.group"
            @on-create-access-group="methods.handleCustomButton('createAccessGroup')"
          />

          <assign-chargers-users
            v-if="!data.invitation.invitationToAccessGroup"
            :ref="setRef('usersAssignChargers')"
            v-model:chargersSelected="data.invitation.chargers"
            :chargers-celected="data.invitation.chargers"
          />
        </div>

        <div v-else-if="isCurrentState('createAccessGroup')" class="content-step">
          <create-access-group
            :ref="setRef('createAccessGroup')"
            v-model:accessConfigId="data.invitation.accessConfigId"
            v-model:chargersSelected="data.invitation.chargers"
            :group="props.group"
          />
        </div>

        <div v-else-if="isCurrentState('usersPaymentSubscription')" class="content-step">
          <payment-subscription-users
            :ref="setRef('usersPaymentSubscription')"
            v-model:subscription="data.invitation.hasSubscription"
            :has-subscription="data.invitation.hasSubscription"
          />
        </div>

        <div v-else-if="isCurrentState('finish')" class="content-step">
          <send-invitations-users
            :invitation="data.invitation"
            @on-finish="emit('on-users-invited')"
          />
        </div>
      </Transition>

      <template #actions>
        <div v-if="!isCurrentState('finish')" class="button-actions">
          <wb-button
            v-if="isCurrentState('inviteUsers')"
            data-test-id="cancelButton"
            type="white"
            outlined
            :label="i18n.t('mywb.common.cancel')"
            @click="emit('close')"
          />
          <wb-button
            v-if="!isCurrentState('inviteUsers')"
            data-test-id="backButton"
            icon="arrow_back"
            type="white"
            outlined
            class="mr-24"
            :label="i18n.t('mywb.common.back')"
            @click="methods.handleBackButton"
          />
          <wb-button
            data-test-id="nextButton"
            :label="!isCurrentState('usersPaymentSubscription')
              ? i18n.t('mywb.common.continue') : i18n.t('mywb.common.send-invitations')"
            :icon="!isCurrentState('usersPaymentSubscription') ? 'arrow_forward' : 'send'"
            icon-position="right"
            :loading="data.loading"
            @click="methods.handleNextButton"
          />
        </div>
      </template>
    </wb-modal>
  </div>
</template>

<script setup lang="ts">
import InviteUsers from '@/components/invitations/InviteUsers.vue'
import RoleUsers from '@/components/invitations/RoleUsers.vue'
import AccessUsers from '@/components/invitations/AccessUsers.vue'
import AssignAccessGroupUsers from '@/components/invitations/AssignAcccessGroupUsers.vue'
import AssignChargersUsers from '@/components/invitations/AssignChargersUsers.vue'
import CreateAccessGroup from '@/components/invitations/CreateAccessGroup.vue'
import PaymentSubscriptionUsers from '@/components/invitations/PaymentSubscriptionUsers.vue'
import SendInvitationsUsers from '@/components/invitations/SendInvitationsUsers.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { EnumRoles } from '@/utilities/user-roles'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { reactive, computed, watch } from 'vue'
import { useStateMachine } from '@/hooks'
import { permissions } from '@/engine/clients'
import type { AccessConfig, User } from '@/types'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()

interface Props {
  group: User.UsersGroupWithUserExpanded,
  userInvitations: Array<{
    email: string
  }>
  accessConfig?: AccessConfig.AccessConfig
}
const props = defineProps<Props>()

const { currentStateKey, isCurrentState, send, currentStatesCount, totalStatesCount } = useStateMachine({
  initial: 'inviteUsers',
  states: {
    inviteUsers: {
      transitions: {
        next: 'usersRole'
      }
    },

    usersRole: {
      transitions: {
        next: 'usersAccess',
        back: 'inviteUsers'
      }
    },

    usersAccess: {
      transitions: {
        next: 'usersAssignChargers',
        back: 'usersRole'
      }
    },

    usersAssignChargers: {
      transitions: {
        back: 'usersAccess',
        next: permissions.showPayments ? 'usersPaymentSubscription' : 'finish',
        createAccessGroup: 'createAccessGroup'
      }
    },

    createAccessGroup: {
      transitions: {
        back: 'usersAssignChargers',
        next: permissions.showPayments ? 'usersPaymentSubscription' : 'finish'
      }
    },

    usersPaymentSubscription: {
      transitions: {
        back: 'usersAssignChargers',
        next: 'finish'
      }
    },

    finish: {
      transitions: {}
    }
  }
})

interface Events {
  (e: 'on-users-invited'): void,
  (e: 'close'): void,
}
const emit = defineEmits<Events>()

interface Data {
  invitation: {
    organizationId: number
    invitationToAccessGroup: number
    profile: EnumRoles
    accessConfigId?: number
    chargers: number[]
    users: AccessConfig.UserInvitation[]
    hasSubscription: 0 | 1
  },
  stepDirection: 'slide-left' | 'slide-right'
  loading: boolean
}

const data = reactive<Data>({
  invitation: {
    organizationId: props.group.id,
    invitationToAccessGroup: -1,
    profile: EnumRoles.user,
    accessConfigId: undefined,
    chargers: [],
    users: [],
    hasSubscription: 0
  },
  stepDirection: 'slide-left',
  loading: false
})

watch(() => data.invitation.invitationToAccessGroup, (value, oldValue) => {
  if (value !== oldValue) {
    data.invitation.chargers = []
    data.invitation.accessConfigId = undefined
  }
})

const compute = reactive({
  exisitingEmails: computed(() => {
    const userAccessConfigEmails = props.group.users.map(user => user.email)
    const userInvitations = props.userInvitations.map(user => user.email)

    const groupAccessConfigEmails = props.group.accessConfigs
      .map(accessConfig => accessConfig.users).flat()
      .map(user => user.email) || []

    return [...userAccessConfigEmails, ...userInvitations, ...groupAccessConfigEmails]
  })
})

const methods = {
  handleBackButton () {
    data.stepDirection = 'slide-left'
    send('back')
  },

  async handleNextButton () {
    data.loading = true
    if (refs[currentStateKey.value] && await refs[currentStateKey.value].validateForm()) {
      data.stepDirection = 'slide-right'
      send('next')
    }
    data.loading = false
  },

  handleCustomButton (stateName: 'next' | 'back' | 'createAccessGroup') {
    data.stepDirection = 'slide-right'
    send(stateName)
  }
}

function created () {
  trackDataScreen('invite-users')
  const hasToPreselectChargers =
    state.groups.groupRelatedToOrganizationWithChargers.chargers?.length < 3 &&
    state.groups.groupRelatedToOrganizationWithChargers.subgroups?.length === 0

  data.invitation.chargers = hasToPreselectChargers
    ? state.groups.groupRelatedToOrganizationWithChargers.chargers?.map(charger => charger.id)
    : []
}

created()
</script>

<style lang="postcss" scoped>
:deep(.button-actions) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.modal-grid) {
  min-height: calc(100vh - 8rem);
}

:deep(.modal-content) {
  overflow-x: hidden;
  max-height: calc(100vh - 18rem) !important;
}

.content-step {
  @media (--tablet) {
    width: calc(80rem - 8rem);
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 360ms ease-out;
  position: absolute;
}

.slide-right-enter-from {
  transform: translateX(80rem);
}

.slide-right-leave-to {
  transform: translateX(-80rem);
}

.slide-left-enter-from {
  transform: translateX(-80rem);
}

.slide-left-leave-to {
  transform: translateX(80rem);
}
</style>
