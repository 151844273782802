interface env {
  name: string
  client: string
  api: string
  userApi: string
  perseusApi: string
  mixpanel: {
    token?: string
    active: boolean
  }
  web: string[]
  featureFlags: {
    baseURL?: string
    active: boolean
  }
  oauth: {
    google?: string
    apple?: string
  }
  datadog: {
    active: boolean
    applicationId?: string
    clientToken?: string
    env?: string
  }

  formspree: {
    form: string
  }

  pwa: {
    active: boolean
  }

  deepLinks: Record<string, string>

  userpilot: {
    token?: string
    active: boolean
  }
}

const envs: Record<string, env> = {
  default: {
    name: 'default',
    client: 'wallbox',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api.preprod.wall-box.com',
    perseusApi: 'api-proxy.preprod.wall-box.com/perseus',
    mixpanel: {
      token: 'bddf6d1925ad08fd68bf304f97e6761d',
      active: false
    },
    web: [],
    featureFlags: {
      baseURL: 'https://mywallbox.pages.dev/functions/feature-flag',
      active: true
    },
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },
    datadog: {
      active: false
    },

    formspree: {
      form: 'xvoloega'
    },

    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },

    userpilot: {
      active: false
    }
  },
  'wallbox-iberdrola-pre': {
    name: 'Iberdrola pre',
    client: 'iberdrola',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api.preprod.wall-box.com',
    perseusApi: 'api-proxy.preprod.wall-box.com/perseus',
    web: [
      'iberdrola-pre.wallbox.com'
    ],
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },
    datadog: {
      active: false
    },
    formspree: {
      form: 'xvoloega'
    },

    pwa: {
      active: false
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },

    mixpanel: {
      token: '',
      active: false
    },

    userpilot: {
      active: false
    },

    featureFlags: {
      baseURL: 'https://mywallbox.pages.dev/functions/feature-flag',
      active: false
    }
  },
  'wallbox-production': {
    name: 'Wallbox production',
    client: 'wallbox',
    api: 'api.wall-box.com',
    userApi: 'user-api.wall-box.com',
    perseusApi: 'api-proxy.preprod.wall-box.com/perseus',
    web: [
      'my.wallbox.com',
      'portal.wallbox.com'
    ],
    mixpanel: {
      active: true,
      token: 'cbda0f738b2f35be8d06fe7170a60503'
    },
    userpilot: {
      token: '19vq83b9',
      active: true
    },
    oauth: {
      google: '324006994476-pr4jjbohohcm536944lkk0117peqjvou.apps.googleusercontent.com',
      apple: 'com.wallbox.prod'
    },
    featureFlags: {
      baseURL: 'https://mywallbox.pages.dev/functions/feature-flag',
      active: true
    },
    datadog: {
      active: true,
      applicationId: '92d8bedc-e714-450b-bee7-9bac0fe83b1d',
      clientToken: 'pubec814f6568c94e0d35c51dcf18b45423',
      env: 'production'
    },
    formspree: {
      form: 'xwkykgpb'
    },

    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.app.link/public-charge'
    }
  },
  'wallbox-pre': {
    name: 'Wallbox pre',
    client: 'wallbox',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api.preprod.wall-box.com',
    perseusApi: 'api-proxy.preprod.wall-box.com/perseus',
    web: [
      'portal-pre.wallbox.com',
      'mywallbox.pages.dev'
    ],
    userpilot: {
      token: '19vq83b9',
      active: true
    },
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },

    featureFlags: {
      baseURL: 'https://mywallbox.pages.dev/functions/feature-flag',
      active: true
    },
    datadog: {
      active: false
    },
    formspree: {
      form: 'xvoloega'
    },

    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },

    mixpanel: {
      token: '',
      active: false
    }
  },
  'wallbox-iberdrola-production': {
    name: 'Iberdrola production',
    client: 'iberdrola',
    api: 'api.wall-box.com',
    userApi: 'user-api.wall-box.com',
    perseusApi: 'api-proxy.preprod.wall-box.com/perseus',
    web: [
      'iberdrola.wallbox.com'
    ],
    mixpanel: {
      token: '7934a65accf89301282f8e97112c3b98',
      active: true
    },
    oauth: {
      google: '324006994476-pr4jjbohohcm536944lkk0117peqjvou.apps.googleusercontent.com',
      apple: 'com.wallbox.prod'
    },
    datadog: {
      active: true,
      applicationId: '92d8bedc-e714-450b-bee7-9bac0fe83b1d',
      clientToken: 'pubec814f6568c94e0d35c51dcf18b45423'
    },
    formspree: {
      form: 'xwkykgpb'
    },

    pwa: {
      active: true
    },

    deepLinks: {
      publicCharge: 'https://mywallbox.app.link/public-charge'
    },

    userpilot: {
      active: false
    },

    featureFlags: {
      baseURL: 'https://mywallbox.pages.dev/functions/feature-flag',
      active: false
    }
  }
}

export default envs
