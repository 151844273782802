<template>
  <shared-filter
    v-model="compute.filterStatus"
    icon="assignment"
    :name="i18n.t('mywb.common.status')"
    :empty-text="i18n.t('mywb.users.empty')"
    option-key="value"
    :options="compute.statusOptions"
    :reduce="(item:filterType) => item.value"
    @on-error="state.filters.resetFilterValue({ filter: 'invoiceFilters', key: 'status' })"
  />

  <shared-filter
    v-model="compute.filterType"
    icon="sessions"
    :name="i18n.t('mywb.common.type')"
    option-key="value"
    :options="compute.typeOptions"
    :reduce="(item:filterType) => item.value"
    @on-error="state.filters.resetFilterValue({ filter: 'invoiceFilters', key: 'type' })"
  />

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'sessionsFilters' })"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import SharedFilter from '@/components/filters/SharedFilter.vue'

export type filterType = {
  label: string,
  value: number
}

const i18n = useI18n()

const compute = reactive({
  typeOptions: computed(() => [
    {
      label: i18n.t('mywb.charger.pay-per-month'),
      value: 'post-payment'
    },
    {
      label: i18n.t('mywb.charger.pay-per-charge'),
      value: 'pay_per_charge'
    }
  ]),

  statusOptions: computed(() => [
    {
      label: i18n.t('mywb.common.pending'),
      value: 'pending'
    },
    {
      label: i18n.t('mywb.payments.paid'),
      value: 'success'
    }
  ]),

  filterStatus: computed({
    get () {
      return state.filters.invoiceFilters?.status
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'status', value })
    }
  }),

  filterType: computed({
    get () {
      return state.filters.invoiceFilters?.type
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'type', value })
    }
  })
})

</script>
