<template>
  <div>
    <wb-card
      :headline="i18n.t('mywb.common.settings-overview')"
      filled
    >
      <template #place-upper-right>
        <wb-button
          data-test-id="viewAllSettings"
          :label="i18n.t('mywb.common.view-all')"
          type="white"
          inverted
          size="small"
          class="is-button-grey"
          :to="{ name: 'charger-settings' }"
        />
      </template>
      <template #content>
        <div class="is-size-300 is-font-weight-500">
          {{ i18n.t('mywb.common.power') }}
        </div>
        <wb-button-card
          data-test-id="widgetCurrent"
          :title="i18n.t('mywb.common.power-limit')"
          :subtitle="`${state.charger.getCurrentCharger.chargerData.maxChargingCurrent + i18n.t('mywb.charger.amps')}`"
          status="enabled"
          action="emit"
          @on-click="data.editingMaxCurrent = true"
        >
          <template #icon>
            <wb-semi-circle-progress
              :percentage="compute.percentageCurrentChargerAmps"
              :r="15"
              :stroke-width="3"
              stroke-color="var(--primary-700)"
            >
              <span class="wb-icons icon has-position-relative has-text-primary-700">bolt_filled</span>
            </wb-semi-circle-progress>
          </template>
        </wb-button-card>

        <wb-button-card
          data-test-id="widgetMidStatus"
          icon="mid"
          :title="i18n.t('mywb.common.mid-power-meter')"
          :subtitle="getMidStatus(state.charger.getCurrentCharger.chargerData)?.label || ''"
          :status="getMidStatus(state.charger.getCurrentCharger.chargerData)?.code || undefined"
        />

        <div class="is-grid-header mt-16">
          <div class="is-size-300 is-font-weight-500">
            {{ i18n.t('mywb.common.smart-features') }}
          </div>
          <wb-button
            :label="i18n.t('mywb.common.more-info')"
            type="white"
            inverted
            size="small"
            icon="info"
            icon-position="right"
            class="is-button-grey"
            @click="methods.goToSupport()"
          />
        </div>

        <wb-button-card
          v-if="compute.supportsDynamicPowerSharing"
          data-test-id="widgetDynamicPowerSharing"
          icon="power_sharing_filled"
          :title="i18n.t('mywb.common.dynamic-power-sharing')"
          :subtitle="compute.powerDynamicSharingStatus.label || ''"
          :status="compute.powerDynamicSharingStatus.status || undefined"
        />

        <wb-button-card
          v-if="compute.supportsPowerBoost"
          data-test-id="widgetPowerBoost"
          icon="charge_boost_filled"
          :title="i18n.t('mywb.common.power-boost')"
          :subtitle="compute.powerBoostStatus.label"
          :status="compute.powerBoostStatus.status"
        />

        <wb-button-card
          v-if="compute.supportsPowerSharing"
          data-test-id="widgetPowerSharing"
          icon="bolt_filled"
          :title="i18n.t('mywb.common.power-sharing')"
          :subtitle="getPowerSharingStatus(state.charger.getCurrentCharger.chargerData)?.label || ''"
          :status="getPowerSharingStatus(state.charger.getCurrentCharger.chargerData)?.status || undefined"
        />

        <wb-button-card
          v-if="compute.supportsEcoSmart"
          data-test-id="widgetEcoSmart"
          icon="leaf_filled"
          :title="i18n.t('mywb.common.eco-smart')"
          :subtitle="compute.ecoSmartStatus.label"
          :status="compute.ecoSmartStatus.status"
        />

        <div v-if="compute.supportsAutolock || compute.supportsGunLock" class="is-size-300 is-font-weight-500 mt-16">
          {{ i18n.t('mywb.common.security') }}
        </div>

        <wb-button-card
          v-if="compute.supportsAutolock"
          data-test-id="widgetAutoLock"
          icon="key_filled"
          :title="i18n.t('mywb.common.auto-lock')"
          :subtitle="compute.autolockStatus.label"
          :status="compute.autolockStatus.status"
          action="emit"
          @on-click="router.push({ name: 'charger-settings', query: { selected: 'lock' } })"
        />

        <wb-button-card
          v-if="compute.supportsGunLock"
          data-test-id="widgetSocketLocking"
          icon="ev_plug_type2"
          :title="i18n.t('mywb.charger.gun-lock')"
          :subtitle="compute.socketLokingStatus.label"
          :status="compute.socketLokingStatus.status"
          action="emit"
          @on-click="router.push({ name: 'charger-settings', query: { selected: 'gunLock' } })"
        />

        <div class="is-size-300 is-font-weight-500 mt-16">
          {{ i18n.t('mywb.common.connectivity') }}
        </div>

        <wb-button-card
          data-test-id="widgetConnectivity"
          :icon="compute.connectivityTypeIcon"
          :title="compute.connectivityTypeLabel"
          :subtitle="compute.connectivityByConnectionType"
          :status="!compute.isDisconnected ? 'enabled' : 'disabled'"
        />

        <wb-button-card
          v-if="compute.supportsOcpp"
          data-test-id="widgetOcpp"
          icon="ocpp"
          :title="i18n.t('mywb.charger.ocpp')"
          :subtitle="compute.ocppStatus.label"
          :status="compute.ocppStatus.code"
          action="emit"
          @on-click="router.push({ name: 'charger-settings', query: { selected: 'ocpp' } })"
        />

        <template v-if="permissions.showPayments && (compute.supportsPayPerCharge || compute.supportsPayPerMonth)">
          <div class="is-size-300 is-font-weight-500 mt-16">
            {{ i18n.t('mywb.common.payments') }}
          </div>

          <wb-button-card
            v-if="compute.supportsPayPerCharge"
            data-test-id="widgetPayPerCharge"
            icon="card"
            :title="i18n.t('mywb.charger.pay-per-charge')"
            :subtitle="compute.payPerChargeStatus.label"
            :status="compute.payPerChargeStatus.status"
            action="emit"
            @on-click="router.push({ name: 'charger-settings', query: { selected: 'payPerCharge' } })"
          />

          <wb-button-card
            v-if="compute.supportsPayPerMonth"
            data-test-id="widgetPayPerMonth"
            icon="calendar"
            :title="i18n.t('mywb.charger.pay-per-month')"
            :subtitle="compute.payPerMonthStatus.label"
            :status="compute.payPerMonthStatus.status"
            action="emit"
            @on-click="router.push({ name: 'charger-settings', query: { selected: 'payPerMonth' } })"
          />
        </template>

        <div class="is-size-300 is-font-weight-500 mt-16">
          {{ i18n.t('mywb.common.system') }}
        </div>

        <wb-button-card
          v-if="permissions.hasAuthToRestartAndUpdate"
          data-test-id="widgetSoftwareVersion"
          icon="send_to_charger"
          :title="i18n.t('mywb.charger.version')"
          :subtitle="compute.softwareVersionStatus.label"
          :status="compute.softwareVersionStatus.status"
          :action="compute.softwareVersionStatus.status === 'enabled' ? 'emit' : undefined"
          :force-open="!compute.isUpdating"
          @on-click="methods.updateRemoteAction()"
        >
          <div
            class="is-size-100 has-text-grey-400"
            data-test-id="lastSoftwareUpdate"
          >
            {{ i18n.t('mywb.common.last-update', [compute.softwareUpdatedAt ]) }}
          </div>
        </wb-button-card>

        <wb-button-card
          v-if="permissions.canSeeLocations"
          data-test-id="widgetLocation"
          icon="location"
          :title="i18n.t('mywb.common.location')"
          :subtitle="compute.locationStatus.label"
          :status="compute.locationStatus.status"
          action="emit"
          @on-click="router.push({
            name: 'location-detail', params: { groupId: state.charger.getCurrentCharger.chargerData.group }
          })"
        />

        <wb-button-card
          data-test-id="widgetManufacturer"
          icon="info_filled"
          :title="i18n.t('mywb.common.manufacturer')"
          :subtitle="i18n.t('mywb.common.wallbox')"
          status="enabled"
          action="content"
        >
          <div
            v-for="(item, key) in compute.getManufacturerData"
            :key="`data-${key}`"
            class="mt-8 mb-8"
          >
            <div class="is-size-300 is-font-weight-500">
              {{ i18n.t(item.label) }}
            </div>
            <div
              class="is-size-300 has-text-grey-500"
              :data-test-id="item.dataTestId"
            >
              {{ item.value }}
            </div>
          </div>
        </wb-button-card>
      </template>
    </wb-card>

    <charger-power-limit-modal
      v-if="data.editingMaxCurrent"
      data-test-id="editMaxCurrentModal"
      @on-close="data.editingMaxCurrent = false"
    />
  </div>
</template>

<script setup lang="ts">
import ChargerPowerLimitModal from '@/components/modals/ChargerPowerLimitModal.vue'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { permissions, clientConfig } from '@/engine/clients'
import { supportsOcpp, ocppStatus, isOcpp } from '@/utilities/charger/chargerOcppSettings'
import { getPowerSharingStatus } from '@/utilities/charger/chargerPowerSharingSettings'
import { getMidStatus } from '@/utilities/charger/midInformation'
import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'
import {
  supportsPowerBoost,
  supportsPowerSharing,
  supportsDynamicPowerSharing,
  supportsEcoSmart
} from '@/utilities/charger/chargerTypes'
import {
  connectivityByConnectionType,
  connectivityTypeIcon,
  connectivityTypeLabel
} from '@/utilities/charger/chargerConnectivity'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import {
  isGunLockCompatible,
  isAutolockCompatible,
  isPaymentsCompatible
} from '@/utilities/charger/chargerCompatible'
import { getChargerAmps } from '@/utilities/charger/chargerAmps'
import api from '@/api'
import state from '@/state'
import { useNotify } from '@wallbox/toolkit-ui'
import { isChargerRemoteAction, REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { Locations, Charger } from '@/types'
import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'

const i18n = useI18n()
const router = useRouter()
const notify = useNotify()

interface WidgetType {
  label: string
  status: string
}

interface DataType {
  chargerWithRates?: Charger.ChargerWithRates
  locations: Locations.Location[]
  editingMaxCurrent: boolean
}

const data: DataType = reactive({
  chargerWithRates: undefined,
  locations: [],
  editingMaxCurrent: false
})

const compute = reactive({
  percentageCurrentChargerAmps: computed(() => {
    const power = getChargerAmps(state.charger.getCurrentCharger.chargerData)
    return (power.current - power.min) / (power.max - power.min) * 100
  }),

  supportsPowerBoost: computed(() => supportsPowerBoost(state.charger.getCurrentCharger.chargerData)),

  supportsPowerSharing: computed(() => supportsPowerSharing(state.charger.getCurrentCharger.chargerData)),

  supportsDynamicPowerSharing: computed(() => supportsDynamicPowerSharing(state.charger.getCurrentCharger.chargerData)),

  supportsEcoSmart: computed(() => supportsEcoSmart(state.charger.getCurrentCharger.chargerData)),

  supportsOcpp: computed(() => supportsOcpp(state.charger.getCurrentCharger.chargerData)),

  supportsAutolock: computed(() => isAutolockCompatible(state.charger.getCurrentCharger.chargerData).isCompatible),

  supportsGunLock: computed(() => isGunLockCompatible(state.charger.getCurrentCharger.chargerData).isCompatible),

  supportsPayPerCharge: computed(() =>
    isPaymentsCompatible(state.charger.getCurrentCharger.chargerData, 'pay_per_charge').isCompatible),

  supportsPayPerMonth: computed(() =>
    isPaymentsCompatible(state.charger.getCurrentCharger.chargerData, 'pay_per_month').isCompatible),

  isDisconnected: computed(() => {
    return state.charger.getCurrentCharger.chargerData?.id &&
      getChargerStatus(state.charger.getCurrentCharger.chargerData)?.code === STATUSES.DISCONNECTED
  }),

  isUpdating: computed(() => isChargerRemoteAction(state.charger.getCurrentCharger.config, REMOTE_ACTIONS.UPDATE)),

  ocppStatus: computed(() => {
    if (!isOcpp(state.charger.getCurrentCharger.config)) {
      return {
        label: i18n.t('mywb.common.disabled'),
        code: 'disabled'
      }
    }
    return ocppStatus(state.charger.getCurrentCharger.chargerData)
  }),

  connectivityTypeIcon: computed(() => connectivityTypeIcon(state.charger.getCurrentCharger.chargerData)),

  connectivityTypeLabel: computed(() => connectivityTypeLabel(state.charger.getCurrentCharger.chargerData)),

  connectivityByConnectionType: computed(() =>
    connectivityByConnectionType(state.charger.getCurrentCharger.chargerData)),

  powerBoostStatus: computed((): WidgetType => {
    const hasPowerBoost = !!state.charger.getCurrentCharger.config?.home_sharing || false
    return {
      label: hasPowerBoost ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled'),
      status: hasPowerBoost ? 'enabled' : 'disabled'
    }
  }),

  powerDynamicSharingStatus: computed((): WidgetType => {
    const dynamicPowerSharing = state.charger.getCurrentCharger.config?.power_sharing_config
    const hasDynamicPowerSharing = !!(dynamicPowerSharing !== 0 && dynamicPowerSharing !== 256)
    return {
      label: hasDynamicPowerSharing ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled'),
      status: hasDynamicPowerSharing ? 'enabled' : 'disabled'
    }
  }),

  ecoSmartStatus: computed((): WidgetType => {
    const hasEcoSmart = !!state.charger.getCurrentCharger.config?.ecosmart?.enabled || false
    return {
      label: hasEcoSmart ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled'),
      status: hasEcoSmart ? 'enabled' : 'disabled'
    }
  }),

  softwareVersionStatus: computed((): WidgetType => {
    const updateVersion = isUpdatesAvailable(
      state.charger.getCurrentCharger,
      state.charger.getCurrentCharger.config.software
    )
    const updateToLabel = updateVersion
      ? ` - ${i18n.t('mywb.common.update-to-version', [state.charger.getCurrentCharger.config.software.latestVersion])}`
      : ''

    return {
      label: !compute.isUpdating
        ? state.charger.getCurrentCharger.config.software.currentVersion + updateToLabel
        : i18n.t('mywb.charger.status.updating'),
      status: updateVersion ? 'enabled' : 'disabled'
    }
  }),

  softwareUpdatedAt: computed(() => {
    return new Intl.DateTimeFormat(i18n.locale.value, { dateStyle: 'short', timeStyle: 'short' })
      .format(state.charger.getCurrentCharger.chargerData.softwareUpdatedAt * 1000)
  }),

  autolockStatus: computed((): WidgetType => {
    const hasAutolock = state.charger.getCurrentCharger.config?.auto_lock
    const autolockTime = hasAutolock ? Math.round(state.charger.getCurrentCharger.config?.auto_lock_time / 60) : 0
    return {
      label: hasAutolock
        ? `${i18n.t('mywb.common.enabled')} - ${autolockTime} ${i18n.t('mywb.common.min')}`
        : i18n.t('mywb.common.disabled'),
      status: state.charger.getCurrentCharger.config?.auto_lock ? 'enabled' : 'disabled'
    }
  }),

  socketLokingStatus: computed((): WidgetType => {
    const hasSocketLocking = !!state.charger.getCurrentCharger?.config?.user_socket_locking || false
    return {
      label: hasSocketLocking
        ? i18n.t('mywb.common.enabled')
        : isGunLockCompatible(state.charger.getCurrentCharger.chargerData).reason || i18n.t('mywb.common.disabled'),
      status: hasSocketLocking ? 'enabled' : 'disabled'
    }
  }),

  payPerChargeStatus: computed((): WidgetType => {
    const isActive = data.chargerWithRates?.payPerChargeSchedule.status === 'active'

    return {
      label: isActive
        ? i18n.t('mywb.common.enabled')
        : i18n.t('mywb.common.disabled'),
      status: isActive ? 'enabled' : 'disabled'
    }
  }),

  payPerMonthStatus: computed((): WidgetType => {
    const isActive = data.chargerWithRates?.payPerMonthSchedule.status === 'active'

    return {
      label: isActive
        ? i18n.t('mywb.common.enabled')
        : i18n.t('mywb.common.disabled'),
      status: isActive ? 'enabled' : 'disabled'
    }
  }),

  locationStatus: computed((): WidgetType => {
    return {
      label: data.locations[0]?.name || '',
      status: data.locations[0] ? 'enabled' : 'disabled'
    }
  }),

  getManufacturerData: computed(() => {
    return [
      {
        label: 'mywb.common.name',
        value: state.charger.getCurrentCharger.chargerData.name || '-',
        dataTestId: 'chargerName'
      },
      {
        label: 'mywb.common.model',
        value: state.charger.getCurrentCharger.chargerData.chargerType || '-',
        dataTestId: 'type'
      },
      {
        label: 'mywb.charger.serial-number',
        value: state.charger.getCurrentCharger.chargerData.serialNumber || '-',
        dataTestId: 'serialNumber'
      },
      {
        label: 'mywb.charger.uid',
        value: state.charger.getCurrentCharger.chargerData.uniqueIdentifier || '-',
        dataTestId: 'uniqueIdentifier'
      },
      {
        label: 'mywb.charger.charging-type',
        value: state.charger.getCurrentCharger.chargerData.chargingType || '-',
        dataTestId: 'chargingType'
      },
      {
        label: 'mywb.charger.connection-type',
        value: state.charger.getCurrentCharger.chargerData.connectorType || '-',
        dataTestId: 'connectorType'
      }
    ]
  })
})

const methods = {
  goToSupport () {
    window.open(clientConfig.energyManagmentSupport)
  },

  async getAssignedSchedule () {
    const {
      getData,
      chargersWithRates
    } = useAssignedRatesApi(data.locations[0])

    await getData([state.charger.getCurrentCharger.chargerData] as any)

    data.chargerWithRates = chargersWithRates.value[0]
  },

  async updateRemoteAction () {
    try {
      await api.chargers.setChargerRemoteAction({
        action: REMOTE_ACTIONS.UPDATE,
        chargerId: state.charger.getCurrentCharger.chargerData.id
      })

      const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
      state.charger.setCurrentCharger(charger)
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async getLocation () {
    const filters = [{
      field: 'group_uid',
      operator: 'eq',
      value: state.charger.getCurrentCharger?.chargerData.groupUid
    }]

    const locations = await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid, filters })

    data.locations = locations.data
  }
}

async function created () {
  if (permissions.canSeeLocations) await methods.getLocation()
  if (permissions.showPayments) await methods.getAssignedSchedule()
}

created()
</script>

<style lang="postcss" scoped>
.is-grid-header {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 16px;
  gap: 16px;
}

:deep(.is-button-grey) {
  color: var(--grey-500) !important;
  background: none !important;

  &:hover {
    background: var(--grey-200) !important;
  }
}

.has-position-relative {
  position: relative;
  top: -4px;
}
</style>
