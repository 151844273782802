<template>
  <wb-modal
    width="60"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.user.personal-data') }}
    </template>

    <wb-form data-test-id="accountPersonalDataForm">
      <wb-input
        v-model="data.name"
        type="text"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
      />
      <wb-input
        v-model="data.surname"
        type="text"
        data-test-id="surnameInput"
        :label="i18n.t('mywb.user.surname')"
        :error="errors.surname"
      />
      <wb-input
        v-model="data.email"
        type="text"
        data-test-id="emailInput"
        disabled
        :label="i18n.t('mywb.common.email')"
        :error="errors.email"
      />
      <wb-select
        v-model="data.country"
        uid="_country"
        option-label="name"
        data-test-id="countrySelect"
        :label="i18n.t('mywb.common.country')"
        :options="state.global.getCountries"
        :error="errors.country"
      />

      <div class="is-fullwidth">
        <p class="phone-label">
          {{ i18n.t('mywb.user.phone') }}
        </p>
        <div class="phone-input">
          <wb-select
            v-model="data.phoneCode"
            uid="_phone_code"
            option-label="prefixLabel"
            data-test-id="prefixPhoneNumber"
            :options="compute.prefixPhoneOptions"
            :get-option-label="(option: typeof compute.prefixPhoneOptions) => option.prefix"
            :filter-by="methods.filterByPrefix"
            :error="errors.phoneCode"
          />
          <wb-input
            v-model="data.phone"
            name="phone"
            type="tel"
            data-test-id="phoneInput"
            :error="errors.phone"
          />
        </div>
      </div>

      <wb-select
        v-model="data.timezone"
        uid="_timezone"
        name="timezoneSelect"
        option-label="name"
        data-test-id="timezoneSelect"
        :options="compute.formattedTimezones"
        :get-option-label="(option: typeof compute.formattedTimezones) => option.name"
        :label="i18n.t('mywb.common.timezone')"
        :error="errors.timezone"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
          :loading="data.loading"
          @click="validate(methods.updatePersonalData)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import state from '@/state'
import api from '@/api'
import type { Currency } from '@/types'

const notify = useNotify()
const i18n = useI18n()
const { yup, errors, defineSchema, validate } = useValidator()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

interface PrefixCountry {
  countryCode: string
  prefixCode: string
  prefixLabel: string
  prefix: string
}

const compute = reactive({
  prefixPhoneOptions: computed((): PrefixCountry[] => {
    return state.global.getCountries
      .filter(country => !!country.phone_code)
      .map(country => {
        return {
          countryCode: country.code,
          prefixCode: country.phone_code,
          prefixLabel: `${country.name} (+${country.phone_code})`,
          prefix: `+${country.phone_code}`
        }
      })
  }),

  formattedTimezones: computed(() => {
    return state.global.getTimezones.map(timezone => (
      {
        id: timezone,
        name: timezone.toString().replaceAll('_', ' ')
      }))
  }),

  prefixPhoneCode: computed((): PrefixCountry | undefined => {
    return compute.prefixPhoneOptions.find(prefix => prefix.countryCode === state.user.userLogged.country_code)
  })
})

interface Data {
  name: string,
  surname: string,
  email: string,
  country?: Currency.Country,
  phone: string
  phoneCode?: PrefixCountry,
  timezone: {
    id: string,
    name: string
  },
  loading: boolean
}

const data = reactive<Data>({
  name: state.user.userLogged.name || '',
  surname: state.user.userLogged.surname || '',
  email: state.user.userLogged.email || '',
  country: state.global.getCountries.find(country => country.id === state.user.userLogged.country_id),
  phone: state.user.userLogged.phone || '',
  phoneCode: compute.prefixPhoneCode,
  timezone: {
    id: state.user.userLogged.time_zone || '',
    name: state.user.userLogged.time_zone.toString().replaceAll('_', ' ') || ''
  },
  loading: false
})

defineSchema(data, {
  name: yup.string().required(),
  surname: yup.string().required(),
  email: yup.string().email().required(),
  country: yup.object().required(),
  phone: yup.string().required(),
  phoneCode: yup.object().required(),
  timezone: yup.object().required()
})

const methods = {
  filterByPrefix (option: PrefixCountry, label: string, search: string) {
    return (option.prefixLabel || '').toLowerCase().indexOf(search.toLowerCase()) > -1
  },

  async updatePersonalData () {
    const dataToUpdate = {
      name: data.name,
      surname: data.surname,
      phone_code: data.phoneCode?.prefixCode,
      phone: data.phone,
      country_id: data.country?.id,
      time_zone: data.timezone.id
    }

    try {
      data.loading = true
      await api.users.updateUser({ params: dataToUpdate, userId: state.user.userLogged.id })

      state.user.set('userLogged.name', data.name)
      state.user.set('userLogged.surname', data.surname)
      state.user.set('userLogged.phone_code', data.phoneCode?.prefixCode)
      state.user.set('userLogged.phone', data.phone)
      state.user.set('userLogged.country_id', data.country?.id)
      state.user.set('userLogged.time_zone', data.timezone?.id)

      notify.success(i18n.t('mywb.common.changes-saved'))
      emit('close')
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.phone-label {
  color: var(--grey-900);
  margin-bottom: 6px;
  font-weight: 500;
  font-size: var(--size-400);
}

.phone-input {
  display: flex;
  align-items: flex-start;

  &:deep(.vs__dropdown-toggle) {
    border-radius: 0.4rem 0 0 0.4rem !important;
  }

  &:deep(.input_element) {
    border-left: 0 !important;
    border-radius: 0 0.4rem 0.4rem 0 !important;
  }
}
</style>
