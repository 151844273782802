<template>
  <wb-card
    :headline="i18n.t('mywb.common.billing-information')"
    alignment="center"
    :loading="data.firstLoading"
  >
    <template v-if="data.hasBillingInfo" #content>
      <wb-card filled>
        <template #content>
          <template v-if="!data.billingInfo.company">
            {{ data.billingInfo.name }} {{ data.billingInfo.surname }}
          </template>
          <template v-else>
            {{ data.billingInfo.name }} - {{ data.billingInfo.cif }}
          </template>
          <br>
          {{ data.billingInfo.address }}, {{ data.billingInfo.city }}, {{ data.billingInfo.zipCode }},
          <span v-if="data.billingInfo.state">{{ data.stateName }}, </span>
          <span v-if="compute.countryParsed" class="is-capitalized">
            {{ compute.countryParsed }}
          </span>
        </template>

        <template #actions>
          <wb-button
            type="white"
            outlined
            data-test-id="editBtn"
            icon="edit"
            class="edit"
            :label="i18n.t('mywb.common.edit')"
            @click="data.openBillingInformationModal = true"
          />
        </template>
      </wb-card>
    </template>

    <template v-if="!data.hasBillingInfo" #actions>
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.add-billing-information')"
        icon="edit"
        data-test-id="editBtn"
        @click="data.openBillingInformationModal = true"
      />
    </template>

    <template #modal>
      <billing-information-modal
        v-if="data.openBillingInformationModal"
        :billing-info="data.billingInfo"
        :group-id="props.groupId"
        @on-updated="methods.handleUpdate"
        @on-close="data.openBillingInformationModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import BillingInformationModal from '@/components/modals/BillingInformationModal.vue'

import { reactive, onMounted, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import api from '@/api'
import type { BillingInfo } from '@/types'

const i18n = useI18n()

interface Events {
  (e: 'update:has-billing-info', hasBillingInfo: boolean): void,
  (e: 'on-updated'): void,
}
const emit = defineEmits<Events>()

const props = defineProps<{
  groupId: number
}>()

interface DataType {
  openBillingInformationModal: boolean
  loading: boolean
  firstLoading: boolean
  hasBillingInfo: boolean
  billingInfo: BillingInfo.BillingInfo,
  stateName: string,
  optionsState: Array<{ name: string, iso2: string }>
}

const data: DataType = reactive({
  openBillingInformationModal: false,
  loading: false,
  firstLoading: false,
  hasBillingInfo: false,
  billingInfo: {} as BillingInfo.BillingInfo,
  stateName: '',
  optionsState: []
})

const compute = reactive({
  countryParsed: computed(() => {
    if (!data.billingInfo?.country?.iso2) return ''
    return state.global.getCountries.find(country => country.iso2 === data.billingInfo.country.iso2)?.name || null
  })
})

const methods = {
  handleUpdate () {
    methods.loadComponent()
    emit('on-updated')
  },

  async getStates (countryCode: string) {
    data.optionsState = (await api.global.getStatesByCountry(countryCode) || [])
      .sort((a, b) => a.name.localeCompare(b.name))
  },

  findState (iso2: string) {
    return data.optionsState.find(state => state.iso2 === iso2)?.name || ''
  },

  async loadComponent () {
    data.firstLoading = true
    const { result: billingInfo } = await api.billing.getBillingInfo(props.groupId)

    data.hasBillingInfo = !!billingInfo
    emit('update:has-billing-info', data.hasBillingInfo)

    data.billingInfo = billingInfo

    if (data.hasBillingInfo && billingInfo?.country?.code) {
      await methods.getStates(billingInfo.country.code)
      data.stateName = methods.findState(billingInfo.state)
    }

    data.firstLoading = false
  }
}

onMounted(async () => {
  await methods.loadComponent()
})
</script>
