import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import ENV from '@/engine/env/web.env'
import type { Ocpp, OcppApi } from '@/types'

export default {
  async getOcppUrl (organizationUid: string): Promise<string | undefined> {
    return await axios.get(endpoints.persuesApi.v1.ocppUrl.replace('{organizationUid}', organizationUid), {
      baseURL: ENV.api.perseusApiBaseUrl
    }).then(resp => resp?.data?.attributes?.url)
  },

  async postOcppUrl (organizationUid: string): Promise<void> {
    return await axios.post(endpoints.persuesApi.v1.ocppUrl.replace('{organizationUid}', organizationUid), {}, {
      baseURL: ENV.api.perseusApiBaseUrl
    })
  },

  async getOcppChargers (organizationUid: string): Promise<OcppApi.GetOcppChargers> {
    return await axios.get(endpoints.persuesApi.v1.ocppCharger.replace('{organizationUid}', organizationUid), {
      baseURL: ENV.api.perseusApiBaseUrl
    })
  },

  async getChargerOcppSettings (chargerId: number): Promise<Ocpp.OcppSettings> {
    return await axios.get(endpoints.v3.chargers_chargerId_ocppConfiguration
      .replace('{chargerId}', chargerId.toString()))
  },

  async postChargerOcppSettings ({ chargerId, settings }: OcppApi.postOcppSetings): Promise<void> {
    return await axios.post(endpoints.v3.chargers_chargerId_ocppConfiguration
      .replace('{chargerId}', chargerId.toString()), settings)
  }
}
