<template>
  <wb-modal
    width="58"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.edit-access-group') }}
    </template>

    <wb-cards-loader
      :loading="data.loading"
    >
      <wb-form>
        <wb-input
          v-model="data.accessConfig.name"
          data-test-id="userGroupNameInput"
          class="mb-24"
          name="name"
          type="text"
          :label="i18n.t('mywb.common.name')"
          :error="data.errors.name"
        />
      </wb-form>

      <locations-accordion
        v-model="data.accessConfig.chargers"
      />
    </wb-cards-loader>

    <template #actions>
      <wb-button
        data-test-id="acceptBtnModal"
        size="block"
        :label="!data.btnLoading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.btnLoading"
        :disabled="data.loading"
        @click="methods.updateAccessConfigGroup"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import { useForm, useField } from 'vee-validate'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import state from '@/state'
import { reactive, nextTick } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const notify = useNotify()

interface Props {
  accessConfigId: number
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

const { errors, handleSubmit } = useForm({
  validationSchema: {
    name: 'required'
  }
})

const { value: name } = useField<string>('name')

interface Data {
  errors: typeof errors
  loading: boolean
  btnLoading: boolean
  accessConfig: {
    chargers: number[],
    group: number
    name: typeof name
  }
}
const data = reactive<Data>({
  errors,
  loading: false,
  btnLoading: false,
  accessConfig: {
    chargers: [],
    group: -1,
    name
  }
})

const methods = {
  updateAccessConfigGroup: handleSubmit(async () => {
    try {
      data.btnLoading = true
      await api.accessConfig.updateAccessConfig({ id: props.accessConfigId, ...data.accessConfig })
      trackDataEvent('edit-group', { group_id: props.accessConfigId })
      state.user.set('usersGroup', await api.users.getUserGroups())
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.btnLoading = false
      emit('close')
    }
  })
}

async function created () {
  data.loading = true
  const { result } = await api.groups.getAllDataOrganizations()
  state.groups.set('groups', result.groups)
  trackDataScreen('users-edit-group')

  await nextTick(async () => {
    const { result } = await api.accessConfig.getAccessConfig(props.accessConfigId)

    if (result?.name) {
      data.accessConfig.name = result.name
      data.accessConfig.group = result.group.id
      data.accessConfig.chargers = result.chargers.map(charger => charger.id)
    }
    data.loading = false
  })
}

created()
</script>
