<template>
  <wb-cards-loader>
    <dashboard-header />

    <enable-auto-refresh-component
      v-if="data.showActivateInterval"
      data-test-id="sharedEnableAutoRefresh"
      class="mb-16"
      @enable-auto-refresh="methods.enableAutoRefresh"
    />

    <div
      class="dashboard-grid g-16"
      :class="{'dashboard-grid-secondary': !permissions.showPayments}"
    >
      <chart-report
        class="energy"
        data-test-id="energyConsumedWidget"
        :title="i18n.t('mywb.common.energy-supplied')"
        :labels="data.labels"
        :series="data.series"
        :categories="[ChartTypesEnum.ENERGY]"
        :visualization="VisualizationEnum.AREA"
        :dates="state.filters.dashboardFilters.dates"
        :calendar="state.filters.dashboardFilters.calendar"
        :periodicity="state.filters.dashboardFilters.periodicity"
        :analysis="state.filters.dashboardFilters.analysis"
        :loading="data.loading"
        show-footer
        hide-options
      />

      <chart-report
        class="time"
        data-test-id="chargedTimeWidget"
        :title="i18n.t('mywb.charger.charging-time')"
        :labels="data.labels"
        :series="data.series"
        :categories="[ChartTypesEnum.TIME]"
        :visualization="VisualizationEnum.AREA"
        :calendar="state.filters.dashboardFilters.calendar"
        :periodicity="state.filters.dashboardFilters.periodicity"
        :analysis="state.filters.dashboardFilters.analysis"
        :loading="data.loading"
        show-footer
        hide-options
      />

      <div v-if="permissions.showPayments" class="income">
        <connect-stripe v-if="!compute.hasOrganizationPaymentsAccount" />

        <chart-report
          v-else
          data-test-id="graph"
          :title="i18n.t('mywb.common.income')"
          :labels="data.labels"
          :series="data.series"
          :categories="[ChartTypesEnum.INCOME]"
          :visualization="VisualizationEnum.BAR"
          :calendar="state.filters.dashboardFilters.calendar"
          :periodicity="state.filters.dashboardFilters.periodicity"
          :analysis="state.filters.dashboardFilters.analysis"
          :loading="data.loading"
          show-footer
          hide-options
        />
      </div>

      <chargers-overview-dashboard
        class="status"
        data-test-id="dashboardChargersStatus"
      />

      <co2-savings-dashboard
        class="savings"
        :series="data.series"
      />

      <active-chargers-table
        class="table"
        data-test-id="dashboardChargersActiveSessions"
        :organization="state.organizations.getCurrentOrganization"
        :has-real-time-information="!data.showActivateInterval"
      />
    </div>
  </wb-cards-loader>
</template>

<script setup lang="ts">
import ChartReport from '@/components/charger/ChartReport.vue'
import ConnectStripe from '@/components/dashboard/ConnectStripe.vue'
import ChargersOverviewDashboard from '@/components/dashboard/ChargersOverviewDashboard.vue'
import ActiveChargersTable from '@/components/tables/ActiveChargersTable.vue'
import Co2SavingsDashboard from '@/components/dashboard/Co2SavingsDashboard.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import DashboardHeader from '@/components/headers/DashboardHeader.vue'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { reactive, watch, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { ChartTypesEnum, VisualizationEnum } from '@/utilities/chartSessions/chartTypeEnums'
import { getData } from '@/utilities/chartSessions/chart'
import type { ChartSeries } from '@/utilities/chartSessions/chart.types'

const i18n = useI18n()

interface Data {
  showActivateInterval: boolean
  labels: Date[]
  series?: ChartSeries
  loading: boolean
}

const data = reactive<Data>({
  showActivateInterval: false,
  labels: [],
  loading: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.payments_account)
})

const methods = {
  enableAutoRefresh () {
    data.showActivateInterval = false
  },

  async getSessionsSeries () {
    const { labels, series } = await getData(
      state.filters.dashboardFilters.periodicity,
      state.filters.dashboardFilters.calendar.interval,
      state.filters.dashboardFilters.dates
    )
    data.labels = labels
    data.series = series

    getData(
      state.filters.dashboardFilters.periodicity,
      state.filters.dashboardFilters.calendar.interval,
      state.filters.dashboardFilters.dates
    ).then(({ labels, series }) => {
      data.labels = labels
      data.series = series
    })
  }
}

watch(async () => [
  state.filters.dashboardFilters.periodicity,
  state.filters.dashboardFilters.calendar.interval,
  state.filters.dashboardFilters.dates], async () => {
  data.loading = true
  await methods.getSessionsSeries()
  data.loading = false
})

const created = async () => {
  data.loading = true
  await methods.getSessionsSeries()
  data.loading = false

  setTimeout(() => {
    data.showActivateInterval = true
  }, 300000)
}

created()
</script>

<style lang="postcss" scoped>
.dashboard-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    "status"
    "energy"
    "time"
    "income"
    "savings"
    "table";

  @media (--tablet) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      "energy income"
      "time status"
      "time savings"
      "table table";
  }

  @media (--widescreen) {
    grid-template-columns: minmax(0, 6fr) minmax(0, 6fr) minmax(0, 5fr);
    grid-template-rows: auto auto max-content;
    grid-template-areas:
      "energy time income"
      "table table status"
      "table table savings"
      "table table ·";
  }
}

.dashboard-grid-secondary {
  @media (--tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto auto;
    grid-template-areas:
      "energy time"
      "savings status"
      "table table";
  }
}

.energy {
  grid-area: energy;
}

.time {
  grid-area: time;
}

.income {
  grid-area: income;
}

.table {
  grid-area: table;
}

.status {
  grid-area: status;
}

.savings {
  grid-area: savings;
}
</style>
