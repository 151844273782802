<template>
  <wb-select
    v-model="compute.modelProxy"
    class="mt-16"
    :options="organizationTypes"
    :reduce="organization => organization.key"
    :label="i18n.t('mywb.organizations-type.title')"
    :searchable="false"
    :error="props.error"
  >
    <template #selected-option="{ option }">
      <div>
        <p class="is-size-400 is-font-weight-500">
          {{ option.title }}
        </p>

        <p class="is-size-400">
          {{ option.desc }}
        </p>
      </div>
    </template>
    <template #option="{ option }">
      <p class="is-size-400 is-font-weight-500">
        {{ option.title }}
      </p>

      <p class="is-size-400 has-text-break">
        {{ option.desc }}
      </p>
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import { organizationTypes, OrganizationTypeEnum } from '@/utilities/organizationTypes'
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

interface Props {
  modelValue?: OrganizationTypeEnum
  error?: string
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', value?: OrganizationTypeEnum): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  modelProxy: computed({
    get () {
      return props.modelValue
    },

    set (value) {
      emit('update:modelValue', value)
    }
  })
})
</script>
