import { reactive } from 'vue'
import state from '@/state'

interface Scope {
  environment_scope: string
}

interface Strategy {
  parameters: {
    userIds: string
  }
  scopes: Scope[]
}

interface Feature {
  active: boolean
  name: string
  strategies: Strategy[]
}

interface State {
  flags: Feature[]
}

const initialFeatures = (): State => ({
  flags: []
})

const features = reactive(initialFeatures())

export function setFeatureFlagsByUserLogged (data: Feature[]) {
  features.flags = data
}

const hasFeatureFlagByEnvironment = (strategy: Strategy) => {
  if (strategy.scopes.some(item => item.environment_scope === '*')) return true

  return (strategy.scopes ?? [])
    .some(({ environment_scope }) => location.hostname.includes(environment_scope))
}

const hasFeatureFlagByUser = (strategy: Strategy) => {
  const users = (strategy.parameters.userIds ?? '').split(',').filter(Boolean)
  if (!users.length) return true

  const userLogged = state.user.userLogged

  return users.includes(userLogged.id.toString()) || users.includes(userLogged.email)
}

export const hasFeatureFlagActive = (flagName: string) => {
  if ((window as { [key: string]: any })[flagName]) return true

  const feature = features.flags.find(flag => flag.name === flagName)

  if (!feature?.active) return false

  const strategies = feature.strategies ?? []

  const hasStrategy = strategies.reduce((strategyAcum, strategy) => {
    return strategyAcum || (hasFeatureFlagByEnvironment(strategy) && hasFeatureFlagByUser(strategy))
  }, false)

  return hasStrategy
}
