<template>
  <wb-table-row :id="props.accessConfig.group">
    <wb-table-row-item data-test-id="name">
      <wb-link
        type="text"
        :to="{ name: 'users', query: { group: props.accessConfig.id.toString() } }"
        class="has-text-grey-700 is-size-400 is-font-weight-500"
      >
        {{ props.accessConfig.name }}
      </wb-link>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="totalUsers">
      <p class="has-text-grey-700">
        {{ props.accessConfig.users.length }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="totalChargers">
      <p class="has-text-grey-700">
        {{ props.accessConfig.chargers.length || 0 }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item>
      <wb-button-groups>
        <wb-popover
          v-if="permissions.canCreateUserGroups"
          class="group-item"
        >
          <template #activator>
            <wb-button
              v-if="permissions.canCreateUserGroups"
              id="group-edit"
              icon="edit"
              data-test-id="editOption"
              type="white"
              size="small"
              outlined
              class="group-item"
              @mousedown="emit('on-edit', props.accessConfig)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.edit') }}
          </template>
        </wb-popover>
        <wb-popover
          v-if="permissions.canCreateUserGroups"
          class="group-item"
        >
          <template #activator>
            <wb-button
              v-if="permissions.canCreateUserGroups"
              id="group-delete"
              icon="delete"
              data-test-id="deleteOption"
              type="danger"
              size="small"
              outlined
              class="group-item"
              @mousedown="emit('on-delete', props.accessConfig)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.delete') }}
          </template>
        </wb-popover>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import type { AccessConfig } from '@/types'

const i18n = useI18n()

interface Props {
  accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-edit', accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded): void
  (e: 'on-delete', accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded): void
}
const emit = defineEmits<Events>()
</script>
