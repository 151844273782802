<template>
  <wb-card
    :headline="i18n.t('mywb.dashboard.co2-savings')"
    data-test-id="co2SavingsWidget"
  >
    <template #place-upper-right>
      <wb-popover
        tooltip-position="left"
        @hover="methods.getCo2Info"
      >
        <template #activator>
          <span class="wb-icons has-text-grey-500 is-size-400">
            info
          </span>
        </template>
        <template #tooltip>
          {{ i18n.t('mywb.co2.description') }}
        </template>
      </wb-popover>
    </template>

    <template #content>
      <wb-level>
        <template #level-left>
          <div class="is-size-500 is-font-weight-500" data-test-id="co2Savings">
            <wb-spinner
              v-if="data.loading"
              spinner="Ring"
              type="grey"
              style="--width: 25px;"
            />
            <span v-else>
              {{ compute.co2SavingsFormatted }}
            </span>
          </div>
          <p v-t="'mywb.dashboard.co2-saved'" class="is-size-300" />
        </template>
        <template #level-right>
          <div class="is-size-500 is-font-weight-500" data-test-id="fuelReplaced">
            <wb-spinner
              v-if="data.loading"
              spinner="Ring"
              type="grey"
              style="--width: 25px;"
            />
            <span v-else>
              {{ compute.fuelSavingsFormatted }}
            </span>
          </div>
          <p v-t="'mywb.dashboard.fuel-replaced'" class="is-size-300" />
        </template>
      </wb-level>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { clientConfig } from '@/engine/clients'
import { reactive, computed, watch } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { ChartSeries } from '@/utilities/chartSessions/chart.types'

const i18n = useI18n()

interface PropsType {
  series?: ChartSeries
}

const props = defineProps<PropsType>()

type DataType = {
  loading: boolean
  energyUserConsumed: number
  co2EmmitedForGasolineCarIn1Km: number
  co2EmmitedForElectricCarIn1Km: number
  energyConsumedByKm: number
  AvergaeFuelConsumedIn1Km: number
}

const data: DataType = reactive({
  loading: false,
  energyUserConsumed: 0,
  co2EmmitedForGasolineCarIn1Km: 122.4,
  co2EmmitedForElectricCarIn1Km: 58,
  energyConsumedByKm: 0.15,
  AvergaeFuelConsumedIn1Km: 0.072
})

const compute = reactive({
  interval: computed(() => state.filters.dashboardFilters.calendar.interval),

  averageKmTravel: computed((): number => {
    return data.energyUserConsumed / data.energyConsumedByKm
  }),

  co2ByEnergyConsumed: computed((): number => {
    return (data.co2EmmitedForElectricCarIn1Km * data.energyUserConsumed) / data.energyConsumedByKm
  }),

  co2ConsumedByCar: computed((): number => {
    return (data.co2EmmitedForGasolineCarIn1Km * compute.averageKmTravel)
  }),

  co2Savings: computed((): number => {
    return compute.co2ConsumedByCar - compute.co2ByEnergyConsumed
  }),

  fuelSavings: computed((): number => {
    return data.AvergaeFuelConsumedIn1Km * compute.averageKmTravel
  }),

  co2SavingsFormatted: computed((): string => {
    return clientConfig.units.mass.formula(compute.co2Savings / 1000)
  }),

  fuelSavingsFormatted: computed((): string => {
    return clientConfig.units.volume.formula(compute.fuelSavings)
  })
})

watch(() => props.series, (range, oldRange) => {
  if (JSON.stringify(range) !== JSON.stringify(oldRange)) {
    methods.getEnergyConsumed()
  }
}, { deep: true })

const methods = {
  async getEnergyConsumed () {
    if (props.series?.energy) {
      data.energyUserConsumed = Object.values(props.series.energy[0].data)
        .reduce((a: number, b: number) => a + b, 0) || 0
    }
  },

  getCo2Info () {
    trackDataAction('dashboard-co2info')
  }
}

const created = async () => {
  data.loading = true
  await methods.getEnergyConsumed()
  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
.savings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.center {
  align-items: center;
}
</style>
