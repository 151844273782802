<template>
  <dashboard-card
    :icon="props.icon"
    :icon-color="props.iconColor"
    :title="props.title"
    :subtitle="props.subtitle"
    :loading="props.loading"
    :number="compute.subtotalPercentage"
  >
    <div class="summary-labels mt-16">
      <p
        v-t="{ path: props.subtotalText, args: [compute.subtotal] }"
        class="is-size-400 is-font-weight-400 has-text-black"
      />
      <span
        v-t="{ path: 'mywb.dashboard.nth-chargers', args: [props.totalChargers] }"
        class="is-size-200 has-text-grey-400"
      />
    </div>
    <div class="summary-chart">
      <wb-chart
        type="bar"
        :height="100"
        :full-rounded-bars="4"
        :options="compute.options"
        :series="props.series"
        :custom-tooltip="methods.customTooltip"
      />
    </div>

    <div class="legend">
      <div
        v-for="data in compute.legend"
        :key="data.name"
        :style="{ '--legend-color': data.color }"
        class="legend-item"
      >
        <wb-link
          :to="{ name: 'chargers' }"
          class="is-size-400"
          type="text"
          @click="() => methods.setFilter(data.status)"
        >
          {{ data.name }}
        </wb-link>
        <p class="is-size-400 is-font-weight-500">
          {{ data.amount }}
        </p>
      </div>
    </div>
  </dashboard-card>
</template>

<script setup lang="ts">
import type { ChartProps, TooltipCustom } from '@wallbox/toolkit-ui'
import { reactive, computed } from 'vue'
import { numbers } from '@wallbox/toolkit-ui'
import { useI18n } from 'vue-i18n'
import state from '@/state'
import type { STATUSES } from '@/utilities/charger/chargerStatuses'
import ChartStackedTooltip from '@/utilities/chartSessions/tooltip/ChartStackedTooltip.html?raw'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'

const { t, locale } = useI18n()

interface Props {
  title: string
  subtitle: string
  icon: string
  iconColor: string
  loading: boolean
  subtotalText: string
  totalChargers: number
  series: {
    amount: number
    status: STATUSES
    data: number[]
    name: string
    color: string
  }[]
}

const props = defineProps<Props>()

const compute = reactive({
  options: computed((): ChartProps['options'] => {
    return {
      plotOptions: {
        bar: {
          horizontal: true,
          colors: {
            backgroundBarColors: ['#F2F2F3']
          }
        }
      },
      chart: {
        type: 'bar',
        stacked: true,
        parentHeightOffset: 0
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: -16
        }
      },
      stroke: { width: 0 },
      xaxis: {
        labels: {
          show: false
        },
        max: 100
      },
      yaxis: { labels: { show: false } },
      legend: {
        show: false
      },

      tooltip: {
        enabled: true
      }
    }
  }),

  subtotal: computed((): number => {
    if (!props.series) return 0

    return props.series
      .reduce((total, chargers) => (total + chargers.amount), 0)
  }),

  subtotalPercentage: computed((): string => {
    const percentage = props.totalChargers ? compute.subtotal / props.totalChargers * 100 : 0

    return numbers.toLocalePercent(percentage, locale.value)
  }),

  legend: computed(() => {
    return props.series.filter(serie => serie.amount > 0)
  })
})

const methods = {
  setFilter (status: STATUSES) {
    state.filters.setFilterValue({ filter: 'chargersFilters', key: 'statuses', value: [status] })
  },

  customTooltip ({ seriesIndex }: Parameters<TooltipCustom>[0]) {
    const info = props.series[seriesIndex]
    return ChartStackedTooltip
      .replace('{label}', [
        info.amount,
        info.status,
        info.amount > 1 ? t('mywb.common.chargers') : t('mywb.common.charger')
      ].join(' ').toLocaleLowerCase())
      .replace('{percentage}', numbers.toLocalePercent(info.data[0], locale.value))
      .replace('{color}', info.color)
  }
}

</script>

<style lang="postcss" scoped>
.summary-labels {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.summary-chart {
  margin-top: -28px;
  margin-bottom: -16px;
}

.legend-item {
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
  border-top: 1px solid var(--grey-100);

  &:first-child {
    border-top: 0;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: var(--legend-color);
  }
}
</style>
