<template>
  <wb-card
    :headline="i18n.t('mywb.common.energy-cost')"
    :subhead="i18n.t('mywb.common.energy-cost.description')"
    alignment="center"
  >
    <template #content>
      <wb-card
        :headline=" compute.energyCostParsed + '/' + i18n.t('mywb.common.kwh')"
        :subhead="data.keepEnergyCostFromLocation
          ? i18n.t('mywb.common.from') + ' '+ props.location?.name
          : ''"
        filled
      >
        <template #actions>
          <wb-button
            icon="edit"
            data-test-id="editBtn"
            :label="i18n.t('mywb.common.edit')"
            type="white"
            outlined
            @click="data.isChargerEnergyCostModalOpen = true"
          />
        </template>
      </wb-card>
    </template>

    <template #modal>
      <charger-edit-energy-cost-modal
        v-if="data.isChargerEnergyCostModalOpen && props.location"
        :location="props.location"
        @close="data.isChargerEnergyCostModalOpen = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerEditEnergyCostModal from '@/components/modals/ChargerEditEnergyCostModal.vue'

import { getCurrencyCode } from '@/utilities/currency'
import state from '@/state'
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import { EnumPlans } from '@/utilities/plans'
import type { Locations } from '@/types'

const i18n = useI18n()

interface Props {
  loading?: boolean
  location?: Locations.Location
}
const props = defineProps<Props>()

const data = reactive({
  keepEnergyCostFromLocation: false,
  loading: false,
  isChargerEnergyCostModalOpen: false
})

const compute = reactive({
  energyCost: computed((): number | undefined => {
    let energyCost
    if (data.keepEnergyCostFromLocation) {
      energyCost = props.location?.energy_cost
    } else {
      energyCost = state.charger.getCurrentCharger?.config?.energyCost?.value
    }

    return energyCost
  }),

  energyCostParsed: computed((): string => {
    return compute.energyCost
      ? numbers.toLocaleCurrencySymbol(
        compute.energyCost,
        getCurrencyCode(),
        i18n.locale.value)
      : '-'
  }),

  getGroupName: computed(() => props.location?.name || '')
})

const methods = {
  loadData () {
    data.loading = true
    if (!state.organizations.isPlan()([EnumPlans.BASIC])) {
      data.keepEnergyCostFromLocation = !!state.charger.getCurrentCharger?.config?.energyCost?.inheritedGroupId
    }
    data.loading = false
  }
}

watchEffect(() => {
  if (!props.loading) {
    methods.loadData()
  }
})

</script>
