<template>
  <sessions-table
    :sessions="data.sessions"
    :loading="data.loading"
  />
</template>

<script setup lang="ts">
import SessionsTable from '@/components/tables/SessionsTable.vue'
import api from '@/api'
import state from '@/state'
import { reactive } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

interface DataType {
  sessions: any[],
  loading: boolean,
  sessionTime: string
}

const data: DataType = reactive({
  sessions: [],
  loading: true,
  sessionTime: ''
})

const created = async () => {
  const aYearFromNow = new Date()

  aYearFromNow.setFullYear(aYearFromNow.getFullYear() - 1)

  const filters = [
    {
      field: 'start_time',
      operator: 'gte',
      value: Math.trunc(aYearFromNow.getTime() / 1000)
    },
    {
      field: 'charger_id',
      operator: 'eq',
      value: +route.params.chargerId
    }
  ]

  const result = await api.sessions.getGroupChargingSessions({
    groupId: state.organizations.getCurrentOrganization.group_id,
    filters: { filters }
  })

  data.sessions = result.data
  data.loading = false
}

created()
</script>
