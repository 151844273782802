import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import type { AccessConfigApi, AccessConfig } from '@/types'
import type { EnumRoles } from '@/utilities/user-roles'

export default {
  async getAccessConfig (accessConfigId: number): Promise<AccessConfigApi.ApiGetAccessConfig> {
    return await axios.get(endpoints.v3.accessConfigs_accessConfigId
      .replace('{accessConfigId}', accessConfigId.toString()))
  },

  async createAccessConfig (accessConfig: Omit<AccessConfig.AccessConfig, 'id'>): Promise<{ result: { id: number } }> {
    return await axios.post(endpoints.v3.accessConfigs, accessConfig)
  },

  async updateAccessConfig ({ id, chargers, name }:
  { id: number, chargers: number[], name?: string | null }): Promise<void> {
    return await axios.put(endpoints.v3.accessConfigs_accessConfigId
      .replace('{accessConfigId}', id.toString()), { chargers, name })
  },

  async deleteAccessConfig (accessConfigId: number): Promise<void> {
    return await axios.delete(endpoints.v3.accessConfigs_accessConfigId
      .replace('{accessConfigId}', accessConfigId.toString()))
  },

  async deleteUserFromGroup ({ userId, groupId }: { userId: number, groupId: number }): Promise<void> {
    const endpoint = endpoints.v2.user_userId_assignGroup_groupId
      .replace('{userId}', userId.toString())
      .replace('{groupId}', groupId.toString())
    return await axios.put(endpoint, { assign: false })
  },

  async deleteUserFromAccessConfig ({ accessConfigId, userId, action }:
  { accessConfigId: number, userId: number, action: string }): Promise<void> {
    const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
      .replace('{accessConfigId}', accessConfigId.toString())
      .replace('{userId}', userId.toString())
    return await axios.delete(endpoint, { params: { action } })
  },

  async addUserToAccessConfig ({ accessConfigId, userId, profile, subscribed }:
  { accessConfigId: number, userId: number, profile?: EnumRoles, subscribed?: boolean }): Promise<void> {
    const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
      .replace('{accessConfigId}', accessConfigId.toString())
      .replace('{userId}', userId.toString())
    return await axios.put(endpoint, { profile, subscribed })
  }
}
