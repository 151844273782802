import global from './global.api'
import payments from './payments.api'
import dashboard from './dashboard.api'
import chargers from './chargers.api'
import users from './users.api'
import support from './support.api'
import sessions from './sessions.api'
import billing from './billing.api'
import invoices from './invoices.api'
import organizations from './organizations.api'
import auth from './auth.api'
import locations from './locations.api'
import ocpp from './ocpp.api'
import config from './config.api'
import groups from './groups.api'
import rates from './rates.api'
import chargerAccess from './chargerAccess.api'
import accessConfig from './accessConfig.api'
import invitations from './invitations.api'

export default {
  global,
  payments,
  dashboard,
  chargers,
  users,
  support,
  sessions,
  billing,
  invoices,
  organizations,
  auth,
  locations,
  ocpp,
  config,
  groups,
  rates,
  chargerAccess,
  accessConfig,
  invitations
}
