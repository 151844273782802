import { createApp } from 'vue'
import initApp from '@/engine'

import 'virtual:svg-icons-register'
import './styles/styles.css'

void (async () => {
  const app = createApp({ template: '<router-view />' })
  const { i18n, router } = await initApp(app)

  app
    .use(i18n)
    .use(router)
    .mount('#app')
})()
