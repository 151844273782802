<template>
  <div>
    <wb-select
      v-model="data.currencyId"
      option-label="name"
      uid="_currencies"
      data-test-id="currencySelect"
      :label="i18n.t('mywb.common.currency')"
      disabled
      :options="state.global.getCurrencies"
    >
      <template #option="{ option }">
        <div class="is-size-400">
          {{ option.name }}
        </div>
        <div class="is-size-500 has-text-grey-500">
          {{ `${ option.code} - ${ option.symbol}` }}
        </div>
      </template>

      <template #selected-option="{ option }">
        {{ option.name }} ({{ `${ option.code} - ${ option.symbol}` }})
      </template>
    </wb-select>
    <p class="is-size-300 has-text-grey-500 mt-8">
      {{ i18n.t('mywb.payments.currency-disabled') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, watch, onMounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { getCurrencyByCountry } from '@/utilities/currency'
import type { Currency } from '@/types'

const i18n = useI18n()

interface Props {
  countryCode?: string
}

const props = defineProps<Props>()

interface Data {
  currencyId?: Currency.Currency
}

const data: Data = reactive({
  currencyId: undefined
})

watch(() => props.countryCode, (code, oldCode) => {
  if (code && code !== oldCode) {
    methods.getCurrencyId()
  }
})

const methods = {
  getCurrencyId () {
    if (props.countryCode) {
      const countryIso = state.global.getCountries?.find(country => country.code === props.countryCode)?.iso2

      if (!countryIso) return

      const currencyCode = props.countryCode && getCurrencyByCountry(countryIso)
      const currency = state.global.getCurrencies.find(currency => currency.code === currencyCode)
      data.currencyId = currency
    }
  }
}

onMounted(() => methods.getCurrencyId())
</script>
