<template>
  <wb-table-row
    v-if="compute.groupRelated"
    :id="props.location.id"
  >
    <wb-table-row-item :data-test-id="'name-'+props.location.name">
      <wb-link
        type="text"
        :to="{ name: 'location-detail', params: { groupId: compute.groupRelated?.id.toString() ?? '' } }"
        class="has-text-grey-700 is-size-400 is-font-weight-500"
      >
        <span class="wb-icons is-size-300">location</span>
        {{ props.location.name }}
      </wb-link>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="chargerAmount">
      <p class="has-text-grey-700">
        {{ compute.groupRelated?.chargers.length }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item>
      <wb-button-groups>
        <wb-popover class="group-item">
          <template #activator>
            <wb-button
              id="configGroup-payments"
              icon="edit"
              data-test-id="editOption"
              type="white"
              outlined
              size="small"
              @mousedown="emit('on-edit', props.location)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.edit') }}
          </template>
        </wb-popover>

        <wb-popover v-if="!compute.isOrganization" class="group-item">
          <template #activator>
            <wb-button
              id="configGroup-delete"
              icon="delete"
              data-test-id="deleteOption"
              type="danger"
              size="small"
              outlined
              @mousedown="emit('on-delete', props.location)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.delete') }}
          </template>
        </wb-popover>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import type { Group, Locations } from '@/types'
import { computed, reactive } from 'vue'

const i18n = useI18n()

interface PropsType {
  location: Locations.Location
}

const props = defineProps<PropsType>()

type Events = {
  (e: 'on-edit', location?: Locations.Location): void,
  (e: 'on-delete', location?: Locations.Location): void,
}

const emit = defineEmits<Events>()

const compute = reactive({
  groupRelated: computed(() => {
    const groups = state.groups.get('groups') as Group.GroupChargersTree[]

    return groups.find((group: Group.GroupChargersTree) => props.location.group_uid === group.uid)
  }),

  isOrganization: computed((): boolean => {
    return compute.groupRelated?.uid === state.groups.groupRelatedToOrganization.uid
  })
})
</script>
