import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import type { DashboardApi, Filters } from '@/types'

export default {
  async getChargersLastSessions ({ filters }: { filters: Filters.Filters[] }):
  Promise<DashboardApi.ApiChargersLastSessionResponse> {
    return await axios.get(endpoints.v4.chargers_last_sessions, {
      params: {
        filters: JSON.stringify({ filters })
      }
    })
  }
}
