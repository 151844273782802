import { reactive, toRefs, computed } from 'vue'
import { calendarOptions } from '@/utilities/chartSessions/chartOptions'
import type { Locations } from '@/types'
import type { VariableFeeType } from '@/types/data/rate.data.types'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { PeriodicityEnum, AnalysisEnum } from '@/utilities/chartSessions/chartTypeEnums'

export interface FilterState {
  chargersFilters: {
    text?: string
    chargers?: number[]
    chargerTypes?: string[]
    groups?: number[]
    statuses?: string[]
  }
  locationsFilters: {
    text?: string
    locations?: Locations.Location[]
  }
  dashboardFilters: {
    dates: Date[]
    calendar: typeof calendarOptions[0]
    periodicity: PeriodicityEnum
    analysis: AnalysisEnum
    locations: Locations.Location[]
  }

  accessConfigFilters: {
    text?: string
    users?: number[]
    groups?: number[]
    role?: string
    status?: string
  }
  sessionsFilters: {
    dates: Date[]
    location?: number
    charger?: number
    user?: number
    type?: number
  }
  ratesFilters: {
    text?: string
    fixedFee?: boolean[]
    variableType?: VariableFeeType[]
    assigned?: boolean[]
  }
  organizationsFilters: {
    profile: string[]
  }
  invoiceFilters: {
    status?: string
    type?: string
  }
}

const today = new Date()
const initialDate = new Date()
initialDate.setDate(1)
initialDate.setHours(0)
initialDate.setMinutes(0)

const initialState = (): FilterState => ({
  chargersFilters: {},
  locationsFilters: {},
  dashboardFilters: {
    dates: [],
    calendar: calendarOptions[4],
    periodicity: PeriodicityEnum.MONTH,
    analysis: AnalysisEnum.LINEAR,
    locations: []
  },
  accessConfigFilters: {},
  sessionsFilters: {
    dates: [initialDate, today]
  },
  ratesFilters: {},
  organizationsFilters: {
    profile: []
  },
  invoiceFilters: {}
})

const state = reactive(initialState())

const getters = {
  backendOrganizationsFilters: computed(() => [
    state.organizationsFilters.profile.length && {
      field: 'profile_id',
      operator: 'in',
      value: (state.organizationsFilters).profile.join('|')
    }
  ].filter(Boolean)),

  backendSessionsFilters: computed(() => {
    const { startDate, endDate } = formatDates(state.sessionsFilters)

    return [
      startDate && {
        field: 'start_time',
        operator: 'gte',
        value: startDate
      },
      endDate && {
        field: 'end_time',
        operator: 'lte',
        value: endDate
      },
      state.sessionsFilters.location && {
        field: 'location_id',
        operator: 'eq',
        value: state.sessionsFilters.location
      },
      state.sessionsFilters.charger && {
        field: 'charger_id',
        operator: 'eq',
        value: state.sessionsFilters.charger
      },
      state.sessionsFilters.user && {
        field: 'user_id',
        operator: 'eq',
        value: state.sessionsFilters.user
      },
      state.sessionsFilters.type && {
        field: 'type',
        operator: 'eq',
        value: state.sessionsFilters.type
      }
    ].filter(Boolean)
  }),

  invoicesBackendFilters: computed(() => {
    const filters = []
    if ((state.invoiceFilters.type?.length ?? 0) > 0) {
      filters.push({
        field: 'type',
        operator: 'in',
        value: state.invoiceFilters.type
      })
    } else {
      filters.push({
        field: 'type',
        operator: 'in',
        value: 'post-payment|pay_per_charge'
      })
    }
    return [
      ...filters,
      state.invoiceFilters.status && {
        field: 'status',
        operator: 'eq',
        value: state.invoiceFilters.status
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
}

const setters = {
  setFilterValue<
    S extends keyof FilterState,
    F extends keyof FilterState[S]
  > ({ filter, key, value }: { filter: S, key: F, value: FilterState[S][F] }): void {
    state[filter][key] = value
  },

  resetFilters<S extends keyof FilterState> ({ filter }: { filter: S }) {
    state[filter] = initialState()[filter]
  },

  resetFilterValue<
    S extends keyof FilterState,
    F extends keyof FilterState[S]
  > ({ filter, key }: { filter: S, key: F }) {
    state[filter][key] = initialState()[filter][key]
  }
}

function formatDates (filters: FilterState['sessionsFilters']): { startDate: number, endDate: number } {
  const [startDate, endDate] = (filters.dates || []) as [Date, Date]

  let startDateFormatted = 0
  let endDateFormatted = 0

  if (startDate) {
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDateFormatted = Math.trunc(startDate.getTime() / 1000)
  }

  if (endDate) {
    const secondsDay = 86400
    endDate.setHours(0)
    endDate.setMinutes(0)
    endDateFormatted = Math.trunc(endDate.getTime() / 1000) + secondsDay
  }

  return {
    startDate: startDateFormatted,
    endDate: endDateFormatted
  }
}

export default reactive({
  ...toRefs(state),
  ...getters,
  ...setters
})
