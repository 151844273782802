import i18n from '@/engine/lang'
import { numbers } from '@wallbox/toolkit-ui'
import { PeriodicityEnum } from '@/utilities/chartSessions/chartTypeEnums'

export function getIncrement (startingValue: number, finalValue: number) {
  if (startingValue === 0 && finalValue === 0) return 0
  const value = ((finalValue - startingValue) / Math.abs(startingValue)) * 100
  return +numbers.toDecimal(value, i18n.global.locale, 1, 1)
}

export function getTooltipDate (date: Date, periodicity: PeriodicityEnum) {
  if (periodicity === PeriodicityEnum.MONTH) {
    return `${new Intl.DateTimeFormat(i18n.global.locale, { month: 'long' }).format(date)}
      ${date.getFullYear().toString()}`
  }
  if (periodicity === PeriodicityEnum.WEEK) {
    const startDate = new Intl.DateTimeFormat(i18n.global.locale, { dateStyle: 'medium' }).format(date)
    date = new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000)
    const endDate = new Intl.DateTimeFormat(i18n.global.locale, { dateStyle: 'medium' }).format(date)
    return `${startDate} - ${endDate}`
  }
  if (periodicity === PeriodicityEnum.DAY) {
    return new Intl.DateTimeFormat(i18n.global.locale, { dateStyle: 'full' }).format(date)
  }
  if (periodicity === PeriodicityEnum.HOUR) {
    return new Intl.DateTimeFormat(i18n.global.locale, { dateStyle: 'medium' }).format(date) +
      periodicity === PeriodicityEnum.HOUR
      ? ', ' + date.getHours().toString() + ':00h'
      : ''
  }
  return new Intl.DateTimeFormat(i18n.global.locale, { dateStyle: 'medium' }).format(date)
}

export function getIncrementPercentage (value: number) {
  if (value === 0) return `${value}%`
  if (!value) return value
  return value < 0 ? `${value}%` : `+${value}%`
}

export function getIncrementColorClass (value: number) {
  if (!value) return 'has-text-grey-500'
  return value < 0 ? 'has-text-danger-500' : 'has-text-primary-500'
}

export function getIncrementSuffix (periodicity: PeriodicityEnum) {
  if (periodicity === PeriodicityEnum.DAY) return i18n.global.t('mywb.common.from-previous-day')
  if (periodicity === PeriodicityEnum.WEEK) return i18n.global.t('mywb.common.from-previous-week')
  if (periodicity === PeriodicityEnum.MONTH) return i18n.global.t('mywb.common.from-previous-month')
  return ''
}
