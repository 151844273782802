<template>
  <organizations-header
    @on-create-organization="data.isModalEditOrganizationOpen = true"
  />

  <organizations-table
    :loading="data.loading"
    :organizations="data.organizations"
    :options-table="data.optionsOrganizations"
  />

  <organization-create-modal
    v-if="data.isModalEditOrganizationOpen"
    :organizations-count="data.organizations.length"
    @on-close="methods.closeCreateOrganization"
  />
</template>

<script setup lang="ts">
import OrganizationCreateModal from '@/components/modals/OrganizationCreateModal.vue'
import OrganizationsTable from '@/components/tables/OrganizationsTable.vue'
import OrganizationsHeader from '@/components/headers/OrganizationsHeader.vue'

import api from '@/api'
import { useRoute, useRouter } from 'vue-router'
import { reactive, watchEffect, watch } from 'vue'
import state from '@/state'
import type { Organizations, Filters } from '@/types'

const route = useRoute()
const router = useRouter()

type DataType = {
  loading: boolean
  organizations: Organizations.Organization[]
  isModalEditOrganizationOpen: boolean
  optionsOrganizations: {
    stickyHead: string
  }
}

const data: DataType = reactive({
  loading: false,
  organizations: [],
  isModalEditOrganizationOpen: false,
  optionsOrganizations: {
    stickyHead: 'var(--header-height)'
  }
})

const methods = {
  async getOrganizations () {
    data.loading = true

    const filters = state.filters.backendOrganizationsFilters as Filters.Filters[]

    const { data: organizations } = await api.organizations.getOrganizations(filters)
    state.organizations.setOrganizations(organizations)

    data.organizations = organizations
    data.loading = false
  },

  closeCreateOrganization () {
    data.isModalEditOrganizationOpen = false
  }
}

watch(() => state.filters.backendOrganizationsFilters, () => {
  methods.getOrganizations()
}, { immediate: true })

watchEffect(() => {
  if (route.query['create-organization']) {
    router.push({ query: {} }).catch(() => {})
    data.isModalEditOrganizationOpen = true
  }
})
</script>
