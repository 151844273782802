import LocationsView from '@/views/LocationsView.vue'
import LocationView from '@/views/LocationView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'locations',
    name: 'locations',
    component: LocationsView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'locations',
        text: 'mywb.common.locations'
      }
    }
  },
  {
    path: 'locations/:groupId',
    name: 'location-detail',
    component: LocationView,
    props: true,
    meta: {
      breadcrumb: {
        name: 'location-detail'
      }
    }
  }
] as RouteRecordRaw[]
