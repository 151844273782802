<template>
  <wb-table
    data-test-id="ratesSection"
    :options="data.options"
    :loading="props.loading"
  >
    <wb-table-row
      v-for="rate in compute.ratesParsed"
      :key="rate.id"
    >
      <wb-table-row-item data-test-id="rateName">
        {{ rate.name }}
      </wb-table-row-item>

      <wb-table-row-item data-test-id="fixedFee">
        {{ rate.fixedFeeParsed }}
      </wb-table-row-item>

      <wb-table-row-item data-test-id="variableType">
        <div class="is-flex">
          <span v-if="rate.iconVariableType" class="wb-icons is-size-500 mr-4">
            {{ rate.iconVariableType }}
          </span>
          {{ rate.variableTypeParsed }}
        </div>
      </wb-table-row-item>

      <wb-table-row-item data-test-id="variablePrice">
        {{ rate.variableFeePriceParsed }}
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-label
          data-test-id="assigned"
          is-active
          disabled
          :color="rate.assigned ? 'success' : 'warning'"
          icon="lens_filled"
        >
          {{ rate.assigned ? i18n.t('mywb.common.yes') : i18n.t('mywb.common.no') }}
        </wb-label>
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-button-groups>
          <wb-popover class="group-item">
            <template #activator>
              <wb-button
                data-test-id="editRateBtn"
                type="white"
                outlined
                icon="edit"
                size="small"
                :to="{ name: 'payments-new-rates-edit', params: { rateId: rate.id }}"
                @click="methods.updateRate(rate)"
              />
            </template>
            <template #tooltip>
              {{ i18n.t('mywb.common.edit') }}
            </template>
          </wb-popover>
          <wb-popover class="group-item">
            <template #activator>
              <wb-button
                data-test-id="deleteRateBtn"
                type="danger"
                icon="delete"
                outlined
                size="small"
                class="group-item"
                @click="methods.deleteRate(rate)"
              />
            </template>
            <template #tooltip>
              {{ i18n.t('mywb.common.delete') }}
            </template>
          </wb-popover>
        </wb-button-groups>
      </wb-table-row-item>
    </wb-table-row>

    <template #empty-table-action>
      <wb-button
        :label="i18n.t('mywb.common.create-rate')"
        type="white"
        outlined
        :to="{ name: 'payments-new-rates-create' }"
      />
    </template>
  </wb-table>
</template>

<script setup lang="ts">
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { numbers, type TableProps } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { getCurrencyCode } from '@/utilities/currency'
import { reactive, computed } from 'vue'
import type { Rate } from '@/types'

const i18n = useI18n()

interface Events {
  (e: 'on-delete-rate', rate: Rate.Rate): void
}

const emit = defineEmits<Events>()

interface Props {
  rates: Rate.RateWithAssigned[]
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  rates: () => []
})

const data = reactive({
  options: {
    offset: '0 9%',
    stickyHead: 'var(--header-height)',
    columns: [
      {
        key: 'name',
        name: i18n.t('mywb.common.name'),
        align: 'left',
        minWidth: '200px',
        width: 'auto'
      },
      {
        key: 'fixed-fee',
        name: i18n.t('mywb.common.fixed-fee'),
        minWidth: '200px',
        align: 'left',
        width: 'auto'
      },
      {
        key: 'variable-fee-type',
        name: i18n.t('mywb.rates.variable-fee-type'),
        minWidth: '150px',
        align: 'left',
        width: 'auto'
      },
      {
        key: 'variable-fee-price',
        name: i18n.t('mywb.rates.variable-fee-price'),
        minWidth: '150px',
        align: 'left',
        width: 'auto'
      },
      {
        key: 'assgined',
        name: i18n.t('mywb.common.assigned'),
        minWidth: 'auto',
        align: 'left',
        width: 'auto'
      },
      {
        key: '',
        name: i18n.t('mywb.common.actions'),
        align: 'left',
        minWidth: '240px',
        width: 'auto'
      }
    ] as TableProps['options']['columns'],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

const compute = reactive({
  ratesParsed: computed(() => {
    return props.rates.map(rate => ({
      ...rate,
      fixedFeeParsed: methods.getFixedFee(rate),
      variableTypeParsed: methods.getVariableType(rate),
      iconVariableType: methods.getIconVariableType(rate),
      variableFeePriceParsed: methods.getVariableFee(rate)
    }))
  })
})

const methods = {
  updateRate (rate: Rate.RateWithAssigned) {
    trackDataAction('edit-rate', {
      rate_name: rate.name,
      rate_fixed_access_fee: rate.fixed_fee,
      rate_variable_fee: rate.variable_fee_price,
      rate_variable_type: rate.variable_fee_type
    })
  },

  deleteRate (rate: Rate.RateWithAssigned) {
    emit('on-delete-rate', rate)
  },

  getFixedFee (rate: Rate.RateWithAssigned) {
    if (rate.fixed_fee === 0) return '--'
    return numbers.toLocaleCurrencySymbol(rate.fixed_fee, getCurrencyCode(), i18n.locale.value)
  },

  getVariableType (rate: Rate.RateWithAssigned) {
    if (rate.variable_fee_type === 'access') return '--'

    const types = {
      time: i18n.t('mywb.common.charging-time.fee'),
      energy: i18n.t('mywb.common.consumed-energy.fee')
    }
    return types[rate.variable_fee_type]
  },

  getIconVariableType (rate: Rate.RateWithAssigned) {
    if (rate.variable_fee_type === 'access') return ''

    const types = {
      time: 'clock',
      energy: 'bolt_circle'
    }
    return types[rate.variable_fee_type]
  },

  getVariableFee (rate: Rate.RateWithAssigned) {
    if (rate.variable_fee_type === 'access') return '--'

    const feeAmount = rate.variable_fee_price ?? 0
    let postfix = ''
    if (rate.variable_fee_type === 'time') {
      postfix = ` / ${i18n.t('mywb.common.hour')}`
    } else if (rate.variable_fee_type === 'energy') {
      postfix = ` / ${i18n.t('mywb.common.kwh')}`
    }

    const fee = numbers.toLocaleCurrencySymbol(feeAmount, getCurrencyCode(), i18n.locale.value)
    return fee + postfix
  }
}
</script>
