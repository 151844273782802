<template>
  <div>
    <p class="label">
      {{ i18n.t('mywb.common.location') }}
      <wb-label
        v-if="permissions.showUpSellingToPlanBusiness"
        is-invert
        disabled
        color="grey"
        icon="lock"
        class="has-position-relative"
      >
        {{ i18n.t('mywb.plans.business') }}
      </wb-label>

      <wb-link
        v-if="permissions.showUpSellingToPlanBusiness && permissions.canChangePlan"
        class="is-size-400 ml-8 has-position-relative"
        @click="methods.goToPlans"
      >
        {{ i18n.t('mywb.plan.upgrade') }}
      </wb-link>
    </p>

    <div class="grid g-24">
      <location-group
        v-model="compute.groupId"
        :disabled="permissions.showUpSellingToPlanBusiness"
        :loading="props.loading"
        :error="props.error"
        @on-change="emit('on-change')"
      />
    </div>

    <wb-button
      v-if="permissions.canSeeLocations"
      :label="i18n.t('mywb.common.add-new-location')"
      data-test-id="addLocation"
      inverted
      type="primary"
      icon="add"
      class="mt-16 has-offset"
      @click="emit('on-create-location')"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { permissions } from '@/engine/clients'
import LocationGroup from '@/components/locations/LocationGroup.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { trackDataAction } from '@/engine/metrics/trackDataManager'

const i18n = useI18n()
const router = useRouter()

interface Props {
  groupId?: number
  error?: string
  loading?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:groupId', groupId?: number): void,
  (e: 'on-change'): void,
  (e: 'on-create-location'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  groupId: computed({
    get () {
      return props.groupId
    },
    set (value) {
      emit('update:groupId', value)
    }
  })
})

const methods = {
  goToPlans () {
    router.push({ name: 'plans' })
    trackDataAction('upgrade_plan', {
      source: 'add_chargers_locations'
    })
  }
}
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 520px auto;
  }
}

.has-position-relative {
  position: relative;
  top: -3px;
}

.is-primary-link {
  color: var(--primary-500);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  margin-bottom: 8px;
}

.has-offset {
  position: relative;
  left: -10px;
}
</style>
