<template>
  <wb-modal
    width="36"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', {element: props.user.name + ' ' + props.user.lastname}) }}
    </template>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          type="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          size="block"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          size="block"
          type="danger"
          :label="i18n.t('mywb.common.delete')"
          :loading="data.loading"
          @click="methods.removeUser"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import { reactive } from 'vue'
import type { User } from '@/types'

const i18n = useI18n()
const notify = useNotify()

interface Props {
  user: User.UserGroup
  groupId: number
  accessConfigId?: number
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const methods = {
  async removeUser () {
    try {
      const { user, accessConfigId } = props
      data.loading = true
      accessConfigId
        ? await methods.removeUserFromGroup()
        : await methods.removeUserFromRootGroup()
      trackDataEvent('delete-user', { user_id: user.id })
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('close')
      data.loading = false
    }
  },

  async removeUserFromRootGroup () {
    const { groupId, user } = props
    await api.accessConfig.deleteUserFromGroup({ groupId, userId: user.id })
    state.user.set('usersGroup', await api.users.getUserGroups())
  },

  async removeUserFromGroup () {
    const { accessConfigId, user } = props
    if (!accessConfigId) return
    await api.accessConfig.deleteUserFromAccessConfig({ accessConfigId, userId: user.id, action: 'delete' })
    state.user.set('usersGroup', await api.users.getUserGroups())
  }
}

function created () {
  trackDataScreen('user-list-user-delete')
}

created()
</script>
