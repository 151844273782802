<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.charger.ocpp') }}
    </template>

    <template #actions>
      <wb-button
        :label="mq.current !== 'mobile' ? i18n.t('mywb.action.scan-chargers') : ''"
        data-test-id="scanChargersButton"
        icon="sync"
        type="primary"
        class="ml-12"
        :loading="data.scanning"
        @click="methods.scanChargers"
      />
    </template>
  </shared-header>

  <div class="card-content mb-24">
    <p class="is-size-400 is-font-weight-500 mb-8">
      {{ i18n.t('mywb.ocpp.websocket-url.title') }}
    </p>
    <p class="is-size-400">
      {{ i18n.t('mywb.ocpp.websocket-url.description') }}
    </p>
    <p class="is-size-400">
      {{ i18n.t('mywb.ocpp.websocket-url.description2') }}
    </p>
    <div
      class="url-copy-button"
      @click="methods.copy"
    >
      <span class="wb-icons icon">file_copy</span>
      <p class="text">
        {{ data.urlWebSocket || i18n.t('mywb.common.loading') }}
      </p>
    </div>
  </div>

  <div>
    <wb-table
      :options="data.options"
      data-test-id="tableGroupsUsers"
    >
      <wb-table-row
        v-for="charger in data.chargers"
        :key="charger.ocppChargerId"
        :data-test-id="`usersGroup${charger.ocppChargerId}`"
      >
        <wb-table-row-item data-test-id="id">
          {{ charger.ocpp_charger_id }}
        </wb-table-row-item>
        <wb-table-row-item data-test-id="serialNumber">
          {{ charger.serial_number }}
        </wb-table-row-item>
        <wb-table-row-item data-test-id="vendor" class="is-uppercase">
          {{ charger.vendor }}
        </wb-table-row-item>
        <wb-table-row-item data-test-id="model" class="is-uppercase">
          {{ charger.model }}
        </wb-table-row-item>
        <wb-table-row-item data-test-id="status">
          <wb-label is-invert color="success">
            {{ i18n.t('mywb.common.connected') }}
          </wb-label>
        </wb-table-row-item>
      </wb-table-row>
    </wb-table>
  </div>
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'
import api from '@/api'
import state from '@/state'
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify, type TableProps } from '@wallbox/toolkit-ui'
import { useMq } from 'vue3-mq'
import type { Ocpp } from '@/types'

const i18n = useI18n()
const notify = useNotify()
const mq = useMq()

interface Data {
  urlWebSocket?: string,
  chargers: Ocpp.OcppCharger[]
  options: TableProps['options'],
  scanning: boolean
  scanUrl?: ReturnType<typeof setInterval>
}
const data = reactive<Data>({
  urlWebSocket: undefined,
  chargers: [],
  options: {
    columns: [
      { name: i18n.t('mywb.common.id'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.charger.serial-number'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.vendor'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.model'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: '200px', width: 'auto' }
    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  },
  scanning: false,
  scanUrl: undefined
})

const methods = {
  async scanChargers () {
    data.scanning = true
    try {
      const { data: chargers } = await api.ocpp.getOcppChargers(state.organizations.getCurrentOrganization.group_uid)
      data.chargers = chargers?.map(element => element.attributes) || []
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.scanning = false
    }
  },

  copy () {
    data.urlWebSocket && navigator.clipboard.writeText(data.urlWebSocket)
      .then(() => notify.success(i18n.t('mywb.common.copied')))
      .catch(() => notify.error(i18n.t('mywb.error.unexpected-error')))
  },

  async enableAutoScanning () {
    data.scanning = true
    data.scanUrl = setInterval(async () => {
      await methods.scanChargers()
      data.scanning = false
    }, 30000)
  }
}

onMounted(async () => {
  data.urlWebSocket = ''
  try {
    data.urlWebSocket = await api.ocpp.getOcppUrl(state.organizations.getCurrentOrganization.group_uid)
    if (!data.urlWebSocket) {
      await api.ocpp.postOcppUrl(state.organizations.getCurrentOrganization.group_uid)
      data.urlWebSocket = await api.ocpp.getOcppUrl(state.organizations.getCurrentOrganization.group_uid)
    }
  } catch {
    notify.error(i18n.t('mywb.error.unexpected-error'))
  }

  methods.scanChargers()
  methods.enableAutoScanning()
})

onBeforeUnmount(() => {
  clearInterval(data.scanUrl)
})
</script>

<style lang="postcss" scoped>
.card-content {
  background: #f8f8f8;
  padding: 1.6rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  color: var(--grey-900);
}

.url-copy-button {
  background: white;
  display: grid;
  grid-template-columns: min-content auto;
  padding: 16px;
  margin: 8px 0;
  align-items: center;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
  border: 1px solid var(--grey-200);

  &:hover {
    color: var(--primary-500);
    border: 1px solid var(--primary-500);
  }

  & .icon {
    font-size: 20px;
    margin-right: 12px;
  }
}
</style>
