import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import { formatResponseJsonApi } from '@/api/config/jsonApi.config'
import { ulid } from 'ulid'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import type { OrganizationsApi, Filters } from '@/types'
import type { OrganizationTypeEnum } from '@/utilities/organizationTypes'

export default {
  async postOrganization ({ name, country_code, type }:
  { name: string, country_code: string, type: OrganizationTypeEnum }): Promise<void> {
    return await axios.post(endpoints.v4.organizations, {
      data: {
        type: 'organization',
        id: ulid(),
        attributes: {
          name,
          type,
          country_code
        }
      }
    })
  },

  async putOrganization ({ groupUid, name, type }:
  { groupUid: string, name: string, type: OrganizationTypeEnum }): Promise<void> {
    trackDataEvent('edit-location', { location_id: groupUid, type })
    return await axios.patch(endpoints.v4.organization.replace('{groupUid}', groupUid.toString()), {
      data: {
        type: 'organization',
        id: groupUid,
        attributes: {
          name,
          type
        }
      }
    })
  },

  async getOrganizations (filters?: Filters.Filters[]) {
    return await axios.get<unknown, OrganizationsApi.ApiOrganizationsResponse>(endpoints.v4.spaces, {
      params: {
        filters: filters && JSON.stringify({ filters }),
        limit: 999
      }
    }).then(formatResponseJsonApi)
  }
}
