<template>
  <wb-modal
    v-if="data.verifiedSuccess"
    data-test-id="confirmModal"
    class="has-text-centered"
    width="30"
    @close="emit('close')"
  >
    <wb-check-icon />
    <p class="is-size-700 has-text-primary-500">
      {{ i18n.t('mywb.user.invite-accepted') }}
    </p>
    <template #actions>
      <wb-button
        size="block"
        data-test-id="confirmBtnModal"
        :label="i18n.t('mywb.common.done')"
        @click="emit('close')"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { getServerError } from '@/utilities/errorMessages'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import api from '@/api'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const route = useRoute()
const notify = useNotify()
const i18n = useI18n()

interface Events {
  (e: 'accept-invite'): void,
  (e: 'close'): void
}

const emit = defineEmits<Events>()

const data = reactive({
  verifiedSuccess: false
})

onMounted(async () => {
  try {
    await api.auth.activateUser({
      email: route.query.email?.toString() ?? '',
      activation_code: route.query.confirmToken?.toString() ?? ''
    })
    data.verifiedSuccess = true
    trackDataScreen('check-invite-user')
    emit('accept-invite')
  } catch (error) {
    if (error instanceof HttpError) {
      if (error?.status === 400) {
        notify.error(i18n.t('mywb.error.activation-code-expired'))
      } else {
        notify.error(getServerError(error))
      }
    } else {
      throw error
    }
  }
})
</script>
