<template>
  <div>
    <shared-header>
      <template #custom-title>
        <charger-header
          class="header"
        />
      </template>

      <template #actions>
        <charger-header-actions
          :loading="data.loading"
        />
      </template>
    </shared-header>

    <enable-auto-refresh-component
      v-if="data.showActivateInterval"
      @enable-auto-refresh="methods.enableAutoRefresh"
    />

    <wb-tabs
      v-if="!data.loading"
      v-model="data.tabSelected"
      :tabs="compute.sections"
      @tab-change="methods.changeTab"
    />

    <router-view v-slot="{ Component }">
      <container-component>
        <component
          :is="Component"
          :charger="state.charger.getCurrentCharger"
          :loading="data.loading"
          :has-real-time-information="!data.showActivateInterval"
          @on-wait-for-action="methods.disableAutoRefresh"
          @on-update="methods.enableAutoRefresh"
        />
      </container-component>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'
import ChargerHeader from '@/components/headers/ChargerHeader.vue'
import ChargerHeaderActions from '@/components/headers/ChargerHeaderActions.vue'
import ContainerComponent from '@/components/ContainerComponent.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import { isOcpp, supportsOcpp } from '@/utilities/charger/chargerOcppSettings'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import api from '@/api'
import state from '@/state'
import { computed, reactive, onBeforeUnmount, watchEffect, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

interface Tab {
  key: string
  value: string
  permissions: {
    plans: EnumPlans[]
    roles: EnumRoles[]
    ocpp?: boolean
  }
  dataTestId: string
}

interface Data {
  tabs: Tab[]
  tabSelected?: Tab
  loading: boolean
  showActivateInterval: boolean
  reloadChargerInterval?: ReturnType<typeof setInterval>
  chargerId?: number
  unmounted: boolean
}

const data: Data = reactive({
  tabs: [
    {
      key: 'charger',
      value: i18n.t('mywb.common.overview'),
      permissions: {
        plans: [EnumPlans.BASIC, EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerTabLabel'
    },
    {
      key: 'charger-users',
      value: i18n.t('mywb.common.users'),
      permissions: {
        ocpp: false,
        plans: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerUsersTabLabel'
    },
    {
      key: 'charger-sessions',
      value: i18n.t('mywb.common.sessions'),
      permissions: {
        plans: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerSessionsTabLabel'
    },
    {
      key: 'charger-settings',
      value: i18n.t('mywb.common.settings'),
      permissions: {
        plans: [EnumPlans.BASIC, EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },

      dataTestId: 'chargerSettingsTabLabel'
    }
  ],
  loading: false,
  showActivateInterval: false,
  unmounted: false
})

const compute = reactive({
  isPlan: computed(state.organizations.isPlan),

  isRole: computed(state.organizations.isRole),

  sections: computed((): Tab[] => {
    return data.tabs.filter(tab =>
      compute.isRole(tab.permissions.roles) &&
        compute.isPlan(tab.permissions.plans) && (
        (tab.permissions.ocpp && supportsOcpp(state.charger.getCurrentCharger.chargerData)) ||
        (!tab.permissions.ocpp && !isOcpp(state.charger.getCurrentCharger.config)) ||
        (typeof tab.permissions.ocpp === 'undefined')
      )
    )
  })
})

watchEffect(() => {
  data.tabSelected = data.tabs.find(tab => tab.key === route.name?.toString())
})

onBeforeUnmount(() => {
  state.charger.resetCurrentCharger()
  clearInterval(data.reloadChargerInterval)
  data.unmounted = true
})

const methods = {
  async loadData (chargerId: number) {
    try {
      const charger = await api.chargers.getCharger(chargerId, { cacheType: 'stale' })
      state.charger.setCurrentCharger(charger)
    } catch (error) {
      if (error instanceof HttpError && error.status === 403) {
        router.push({ name: 'not-found' }).catch(() => {})
      } else {
        throw error
      }
    }
  },

  disableAutoRefresh () {
    clearInterval(data.reloadChargerInterval)
    data.showActivateInterval = true
  },

  async enableAutoRefresh ({ withLoading = false } = {}) {
    if (!data.chargerId) return
    data.showActivateInterval = false

    clearInterval(data.reloadChargerInterval)
    data.loading = withLoading
    await methods.loadData(data.chargerId)
    data.loading = false

    if (!data.unmounted) {
      data.reloadChargerInterval = setInterval(() => {
        if (!data.chargerId) return
        methods.loadData(data.chargerId)
      }, 10000)

      setTimeout(() => {
        methods.disableAutoRefresh()
      }, 500000)
    }
  },

  changeTab (tab: Tab) {
    router.push({ name: tab.key })
  }
}

const created = async () => {
  data.chargerId = +route.params.chargerId
  await methods.enableAutoRefresh({ withLoading: true })
}

created()

watch(
  () => state.charger.getCurrentCharger,
  () => state.routes.hydrateCurrentBreadCrumb(state.charger.getCurrentCharger.config.name ?? '')
)
</script>
