<template>
  <wb-card
    :headline="i18n.t('mywb.charger.gun-lock-title')"
  >
    <template #content>
      <wb-notification
        type="info"
        class="mt-16 mb-16"
        icon="info_filled"
      >
        <p
          v-if="compute.supportsGunLock.isCompatible"
          v-t="'mywb.charger.gun-lock-notification'"
        />

        <p
          v-else
        >
          {{ compute.supportsGunLock.reason }}
        </p>
      </wb-notification>

      <div v-t="'mywb.charger.gun-lock-subtitle'" />
    </template>

    <template #place-upper-right>
      <wb-switch
        v-model="compute.lockGun"
        data-test-id="switch"
        :disabled="props.loading || data.saving || !compute.supportsGunLock.isCompatible"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import api from '@/api'
import state from '@/state'
import { reactive, computed } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { isGunLockCompatible } from '@/utilities/charger/chargerCompatible'

const notify = useNotify()
const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  saving: boolean
}

const data = reactive<Data>({
  saving: false
})

const compute = reactive({
  supportsGunLock: computed(() => {
    return isGunLockCompatible(state.charger.getCurrentCharger.chargerData)
  }),

  lockGun: computed({
    get () {
      return !!state.charger.getCurrentCharger.config.user_socket_locking
    },

    set (value) {
      methods.changeLockGun(value)
    }
  })
})

const methods = {
  async changeLockGun (value: boolean) {
    data.saving = true
    try {
      await api.chargers.updateChargerConfig({
        id: state.charger.getCurrentCharger.chargerData.id,
        config: { user_socket_locking: value }
      })

      const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
      state.charger.setCurrentCharger(charger)
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.saving = false
    }
  }
}

</script>
