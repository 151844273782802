<template>
  <wb-card
    :headline="i18n.t('mywb.active-chargers')"
  >
    <template #content>
      <wb-table
        :options="data.options"
        :loading="data.loading"
      >
        <wb-table-row
          v-for="charger in data.chargers"
          :key="charger.chargerData.id"
          data-test-id="activeSession"
        >
          <wb-table-row-item
            class="has-cursor-pointer"
            @click="methods.handleClickCharger(charger.chargerData)"
          >
            <img :src="charger.chargerData.image" :alt="charger.chargerData.name">
          </wb-table-row-item>

          <wb-table-row-item
            class="has-cursor-pointer has-text-underline-hover"
            @click="methods.handleClickCharger(charger.chargerData)"
          >
            <div>
              <p data-test-id="chargerName" class="is-font-weight-500">
                {{ charger.chargerData.name }}
              </p>
              <p data-test-id="chargerLocation">
                {{ charger.chargerData.location.name }}
              </p>
            </div>
          </wb-table-row-item>

          <wb-table-row-item>
            <charger-status data-test-id="chargerStatus" :charger="charger.chargerData" />
          </wb-table-row-item>

          <wb-table-row-item
            :class="{'has-cursor-pointer has-text-underline-hover': charger.user.id }"
            data-test-id="chargerUser"
            @click="charger.user.id && methods.handleClickUser(charger.user)"
          >
            <div class="row-content">
              <wb-user-avatar
                size="small"
                :src="charger.user.avatar"
                :initials="charger.user.initials"
                class="mr-8 no-flex-shrink"
              />
              <span>
                {{ charger.user.name }} {{ charger.user.lastname }}
              </span>
            </div>
          </wb-table-row-item>

          <wb-table-row-item data-test-id="sessionDuration">
            {{ charger.duration }}
          </wb-table-row-item>

          <wb-table-row-item data-test-id="sessionEnergy">
            {{ charger.energy }}
          </wb-table-row-item>

          <wb-table-row-item data-test-id="sessionPower">
            {{ charger.power }}
          </wb-table-row-item>
        </wb-table-row>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import { numbers, time, useNotify, type TableProps } from '@wallbox/toolkit-ui'
import { userInitials } from '@/utilities/users'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import api from '@/api'
import { reactive, watch, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import {
  getChargerStatus,
  STATUSES
} from '@/utilities/charger/chargerStatuses'
import type { Organizations, Charger, Session, User, Group } from '@/types'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const notify = useNotify()
const i18n = useI18n()
const router = useRouter()

interface Props {
  hasRealTimeInformation?: boolean
  organization?: Organizations.Organization
}
const props = withDefaults(defineProps<Props>(), {
  hasRealTimeInformation: true,
  organization: undefined
})

interface SessionsParsed {
  chargerData: Charger.Charger & { location: Group.GroupCharger }
  user: User.UserGroup & { initials: string }
  duration: string
  energy: string
  power: string
}

interface Data {
  loading: boolean,
  unmounted: boolean
  options: TableProps['options']
  reloadChargersInterval?: ReturnType<typeof setInterval>
  chargers: SessionsParsed[]
}

const data = reactive<Data>({
  loading: false,
  unmounted: false,
  options: {
    columns: [
      { name: '', align: 'left', width: '75px' },
      {
        name: i18n.t('mywb.common.chargers'),
        align: 'left',
        minWidth: '150px',
        width: 'auto',
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: '150px', width: 'auto' },
      {
        name: i18n.t('mywb.common.user'),
        align: 'left',
        minWidth: '150px',
        width: 'auto',
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.common.duration'), align: 'left', minWidth: '100px', width: 'auto' },
      { name: i18n.t('mywb.common.energy'), align: 'left', minWidth: '100px', width: 'auto' },
      { name: i18n.t('mywb.common.power'), align: 'left', minWidth: '100px', width: 'auto' }

    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  },
  reloadChargersInterval: undefined,
  chargers: []
})

const methods = {
  enableAutoRefresh () {
    methods.loadChargers(true)

    if (!data.unmounted) {
      data.reloadChargersInterval = setInterval(() => {
        methods.loadChargers(false)
      }, 10000)
    }
  },

  cancelAutoRefresh () {
    clearInterval(data.reloadChargersInterval)
  },

  async setStates () {
    state.user.set('usersGroup', await api.users.getUserGroups())
    const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
    state.groups.set('groups', result.groups)
  },

  async loadChargers (withLoading = true) {
    data.loading = withLoading
    await methods.setStates()
    data.chargers = await methods.getActiveChargers()
    data.loading = false
  },

  async getActiveChargers () {
    try {
      const filters = [{ field: 'group_id', operator: 'eq', value: props.organization.group_id }]
      const { data } = await api.dashboard.getChargersLastSessions({ filters })
      return methods.getActiveChargersParsed(data)
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }

      return []
    }
  },

  getActiveChargersParsed (sessions: Session.ChargersLastSessions[]): SessionsParsed[] {
    const activeChargersParsed = sessions
      .filter(session => {
        const charger = methods.getChargerData(session)

        if (!charger) return false

        if (state.filters.dashboardFilters.locations.some(location => location.group_uid === charger.location.uid)) {
          return false
        }

        const status = getChargerStatus(charger)

        return [
          STATUSES.WAITING,
          STATUSES.SCHEDULED,
          STATUSES.CHARGING,
          STATUSES.DISCHARGING,
          STATUSES.PAUSED
        ].includes(status.code)
      })

    return activeChargersParsed.map(session => {
      const chargerData = methods.getChargerData(session)

      return {
        chargerData,
        user: methods.getUserData(session),
        duration: methods.getDurationActiveSession(session, new Date().getTime() / 1000),
        energy: methods.getEnergyActiveSession(session),
        power: methods.getPowerActiveSession(chargerData, session)
      }
    })
  },

  getUserData (charger: Session.ChargersLastSessions): User.UserGroup & { initials: string } {
    const groupUsers = state.user.getCurrentUserGroupsMap[props.organization.group_id].users
    const userParsed = {
      name: i18n.t('mywb.common.anonymous'),
      ...groupUsers.find(user => user.id === charger.attributes.user_id) as User.UserGroup
    }
    return { ...userParsed, initials: userInitials(userParsed) }
  },

  getChargerData (session: Session.ChargersLastSessions) {
    const chargers = [...state.groups.groupRelatedToOrganizationWithChargers.chargers]
    let chargersWithLocation = chargers.map(charger => ({
      ...charger,
      location: state.groups.groupRelatedToOrganizationWithChargers
    }))

    if (state.groups.groupRelatedToOrganizationWithChargers?.subgroups) {
      state.groups.groupRelatedToOrganizationWithChargers.subgroups.forEach(group => {
        const subGroupChargersTree = group.chargers.map(chargerData => ({
          ...chargerData,
          location: group
        }))

        chargersWithLocation = [
          ...chargersWithLocation,
          ...subGroupChargersTree
        ]
      })
    }
    const chargerParsed = chargersWithLocation.find(chargerData => chargerData.id === session.attributes.charger_id)

    return chargerParsed as NonNullable<typeof chargerParsed>
  },

  getDurationActiveSession (session: Session.ChargersLastSessions, nowTime: number) {
    if (session.attributes.start_time && session.attributes.start_time > 0) {
      return time.getTimeDurationString(nowTime - session.attributes.start_time, ['h', 'm'])
    }
    return '-'
  },

  getEnergyActiveSession (session: Session.ChargersLastSessions) {
    if (session.attributes.start_time && session.attributes.start_time > 0) {
      return `${numbers.toDecimal(
        session.attributes.charging_energy, i18n.locale.value, 3, 3)} ${i18n.t('mywb.common.kwh')
      }`
    }
    return '-'
  },
  getPowerActiveSession (charger: Charger.Charger, session: Session.ChargersLastSessions) {
    if (session.attributes.start_time && session.attributes.start_time > 0) {
      return `${numbers.toDecimal(
        charger.chargingPower, i18n.locale.value, 3, 3)} ${i18n.t('mywb.common.kw')
      }`
    }
    return '-'
  },
  handleClickCharger (charger: Charger.Charger) {
    trackDataAction('dashboard-charger', { widget: 'activeSessionsTable', type: 'goToChargers' })
    router.push({ name: 'charger', params: { chargerId: charger.id } })
  },

  handleClickUser (user: User.UserGroup) {
    trackDataAction('dashboard-user', { widget: 'activeSessionsTable', type: 'goToUsers' })
    router.push({ name: 'user', params: { id: user.id } })
  }
}

onBeforeUnmount(() => {
  data.unmounted = true
  clearInterval(data.reloadChargersInterval)
})

watch(() => props.hasRealTimeInformation, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
}, { immediate: true })

watch(() => props.organization, (value, oldvalue) => {
  if (value.id !== oldvalue.id) {
    methods.loadChargers()
  }
})

</script>

<style lang="postcss" scoped>
.no-flex-shrink {
  flex-shrink: 0;
}

.row-content {
  display: flex;
  align-items: center;
}

:deep(.table-wrapper) {
  position: relative !important;
  left: -18px;
  width: calc(100% + 36px);
  border: 0 !important;
  border-radius: 0 !important;
}

:deep(.head) {
  border-radius: 0 !important;
}
</style>
