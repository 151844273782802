<template>
  <dashboard-card
    title="mywb.common.organizations"
    subtitle="mywb.common.total-organizations-created"
    icon-color="var(--grey-400)"
    icon="business"
    :number="compute.totalOrganizations"
    :loading="props.loading"
  >
    <div class="is-grid mt-16">
      <wb-button
        data-test-id="viewAllOrganizations"
        :label="t('mywb.common.view-all')"
        type="white"
        :to="{ name: 'organizations' }"
      />
    </div>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'

const { t } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

const compute = reactive({
  totalOrganizations: computed((): number => {
    return state.organizations.allOrganizations.length
  })
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  align-items: center;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 8px;
}
</style>
