import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import type { BillingInfoApi } from '@/types'

export default {
  async getBillingInfo (groupId: number): Promise<BillingInfoApi.apiBillingInfo> {
    return await axios.get(endpoints.v3.groups_groupId_billingInfo.replace('{groupId}', groupId?.toString()))
  },

  async putBillingInfo ({ groupId, billingInfo }: BillingInfoApi.putBillingInfo): Promise<void> {
    return await axios.put(endpoints.v3.groups_groupId_billingInfo
      .replace('{groupId}', groupId.toString()), billingInfo)
  }
}
