<template>
  <wb-table
    :options="data.options"
    :loading="!compute.users.length"
  >
    <wb-table-row
      v-for="user in compute.users"
      :key="user.id"
    >
      <wb-table-row-item>
        <div class="row-content">
          <wb-user-avatar
            size="small"
            :src="user.avatar"
            :initials="user.initials"
            class="mr-8"
          />
          {{ user.name }} {{ user.surname }}
        </div>
      </wb-table-row-item>
      <wb-table-row-item>
        {{ user.email }}
      </wb-table-row-item>
      <wb-table-row-item>
        <user-role-item :profile="user.role" />
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import UserRoleItem from '@/components/users/UserRoleItem.vue'
import { EnumRoles } from '@/utilities/user-roles'
import { userInitials } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import type { TableProps } from '@wallbox/toolkit-ui'

const i18n = useI18n()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 4%',
    stickyHead: 'var(--header-height)',
    columns: [
      { name: i18n.t('mywb.common.name'), align: 'left', minWidth: '300px', width: 'auto' },
      { name: i18n.t('mywb.common.email'), align: 'left', minWidth: '300px', width: 'auto' },
      { name: i18n.t('mywb.user.user-type'), align: 'center', minWidth: '300px', width: 'auto' }
    ]
  }
})

const compute = reactive({
  users: computed(() => {
    return state.charger.getCurrentCharger.users
      .filter(user => user.assigned)
      .map(user => ({
        ...user,
        role: EnumRoles[user.profile],
        initials: userInitials(user)
      }))
  })
})
</script>

<style lang="postcss" scoped>
.row-content {
  display: flex;
  align-items: center;
}
</style>
