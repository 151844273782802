import type { Rate } from '@/types'
import { useForm, useField } from 'vee-validate'
import { reactive, type Ref } from 'vue'

export function useFormValidator (
  { payPerMonthActivated, payPerChargeActivated }:
  { payPerMonthActivated: Ref<boolean>, payPerChargeActivated: Ref<boolean> }
) {
  const validationSchema = reactive({
    payPerMonthRate: { required: payPerMonthActivated },
    payPerChargeRate: { required: payPerChargeActivated }
  })

  return useForm({ validationSchema })
}

export function useValidatedRateTypes () {
  return reactive({
    payPerMonthRate: { ...useField<Rate.Rate>('payPerMonthRate') },
    payPerChargeRate: { ...useField<Rate.Rate>('payPerChargeRate') }
  })
}
