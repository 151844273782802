import toolkitInit from '@/engine/vendors/toolkit'
import mqInit from '@/engine/vendors/mq'
import structuredClonePolyfillInit from '@/engine/vendors/structuredClonePolyfill'

import type { App } from 'vue'

export default function initVendors (app: App) {
  toolkitInit(app)
  mqInit(app)
  structuredClonePolyfillInit()
}
