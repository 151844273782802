<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.settings') }}
    </template>
  </shared-header>

  <wb-tabs
    v-model="data.tabSelected"
    :tabs="compute.sections"
    @tab-change="(option: Tab) => router.push({ name: option.key })"
  />
  <router-view
    v-slot="{ Component }"
    can-change-group
    :user="state.user.userLogged"
    v-bind="$attrs"
  >
    <container-component>
      <component :is="Component" />
    </container-component>
  </router-view>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'

import { permissions } from '@/engine/clients'
import state from '@/state'
import { computed, reactive, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import type { ExcludesNullish, Tab } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const compute = reactive({
  sections: computed(() => {
    return [
      { key: 'account', value: i18n.t('mywb.common.account'), dataTestId: 'personalDetail' },
      permissions.showBilling &&
        { key: 'payment-history', value: i18n.t('mywb.common.payment-history'), dataTestId: 'paymentHistory' }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

interface Data {
  tabSelected?: Tab
}
const data = reactive<Data>({
  tabSelected: undefined
})

watchEffect(() => {
  data.tabSelected = compute.sections.find(tab => tab.key === route.name?.toString())
})
</script>
