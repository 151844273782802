<template>
  <div>
    <charger-settings-free-charge />

    <charger-settings-assign-rate
      v-if="permissions.showPayments"
      id="payPerCharge"
      class="my-8"
      :class="{ 'selected-blink': props.selected === 'payPerCharge'}"
      :charger="state.charger.getCurrentCharger.chargerData"
      :has-autolock="state.charger.getCurrentCharger.config.auto_lock"
      :schedule="data.schedules.find(schedule => schedule.paymentType === 'pay_per_charge')"
      :rates="data.rates"
      :billing-country-iso2="data.billingCountryIso2"
      type="payPerCharge"
      data-test-id="payPerChargeSection"
      :compatible="compute.enablePayPerCharge"
      allow-hourly-selection
      @schedule-updated="methods.updateSchedule"
    >
      <charger-pay-per-charge-resources />
    </charger-settings-assign-rate>

    <charger-settings-assign-rate
      v-if="permissions.showPayments"
      id="payPerMonth"
      :charger="state.charger.getCurrentCharger.chargerData"
      :class="{ 'selected-blink': props.selected === 'payPerMonth'}"
      :has-autolock="state.charger.getCurrentCharger.config.auto_lock"
      :rates="data.rates"
      :schedule="data.schedules.find(schedule => schedule.paymentType === 'pay_per_month')"
      :billing-country-iso2="data.billingCountryIso2"
      :compatible="compute.enablePayPerMonth"
      allow-hourly-selection
      type="payPerMonth"
      data-test-id="payPerMonthSection"
    />
  </div>
</template>

<script setup lang="ts">
import ChargerSettingsAssignRate from '@/components/charger/ChargerSettingsAssignRate.vue'
import ChargerSettingsFreeCharge from '@/components/charger/ChargerSettingsFreeCharge.vue'
import ChargerPayPerChargeResources from '@/components/charger/ChargerPayPerChargeResources.vue'

import api from '@/api'
import state from '@/state'
import { reactive, computed, watch } from 'vue'
import { permissions } from '@/engine/clients'
import { isPaymentsCompatible } from '@/utilities/charger/chargerCompatible'
import type { Locations, Rate } from '@/types'
import type { CountryCodes } from '@/types/data/currency.data.types'

interface PropsType {
  loading?: boolean
  selected: string
  location?: Locations.Location
}

const props = defineProps<PropsType>()

interface Data {
  rates: Rate.Rate[]
  schedules: Rate.ScheduleCharger[]
  loading: boolean
  billingCountryIso2?: CountryCodes
}

const data: Data = reactive({
  rates: [],
  schedules: [],
  loading: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.payments_account),

  enablePayPerCharge: computed(() => {
    return (
      isPaymentsCompatible(state.charger.getCurrentCharger.chargerData, 'pay_per_charge', props.location)
    )
  }),

  enablePayPerMonth: computed(() => {
    return (
      isPaymentsCompatible(state.charger.getCurrentCharger.chargerData, 'pay_per_month', props.location)
    )
  })
})

watch(() => props.loading, async () => {
  if (props.loading) return

  if (
    permissions.showPayments &&
    compute.hasOrganizationPaymentsAccount &&
    (compute.enablePayPerCharge.isCompatible || compute.enablePayPerMonth.isCompatible)
  ) {
    const { data: rates } = await api.rates.getRates({
      groupId: state.organizations.getCurrentOrganization.group_id
    })

    data.schedules =
      (await api.rates.getChargerRatesWeekSchedules(state.charger.getCurrentCharger.chargerData.uid ?? '')).data

    data.rates = rates

    const { result: billingInfo } =
      await api.billing.getBillingInfo(state.organizations.getCurrentOrganization.group_id)

    data.billingCountryIso2 = billingInfo.country.iso2
  }
}, { immediate: true })

const methods = {
  updateSchedule (scheduleSaved: Rate.ScheduleCharger) {
    const index = data.schedules.findIndex(schedule => schedule.paymentType === scheduleSaved.paymentType)

    if (data.schedules[index]) {
      data.schedules[index] = scheduleSaved
    }
  }
}
</script>

<style lang="postcss" scoped>
.selected-blink {
  animation: blink 900ms linear;
  border-radius: 0.8rem;
}

@keyframes blink {
  0% { box-shadow: none; }
  50% { box-shadow: 0 0 10px var(--primary-500); }
  100% { box-shadow: none; }
}
</style>
