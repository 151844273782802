import i18n from '@/engine/lang'
import { EnumRoles } from '@/utilities/user-roles'
import supportInfoJson from '@/utilities/support/supportInfoIberdrola'

import { hasRole } from '../validators'
import { numbers } from '@wallbox/toolkit-ui'
import type { Client } from './client'

const iberdrola: Client = {
  name: 'iberdrola',
  partner: 'iberdrola',
  brandingId: 'iberdrola',
  actions: {
    showCommanderPin: true,
    showRfid: true,
    editRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showSelectedChargers: false,
    canChangePlan: false,
    showPayments: false,
    showPaymentsAsideMenu: false,
    showBilling: false,
    showAutoReporting: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canEditChargerName: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasBulkActions: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasAuthToRestartAndUpdate: hasRole(EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator),
    canMarkAsPaid: false,
    canAssignedRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canSeeRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCharge: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canChargerItemClickeable: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddChargerFromEmptyState: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddCharger: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canWriteEnergyCost: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeLocations: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canViewUpdateChargersPending: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCreateUserGroups: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    isDisabledSelectAccessGroup: hasRole(EnumRoles.user, EnumRoles.admin),
    showUserGroupOptions: true,
    showDashboardAsideMenu: true,
    showUserAsideMenu: true,
    canPaymentsAsideMenu: false,
    canAddUser: true,
    canEditAndDeleteUserGroupOption: true,
    canAddRfidInInvitations: true,
    canUserListRfidEdit: true,
    canUserListRfidActions: true,
    canRevokeOrResendUserInvitation: hasRole(EnumRoles.operator, EnumRoles['super-admin']),
    hasSocialSingin: false,
    showPaymentsEngage: false,
    showUpSellingToPlanBusiness: false,
    canConnectStripe: false,
    showPublishOnElectromaps: false,
    canOperateOrganizations: hasRole(EnumRoles.operator),
    showCarouselLogin: false,
    canSeeOcppChargers: false,
    canAddOperators: false,
    canSeeModalEditOrganization: false
  },

  academy: 'https://support.wallbox.com/',

  energyManagmentSupport: 'https://support.wallbox.com/en/knowledge-base/energy-management-solutions/',

  ecommerceUrl: '',

  docs: {
    terms: 'https://www.iberdrola.es/webclipb/gc/prod/es_ES/Wallbox.pdf'
  },

  logo: {
    login: {
      src: 'logos/logo-iberdrola.svg',
      style: 'width: 75%; max-width: 200px;',
      alt: 'myWallbox'
    },
    aside: { src: 'logos/logo-iberdrola-inverted.svg', style: 'height: 12rem;', alt: 'Wallbox' },
    asideCollapse: { src: 'logos/logo-iberdrola-inverted-collapse.svg', alt: 'Wallbox' },
    header: { src: 'logos/logo-wallbox-iberdrola.svg', style: 'height: 60px;', alt: 'iberdrola-logo' }
  },

  units: {
    volume: {
      unit: 'L',
      name: 'liters',
      formula: (liters: number) => `${numbers.toLocaleNumber(liters, i18n.global.locale, 2, 2)} L`
    },
    mass: {
      unit: 'Kg',
      name: 'Kilograms',
      formula: (kilogram: number) => `${numbers.toLocaleNumber(kilogram, i18n.global.locale, 2, 2)} Kg`
    }
  },

  supportInfo: supportInfoJson
}

export default iberdrola
