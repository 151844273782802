<template>
  <div class="wrapper-grid g-32">
    <vertical-menu-component
      v-model="data.selected"
      :menu="compute.menu"
      is-sticky
    />

    <section class="content-grid g-24">
      <subscription-plan-account
        v-if="permissions.canChangePlan"
        id="plan"
        data-test-id="subscriptionPlanSection"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'plan'}"
      />

      <personal-data-account
        id="personal"
        :class="{ 'selected-blink': data.selected === 'personal'}"
        class="tablet-span-2"
      />

      <billing-information
        v-if="permissions.showBilling"
        id="billing"
        v-model:has-billing-info="data.hasBillingInfo"
        data-test-id="billingInformationSection"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'billing'}"
        :group-id="state.organizations.getCurrentOrganization.group_id"
      />

      <wb-card
        id="charger"
        data-test-id="chargerAccessSection"
        :headline="i18n.t('mywb.common.charger-access')"
        :class="{ 'selected-blink': data.selected === 'charger'}"
        class="tablet-span-2"
      >
        <template #content>
          <avatar-uploader-account
            data-test-id="faceId"
          />

          <commander-pin-account
            v-if="permissions.showCommanderPin"
            data-test-id="commanderPin"
            class="mt-16"
          />
          <rfid-group-account
            v-if="permissions.showRfid"
            data-test-id="rfid"
            class="mt-16"
          />
        </template>
      </wb-card>

      <password-account
        id="security"
        :class="{ 'selected-blink': data.selected === 'security'}"
        class="tablet-span-2"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import SubscriptionPlanAccount from '@/components/account/SubscriptionPlanAccount.vue'
import PersonalDataAccount from '@/components/account/PersonalDataAccount.vue'
import CommanderPinAccount from '@/components/account/CommanderPinAccount.vue'
import RfidGroupAccount from '@/components/account/RfidGroupAccount.vue'
import PasswordAccount from '@/components/account/PasswordAccount.vue'
import AvatarUploaderAccount from '@/components/account/AvatarUploaderAccount.vue'
import BillingInformation from '@/components/payments/BillingInformation.vue'
import VerticalMenuComponent from '@/components/VerticalMenuComponent.vue'

import { permissions } from '@/engine/clients'
import { computed, reactive, onMounted, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRoute } from 'vue-router'
import state from '@/state'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const route = useRoute()

interface Data {
  selected?: string,
  hasBillingInfo: boolean
}
const data = reactive<Data>({
  selected: undefined,
  hasBillingInfo: false
})

const compute = reactive({
  menu: computed(() => {
    return [
      permissions.canChangePlan && {
        key: 'plan',
        icon: 'assignment',
        text: i18n.t('mywb.common.plan')
      },
      {
        key: 'personal',
        icon: 'account_circle',
        text: i18n.t('mywb.user.personal-data')
      },
      permissions.showBilling && {
        key: 'billing',
        icon: 'business',
        text: i18n.t('mywb.common.billing-information')
      },
      {
        key: 'charger',
        icon: 'key',
        text: i18n.t('mywb.common.charger-access')
      },
      {
        key: 'security',
        icon: 'lock',
        text: i18n.t('mywb.common.security')
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
})

onMounted(() => {
  data.selected = route.hash?.replace('#', '')
})
</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.selected-blink {
  animation: blink 900ms linear;
  border-radius: 0.8rem;
}

@keyframes blink {
  0% { box-shadow: none; }
  50% { box-shadow: 0 0 10px var(--primary-500); }
  100% { box-shadow: none; }
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 2fr 1fr;
  }
}

.tablet-span-2 {
  @media (--tablet) {
    grid-column: span 2;
  }
}
</style>
