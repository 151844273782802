<template>
  <div>
    <p class="is-size-600 is-font-weight-400">
      {{ i18n.t('mywb.common.estimation-revenue') }}
    </p>
    <p class="is-size-300 has-text-grey-500">
      {{ i18n.t('mywb.common.full-charge-session') }}
    </p>
    <p class="is-size-1000 is-font-weight-600" data-test-id="estimationRevenue">
      {{ methods.currencyValue(+compute.estimationRevenue) }}
    </p>

    <table
      v-if="+compute.estimationRevenue > 0"
      class="content-table mt-8"
      :summary="i18n.t('mywb.common.estimation-revenue')"
    >
      <thead>
        <tr>
          <th>{{ i18n.t('mywb.common.description') }}</th>
          <th>{{ i18n.t('mywb.common.amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ compute.variableFeeDescription }}</td>
          <td data-test-id="variableFee">
            {{ methods.currencyValue(compute.variableFee) }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="no-padding">
            <hr>
          </td>
        </tr>
        <tr>
          <td>{{ i18n.t('mywb.common.fixed-fee') }}</td>
          <td data-test-id="fixedFee">
            {{ methods.currencyValue(compute.fixedFee) }}
          </td>
        </tr>
        <br>
        <tr>
          <td>
            <wb-popover>
              <template #activator>
                <span class="mr-8">
                  {{ i18n.t('mywb.common.total-user-pays') }}
                </span>
                <span class="wb-icons is-size-300">info_filled</span>
              </template>
              <template #tooltip>
                {{ compute.estimationDescription }}
              </template>
            </wb-popover>
          </td>
          <td data-test-id="totalUserPays">
            {{ methods.currencyValue(compute.totalUserPays) }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="no-padding">
            <hr>
          </td>
        </tr>
        <tr v-if="compute.toCalculateRevenue" class="light">
          <td>{{ i18n.t('mywb.common.wallbox-fee') }} ({{ compute.percentageFee }})</td>
          <td data-test-id="wallboxFee">
            - {{ methods.currencyValue(+compute.wallboxFee) }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="no-padding">
            <hr>
          </td>
        </tr>
        <tr>
          <td>{{ i18n.t('mywb.session.total-you-recibe') }}</td>
          <td>{{ methods.currencyValue(+compute.estimationRevenue) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { getCurrencyCode } from '@/utilities/currency'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'

const i18n = useI18n()

interface Props {
  rate: {
    fixedFee: {
      value?: number,
      errors: string[]
    },

    variableType: {
      value: string,
      errors: string[]
    },

    variablePrice: {
      value?: number,
      errors: string[]
    }
  }
  isVariableFeeActivated: boolean,
  isFixedFeeActivated: boolean
}
const props = defineProps<Props>()

const data = reactive({
  avgTimeHours: 1,
  avgEnergyKwh: 7,
  fixedFee: 0,
  variableFee: 0,
  percentageFee: 5
})

const compute = reactive({
  currencyCode: computed(() => getCurrencyCode()),

  variableFeeDescription: computed((): string => {
    if (props.rate.variableType?.value === 'time') {
      return i18n.t('mywb.common.cost-per-hour')
    }

    if (props.rate.variableType?.value === 'energy') {
      return i18n.t('mywb.common.cost-per-kwh')
    }
    return i18n.t('mywb.common.variable-fee')
  }),

  estimationDescription: computed(():string => {
    if (props.rate.variableType?.value === 'time') {
      return i18n.t('mywb.common.aprox-estimations-time', [data.avgTimeHours])
    }

    if (props.rate.variableType?.value === 'energy') {
      return i18n.t('mywb.common.aprox-estimations-energy', [data.avgEnergyKwh, data.avgTimeHours])
    }
    return i18n.t('mywb.common.full-charge-session')
  }),

  fixedFee: computed(():number => {
    return props.isFixedFeeActivated && !props.rate?.fixedFee.errors[0]
      ? props.rate?.fixedFee?.value || 0
      : 0
  }),

  variableFee: computed(():number => {
    return props.isVariableFeeActivated && !props.rate?.variablePrice.errors[0]
      ? props.rate?.variablePrice?.value || 0
      : 0
  }),

  toCalculateRevenue: computed(():boolean => !!(compute.variableFee || compute.fixedFee)),

  wallboxFee: computed(():string => ((data.percentageFee * compute.totalUserPays) / 100).toFixed(2) || '0'),

  percentageFee: computed(() => numbers.toLocalePercent(data.percentageFee, i18n.locale.value)),

  totalUserPays: computed(():number => {
    if (props.rate.variableType?.value === 'time') {
      return data.avgTimeHours * compute.variableFee + compute.fixedFee
    }
    if (props.rate.variableType?.value === 'energy') {
      return data.avgEnergyKwh * compute.variableFee + compute.fixedFee
    }
    return compute.fixedFee
  }),

  estimationRevenue: computed(():string => {
    return compute.toCalculateRevenue
      ? (compute.totalUserPays - +compute.wallboxFee).toFixed(2)
      : '0.00'
  })
})

const methods = {
  currencyValue (amount: number) {
    return numbers.toLocaleCurrencySymbol(
      amount,
      compute.currencyCode,
      i18n.locale.value
    )
  }
}
</script>

<style lang="postcss" scoped>
table {
  border-collapse: separate;
  border-spacing: 1rem 0;
  padding: 1rem 0;
}

td,
th {
  padding: 8px;
}

.content-table {
  background: var(--grey-100);
  border-radius: 0.6rem;

  & hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--grey-200);
    margin: 0;
    padding: 0;
  }

  & thead {
    font-weight: 500;
    text-align: left;
    color: var(--grey-400);
    font-size: var(--size-200);
  }

  & tbody {
    font-size: var(--size-300);

    & .light {
      color: var(--grey-400);
    }
  }

  & .no-padding {
    padding: 0;
  }
}
</style>
