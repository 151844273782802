<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.auth.register') }}
      </p>
    </template>

    <wb-form>
      <wb-input
        ref="input"
        v-model="user.name"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
        data-test-id="nameInput"
        name="name"
        type="text"
        v-on="validationListenersName"
      />

      <wb-input
        v-model="user.email"
        :label="i18n.t('mywb.common.email')"
        :error="errors.email"
        data-test-id="emailInput"
        name="email"
        type="text"
        v-on="validationListenersEmail"
      />

      <wb-input
        v-model="user.password"
        name="password"
        type="password"
        data-test-id="passwordInput"
        :label="i18n.t('mywb.common.password')"
        :error="errors.password"
        v-on="validationListenersPassword"
        @on-enter.prevent="methods.continueRegister"
      />
      <auth-password-hint :value="user.password" />
      <wb-button
        :label="i18n.t('mywb.common.continue')"
        size="large"
        data-test-id="firstStepBtn"
        @click="methods.continueRegister"
      />

      <wb-link
        class="is-size-400 has-text-centered has-margin-auto"
        type="text"
        :to="{ name: 'login' }"
      >
        <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
        <span>{{ i18n.t('mywb.common.back') }}</span>
      </wb-link>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import { useForm } from 'vee-validate'
import { ref, onMounted, reactive, watch } from 'vue'
import { useField, useWbInputAutofocus } from '@/hooks'
import { trackScreen, trackAction } from '@/engine/metrics/metricsManager'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import type { User } from '@/types'

const route = useRoute()
const i18n = useI18n()

interface Events {
  (e: 'continue-register', partialUser: User.UserInRegister): void
}

const emit = defineEmits<Events>()

const props = defineProps({
  emailError: {
    type: Boolean,
    default: false
  }
})

const input = ref()
useWbInputAutofocus(input)

const { errors, handleSubmit, setFieldError } = useForm({
  validationSchema: {
    name: 'required',
    email: 'required|email',
    password: 'required|verifyPassword'
  },
  initialValues: {
    name: '',
    email: route.query.email ?? '',
    password: ''
  }
})

const { value: name, validationListeners: validationListenersName } = useField<string>('name')
const { value: email, validationListeners: validationListenersEmail } = useField<string>('email')
const { value: password, validationListeners: validationListenersPassword } = useField<string>('password')

const user = reactive({
  name,
  email,
  password
})

onMounted(() => {
  trackScreen('register')
})

const methods = {
  continueRegister: handleSubmit(async () => {
    trackAction('register-continue')
    trackScreen('terms')

    emit('continue-register', {
      name: user.name,
      email: user.email,
      password: user.password
    })
  })
}

watch(() => props.emailError, value => {
  value && setFieldError('email', i18n.t('mywb.error.user-already-registered'))
}, { immediate: true })

</script>
