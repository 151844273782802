<template>
  <auth-layout>
    <p
      v-t="'mywb.auth.register-one-step-more'"
      class="finish-title"
    />
    <p
      v-t="'mywb.auth.register-verify-email'"
      class="finish-title my-32"
    />

    <wb-link
      class="is-size-500 link"
      type="text"
      data-test-id="loginBtn"
      @click="router.push({ name: 'login' })"
    >
      <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
      <span>{{ i18n.t('mywb.auth.login') }}</span>
    </wb-link>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { trackScreen } from '@/engine/metrics/metricsManager'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
const router = useRouter()

onMounted(() => {
  trackScreen('terms')
})
</script>

<style lang="postcss" scoped>
.finish-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}

.link {
  display: block;
  text-align: center;
}
</style>
