<template>
  <wb-card
    :headline="i18n.t('mywb.common.location')"
  >
    <template #content>
      <wb-card
        filled
        :loading="props.loading"
        alignment="center"
      >
        <template #content>
          <div v-if="permissions.canSeeLocations && !props.loading">
            <wb-link
              class="is-size-400 has-text-black is-font-weight-500"
              type="text"
              :to="{ name: 'location-detail', params: {
                groupId: state.charger.getCurrentCharger.chargerData.group.toString()
              }}"
              data-test-id="locationNameLink"
            >
              {{ props.location?.name }}
            </wb-link>
            <p class="is-size-300 has-text-black">
              {{ props.location?.address }}
            </p>
            <div
              v-if="compute.locationTypes"
              class="mt-8 is-fullwidth is-font-weight-500 has-text-black is-flex flex-wrap"
            >
              <div v-for="locationType in compute.locationTypes" :key="locationType">
                <wb-label
                  is-invert
                  color="info"
                  class="mr-8 remove-cursor-pointer"
                >
                  {{ locationType }}
                </wb-label>
              </div>
            </div>
            <location-change-location-modal
              v-if="data.showModal"
              data-test-id="changeLocationModal"
              :group-uid="props.location?.group_uid"
              :charger="state.charger.getCurrentCharger.chargerData"
              @close="data.showModal = false"
            />
          </div>
          <div v-else>
            <p
              data-test-id="upsellingText"
              class="is-size-300 is-font-weight-400 has-text-grey-500"
            >
              {{ i18n.t('mywb.common.locations-empty-state') }}
            </p>
          </div>
        </template>

        <template #actions>
          <wb-button
            v-if="permissions.canSeeLocations"
            icon="change_location"
            :label="i18n.t('mywb.common.change-location')"
            type="white"
            outlined
            :disabled="props.loading"
            data-test-id="changeLocationButton"
            @click="data.showModal = true"
          />
          <wb-button
            v-if="permissions.canChangePlan && !permissions.canSeeLocations"
            icon="lock_open"
            data-test-id="upgradePlanBtn"
            :label="i18n.t('mywb.plan.upgrade')"
            type="white"
            outlined
            :to="{ name: 'plans' }"
            @click="methods.goToPlans"
          />
        </template>
      </wb-card>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { computed, reactive } from 'vue'
import { locationTypesArray } from '@/utilities/locationTypesEnum'
import LocationChangeLocationModal from '@/components/modals/LocationChangeLocationModal.vue'
import { permissions } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import type { Locations } from '@/types'

const i18n = useI18n()

const data = reactive({
  loading: false,
  showModal: false
})

interface Props {
  loading?: boolean,
  location?: Locations.Location
}
const props = defineProps<Props>()

const compute = reactive({
  locationTypes: computed((): Array<string | undefined> => locationTypesArray
    .filter(location => compute.location_types.includes(location.key))
    .map(location => location ? location.value : undefined)),
  location_types: computed((): string[] =>
    props.location?.location_types ? props.location?.location_types.split(',') : [])
})

const methods = {
  goToPlans () {
    trackDataAction('upgrade_plan', {
      source: 'locations'
    })
  }
}

</script>

<style lang="postcss" scoped>
:deep(.remove-cursor-pointer) {
  cursor: default !important;
}

.flex-wrap {
  flex-wrap: wrap;
}
</style>
