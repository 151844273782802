<template>
  <shared-header data-test-id="paymentsSection">
    <template #title>
      {{ i18n.t('mywb.common.payments') }}
    </template>
  </shared-header>

  <wb-tabs
    v-model="data.tabSelected"
    class="tabs"
    :tabs="compute.tabs"
    @tab-change="(option: TabsProps['tabs'][number]) => router.push({ name: option.key })"
  />
  <router-view v-slot="{ Component }">
    <container-component>
      <component :is="Component" />
    </container-component>
  </router-view>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import { computed, reactive, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TabsProps } from '@wallbox/toolkit-ui'

const router = useRouter()
const route = useRoute()
const i18n = useI18n()

interface Data {
  tabSelected?: TabsProps['tabs'][number]
}
const data = reactive<Data>({
  tabSelected: undefined
})

const compute = reactive({
  tabs: computed(() => {
    return [
      { key: 'payments-invoices', value: i18n.t('mywb.common.invoices'), dataTestId: 'invoicesTab' },
      { key: 'payments-rates', value: i18n.t('mywb.common.rates'), dataTestId: 'ratesTab' }
    ]
  })
})

watchEffect(() => {
  data.tabSelected = compute.tabs.find(tab => tab.key === route.name?.toString())
})
</script>

<style lang="postcss" scoped>
ul.tabs {
  margin-bottom: 0;
}
</style>
