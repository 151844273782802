import { computed, reactive } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import type { User } from '@/types'

interface UserState {
  userLogged: User.UserLogged & User.UserDetail
  usersGroup: User.UsersGroup[]
}

const initialState = (): UserState => ({
  userLogged: {
    id: 0,
    email: '',
    name: '',
    surname: '',
    avatar: '',
    access: [],
    accessConfigs: [],
    address: '',
    city: '',
    corp_id: -1,
    country: '',
    currency_code: '',
    groups: [],
    language: null,
    payments_account: false,
    postal_code: '',
    profile_id: 1,
    register_date: 0,
    reporting: false,
    rfid: '',
    subscription: 0,
    system_format: '',
    time_zone: '',
    phone_code: '',
    phone: '',
    pin: '',
    country_code: '',
    country_id: 0,
    plan: {
      plan_name: 'basic'
    }
  },
  usersGroup: []
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<UserState>) {
    return objects.getProperty(state, path)
  },

  userLogged: computed(() => state.userLogged),

  getCurrentUserGroupsMap: computed((): Record<number, User.UsersGroup> => {
    return state.usersGroup.reduce((groupsMap, group) => ({ ...groupsMap, [group.id]: group }), {})
  }),

  getGroupsByAccessConfig: computed((): User.UsersGroupWithUserExpanded[] => {
    return state.usersGroup.map(group => ({
      ...group,

      users: group.users.filter((user) =>
        !group.accessConfigs
          .some((accessConfig) => accessConfig.id === user.accessConfig)),

      accessConfigs: (group.accessConfigs || [])
        .map((accessConfig) => ({
          ...accessConfig,
          users: group.users
            .filter((user) => user.accessConfig && user.accessConfig === accessConfig.id)
        })
        )
    })
    )
  }),

  getDefaultOrganization: computed(() => {
    return `${state.userLogged.id}-${state.userLogged.corp_id}`
  })
}

const setters = {
  set (path: Paths<UserState>, value: unknown) {
    objects.setProperty(state, path, value)
  },

  setUserLogged (user: User.UserDetail | User.User) {
    state.userLogged = { ...state.userLogged, ...user }
  },

  setInitialState () {
    state = reactive(initialState())
  }
}

export default reactive({
  ...getters,
  ...setters
})
