<template>
  <wb-date-picker
    v-model="compute.filterDates"
    data-test-id="calendar"
    :label="i18n.t('mywb.common.dates')"
    :min-date="data.minDate"
    :max-date="data.today"
    :year-range="[2015, data.today.getFullYear()]"
    :multi-calendars="mq.current !== 'mobile'"
    range
    :text-input="true"
    :locale="lang.__rootLanguage"
    :clearable="false"
    :auto-apply="false"
    :cancel-text="i18n.t('mywb.common.cancel')"
    :apply-text="i18n.t('mywb.common.apply')"
  >
    <template #input="{ value }">
      <shared-filter
        :model-value="value ? { label: value, id: 1 } : undefined"
        icon="calendar"
        :name="i18n.t('mywb.common.dates')"
        read-only
        :options="[{ label: value, id: 1 }]"
        @update:model-value="methods.resetDates"
        @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'dates' })"
      />
    </template>
  </wb-date-picker>

  <shared-filter
    v-model="compute.filterUser"
    with-search
    icon="person"
    :name="i18n.t('mywb.common.user')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.users') })"
    :empty-text="i18n.t('mywb.users.empty')"
    option-key="value"
    :options="compute.userOptions"
    :reduce="(item:filterType) => item.value"
    data-test-id="usersFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'user' })"
  />

  <shared-filter
    v-model="compute.filterCharger"
    with-search
    icon="charger"
    :name="i18n.t('mywb.common.charger')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
    :empty-text="i18n.t('mywb.chargers.empty')"
    option-key="value"
    :options="compute.chargerOptions"
    :reduce="(item:filterType) => item.value"
    data-test-id="chargerFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'charger' })"
  />

  <shared-filter
    v-model="compute.filterLocation"
    with-search
    icon="location"
    :name="i18n.t('mywb.common.location')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    option-key="value"
    :options="compute.locationOptions"
    :reduce="(item:filterType) => item.value"
    data-test-id="locationFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'location' })"
  />

  <shared-filter
    v-model="compute.filterType"
    icon="sessions"
    :name="i18n.t('mywb.common.type')"
    option-key="value"
    :options="compute.typeOptions"
    :reduce="(item:filterType) => item.value"
    data-test-id="paymentsFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'type' })"
  />

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'sessionsFilters' })"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import api from '@/api'
import lang from '@/engine/lang'
import { dates } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { useMq } from 'vue3-mq'
import SharedFilter from '@/components/filters/SharedFilter.vue'

export type filterType = {
  label: string,
  value: number
}

type DataType = {
  option: {
    subgroups: Record<number, string>,
    chargers: Record<number, string>,
    users: Record<number, string>,
  },
  minDate: Date
  today: Date
}

const i18n = useI18n()
const mq = useMq()

const data: DataType = reactive({
  option: {
    subgroups: [],
    chargers: [],
    users: []
  },
  minDate: new Date(2015, 0, 1),
  today: new Date()
})

const compute = reactive({
  typeOptions: computed(() => [
    {
      label: i18n.t('mywb.common.free'),
      value: 'free'
    },
    {
      label: i18n.t('mywb.charger.pay-per-month'),
      value: 'pay_per_month'
    },
    {
      label: i18n.t('mywb.charger.pay-per-charge'),
      value: 'pay_per_charge'
    }
  ]),

  locationOptions: computed(() => methods.getOptions(data.option.subgroups)),

  chargerOptions: computed(() => methods.getOptions(data.option.chargers)),

  userOptions: computed(() => methods.getOptions(data.option.users, 'users')),

  firstDatOfWeek: computed(() => dates.getFirstDayOfTheWeek(lang.__rootLanguage)),

  filterDates: computed({
    get () {
      return state.filters.sessionsFilters?.dates
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'dates', value })
    }
  }),

  filterUser: computed({
    get () {
      return state.filters.sessionsFilters?.user
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'user', value })
    }
  }),

  filterCharger: computed({
    get () {
      return state.filters.sessionsFilters?.charger
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'charger', value })
    }
  }),

  filterLocation: computed({
    get () {
      return state.filters.sessionsFilters?.location
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'location', value })
    }
  }),

  filterType: computed({
    get () {
      return state.filters.sessionsFilters?.type
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'type', value })
    }
  })
})

const methods = {
  async getSessionFilters () {
    data.option = await api.sessions
      .getSessionFilters({ group_id: state.organizations.getCurrentOrganization.group_id })
  },

  getOptions (options: Record<number, string>, type?: string) {
    const result = []
    if (type === 'users') result.push({ label: i18n.t('mywb.common.anonymous'), value: 1 })

    for (const item in options) {
      result.push({ label: options[item] ? options[item] : 'SN' + item, value: +item })
    }

    return result
  },

  resetDates () {
    state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'dates', value: [] })
  }
}

function created () {
  methods.getSessionFilters()
}

created()
</script>
