<template>
  <wb-card
    :headline="i18n.t('mywb.common.free-charging')"
    :subhead="i18n.t('mywb.common.free-charging.description')"
  >
    <template #place-upper-right>
      <wb-switch
        model-value
        disabled
        data-test-id="switch"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
</script>
