<template>
  <wb-table
    :options="compute.options"
    :loading="props.loading"
    data-test-id="chargerSessionsTable"
    @on-pagination-change="emit('update:offset', $event)"
  >
    <sessions-table-row
      v-for="session in props.sessions"
      :key="session.id"
      :show-charger-column="props.showChargerColumn"
      :session="session"
    />
  </wb-table>
</template>

<script setup lang="ts">
import SessionsTableRow from '@/components/tables/SessionsTableRow.vue'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Pagination, Session } from '@/types'
import type { ExcludesNullish, TableProps } from '@wallbox/toolkit-ui'

const i18n = useI18n()

interface PropsType {
  offset?: number,
  sessions?: Session.Session[],
  pagination?: Pagination.Pagination,
  loading?: boolean,
  showChargerColumn?: boolean,
  stickyHeaderPosition?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  offset: 0,
  sessions: undefined,
  pagination: undefined,
  stickyHeaderPosition: 'var(--header-height)'
})

type Events = {
  (e: 'update:offset', offset: number): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  options: computed(() => {
    return {
      offset: '0 16px',
      columns: ([
        { name: i18n.t('mywb.common.id'), align: 'left', minWidth: '110px', width: '110px' },
        {
          name: i18n.t('mywb.common.date'),
          align: 'left',
          minWidth: '150px',
          width: '150px',
          lineBehaviour: 'multiline'
        },
        {
          name: i18n.t('mywb.common.user'),
          align: 'left',
          minWidth: '200px',
          width: 'auto'
        },
        props.showChargerColumn &&
          {
            name: i18n.t('mywb.common.charger'),
            align: 'left',
            minWidth: '150px',
            width: 'auto',
            lineBehaviour: 'multiline'
          },
        {
          name: i18n.t('mywb.common.energy'),
          align: 'left',
          minWidth: '150px',
          width: '150px',
          lineBehaviour: 'multiline'
        },
        {
          name: i18n.t('mywb.common.type'),
          align: 'left',
          minWidth: '175px',
          width: '175px',
          lineBehaviour: 'multiline'
        },
        {
          name: i18n.t('mywb.common.total-cost'),
          align: 'left',
          minWidth: '100px',
          width: '100px',
          lineBehaviour: 'multiline'
        },
        permissions.showPayments &&
          { name: i18n.t('mywb.common.payment'), align: 'left', minWidth: '100px', width: '100px' }
      ] as TableProps['options']['columns']).filter(Boolean as unknown as ExcludesNullish),
      stickyHead: props.stickyHeaderPosition,
      resizable: true,
      pagination: props.pagination,
      empty: {
        icon: 'table_rows',
        title: i18n.t('mywb.common.table-empty')
      }
    }
  })
})
</script>
