import ENV from '@/engine/env/web.env'
import axios from 'axios'
import { setFeatureFlagsByUserLogged } from './featureFlags.state'

export async function getFeatureFlags () {
  if (!ENV.featureFlags.baseURL) return

  try {
    const { data } = await axios.get(ENV.featureFlags.baseURL)

    setFeatureFlagsByUserLogged(data)
  } catch {}
}
