<template>
  <wb-card
    :headline="i18n.t('mywb.charger.commander-pin')"
    :subhead="i18n.t('mywb.account.commander-pin-description')"
    alignment="center"
    filled
  >
    <template v-if="field.pin" #content>
      <div class="is-flex">
        <wb-button
          type="white"
          outlined
          size="small"
          :icon="data.hide ? 'visibility' : 'visibility_off'"
          @click="data.hide = !data.hide"
        />
        <div class="is-size-500 mx-8">
          <div v-if="data.hide">
            * * * *
          </div>
          <div
            v-else
            class="has-letter-spacing"
          >
            {{ field.pin }}
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="data.editPinModal = true"
      />
    </template>

    <template #modal>
      <wb-modal
        v-if="data.editPinModal"
        width="36"
        @close="data.editPinModal = false"
      >
        <template #title>
          {{ i18n.t('mywb.charger.commander-pin') }}
        </template>
        <wb-input
          v-model="field.pin"
          data-test-id="commanderPinInput"
          name="pin"
          type="password"
          maxlength="4"
          :error="errors.pin"
        />

        <template #actions>
          <div class="button-actions">
            <wb-button
              data-test-id="cancelButton"
              type="white"
              outlined
              :label="i18n.t('mywb.common.cancel')"
              size="block"
              @click="data.editPinModal = false"
            />
            <wb-button
              data-test-id="saveBtn"
              type="primary"
              :label="i18n.t('mywb.common.save')"
              :loading="data.loading"
              size="block"
              @click="validate(methods.updateCommanderPin)"
            />
          </div>
        </template>
      </wb-modal>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import state from '@/state'
import api from '@/api'
import { reactive } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'

const i18n = useI18n()
const notify = useNotify()
const { yup, errors, defineSchema, validate } = useValidator()

interface DataType {
  isReadMode: boolean
  loading: boolean
  hide: boolean
  editPinModal: boolean
}

const data: DataType = reactive({
  isReadMode: true,
  loading: false,
  hide: true,
  editPinModal: false
})

const field = reactive({
  pin: state.user.userLogged.pin
})

defineSchema(field, {
  pin: yup
    .number()
    .min(1, i18n.t('mywb.error.invalid-charger-pin'))
    .required()
    .typeError(i18n.t('mywb.error.invalid-charger-pin'))
})

const methods = {
  async updateCommanderPin () {
    const userId = state.user.userLogged.id

    try {
      data.loading = true
      await api.users.updateUser({ params: { pin: field.pin }, userId })
      const userDetail = await api.users.getUserDetail(userId)
      state.user.setUserLogged(userDetail)
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
      data.isReadMode = true
      data.editPinModal = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.has-letter-spacing {
  letter-spacing: 4px;
}
</style>
