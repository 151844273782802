<template>
  <container-component>
    <div>
      <sessions-header
        @show-autoreporting="data.showReportingModal = true"
        @click-download-report="data.showDownloadReportingModal = true"
      />

      <sessions-table
        v-model:offset="data.offset"
        :sessions="data.sessions"
        :loading="data.loading"
        :pagination="data.pagination"
        show-charger-column
        sticky-header-position="var(--header-filters-height)"
        @update:offset="methods.getSessions"
      />

      <sessions-download-report-modal
        v-if="data.showDownloadReportingModal"
        :sessions-count="data.pagination.total"
        @close="data.showDownloadReportingModal = false"
      />

      <sessions-reporting-modal
        v-if="data.showReportingModal"
        @close="data.showReportingModal = false"
      />
    </div>
  </container-component>
</template>

<script setup lang="ts">
import state from '@/state'
import api from '@/api'
import SessionsTable from '@/components/tables/SessionsTable.vue'
import ContainerComponent from '@/components/ContainerComponent.vue'
import SessionsReportingModal from '@/components/modals/SessionsReportingModal.vue'
import SessionsDownloadReportModal from '@/components/modals/SessionsDownloadReportModal.vue'
import SessionsHeader from '@/components/headers/SessionsHeader.vue'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import { reactive, watch } from 'vue'

import type { Pagination, Session } from '@/types'

const i18n = useI18n()
const notify = useNotify()

type DataType = {
  showDownloadReportingModal: boolean
  showReportingModal: boolean,
  sessions: Session.Session[]
  offset: number,
  loading: boolean,
  pagination: Pagination.Pagination
}

const data: DataType = reactive({
  showDownloadReportingModal: false,
  showReportingModal: false,
  sessions: [],
  offset: 0,
  loading: true,
  pagination: {
    limit: 50,
    total: 0,
    offset: 0
  }
})

async function created () {
  const user = await api.users.getUserDetail(state.user.userLogged.id)
  state.user.setUserLogged(user)
}

const methods = {
  handleApplyFilters (filters: typeof state.filters.sessionsFilters) {
    trackDataEvent('apply-filter-sessions', { ...filters })
    data.offset = 0
    methods.getSessions()
  },

  async getSessions () {
    data.loading = true
    try {
      const result = await api.sessions.getGroupChargingSessions({
        groupId: state.organizations.getCurrentOrganization.group_id,
        filters: { filters: state.filters.backendSessionsFilters },
        limit: data.pagination.limit,
        offset: data.offset
      })

      data.sessions = result.data
      data.pagination.total = result.pagination.count
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  }
}

created()

watch(() => state.filters.sessionsFilters, () => {
  methods.handleApplyFilters(state.filters.sessionsFilters)
}, { immediate: true, deep: true })
</script>
