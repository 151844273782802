import type { Support } from '../../types/data/support.data.types'

const support: Support[] = [
  {
    lang: 'es',
    countryIso2: 'ES',
    supportPhone: '+34 900 92 92 93',
    mail: 'smartmobility@iberdrola.es',
    timeZone: '(+01:00)',
    timeService: '24/7'
  },
  {
    lang: 'it',
    countryIso2: 'IT',
    supportPhone: '800 69 09 60',
    mail: 'smartmobility@iberdrola.it',
    timeZone: '(+01:00)'
  },
  {
    lang: 'pt',
    countryIso2: 'PT',
    supportPhone: '+351 800 60 77 17',
    mail: 'smartmobility@iberdrola.pt',
    timeZone: '(+01:00)',
    timeService: '24/7'
  },
  {
    lang: 'fr',
    countryIso2: 'FR',
    supportPhone: '+33 0 805 98 12 05',
    mail: 'serviceclients@iberdrola.fr',
    timeZone: '(+01:00)'
  }
]

export default support
