<template>
  <wb-card filled class="is-fullheight">
    <template #content>
      <client-img-component
        inline
        class="stripe"
        src="stripe"
      />
      <p
        v-t="'mywb.dashboard.stripe-description'"
        class="is-size-300 mb-16"
      />

      <wb-button
        data-test-id="stripe-cta"
        :label="i18n.t('mywb.dashboard.stripe-cta')"
        :to="{ name: 'payments-configuration' }"
        @click="methods.stripeClick"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

const methods = {
  stripeClick () {
    trackDataAction('dashboard-payments')
  }
}
</script>

<style lang="postcss" scoped>
.stripe {
  fill: var(--grey-500);
  height: 80px;
  margin-left: -12px;
}

:deep(.is-grid) {
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
