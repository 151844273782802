<template>
  <div>
    <access-configs-groups-table
      v-if="compute.rootGroupSelected?.accessConfigs || data.loading"
      :access-configs="compute.rootGroupSelected?.accessConfigs ?? []"
      :loading="data.loading"
      @on-delete="methods.deleteGroup"
      @on-edit="methods.editGroup"
    />
    <access-config-empty-state
      v-else
      @on-create-group="data.isModalUserListAccessConfigAddOpen = true"
    />

    <access-config-edit-modal
      v-if="data.isModalUserListAccessConfigEditOpen && data.selectedAccessConfig"
      :access-config-id="data.selectedAccessConfig.id"
      @close="data.isModalUserListAccessConfigEditOpen = false"
    />

    <access-config-delete-modal
      v-if="data.isModalUserListAccessConfigDeleteOpen && data.selectedAccessConfig"
      :access-config="data.selectedAccessConfig"
      @close="data.isModalUserListAccessConfigDeleteOpen = false"
    />

    <access-config-creation-modal
      v-if="data.isModalUserListAccessConfigAddOpen && compute.rootGroupSelected"
      :root-group="compute.rootGroupSelected"
      @close="data.isModalUserListAccessConfigAddOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import AccessConfigsGroupsTable from '@/components/tables/AccessConfigsGroupsTable.vue'
import AccessConfigEditModal from '@/components/modals/AccessConfigEditModal.vue'
import AccessConfigDeleteModal from '@/components/modals/AccessConfigDeleteModal.vue'
import AccessConfigCreationModal from '@/components/modals/AccessConfigCreationModal.vue'
import AccessConfigEmptyState from '@/components/emptyStates/AccessConfigEmptyState.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import state from '@/state'
import api from '@/api'
import type { AccessConfig, User } from '@/types'

interface Data {
  loading: boolean
  selectedAccessConfig?: AccessConfig.AccessConfigGroupWithUsersExpanded
  isModalUserListAccessConfigDeleteOpen: boolean
  isModalUserListAccessConfigEditOpen: boolean
  isModalUserListAccessConfigAddOpen: boolean
}
const data = reactive<Data>({
  loading: false,
  isModalUserListAccessConfigEditOpen: false,
  isModalUserListAccessConfigDeleteOpen: false,
  isModalUserListAccessConfigAddOpen: false
})

const compute = reactive({
  rootGroupSelected: computed((): User.UsersGroupWithUserExpanded | undefined => {
    return state.user?.getGroupsByAccessConfig
      ?.find?.(group => state.groups.groupRelatedToOrganization.id === group.id)
  })
})

const methods = {
  async getChargersCount (userId: number) {
    const { data: user } = await api.users.getUser(userId)
    const accessConfig = user.accessConfigs
      .find(accessConfig => state.organizations.getCurrentOrganization.group_id === accessConfig.group)
    return accessConfig?.chargers.length
  },

  async getAllAccessConfigGroups (withLoading = true) {
    data.loading = withLoading
    state.user.set('usersGroup', await api.users.getUserGroups())
    data.loading = false
  },

  deleteGroup (accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded) {
    data.selectedAccessConfig = accessConfig
    trackDataAction('delete-group', { group_id: accessConfig.group })
    data.isModalUserListAccessConfigDeleteOpen = true
  },

  editGroup (accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded) {
    data.selectedAccessConfig = accessConfig
    trackDataAction('edit-group', { group_id: accessConfig.group })
    data.isModalUserListAccessConfigEditOpen = true
  },

  handleAddGroup () {
    trackDataAction('add-group')
    data.isModalUserListAccessConfigAddOpen = true
  }
}

function created () {
  methods.getAllAccessConfigGroups()
}

created()

defineExpose({ handleAddGroup: () => methods.handleAddGroup() })
</script>
