<template>
  <auth-terms-and-conditions
    data-test-id="AuthTermsAndConditions"
    @back="emit('back')"
    @terms-accept="methods.finishRegister"
  />
</template>

<script setup lang="ts">
import AuthTermsAndConditions from '@/components/auth/AuthTermsAndConditions.vue'
import { reactive } from 'vue'
import { trackAction, trackError, trackEvent } from '@/engine/metrics/metricsManager'
import { useRoute, useRouter } from 'vue-router'
import { useNotify } from '@wallbox/toolkit-ui'
import { getServerError } from '@/utilities/errorMessages'
import { clientConfig } from '@/engine/clients'
import state from '@/state'
import api from '@/api'
import lang from '@/engine/lang'
import type { Auth, User } from '@/types'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const route = useRoute()
const router = useRouter()
const notify = useNotify()

type Events = {
  (e: 'finish-register'): void,
  (e: 'back'): void
  (e: 'error', error: string): void
}

const emit = defineEmits<Events>()

interface PropsType {
  user: User.UserInRegister
}

const props = defineProps<PropsType>()

const data = reactive({
  loading: false
})

const methods = {
  async finishRegister ({ terms, countryCode }: { terms: Auth.Terms, countryCode: string }) {
    trackAction('signin_submit', { provider: 'email', new_user: true })
    data.loading = true
    try {
      const result = await api.auth.create({
        ...props.user,
        terms,
        country_code: countryCode,
        language: lang.__rootLanguage,
        activation_code: route.query.confirmToken?.toString(),
        client_type: clientConfig.brandingId
      })

      trackEvent('signin_success', {
        id: result.id,
        accept_marketing: terms.marketing,
        accept_policy: terms.policy,
        accept_profile: terms.profile,
        new_user: true
      })

      if (route.query.confirmToken) {
        trackAction('signin_submit', { provider: 'email', new_user: false })
        const userLogin = await api.auth.signIn({
          username: props.user.email,
          password: props.user.password
        })

        const auth = userLogin.data.attributes

        state.global.setAuth(auth)
        trackEvent('signin_success', { user_id: auth.user_id, new_user: false })
        router.push({ name: 'dashboard' })
      } else {
        emit('finish-register')
      }
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handleError(error)
      }
      trackError('register-error', { error })
    }

    data.loading = false
  },

  async handleError (error: HttpError) {
    if (error.status === '409') {
      emit('error', 'user-already-registered')
    } else {
      notify.error(getServerError(error))
    }
  }
}
</script>
