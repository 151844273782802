<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.dashboard') }}
    </template>

    <template #filters>
      <wb-button-groups>
        <div class="group-item">
          <wb-date-picker
            v-model="compute.filterDates"
            data-test-id="calendar"
            :min-date="data.dashboardOptions.dates.minDate"
            :max-date="data.dashboardOptions.dates.today"
            :year-range="[2015, data.dashboardOptions.dates.today.getFullYear()]"
            :multi-calendars="mq.current !== 'mobile'"
            range
            :locale="lang.__rootLanguage"
            :clearable="false"
            :auto-apply="false"
            :cancel-text="i18n.t('mywb.common.cancel')"
            :apply-text="i18n.t('mywb.common.apply')"
          >
            <template #input>
              <wb-popover tooltip-position="top">
                <template #activator>
                  <wb-button
                    :active="!!compute.filterDates.length"
                    icon="calendar"
                    :label="!!compute.filterDates.length
                      ? getDatesLabel(compute.filterDates) : i18n.t('mywb.common.custom')"
                    type="white"
                  />
                </template>
                <template v-if="compute.filterDates.length" #tooltip>
                  {{ i18n.t('mywb.common.num-days', [daysDiff(compute.filterDates[0], compute.filterDates[1])]) }}
                </template>
              </wb-popover>
            </template>
          </wb-date-picker>
        </div>

        <wb-popover
          v-for="option in data.dashboardOptions.calendar"
          :key="option.id"
          class="group-item"
          tooltip-position="top"
        >
          <template #activator>
            <wb-button
              :label="option.label"
              type="white"
              :active="option.id === compute.calendar.id && !compute.filterDates.length"
              @click="methods.handleIntervalOption(option)"
            />
          </template>
          <template #tooltip>
            {{ getIntervalLabel(option) }}
          </template>
        </wb-popover>
      </wb-button-groups>
    </template>

    <template #secondary-actions>
      <shared-filter
        v-model="compute.analysis"
        :icon="compute.analysis === AnalysisEnum.LINEAR ? 'chart_linear' : 'chart_cumulative'"
        option-key="value"
        :options="data.dashboardOptions.analysis"
        :reduce="(item: typeof data.dashboardOptions.analysis[number]) => item.value"
        non-clearable
        non-active-status
        non-sorted-options
        auto-selected
      >
        <template #option="{ option }">
          <div class="is-grid-option g-8">
            <span class="wb-icons is-size-500">{{ option.icon }}</span>
            {{ option.label }}
          </div>
        </template>
      </shared-filter>

      <shared-filter
        v-model="compute.periodicity"
        option-key="value"
        :options="data.dashboardOptions.periodicity"
        :reduce="(item: Record<string, string>) => item.value"
        non-clearable
        non-active-status
        non-sorted-options
        class="ml-12"
        auto-selected
      />
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import state from '@/state'
import lang from '@/engine/lang'
import { useMq } from 'vue3-mq'
import { reactive, computed } from 'vue'
import {
  calendarOptions,
  periodicityOptions,
  analysisOptions
} from '@/utilities/chartSessions/chartOptions'
import { daysDiff, getDatesLabel, getIntervalLabel } from '@/utilities/chartSessions/dateIntervals'

import { AnalysisEnum } from '@/utilities/chartSessions/chartTypeEnums'

const i18n = useI18n()
const mq = useMq()

interface Data {
  dashboardOptions: {
    dates: {
      minDate: Date,
      today: Date
    }
    calendar: typeof calendarOptions
    periodicity: typeof periodicityOptions
    analysis: typeof analysisOptions
  }
}

const data = reactive<Data>({
  dashboardOptions: {
    dates: {
      minDate: new Date(2015, 0, 1),
      today: new Date()
    },
    calendar: calendarOptions,
    periodicity: periodicityOptions,
    analysis: analysisOptions
  }
})

const compute = reactive({
  filterDates: computed({
    get () {
      return state.filters.dashboardFilters?.dates
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'dates', value })
    }
  }),

  calendar: computed({
    get () { return state.filters.dashboardFilters?.calendar },
    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'calendar', value })
    }
  }),

  periodicity: computed({
    get () { return state.filters.dashboardFilters?.periodicity },
    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'periodicity', value })
    }
  }),

  analysis: computed({
    get () { return state.filters.dashboardFilters?.analysis },
    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'analysis', value })
    }
  })
})

const methods = {
  handleIntervalOption (option: typeof calendarOptions[0]) {
    compute.calendar = option
    compute.filterDates = []
  }
}
</script>

<style lang="postcss" scoped>
.is-grid-option {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
}
</style>
