import { computed, reactive } from 'vue'

type PayPerChargeOrMonth = 'pay_per_charge' | 'pay_per_month'
type ColorsRate = Record<PayPerChargeOrMonth, Record<string, string>>
interface RatesState {
  selectedRates: {
    pay_per_charge: Set<string>
    pay_per_month: Set<string>
  }

  savedColors?: ColorsRate

  colors: Record<PayPerChargeOrMonth, Record<string, boolean>>

  firstTimeColors: Record<PayPerChargeOrMonth, boolean>
}

const initialState = (): RatesState => ({
  selectedRates: {
    pay_per_charge: new Set(),
    pay_per_month: new Set()
  },

  colors: {
    pay_per_charge: {
      '--color-1-500': false,
      '--color-2-500': false,
      '--color-3-500': false,
      '--color-4-500': false,
      '--color-5-500': false
    },

    pay_per_month: {
      '--color-1-500': false,
      '--color-2-500': false,
      '--color-3-500': false,
      '--color-4-500': false,
      '--color-5-500': false
    }
  },

  firstTimeColors: {
    pay_per_charge: true,
    pay_per_month: true
  }
})

const state = reactive(initialState())

function getColor (rate: string, type: PayPerChargeOrMonth, index: number) {
  if (state.firstTimeColors[type]) {
    state.colors[type][`--color-${index}-500`] = true
    return `--color-${index}-500`
  }

  const prevColor = state.savedColors?.[type][rate]
  if (prevColor) {
    state.colors[type][prevColor] = true
    return prevColor
  }

  const colorToUse = Object.entries(state.colors[type]).reduce((colorToUse, [currentColor, isInUse]) => {
    if (!colorToUse && !isInUse) return currentColor
    return colorToUse
  }, '')

  state.colors[type][colorToUse] = true

  return colorToUse
}

const internal = {
  colors: computed((): ColorsRate => {
    const colors = {
      pay_per_charge: [...state.selectedRates.pay_per_charge].reduce((acum, rate, index) => {
        return {
          ...acum,
          [rate]: getColor(rate, 'pay_per_charge', index + 1)
        }
      }, {}),

      pay_per_month: [...state.selectedRates.pay_per_month].reduce((acum, rate, index) => ({
        ...acum,
        [rate]: getColor(rate, 'pay_per_month', index + 1)
      }), {})
    }

    state.savedColors = structuredClone(colors)

    state.firstTimeColors.pay_per_charge = !(state.selectedRates.pay_per_charge.size > 0)
    state.firstTimeColors.pay_per_month = !(state.selectedRates.pay_per_month.size > 0)

    return colors
  })
}

const setters = {
  addRates (rates: string[], type: PayPerChargeOrMonth) {
    state.firstTimeColors[type] = true
    state.colors[type] = {
      '--color-1-500': false,
      '--color-2-500': false,
      '--color-3-500': false,
      '--color-4-500': false,
      '--color-5-500': false
    }
    state.selectedRates[type] = new Set(rates)
  },

  addRate (rate: string, type: PayPerChargeOrMonth) {
    state.selectedRates[type].add(rate)
  },

  removeRate (rate: string, type: PayPerChargeOrMonth) {
    state.colors[type][internal.colors.value[type][rate]] = false
    state.selectedRates[type].delete(rate)
  },

  removeRates (type: PayPerChargeOrMonth) {
    state.selectedRates[type].clear()
    state.colors[type] = {
      '--color-1-500': false,
      '--color-2-500': false,
      '--color-3-500': false,
      '--color-4-500': false,
      '--color-5-500': false
    }
  },

  removeUnusedRates (rates: string[], type: PayPerChargeOrMonth) {
    const usedRates = new Set(rates)
    state.selectedRates[type].forEach(rate => {
      if (!usedRates.has(rate)) {
        state.selectedRates[type].delete(rate)
        state.savedColors && (state.colors[type][state.savedColors[type][rate]] = false)
      }
    })
  }
}

const getters = {
  hasCustomColor (rate: string, type: PayPerChargeOrMonth): boolean {
    return !!(rate && internal.colors.value[type][rate])
  },

  getStyleByRate (rate: string, type: PayPerChargeOrMonth, fallback = 'var(--grey-300)') {
    if (!rate || !internal.colors.value[type][rate]) return { '--primary-500': fallback }

    return {
      '--primary-500': `var(${internal.colors.value[type][rate]})`
    }
  }
}

export default reactive({
  ...state,
  ...getters,
  ...setters
})
