<template>
  <wb-modal
    width="45"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.organizations-edit-organization') }}
    </template>

    <wb-notification
      v-if="props.showNotification"
      type="info"
      icon="info"
      class="mb-16"
    >
      <p v-t="'mywb.organizations-type.why'" />
    </wb-notification>

    <wb-input
      v-model="field.name"
      data-test-id="inputName"
      name="name"
      type="text"
      :label="i18n.t('mywb.common.name')"
      :error="errors.name"
    />

    <organization-type-select
      v-model="field.organizationType"
      data-test-id="selectInputType"
      :error="errors.organizationType"
    />

    <template #actions>
      <wb-button
        class="has-margin-auto"
        data-test-id="acceptBtnModal"
        size="block"
        :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.loading"
        @click="validate(methods.updateOrganization)"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import api from '@/api'
import { reactive } from 'vue'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Organizations } from '@/types'
import { useValidator } from '@/hooks/useValidator.hook'
import OrganizationTypeSelect from '@/components/forms/OrganizationTypeSelect.vue'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'
import type { OrganizationTypeEnum } from '@/utilities/organizationTypes'

const notify = useNotify()
const i18n = useI18n()
const { yup, errors, defineSchema, validate } = useValidator()

interface Events {
  (e: 'on-update', payload: { name: string, type: OrganizationTypeEnum}): void,
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const props = defineProps<{
  organization: Organizations.Organization
  showNotification?: boolean
}>()

interface DataType {
  loading: boolean
  organizations: Organizations.Organization[]
}

const data: DataType = reactive({
  loading: false,
  organizations: []
})

interface Field {
  name: string,
  organizationType?: OrganizationTypeEnum
}

const field = reactive<Field>({
  name: '',
  organizationType: undefined
})

defineSchema(field, {
  name: yup
    .string()
    .required(),

  organizationType: yup
    .string()
    .required()
})

const methods = {
  async updateOrganization () {
    if (!field.organizationType) return

    data.loading = true
    try {
      await api.organizations.putOrganization({
        groupUid: props.organization.group_uid,
        name: field.name,
        type: field.organizationType
      })

      trackDataAction('organizations-edited', {
        groupUid: props.organization.group_uid,
        name: field.name,
        type: field.organizationType
      })

      notify.success(i18n.t('mywb.common.changes-saved'))
      emit('on-update', { name: field.name, type: field.organizationType })
      emit('on-close')
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }
    } finally {
      data.loading = false
      emit('on-close')
    }
  }
}

function created () {
  trackDataScreen('organizations-edit-organization')
  field.name = props.organization?.name || ''
  field.organizationType = props.organization.organization_type || ''
}

created()
</script>
