<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.dashboard') }}
    </template>

    <template #filters>
      <dashboard-filters
        :loading="props.loading"
        :locations="props.locations"
      />
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import DashboardFilters from '@/components/filters/DashboardFilters.vue'
import type { Locations } from '@/types'

const i18n = useI18n()

interface Props {
  loading?: boolean,
  locations: Locations.Location[]
}

const props = defineProps<Props>()
</script>
