export enum CHARGER_TYPES {
  PULSAR = 'pulsar',
  PULSAR_CHINA = 'pulsar-china',
  PULSAR_UP = 'pulsar-up',
  PULSAR_PLUS = 'pulsarplus',
  PULSAR_PLUS_US = 'pulsar-plus-us',
  PULSAR_MAX = 'pulsar-max',
  COMMANDER = 'commander',
  COMMANDER_2 = 'commander-2',
  COPPER = 'copper',
  COPPER_BUSINESS = 'copper-business',
  QUASAR = 'quasar',
  QUASAR_BUSINESS = 'quasar-business',
}

interface ChargerType {
  name: string
  code: CHARGER_TYPES
  actions?: {
    minVersion: string
  }
  payments?: {
    ppm: {
      minVersion: string
    }
    ppc: {
      minVersion: string
    }
  }

  gunLock?: {
    minVersion: string
  }
}

const chargerTypes: readonly ChargerType[] = [
  {
    name: 'Pulsar',
    code: CHARGER_TYPES.PULSAR
  },
  {
    name: 'Pulsar China',
    code: CHARGER_TYPES.PULSAR_CHINA
  },
  {
    name: 'Pulsar Up',
    code: CHARGER_TYPES.PULSAR_UP
  },
  {
    name: 'PulsarPlus',
    code: CHARGER_TYPES.PULSAR_PLUS,
    actions: {
      minVersion: '3.9.0'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.10'
      }
    }
  },
  {
    name: 'Pulsar Plus US',
    code: CHARGER_TYPES.PULSAR_PLUS_US,
    actions: {
      minVersion: '4.4.0'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.10'
      }
    }
  },
  {
    name: 'Pulsar Max',
    code: CHARGER_TYPES.PULSAR_MAX,
    actions: {
      minVersion: '0.0.1'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.10'
      }
    }
  },
  {
    name: 'Commander',
    code: CHARGER_TYPES.COMMANDER,
    actions: {
      minVersion: '2.8.0'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    }
  },
  {
    name: 'Commander 2',
    code: CHARGER_TYPES.COMMANDER_2,
    actions: {
      minVersion: '0.0.1'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    }
  },
  {
    name: 'Copper',
    code: CHARGER_TYPES.COPPER,
    actions: {
      minVersion: '3.6.0'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    },

    gunLock: {
      minVersion: '5.13.0'
    }
  },
  {
    name: 'Copper Business',
    code: CHARGER_TYPES.COPPER_BUSINESS,
    actions: {
      minVersion: '0.0.1'
    },
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    },

    gunLock: {
      minVersion: '5.13.0'
    }
  },
  {
    name: 'Quasar',
    code: CHARGER_TYPES.QUASAR,
    actions: {
      minVersion: '0.0.1'
    }
  },
  {
    name: 'Quasar Business',
    code: CHARGER_TYPES.QUASAR_BUSINESS,
    actions: {
      minVersion: '0.0.1'
    }
  }
]

export default chargerTypes
