<template>
  <wb-modal
    width="40"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.confirmation.mark-as-paid', { name: props.invoice.customer_data.name }) }}
    </template>

    <p class="is-size-400">
      {{ i18n.t('mywb.common.invoice-amount', compute.invoiceAmount) }}
    </p>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          class="mr-12"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="confirmMarkAsPaidButton"
          size="block"
          type="primary"
          :label="i18n.t('mywb.common.mark-as-paid')"
          :loading="props.loading"
          @click="methods.goToPendingBills"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen, trackDataEvent, trackDataAction } from '@/engine/metrics/trackDataManager'
import { onMounted, reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Invoice } from '@/types'

const i18n = useI18n()

interface Props {
  invoice: Invoice.Invoice,
  loading?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
  (e: 'on-mark-as-paid', invoice: Invoice.Invoice): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  invoiceAmount: computed(() => ({
    amount: props.invoice.total / 100,
    currency: props.invoice.currency_code
  }))
})

function created () {
  trackDataScreen('payments-invoices-mark-as-paid')
}

onMounted(() => {
  trackDataEvent('unsubscribe-blocked')
})

const methods = {
  goToPendingBills () {
    trackDataAction('unsubscribe-bills')
    emit('on-mark-as-paid', props.invoice)
  }
}

created()
</script>
