<template>
  <content-component>
    <wb-cards-loader :loading="data.loading">
      <div class="grid-content tablet:g-24">
        <div>
          <user-settings-personal-info
            :user="data.user"
            class="mb-24"
          />
          <user-settings-group-settings
            v-if="data.user.accessConfigs"
            :user="data.user"
          />
        </div>
        <div>
          <user-settings-subscribe
            v-if="compute.canSubscribe"
            :user="data.user"
            @on-update="methods.loadUser"
          />
          <user-settings-rfid
            v-if="permissions.showRfid"
            :user="data.user"
          />
        </div>
      </div>
    </wb-cards-loader>
  </content-component>
</template>

<script setup lang="ts">
import UserSettingsPersonalInfo from '@/components/users/UserSettingsPersonalInfo.vue'
import UserSettingsGroupSettings from '@/components/users/UserSettingsGroupSettings.vue'
import UserSettingsSubscribe from '@/components/users/UserSettingsSubscribe.vue'
import ContentComponent from '@/components/ContentComponent.vue'
import UserSettingsRfid from '@/components/users/UserSettingsRfid.vue'
import api from '@/api'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import type { User } from '@/types'

const props = defineProps<{
  userId: string | number
}>()

interface DataType {
  loading: boolean,
  user: User.User & User.UserDetail
}

const data: DataType = reactive({
  loading: true,
  user: {} as User.User & User.UserDetail
})

const compute = reactive({
  canSubscribe: computed(() => {
    return permissions.showPayments &&
      state.organizations.getCurrentOrganization.payments_account
  })
})

async function created () {
  await methods.loadUser()
  data.loading = false
}

const methods = {
  async loadUser () {
    const { data: user } = await api.users.getUser(+props.userId)
    const userDetail = await api.users.getUserDetail(+props.userId)
    data.user = { ...userDetail, ...user }
  }
}

created()
</script>

<style lang="postcss" scoped>
.grid-content {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
