<template>
  <wb-modal
    data-test-id="exportModal"
    width="50"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.export') }}
    </template>

    <p
      v-t="'mywb.sessions.download-report'"
      class="is-size-400 my-8"
    />

    <wb-radio-card
      v-model="data.exportcsv"
      :value="1"
      icon="description"
      class="mt-8"
    >
      <p
        v-t="'mywb.common.csv-long'"
        class="is-size-400 has-text-grey-700 is-font-weight-500"
      />
      <p
        v-t="{ path: 'mywb.sessions.download-file', args: ['(.csv)'] }"
        class="is-size-400 mt-8"
      />
    </wb-radio-card>

    <div class="is-flex mt-24">
      <wb-switch
        v-model="data.isSystemFormat"
        data-test-id="systemFormatSwitch"
        @update:model-value="methods.changeSystemFormat"
      />
      <p class="is-size-400 ml-12" data-test-id="commaText">
        {{ compute.systemFormat }}
      </p>
    </div>
    <template #actions>
      <wb-button
        :label="i18n.t('mywb.sessions.download-cta')"
        :loading="data.loading"
        data-test-id="downloadCta"
        size="block"
        icon="download"
        @click="methods.handleGenerateReport"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import api from '@/api'
import state from '@/state'
import { download, useNotify } from '@wallbox/toolkit-ui'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import type { Session } from '@/types'

const i18n = useI18n()
const notify = useNotify()

interface PropsType {
  sessionsCount?: number
}

const props = withDefaults(defineProps<PropsType>(), {
  sessionsCount: 0
})

type Events = {
  (e: 'close'): void
}

const emit = defineEmits<Events>()

type dataType = {
  exportcsv: 1,
  loading: boolean,
  systemFormats: Session.SessionSystemFormat[]
  isSystemFormat: 0 | 1
}
const data: dataType = reactive({
  exportcsv: 1,
  loading: false,
  systemFormats: [],
  isSystemFormat: 0
})

const compute = reactive({
  systemFormat: computed(() => {
    return data.isSystemFormat
      ? i18n.t('mywb.common.format-csv-report-comma')
      : i18n.t('mywb.common.format-csv-report-dot')
  })
})

async function created () {
  data.systemFormats = await api.sessions.getSystemFormats()
  data.isSystemFormat = data.systemFormats
    .findIndex(format => format.id === state.user.userLogged.system_format) as 0 | 1
}

const methods = {
  async changeSystemFormat () {
    try {
      await api.users.updateUser({
        params: { system_format: data.systemFormats[data.isSystemFormat].id },
        userId: state.user.userLogged.id
      })

      await api.users.getUserDetail(state.user.userLogged.id)
        .then(state.user.setUserLogged)
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async handleGenerateReport () {
    trackDataAction('generate-report')
    data.loading = true

    const params = {
      groupId: state.organizations.getCurrentOrganization.group_id,
      filters: { filters: state.filters.backendSessionsFilters },
      limit: props.sessionsCount
    }

    const stringCsv = await api.sessions.getGroupChargingSessionsCSV(params)
    const blob = new Blob([stringCsv], { type: 'text/csv;charset=utf-8;' })
    download.downloadBlob(blob, 'SessionsReport.csv')

    data.loading = false
    emit('close')
  }
}

created()
</script>

<style lang="postcss" scoped>
.justify-between {
  justify-content: space-between;
}
</style>
