<template>
  <wb-modal
    width="49"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.create-access-group') }}
    </template>

    <access-config-creation-form
      :ref="setRef('access-config-creation')"
      :group-id="props.rootGroup.id"
    />

    <template #actions>
      <wb-button
        data-test-id="acceptBtnModal"
        :label="i18n.t('mywb.common.accept')"
        size="block"
        :loading="data.loading"
        @click="methods.handleSubmitButton"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import AccessConfigCreationForm from '@/components/forms/AccessConfigCreationForm.vue'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useTemplateRef, useNotify } from '@wallbox/toolkit-ui'
import type { User } from '@/types'

const i18n = useI18n()
const notify = useNotify()
const { refs, setRef } = useTemplateRef()

interface Props {
  rootGroup: User.UsersGroupWithUserExpanded
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const methods = {
  async handleSubmitButton () {
    if (await refs['access-config-creation'].validateForm()) {
      data.loading = true
      notify.success(i18n.t('mywb.common.changes-saved'))
      emit('close')
    }
    data.loading = false
  }
}
</script>
