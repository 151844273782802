<template>
  <div v-if="props.charger.id" class="grid g-8">
    <wb-popover>
      <template #activator>
        <span
          class="item wb-icons"
          data-test-id="chargerMid"
          :data-status="compute.midStatusParsed.code === 'disabled'
            ? 'disabled' : 'enabled'"
        >
          {{ compute.midStatusParsed.icon }}
        </span>
      </template>
      <template #tooltip>
        {{ compute.midStatusParsed.label }}
      </template>
    </wb-popover>

    <wb-popover v-if="permissions.hasAuthToRestartAndUpdate">
      <template #activator>
        <span
          class="item wb-icons"
          data-test-id="updatePendingIcon"
          :data-status="!compute.softwareUpdate ? 'disabled' : 'enabled'"
        >
          send_to_charger
        </span>
      </template>
      <template #tooltip>
        {{ compute.softwareUpdate ? i18n.t('mywb.common.update-available') : i18n.t('mywb.common.up-to-date') }}
      </template>
    </wb-popover>

    <wb-popover>
      <template #activator>
        <span
          class="item wb-icons"
          :data-test-id="!!props.charger.locked ? 'chargerLocked' : 'chargerUnlocked'"
          :data-status="compute.isChargerDisconnected ? 'disabled' : 'enabled'"
        >
          {{ !!charger.locked ? 'lock' : 'lock_open' }}
        </span>
      </template>
      <template #tooltip>
        {{ !!props.charger.locked ? i18n.t('mywb.common.locked') : i18n.t('mywb.common.unlocked') }}
      </template>
    </wb-popover>

    <wb-popover>
      <template #activator>
        <span
          class="item wb-icons"
          data-test-id="chargerTypeIcon"
          :data-status="compute.isChargerDisconnected ? 'disabled': 'enabled'"
        >
          {{ compute.connectivityTypeIcon }}
        </span>
      </template>
      <template #tooltip>
        <p>{{ compute.connectivityTypeLabel }}</p>
        <p class="has-text-grey-200">
          {{ compute.connectivityByConnectionType }}
        </p>
      </template>
    </wb-popover>

    <charger-ocpp-status-badge :charger="props.charger" />
  </div>
</template>

<script setup lang="ts">
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { getChargerStatus, STATUSES } from '@/utilities/charger/chargerStatuses'
import {
  connectivityByConnectionType,
  connectivityTypeIcon,
  connectivityTypeLabel
} from '@/utilities/charger/chargerConnectivity'
import { getMidStatus, MID_STATUS } from '@/utilities/charger/midInformation'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargerOcppStatusBadge from '@/components/charger/ChargerOcppStatusBadge.vue'
import { permissions } from '@/engine/clients'
import type { Charger } from '@/types'

const i18n = useI18n()

interface PropsType {
  charger: Charger.ChargerData | Charger.Charger
  software: Charger.Software
}

const props = defineProps<PropsType>()

const compute = reactive({
  midStatusParsed: computed(() => {
    const midStatus = getMidStatus(props.charger)
    const icon = midStatus?.code === MID_STATUS.DISABLED
      ? 'mid'
      : midStatus?.code !== MID_STATUS.ERROR ? 'mid' : 'mid_error'

    return {
      icon,
      code: midStatus?.code || '',
      label: midStatus?.label || ''
    }
  }),

  connectivityTypeIcon: computed(() => connectivityTypeIcon(props.charger)),

  connectivityTypeLabel: computed(() => connectivityTypeLabel(props.charger)),

  connectivityByConnectionType: computed(() => connectivityByConnectionType(props.charger)),

  softwareUpdate: computed(() => isUpdatesAvailable(props.charger, props.software)),

  isChargerDisconnected: computed(() => getChargerStatus(props.charger)?.code === STATUSES.DISCONNECTED)
})
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 24px);
}

.item {
  font-size: 2.2rem;

  &[data-status="disabled"] {
    color: var(--grey-300);
    fill: var(--grey-300);
  }
}
</style>
