<template>
  <shared-header>
    <template #title>
      {{ compute.organizationName }}
    </template>
  </shared-header>

  <div class="wrapper-grid g-32">
    <vertical-menu-component
      v-model="data.selected"
      :menu="compute.menu"
      is-sticky
    />
    <section class="content-grid g-24">
      <wb-card
        id="name"
        :headline="compute.organizationName"
        :subhead="compute.organizationType"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'name'}"
        alignment="center"
      >
        <template #actions>
          <wb-button
            type="white"
            outlined
            data-test-id="editNameOrganization"
            icon="edit"
            :label="i18n.t('mywb.common.edit')"
            @click="data.isModalEditOrganizationOpen = true"
          />
        </template>
      </wb-card>

      <billing-information
        v-if="data.organization"
        id="billing"
        v-model:has-billing-info="data.hasBillingInfo"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'billing'}"
        data-test-id="billingInformationSection"
        :group-id="data.organization?.group_id"
      />
    </section>
  </div>

  <organization-edit-modal
    v-if="data.isModalEditOrganizationOpen && data.organization"
    :organization="data.organization"
    @on-update="methods.handleUpdateName"
    @on-close="data.isModalEditOrganizationOpen = false"
  />
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'
import VerticalMenuComponent from '@/components/VerticalMenuComponent.vue'
import OrganizationEditModal from '@/components/modals/OrganizationEditModal.vue'
import BillingInformation from '@/components/payments/BillingInformation.vue'

import state from '@/state'
import { useRoute } from 'vue-router'
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { organizationText, OrganizationTypeEnum } from '@/utilities/organizationTypes'
import type { Organizations } from '@/types'

const route = useRoute()
const i18n = useI18n()

interface Data {
  organization?: Organizations.Organization
  isModalEditOrganizationOpen: boolean
  hasBillingInfo: boolean
  selected?: string
}

const data = reactive<Data>({
  organization: undefined,
  isModalEditOrganizationOpen: false,
  hasBillingInfo: false,
  selected: undefined
})

const compute = reactive({
  menu: computed(() => {
    return [
      { key: 'name', icon: 'business', text: i18n.t('mywb.common.name') },
      { key: 'billing', icon: 'card', text: i18n.t('mywb.common.billing-information') }
    ].filter(Boolean)
  }),

  organizationName: computed({
    get () {
      return data.organization?.name || ''
    },
    set (name) {
      data.organization = { ...data.organization, name } as Organizations.Organization
    }
  }),

  organizationType: computed({
    get () {
      return organizationText[data.organization?.organization_type ?? OrganizationTypeEnum.HOME]?.name ?? '-'
    },
    set (type) {
      data.organization = {
        ...data.organization,
        organization_type: type as OrganizationTypeEnum
      } as Organizations.Organization
    }
  })
})

const methods = {
  handleUpdateName (payload: { name: string, type: OrganizationTypeEnum }) {
    compute.organizationName = payload.name
    compute.organizationType = payload.type
  }
}

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
})

function created () {
  data.organization = state.organizations.getOrganizationByUid(route.params.organizationUid?.toString())
}

created()
</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.selected-blink {
  animation: blink 900ms linear;
  border-radius: 0.8rem;
}

@keyframes blink {
  0% { box-shadow: none; }
  50% { box-shadow: 0 0 10px var(--primary-500); }
  100% { box-shadow: none; }
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 2fr 1fr;
  }
}

.tablet-span-2 {
  @media (--tablet) {
    grid-column: span 2;
  }
}
</style>
