<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-assign-group.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-assign-group.description') }}
    </div>

    <wb-notification
      type="info"
      icon="bulb"
      class="mb-24"
    >
      <p class="is-font-weight-500 mb-8">
        {{ i18n.t('mywb.common.what-is-access-group') }}
      </p>
      <p>{{ i18n.t('mywb.common.what-is-access-group.description') }}</p>
    </wb-notification>

    <wb-select
      v-if="props.group?.accessConfigs"
      v-model="compute.accessConfigIdSelected"
      data-test-id="groupSelect"
      uid="_groups"
      class="is-capitalized"
      :label="i18n.t('mywb.common.access-group')"
      option-label="name"
      :reduce="option => option.id"
      name="subgroup"
      :placeholder="i18n.t('mywb.common.add-existing-access-group')"
      :options="props.group?.accessConfigs || []"
      :error="data.errors[0]"
      @change="data.errors = []"
    />

    <wb-button
      type="primary"
      inverted
      icon="create_new_folder"
      class="mt-24"
      :label="i18n.t('mywb.common.create-access-group')"
      data-test-id="create-access-config"
      @click="emit('on-create-access-group')"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { validate } from 'vee-validate'
import type { User } from '@/types'

const i18n = useI18n()

interface Props {
  accessConfigId?: number
  chargersSelected: number[]
  group: User.UsersGroupWithUserExpanded
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:accessConfigId', accessConfigId?: number): void
  (e: 'update:chargersSelected', chargers: number[]): void
  (e: 'on-create-access-group'): void
}
const emit = defineEmits<Events>()

interface Data {
  errors: string[]
}
const data = reactive<Data>({
  errors: []
})

const compute = reactive({
  getChargersFromAccessConfig: computed(() => {
    return props.group.accessConfigs
      .find(accessConfig => accessConfig.id === props.accessConfigId)?.chargers || []
  }),

  accessConfigIdSelected: computed({
    get () {
      return props.accessConfigId
    },
    set (accessConfigId) {
      emit('update:accessConfigId', accessConfigId)
      setTimeout(() => { emit('update:chargersSelected', compute.getChargersFromAccessConfig) }, 150)
    }
  })
})

const methods = {
  async isValidateForm () {
    const { valid, errors } = await validate(props.accessConfigId, 'required')
    data.errors = errors
    return valid
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
