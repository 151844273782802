<template>
  <wb-breadcrumb
    :items="state.routes.getBreadcrumb"
    size="large"
    :dynamic-appearance-offset="60"
    @click="methods.handleClickBreadcrumb"
  />
</template>

<script setup lang="ts">
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import state from '@/state'
import type { BreadcrumbItem } from '@wallbox/toolkit-ui'

const route = useRoute()

const methods = {
  handleClickBreadcrumb (item: BreadcrumbItem) {
    state.routes.backToRoute(item)
    trackDataAction(`breadcrumb-${item.name}`, { option: item.name })
  }
}

if (!route.meta?.resetBreadcrumb && !state.routes.routes.length) {
  state.routes.pushRoute({
    name: 'dashboard',
    fullPath: '/dashboard',
    meta: {
      breadcrumb: { name: 'dashboard', text: 'mywb.common.dashboard' }
    }
  })
}

if (route.meta?.resetBreadcrumb) {
  state.routes.clearRoutes()
}

state.routes.pushRoute(route)

onBeforeRouteUpdate(to => {
  if (to.meta?.resetBreadcrumb) {
    state.routes.clearRoutes()
  }

  state.routes.pushRoute(to)
})

window.addEventListener('popstate', () => {
  const index = state.routes.routes.length - 1
  const lastRoute = state.routes.routes[index]
  const previousElement = state.routes.routes[index - 2]

  if (lastRoute?.name === previousElement?.name) {
    state.routes.getLastRoute()
  }
})
</script>
