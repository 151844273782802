export const endpoints = {
  v1: {
    sessions_filters: '/sessions/filters',
    chargers_config_chargerId: '/chargers/config/{chargerId}',
    users_chargers_bulkAdd: '/users/chargers/bulk-add',
    updatePassword: '/password/update',
    users_data_userId: '/users/data/{userId}',
    users_rekognition_masterImage: '/users/rekognition/master-image'
  },
  v2: {
    currencies: 'v2/currencies',
    countries: 'v2/countries',
    countries_countryCode_states: 'v2/countries/{countryCode}/states',
    charger_chargerId: 'v2/charger/{chargerId}',
    charger_chargerId_group: 'v2/charger/{chargerId}/group',
    user_userId: 'v2/user/{userId}',
    user_userId_assignGroup_groupId: 'v2/user/{userId}/assign-group/{groupId}'
  },
  v3: {
    groups_groupId_invoices: 'v3/groups/{groupId}/invoices',
    groups_groupId_billingInfo: 'v3/groups/{groupId}/billing-info',
    invoices_invoiceId_payments: 'v3/invoices/{invoiceId}/pay',
    systemFormats: 'v3/system-formats',
    chargers_groups: 'v3/chargers/groups',
    chargers_group: 'v3/groups/{groupId}',
    chargers_chargerId_remoteAction: 'v3/chargers/{chargerId}/remote-action',
    groups_groupId: 'v3/groups/{groupId}',
    groups: 'v3/groups',
    chargers: 'v3/chargers',
    chargers_chargerId: 'v3/chargers/{chargerId}',
    chargers_chargerId_checkUpdates: 'v3/chargers/{chargerId}/check-updates',
    chargers_chargerId_ocppConfiguration: 'v3/chargers/{chargerId}/ocpp-configuration',
    users_groups: 'v3/users/groups',
    access: 'v3/access',
    accessConfigs: 'v3/access-configs',
    accessConfigs_accessConfigId: 'v3/access-configs/{accessConfigId}',
    add_users_to_access_config: 'v3/users/invite',
    accessConfigs_accessConfigId_users_userId: 'v3/access-configs/{accessConfigId}/users/{userId}',
    contracts_contractId: 'v3/contracts/{contractId}',
    users_contracts_contractId_active: 'v3/contracts/{contractId}/activate',
    contact_reportIssue: '/v3/contact/report-issue',
    contact_reportIssue_topics: '/v3/contact/report-issue/topics'
  },

  v4: {
    application_fees: 'v4/application-fees',
    chargers_timezones: 'v4/chargers/timezones',
    groups_groupId_invitations: 'v4/groups/{groupId}/invitations',
    chargers_last_sessions: '/v4/charger-last-sessions',
    groups_groupId_invitations_invitationId: 'v4/groups/{groupId}/invitations/{invitationId}',
    groups_groupId_payment_account: 'v4/groups/{groupId}/payment-account',
    groups_groupId_payment_account_link: 'v4/groups/{groupId}/payment-account-link',
    spaces: '/v4/space-accesses',
    payments_invoices_void: '/v4/invoices/{invoiceId}/void',
    user_ulid_id: '/v4/users/{ulid}/id',
    groups_groupId_charger_charging_sessions: '/v4/groups/{groupId}/charger-charging-sessions',
    operator_request: 'v4/operator-request',
    operator_request_uid: 'v4/operator-request/{operatorRequestUid}',
    group_rates: '/v4/groups/{groupId}/rates',
    group_rate: '/v4/groups/{groupId}/rates/{rateId}',
    subscriptions_summary: '/v4/subscriptions/summary/{groupUid}',
    get_locations: '/v4/spaces/{organizationUid}/locations',
    location: '/v4/locations/{locationUid}',
    organizations: '/v4/organizations',
    group_id: '/v4/groups/{groupUid}/id',
    config: '/v4/app/config',
    fee_invoices: '/v4/organizations/{organizationUid}/wallbox-fee-invoices',
    fee_invoice_details: '/v4/organizations/{organizationUid}/wallbox-fee-invoices/{invoiceNumber}',
    chargers_charger_rate_schedules: '/v4/chargers/{chargerUid}/charger-rate-schedules',
    chargers_charger_rate_schedule_id: '/v4/chargers/{chargerUid}/charger-rate-schedules/{scheduleId}',
    organizations_invoices: 'v4/organizations/{groupUid}/invoices',
    organizations_assigned_rates: 'v4/organizations/{groupUid}/assigned-rates/{rateUid}',
    organization: '/v4/organizations/{groupUid}'
  },

  userApi: {
    userEmail: '/users/emails/{email}',
    signIn: '/users/signin',
    create: '/users',
    passwordResetRequest: '/users/password/reset/email',
    passwordReset: '/users/password/reset',
    googleSignin: '/users/signin/google',
    appleSignin: '/users/signin/apple',
    activateUser: '/users/activate',
    googleSignup: '/users/google',
    appleSignup: '/users/apple',
    refreshToken: '/users/refresh-token'
  },

  persuesApi: {
    v1: {
      ocppUrl: '/v1/organizations/{organizationUid}/ocpp-url',
      ocppCharger: 'v1/organizations/{organizationUid}/ocpp-chargers'
    }
  }
}
