<template>
  <div class="content">
    <div :class="{ 'sidebar-content-air': props.hasAir }">
      <slot />
    </div>
    <div v-if="slots.actions" class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

const slots = useSlots()

interface PropsType {
  hasAir?: boolean
}

const props = withDefaults(defineProps<PropsType>(), {
  hasAir: false
})
</script>

<style lang="postcss" scoped>
.content {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content;
  width: 100%;
}

.sidebar-content-air {
  padding: 2.4rem 3.5rem;
}

.actions {
  grid-column: span 2;
  position: sticky;
  bottom: 0;
  border-top: 1px solid var(--grey-100);
  padding: 1.2rem 3.4rem;
  width: 100%;
  text-align: right;
  border-radius: 0 0 1rem 1rem;
  z-index: 10;
}
</style>
