<template>
  <div>
    <wb-table
      :options="compute.options"
      :loading="props.loading"
      data-test-id="tableOrganizations"
    >
      <organizations-table-row
        v-for="organization in compute.organizationsParsed"
        :key="organization.id"
        :organization="organization"
        :data-test-id="`organizationElement${organization.name}`"
        @on-change-organization="methods.handleRedirectToDefaultRoute"
      />
    </wb-table>
  </div>
</template>

<script setup lang="ts">
import OrganizationsTableRow from '@/components/tables/OrganizationsTableRow.vue'

import state from '@/state'
import { getRoleById } from '@/utilities/user-roles'
import { useI18n } from '@/hooks/useI18n.hook'
import { computed, reactive } from 'vue'
import api from '@/api'
import { useRouter } from 'vue-router'
import type { Organizations } from '@/types'
import type { TableProps } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const router = useRouter()

interface PropsType {
  organizations: Organizations.Organization[]
  loading: boolean,
  optionsTable: {
    stickyHead?: string
  }
}

const props = withDefaults(defineProps<PropsType>(), {
  organizations: () => [],
  loading: false,
  optionsTable: () => ({})
})

interface Data {
  options: TableProps['options']
}
const data = reactive<Data>({
  options: {
    offset: '0 4%',
    columns: [
      { name: i18n.t('mywb.common.organization'), align: 'left', minWidth: '300px', width: 'auto' },
      { name: i18n.t('mywb.common.owner'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.role'), align: 'left', minWidth: '140px', width: 'auto' },
      { name: i18n.t('mywb.common.plan'), align: 'left', minWidth: '120px', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: '200px', width: '300px' }
    ]
  }
})

const compute = reactive({
  options: computed(() => ({ ...data.options, ...props.optionsTable })),

  organizationsParsed: computed(() => {
    return props.organizations
      .map(organization => ({
        ...organization,
        owner_email: organization.owner_email,
        role: i18n.t(`mywb.common.${getRoleById(organization.profile_id)?.name}`),
        plan: i18n.t(state.plans.getPlan(organization.product_id).label),
        is_active: state.organizations.getCurrentOrganization.id === organization.id
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  })
})

const methods = {
  async handleRedirectToDefaultRoute (organizationSelected: Organizations.Organization) {
    state.organizations.setCurrentOrganization(organizationSelected.id)
    const { result: chargersGroup } = await api.groups.getDataGroup(organizationSelected.group_id)

    state.groups.set('groupRelatedToOrganization', chargersGroup)
    router.push({ name: state.organizations.defaultHomePage })
  }
}
</script>
