<template>
  <wb-card
    :headline="props.title"
    :subhead="props.subtitle"
  >
    <template #place-upper-right>
      <div class="is-flex">
        <wb-label
          v-if="!compute.compatible.isCompatible"
          is-invert
          disabled
          color="grey"
          class="label"
        >
          {{ i18n.t('mywb.error.not-compatible') }}
        </wb-label>
        <wb-label
          v-if="permissions.showUpSellingToPlanBusiness && compute.compatible.isCompatible"
          is-invert
          disabled
          color="grey"
          icon="lock"
          class="label"
        >
          {{ i18n.t('mywb.plans.business') }}
        </wb-label>

        <wb-switch
          v-model="compute.activatedProxy"
          :disabled="compute.isSwitchDisabled"
          data-test-id="switch"
        />
      </div>
    </template>

    <template #content>
      <wb-link
        v-if="permissions.showUpSellingToPlanBusiness && permissions.canChangePlan && compute.compatible.isCompatible"
        class="is-size-400 u-block mb-8"
        @click="methods.goToPlans"
      >
        {{ i18n.t('mywb.plan.upgrade') }}
      </wb-link>

      <wb-link
        v-if="!compute.hasOrganizationPaymentsAccount &&
          permissions.canConnectStripe &&
          compute.compatible.isCompatible"
        class="is-size-400 u-block mb-8"
        :to="{ name: 'payments-configuration' }"
      >
        {{ i18n.t('mywb.dashboard.stripe-cta') }}
      </wb-link>
      <wb-notification
        v-if="!compute.compatible.isCompatible && compute.compatible.reason"
        icon="info_filled"
        type="info"
        class="mb-8 mt-8"
      >
        {{ compute.compatible.reason }}
      </wb-notification>

      <template v-if="compute.activatedProxy && props.chooseRate || data.isAddRateModalOpen">
        <charger-rates-select
          v-if="!props.allowHourlySelection"
          v-model="compute.selectedRate"
          data-test-id="selectInput"
          append-to-body
          class="mt-8"
          :billing-country-iso2="props.billingCountryIso2"
          :rates="props.rates"
          :error="props.error"
          :type="props.type"
          :disabled="props.loading"
          :charger="props.charger"
        />

        <charger-rate-schedule
          v-else
          v-model:schedule="compute.schedule"
          v-model:is-add-rate-modal-open="data.isAddRateModalOpen"
          :charger-schedule-id="props.chargerScheduleId"
          class="mt-24"
          :rates="props.rates"
          :billing-country-iso2="props.billingCountryIso2"
          :type="props.type"
          :charger="props.charger"
          @close="methods.cancelSchedule"
        />
      </template>

      <wb-notification
        v-if="props.type === 'pay_per_charge' &&
          compute.minimumChargeAmount && compute.activatedProxy && compute.selectedRate"
        type="info"
        icon="info_filled"
        class="mt-8"
      >
        {{ i18n.t('mywb.currencies.minimum-charge-amount.html', { amount: compute.minimumChargeAmount }) }}
      </wb-notification>

      <slot />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { computed, reactive, toRaw } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { useRouter } from 'vue-router'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { numbers } from '@wallbox/toolkit-ui'
import { getMinimumChargeAmount, getCurrencyCode } from '@/utilities/currency'
import ChargerRateSchedule from './charger/ChargerRateSchedule.vue'
import ChargerRatesSelect from '@/components/charger/ChargerRatesSelect.vue'
import type { Charger, Rate } from '@/types'
import type { CountryCodes } from '@/types/data/currency.data.types'
import { isPaymentsCompatible } from '@/utilities/charger/chargerCompatible'

const i18n = useI18n()
const router = useRouter()

interface Events {
  (e: 'update:activated', activated: boolean): void
  (e: 'update:rate', rate?: Rate.Rate): void
  (e: 'update:schedule', schedule?: Rate.Schedule): void
}
const emit = defineEmits<Events>()

interface PropsType {
  activated: boolean
  title: string
  subtitle: string
  error?: string
  rates?: Rate.Rate[]
  rate?: Rate.Rate
  chooseRate?: boolean
  type: 'pay_per_charge' | 'pay_per_month'
  loading?: boolean
  billingCountryIso2?: CountryCodes
  charger?: Charger.AnyTypeOfCharger
  schedule?: Rate.Schedule
  allowHourlySelection?: boolean
  compatible?: {
    isCompatible: boolean
    reason: string
  },
  chargerScheduleId?: string
}

const props = defineProps<PropsType>()

const data = reactive({
  isAddRateModalOpen: false,
  isTemporaryDisabled: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.payments_account),

  isSwitchDisabled: computed((): boolean => {
    return props.loading ||
      !compute.compatible.isCompatible ||
      !compute.hasOrganizationPaymentsAccount ||
      data.isTemporaryDisabled
  }),

  activatedProxy: computed({
    get () {
      return props.activated
    },

    set (value) {
      data.isTemporaryDisabled = true

      setTimeout(() => {
        data.isTemporaryDisabled = false
      }, 1000)

      const rates = Object.values(props.schedule ?? {}).map(Object.values).flat()
      const notSchedule = rates.length === 0 || !rates?.some(Boolean)

      data.isAddRateModalOpen = (props.chooseRate || false) && value && notSchedule

      emit('update:activated', value)
    }
  }),

  selectedRate: computed({
    get () {
      return props.rate
    },

    set (value) {
      emit('update:rate', value)
    }
  }),

  schedule: computed({
    get () {
      return props.schedule
    },

    set (value) {
      emit('update:schedule', value)
    }
  }),

  compatible: computed(() => {
    const compatible = {
      isCompatible: true,
      reason: ''
    }
    return props.compatible ?? (props.charger ? isPaymentsCompatible(props.charger, props.type) : compatible)
  }),

  minimumChargeAmount: computed(() => {
    const minimumChargeAmount = getMinimumChargeAmount()
    if (!minimumChargeAmount) return 0

    return numbers.toLocaleCurrencySymbol(minimumChargeAmount, getCurrencyCode(), i18n.locale.value)
  })
})

const methods = {
  goToPlans () {
    router.push({ name: 'plans' })
    trackDataAction('upgrade_plan', {
      source: 'charger_settings'
    })
  },

  cancelSchedule (schedule: Rate.Schedule) {
    compute.schedule = toRaw(schedule)
    const notSchedule = !Object.values(props.schedule ?? {}).flat()?.every(Boolean) ?? true

    if (!schedule || notSchedule) {
      compute.activatedProxy = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.is-flex {
  display: flex;
  align-items: center;

  & .label {
    padding: 0 8px;
    margin-right: 8px;
  }
}
</style>
