import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import { formatResponseJsonApi } from '@/api/config/jsonApi.config'
import type { Invoice, PaymentsApi } from '@/types'

export default {
  async getInvoices ({ params, groupUid }: PaymentsApi.apiGetInvoice) {
    const result = await axios.get<unknown, PaymentsApi.apiInvoicesGroups>(
      endpoints.v4.organizations_invoices.replace('{groupUid}', groupUid?.toString()), {
        params: {
          ...params,
          filters: JSON.stringify(params.filters)
        }
      }
    )

    return formatResponseJsonApi<Invoice.Invoice>(result)
  },

  async postPaymentsAccount ({ groupId, type }: PaymentsApi.PaymentsAccount) {
    return await axios.post(endpoints.v4.groups_groupId_payment_account
      .replace('{groupId}', groupId?.toString()), {
      data: {
        type: 'group_payment_account',
        attributes: {
          type
        }
      }
    })
  },

  async getPaymentsAccount ({ groupId }: { groupId: number }): Promise<PaymentsApi.PaymentsAccountResponse> {
    return await axios.get(endpoints.v4.groups_groupId_payment_account
      .replace('{groupId}', groupId?.toString()), {
    })
  },

  async deletePaymentsAccount ({ groupId }: { groupId: number }): Promise<void> {
    return await axios.delete(endpoints.v4.groups_groupId_payment_account
      .replace('{groupId}', groupId?.toString()), {
    })
  },

  async getLinkPaymentsAccount ({ groupId, refreshUrl, redirectUrl }: PaymentsApi.LinkPaymentAccount):
  Promise<string> {
    return await axios.get(endpoints.v4.groups_groupId_payment_account_link
      .replace('{groupId}', groupId?.toString()), {
      params: {
        refresh_url: refreshUrl,
        redirect_url: redirectUrl
      }
    }).then(response => response.data.attributes?.url)
  },

  async postVoidInvoice ({ invoiceId }: { invoiceId: number }): Promise<void> {
    return await axios.post(endpoints.v4.payments_invoices_void
      .replace('{invoiceId}', invoiceId.toString())
    )
  }
}
