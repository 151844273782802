<template>
  <chargers-summary
    title="mywb.dashboard.active-chargers"
    subtitle="mywb.dashboard.total-active-chargers"
    icon="bolt"
    icon-color="var(--info-500)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-active-chargers"
    :total-chargers="compute.totalChargers"
    :series="compute.activeChargersSeries"
  />

  <chargers-summary
    title="mywb.dashboard.available-chargers"
    subtitle="mywb.dashboard.total-available-chargers"
    icon="check_circle"
    icon-color="var(--success-500)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-available-chargers"
    :total-chargers="compute.totalChargers"
    :series="compute.onlineChargersSeries"
  />

  <chargers-summary
    title="mywb.dashboard.unavailable-chargers"
    subtitle="mywb.dashboard.total-unavailable-chargers"
    icon="error_outline"
    icon-color="var(--danger-500)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-unavailable-chargers"
    :total-chargers="compute.totalChargers"
    :series="compute.offlineChargersSeries"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed } from 'vue'
import { getChargerStatus, getColorByStatus, STATUSES, type Status } from '@/utilities/charger/chargerStatuses'
import { objects } from '@wallbox/toolkit-ui'
import { useI18n } from 'vue-i18n'
import ChargersSummary from './ChargersSummary.vue'
import type { Group } from '@/types'

const { t } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

interface ChartInfo {
  amount: number
  data: number[]
  name: string
  color: string
  status: STATUSES
}

const compute = reactive({
  groupsFiltered: computed((): Group.GroupCharger[] => {
    let { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    if (state.filters.dashboardFilters.locations.length > 0) {
      subgroups = subgroups.filter(group => state.filters
        .dashboardFilters.locations.some(location => location.group_uid === group.uid))
    }

    return subgroups
  }),

  totalChargers: computed((): number => {
    return compute.groupsFiltered.flatMap(group => group.chargers).length
  }),

  chargersByStatus: computed((): Record<string, Status[]> => {
    return objects.groupBy(
      compute.groupsFiltered.flatMap(group => group.chargers.map(charger => getChargerStatus(charger))),
      'code'
    )
  }),

  onlineChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.LOCKED,
      STATUSES.READY,
      STATUSES.SCHEDULED,
      STATUSES.UPDATING,
      STATUSES.WAITING
    ])
  }),

  activeChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.CHARGING,
      STATUSES.DISCHARGING,
      STATUSES.PAUSED
    ])
  }),

  offlineChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.ERROR,
      STATUSES.DISCONNECTED,
      STATUSES.NOT_CONFIGURED
    ])
  })
})

const methods = {
  getLabelByStatuses (statuses: STATUSES[]) {
    return statuses.map(status => {
      const amount = compute.chargersByStatus[status]?.length ?? 0
      return {
        amount,
        status,
        data: [amount / compute.totalChargers * 100],
        name: t(compute.chargersByStatus[status]?.[0]?.label ?? ''),
        color: getColorByStatus(status).bg
      }
    })
      .filter(Boolean)
  }
}

</script>
