<template>
  <wb-checkbox
    v-model="compute.model"
    class="location-charger-tree-item "
    :data-test-id="`charger-${charger.id}`"
    :disabled="props.disabled"
  >
    <span class="pl-8" data-test-id="chargerSn">
      SN {{ charger.id }}
    </span>
    <span class="px-16">-</span>
    <span data-test-id="chargerName">
      {{ charger.name }}
    </span>
  </wb-checkbox>
</template>

<script lang="ts">
export default {
  name: 'LocationChargerTreeItem'
}
</script>

<script setup lang="ts">
import type { Charger } from '@/types'
import { reactive, computed } from 'vue'

interface Props {
  modelValue: boolean
  disabled?: boolean
  charger: Charger.Charger
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', model: boolean): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})
</script>

<style lang="postcss" scoped>
.location-charger-tree-item {
  display: flex;
  border: 1px solid var(--grey-200);
  border-bottom: none;
  padding: 8px 32px;
}
</style>
