<template>
  <wb-card
    :headline="i18n.t('mywb.locations.location-detail')"
    filled
  >
    <template #place-upper-right>
      <div v-if="props.isTitleALink" class="is-flex">
        <wb-button-groups>
          <wb-button
            data-test-id="editGroupCta"
            icon="edit"
            :label="i18n.t('mywb.common.edit')"
            type="white"
            size="small"
            class="group-item"
            @mousedown="emit('on-edit', props.location)"
          />
          <wb-button
            data-test-id="deleteGroupCta"
            icon="delete"
            type="danger"
            inverted
            size="small"
            class="group-item"
            @mousedown="emit('on-delete', props.location)"
          />
        </wb-button-groups>
      </div>
    </template>

    <template #content>
      <wb-button-card
        v-if="props.location?.id && props.isTitleALink"
        data-test-id="locationName"
        icon="location_filled"
        :title="i18n.t('mywb.common.name')"
        :subtitle="props.location.name"
        status="enabled"
        action="emit"
        @click="methods.goToLocation()"
      />

      <wb-button-card
        data-test-id="organizationName"
        icon="business_filled"
        :title="i18n.t('mywb.common.organization')"
        :subtitle="state.organizations.getCurrentOrganization.name"
        status="enabled"
        action="emit"
        @click="methods.goToOrganization()"
      />

      <wb-button-card
        v-if="props.location?.address"
        data-test-id="locationAddress"
        icon="change_location"
        :title="i18n.t('mywb.common.address')"
        status="enabled"
        force-open
      >
        <google-maps-and-address-input-form
          v-if="props.showMap"
          read-mode
          :latitude="props.location.latitude"
          :longitude="props.location.longitude"
        />
        <div class="is-size-300">
          {{ props.location.address }}
        </div>
      </wb-button-card>

      <wb-button-card
        v-if="props.location?.location_types"
        data-test-id="locationTypes"
        icon="ev_station"
        :title="i18n.t('mywb.location.location-type')"
        status="enabled"
        force-open
      >
        <wb-label
          v-for="locationType in compute.locationTypes"
          :key="locationType"
          color="black"
          is-active
          class="mr-4"
        >
          {{ locationType }}
        </wb-label>
      </wb-button-card>

      <wb-button-card
        v-if="props.location?.energy_type"
        data-test-id="locationEnergyType"
        :icon="compute.energyType.icon ? compute.energyType.icon : 'help'"
        :title="i18n.t('mywb.location.energy-type')"
        :subtitle="compute.energyType.text"
        status="enabled"
      />

      <wb-button-card
        v-if="props.location?.energy_cost"
        data-test-id="locationEnergyCost"
        icon="charge_boost_filled"
        :title="i18n.t('mywb.common.energy-cost')"
        :subtitle="compute.energyCost"
        status="enabled"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import GoogleMapsAndAddressInputForm from '@/components/forms/GoogleMapsAndAddressInputForm.vue'
import { locationTypesObject, type LocationTypesEnum } from '@/utilities/locationTypesEnum'
import { numbers } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { getCurrencyCode } from '@/utilities/currency'
import state from '@/state'
import type { Group, Locations } from '@/types'

const i18n = useI18n()
const router = useRouter()

type Events = {
  (e: 'on-edit', location?: Locations.Location): void,
  (e: 'on-delete', location?: Locations.Location): void,
}
const emit = defineEmits<Events>()

interface PropsType {
  location?: Locations.Location
  group?: Group.GroupChargersTree
  showMap?: boolean
  isTitleALink?: boolean
}

const props = defineProps<PropsType>()

const compute = reactive({
  currencyCode: computed(() => getCurrencyCode()),

  locationTypes: computed(() => {
    const types = (props.location?.location_types?.split(',') ?? []) as LocationTypesEnum[]
    return types.map(entry => locationTypesObject[entry])
  }),

  energyType: computed(() => {
    return {
      renewable: { icon: 'leaf', text: i18n.t('mywb.common.renewable') },
      nonrenewable: { icon: 'bolt', text: i18n.t('mywb.common.non-renewable') },
      unknown: { text: i18n.t('mywb.common.unknown') }
    }[props.location?.energy_type ?? ''] ?? {}
  }),

  energyCost: computed((): string => {
    return numbers.toLocaleCurrencySymbol(props.location?.energy_cost ?? 0, compute.currencyCode, i18n.locale.value)
  })
})

const methods = {
  goToLocation () {
    router.push({ name: 'location-detail', params: { groupId: props.group?.id.toString() ?? '' } })
  },

  goToOrganization () {
    router.push({
      name: 'organizationDetail',
      params: { organizationUid: state.organizations.getCurrentOrganization.group_uid }
    })
  }
}
</script>
