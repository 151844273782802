<template>
  <wb-card
    :headline="`${props.chargers.length + ' ' + i18n.t('mywb.common.chargers')}`"
  >
    <template #place-upper-right>
      <div class="is-flex">
        <charger-rates-popover
          v-if="compute.showRates && data.chargersWithRates.length > 0 && props.location?.address"
          data-test-id="chargerRatesPopover"
          class="mr-12"
          @assign-rates="methods.configureAllRates('assign')"
          @unassign-rates="methods.configureAllRates('unassign')"
        />
        <wb-button
          v-if="permissions.canAddCharger"
          :disabled="props.loadingChargers"
          :label="i18n.t('mywb.common.add-charger')"
          icon="add"
          type="primary"
          outlined
          size="small"
          data-test-id="locationAddCharger"
          @click="emit('add-chargers')"
        />
      </div>
    </template>
    <template #content>
      <wb-table
        :options="data.options"
        :loading="props.loadingChargers"
      >
        <location-chargers-table-row
          v-for="charger in data.chargersWithRates"
          :key="charger.id"
          data-test-id="locationTableChargersRow"
          :charger="charger"
          :group-id="groupId"
          :loading-rates="data.loadingAssignedRates"
          :location="props.location"
          @assign-rates="methods.configureRatesForCharger"
          @rates-changed="getAssignedRates(props.chargers)"
        />
      </wb-table>

      <chargers-assign-rates-modal
        v-if="data.isModalAssignRatesOpen"
        data-test-id="chargersAssignRatesModal"
        @close="data.isModalAssignRatesOpen = false"
        @rates-updated="getAssignedRates(props.chargers)"
        @restore-chargers="state.charger.setCheckChargersGroup({ status: true, chargers: props.chargers })"
      />

      <chargers-unassign-rates-modal
        v-if="data.isModalUnassignRatesOpen"
        data-test-id="chargersUnassignRatesModal"
        @close="data.isModalUnassignRatesOpen = false"
        @rates-updated="getAssignedRates(props.chargers)"
        @restore-chargers="state.charger.setCheckChargersGroup({ status: true, chargers: props.chargers })"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import state from '@/state'

import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'

import LocationChargersTableRow from '@/components/tables/LocationChargersTableRow.vue'
import ChargersAssignRatesModal from '@/components/modals/ChargersAssignRatesModal.vue'
import ChargerRatesPopover from '@/components/charger/ChargerRatesPopover.vue'
import ChargersUnassignRatesModal from '@/components/modals/ChargersUnassignRatesModal.vue'
import type { Charger, Locations } from '@/types'
import type { TableProps, ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()

const emit = defineEmits(['update-chargers', 'add-chargers'])

interface Props {
  groupId: number,
  chargers: Charger.Charger[],
  loadingChargers?: boolean,
  location: Locations.Location
}
const props = defineProps<Props>()

const {
  getData: getAssignedRates,
  setChargers,
  loading: loadingAssignedRates,
  chargersWithRates
} = useAssignedRatesApi(props.location)

const compute = reactive({
  hasOrganizationPaymentsAccount:
    computed((): boolean => !!state.organizations.getCurrentOrganization.payments_account),

  showRates: computed((): boolean => permissions.showPayments && compute.hasOrganizationPaymentsAccount)
})

interface Data {
  options: TableProps['options']
  isModalAssignRatesOpen: boolean
  isModalUnassignRatesOpen: boolean
  isModalAssignRatesSingleChargerOpen: boolean
  loadingAssignedRates: typeof loadingAssignedRates
  chargersWithRates: typeof chargersWithRates
  charger?: Charger.Charger,
}

const data = reactive<Data>({
  options: {
    offset: '0 4%',
    resizable: true,
    columns: [
      { name: i18n.t('mywb.common.charger'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: '140px', width: '140px' },
      compute.showRates && {
        name: i18n.t('mywb.charger.pay-per-charge'),
        align: 'left',
        minWidth: '200px',
        width: 'auto'
      },
      compute.showRates && {
        name: i18n.t('mywb.charger.pay-per-month'),
        align: 'left',
        minWidth: '200px',
        width: 'auto'
      },

      { name: i18n.t('mywb.common.actions'), align: 'right', minWidth: '200px', width: '200px' }
    ].filter(Boolean as unknown as ExcludesNullish) as TableProps['options']['columns'],
    stickyHead: 'var(--header-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  },

  isModalAssignRatesOpen: false,
  isModalUnassignRatesOpen: false,
  isModalAssignRatesSingleChargerOpen: false,
  loadingAssignedRates,
  chargersWithRates
})

const methods = {
  openRatesModal (type: 'assign' | 'unassign') {
    if (type === 'assign') {
      data.isModalAssignRatesOpen = true
    } else {
      data.isModalUnassignRatesOpen = true
    }
  },

  configureAllRates (type: 'assign' | 'unassign') {
    state.charger.resetCheckedChargers()
    state.charger.setCheckChargersGroup({ status: true, chargers: props.chargers })

    methods.openRatesModal(type)
  },

  async configureRatesForCharger (charger: Charger.Charger) {
    data.charger = charger
    data.isModalAssignRatesSingleChargerOpen = true
  }
}

watchEffect(() => {
  if (compute.showRates && props.chargers.length) {
    getAssignedRates(props.chargers)
  } else {
    setChargers(props.chargers)
  }
})
</script>

<style lang="postcss" scoped>
:deep(.table-wrapper) {
  position: relative !important;
  left: -18px;
  width: calc(100% + 36px);
  border: 0 !important;
  border-radius: 0 !important;
}

:deep(.head) {
  border-radius: 0 !important;
}
</style>
