<template>
  <wb-table-row :data-test-id="`${props.session.charger_id}-${props.session.start_time}`">
    <wb-table-row-item data-test-id="sessionId">
      {{ compute.sessionId }}
    </wb-table-row-item>
    <wb-table-row-item data-test-id="sessionStart">
      <div data-test-id="date" class="has-text-grey-700 is-font-weight-500">
        <span>
          {{ compute.startTimeDate }}
        </span>
      </div>
      <div data-test-id="time">
        {{ compute.startTimeHour }} - {{ compute.endTimeHour }}
      </div>
    </wb-table-row-item>
    <wb-table-row-item>
      <div class="is-flex has-align-items">
        <wb-user-avatar
          class="mr-8 avatar"
          data-test-id="userPicture"
          size="small"
          :src="compute.user.avatar"
          :initials="compute.user.initials"
        />
        <div class="has-text-overflow">
          <p class="has-text-grey-700 is-font-weight-500" data-test-id="userName">
            {{ compute.user.name }}
          </p>
          <p data-test-id="userEmail">
            {{ compute.user.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>
    <wb-table-row-item v-if="props.showChargerColumn">
      <div>
        <wb-link
          type="text"
          :to="{ name: 'charger', params: { chargerId: props.session.charger_id.toString() }}"
          class="has-text-grey-700 is-font-weight-500"
          data-test-id="charger"
        >
          {{ props.session.charger_name }}
        </wb-link>
        <div class="has-text-overflow" data-test-id="location">
          <span>
            {{ props.session.location_name }}
          </span>
        </div>
      </div>
    </wb-table-row-item>
    <wb-table-row-item>
      <div class="energy-field">
        <span data-test-id="sessionEnergy">
          <span class="wb-icons data-row-icon">
            bolt
          </span>
          <span>
            {{ compute.sessionEnergy }}
          </span>
        </span>
        <span
          v-if="compute.midInformation"
          class="wb-icons mid-icon ml-8"
          :data-test-id="`chargerMid-${compute.midInformation}`"
        >
          {{ compute.midInformation !== MID_STATUS.ERROR ? 'mid': 'mid_error' }}
        </span>
      </div>
      <div data-test-id="timeDuration">
        <span class="wb-icons data-row-icon">
          timelapse
        </span>
        <span>
          {{ compute.timeDuration }}
        </span>
      </div>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="sessionType">
      <div class="types">
        <wb-label
          v-if="props.session.session_type !== 'free'"
          disabled
          is-active
          color="grey"
          class="label"
        >
          {{ compute.rateVariableTypeParsed }}
        </wb-label>
        <wb-label
          disabled
          is-active
          :color="compute.typeParsed?.color"
          class="label"
        >
          {{ compute.typeParsed?.text }}
        </wb-label>
      </div>
    </wb-table-row-item>
    <wb-table-row-item>
      <div data-test-id="energyCost">
        {{ compute.costParsed }}
      </div>
    </wb-table-row-item>
    <wb-table-row-item v-if="permissions.showPayments">
      <div>
        <div class="has-text-grey-700 is-font-weight-500" data-test-id="amoutPaid">
          {{ compute.totalParsed }}
        </div>
        <wb-popover
          v-if="props.session.session_type !== 'free'"
          menu-position="bottom-end"
          :distance-offset="16"
          @click="methods.trackPayments"
        >
          <template #activator>
            <wb-link class="link" data-test-id="paymentDetailsBtn">
              {{ i18n.t('mywb.common.details') }}
            </wb-link>
          </template>
          <template #container>
            <div class="popover" data-test-id="paymentDetails">
              <wb-level class="mb-8">
                <template #level-left>
                  <p
                    v-t="{ path:'mywb.session.user-has-paid', args: [compute.user.name] }"
                    class="is-size-300 is-font-weight-500 mr-24"
                  />
                </template>
                <template #level-right>
                  <p class="is-size-400 is-font-weight-500" data-test-id="total">
                    {{ compute.totalParsed }}
                  </p>
                </template>
              </wb-level>
              <wb-level>
                <template #level-left>
                  <p
                    v-t="{ path:'mywb.common.subtotal-amount' }"
                    data-test-id="subtotal"
                    class="is-size-300 has-text-grey-500"
                  />
                </template>
                <template #level-right>
                  <p class="is-size-300 has-text-grey-500" data-test-id="subtotal">
                    {{ compute.subtotalParsed }}
                  </p>
                </template>
              </wb-level>
              <wb-level>
                <template #level-left>
                  <p
                    data-test-id="vat"
                    class="is-size-300 has-text-grey-500"
                  >
                    {{ i18n.t('mywb.common.vat') }} ({{ compute.vatPercentage }})
                  </p>
                </template>
                <template #level-right>
                  <p class="is-size-300 has-text-grey-500" data-test-id="tax">
                    {{ compute.taxAmountParsed }}
                  </p>
                </template>
              </wb-level>
              <wb-line class="my-12" />
              <wb-level>
                <template #level-left>
                  <p class="is-size-300 has-text-grey-500 mr-24">
                    <span
                      v-t="'mywb.common.wallbox-fee'"
                      class="pr-8"
                    />
                    <span>
                      {{ compute.feePercentage }}
                    </span>
                  </p>
                </template>
                <template #level-right>
                  <p class="is-size-300 has-text-grey-500" data-test-id="totalFee">
                    {{ compute.feeParsed }}
                  </p>
                </template>
              </wb-level>
              <wb-line class="my-12" />
              <wb-level>
                <template #level-left>
                  <p
                    v-t="'mywb.session.total-you-recibe'"
                    class="is-size-300 is-font-weight-500 mr-24"
                  />
                </template>
                <template #level-right>
                  <p class="is-size-500 is-font-weight-500" data-test-id="totalReceived">
                    {{ compute.amountParsed }}
                  </p>
                </template>
              </wb-level>
            </div>
          </template>
        </wb-popover>
      </div>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { time, dates, numbers } from '@wallbox/toolkit-ui'
import { getSessionEnergy } from '@/utilities/energy'
import { getMidSession, MID_STATUS } from '@/utilities/charger/midInformation'
import { permissions } from '@/engine/clients'
import { userInitials } from '@/utilities/users'
import { trackAction } from '@/engine/metrics/metricsManager'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { Session } from '@/types'

const i18n = useI18n()

const props = defineProps<{
  session: Session.Session
  showChargerColumn?: boolean
}>()

const compute = reactive({
  user: computed(() => {
    return props.session.user_id === 1
      ? {
          initials: 'A',
          name: i18n.t('mywb.common.anonymous')
        }
      : {
          initials: userInitials({ name: props.session.user_name, surname: props.session.user_surname }),
          name: [props.session.user_name, props.session.user_surname].filter(Boolean).join(' '),
          email: props.session.user_email,
          avatar: props.session.user_avatar
        }
  }),

  startTimeDate: computed(() => {
    return dates.toDate((props.session.start_time), i18n.locale.value)
  }),

  startTimeHour: computed(() => {
    return dates.toHoursAndMinutes((props.session.start_time), i18n.locale.value)
  }),

  endTimeHour: computed(() => {
    return dates.toHoursAndMinutes((props.session.end_time), i18n.locale.value)
  }),

  timeDuration: computed(() => {
    return time.getTimeDurationString(props.session.charging_time)
  }),

  costParsed: computed(() => {
    return props.session.total_cost
      ? numbers.toLocaleCurrencySymbol(
        props.session.total_cost,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  amountParsed: computed(():string => {
    return props.session.total
      ? numbers.toLocaleCurrencySymbol(
        props.session.total - compute.variableFee,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  taxAmountParsed: computed(() => {
    return props.session.tax_amount
      ? numbers.toLocaleCurrencySymbol(
        props.session.tax_amount,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  subtotalParsed: computed(() => {
    return props.session.subtotal
      ? numbers.toLocaleCurrencySymbol(
        props.session.subtotal,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  totalParsed: computed(() => {
    return props.session.total
      ? numbers.toLocaleCurrencySymbol(
        props.session.total,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  variableFee: computed(() => {
    return (props.session.total) * props.session.application_fee_percentage / 100
  }),

  feeParsed: computed(():string => {
    return props.session.application_fee_percentage
      ? numbers.toLocaleCurrencySymbol(
        -1 * compute.variableFee,
        props.session.currency_code,
        i18n.locale.value)
      : '-'
  }),

  feePercentage: computed(() => {
    return props.session.application_fee_percentage
      ? numbers.toLocalePercent(
        props.session.application_fee_percentage,
        i18n.locale.value)
      : '-'
  }),

  vatPercentage: computed(() => {
    return props.session.tax_percentage
      ? numbers.toLocalePercent(
        props.session.tax_percentage * 100,
        i18n.locale.value,
        3)
      : '-'
  }),

  sessionId: computed(() => {
    return `#S${props.session.id}`
  }),

  sessionEnergy: computed(() => {
    return getSessionEnergy(props.session, i18n.locale.value)
  }),

  midInformation: computed(() => {
    return getMidSession(props.session)
  }),

  typeParsed: computed(() => {
    const obj = {
      free: {
        text: i18n.t('mywb.common.free'),
        color: 'primary'
      },

      pay_per_month: {
        text: i18n.t('mywb.charger.pay-per-month'),
        color: 'info'
      },

      pay_per_charge: {
        text: i18n.t('mywb.charger.pay-per-charge'),
        color: 'info'
      }
    } as const

    return obj[props.session.session_type]
  }),

  rateVariableTypeParsed: computed(() => {
    if (props.session.rate_price === 0 && props.session.access_price > 0) {
      return i18n.t('mywb.common.without-fee-variable')
    } else if (props.session.rate_variable_type === Session.RateVariableTypeEnum.TIME) {
      return i18n.t('mywb.common.charging-time.fee')
    } else if (props.session.rate_variable_type === Session.RateVariableTypeEnum.ENERGY) {
      return i18n.t('mywb.common.consumed-energy.fee')
    } else if (props.session.rate_variable_type === Session.RateVariableTypeEnum.RESERVATION_TIME) {
      return i18n.t('mywb.common.reserved-time-fee')
    } else {
      return ''
    }
  })
})

const methods = {
  trackPayments () {
    trackAction('view_payment')
  }
}
</script>

<style lang="postcss" scoped>
.data-row-icon {
  font-size: 12px !important;
  color: var(--grey-400);
  margin-right: 4px;
  position: relative;
  top: 3px;
}

.link {
  display: flex;
  align-items: center;
}

.popover {
  padding: 32px;
  min-width: 300px;

  &::after {
    bottom: 100%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--white);
    border-width: 15px;
    right: 0;
    filter: drop-shadow(0 -5px 4px rgb(207 207 207 / 70%));
  }
}

.has-align-items {
  align-items: center;
}

.avatar {
  flex-shrink: 0;
}

.types {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.types .label {
  display: inline-block;
  line-height: 1;

  & + .label {
    margin-top: 0.5rem;
  }
}

.energy-field {
  display: inline-flex;

  & .mid-icon {
    height: 20px;
  }
}
</style>
