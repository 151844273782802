<template>
  <wb-table
    :options="data.options"
    :loading="props.loading"
    data-test-id="invoicesSection"
    class="table"
    @on-pagination-change="methods.handlePaginationChange"
  >
    <wb-table-row
      v-for="invoice in compute.invoicesParsed"
      :key="invoice.id"
    >
      <wb-table-row-item data-test-id="invoiceDate">
        {{ methods.getDate(invoice.issue_date) }}
      </wb-table-row-item>
      <wb-table-row-item data-test-id="invoiceStatus">
        <wb-label
          v-if="invoice.statusLabel"
          is-active
          disabled
          :color="invoice.statusColor"
          icon="lens_filled"
        >
          {{ invoice.statusLabel }}
        </wb-label>
      </wb-table-row-item>
      <wb-table-row-item data-test-id="invoiceType">
        {{ invoice.typeTranslated }}
      </wb-table-row-item>
      <wb-table-row-item data-test-id="invoiceUser">
        {{ invoice.userName }}
      </wb-table-row-item>
      <wb-table-row-item data-test-id="invoiceTotalAmount">
        {{ invoice.totalLocalized }}
      </wb-table-row-item>
      <wb-table-row-item data-test-id="invoiceView" @click.stop>
        <wb-button-groups>
          <wb-popover
            v-for="(option, key) in methods.getInvoiceOptions(invoice)"
            :key="key"
            class="group-item"
          >
            <template #activator>
              <wb-button
                :id="option.id"
                type="white"
                outlined
                size="small"
                :icon="option.icon"
                :data-test-id="option.dataTestId"
                @click="methods.handleClick(invoice, option.id)"
              />
            </template>
            <template #tooltip>
              {{ option.label }}
            </template>
          </wb-popover>
        </wb-button-groups>
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { dates, numbers, type TableProps } from '@wallbox/toolkit-ui'
import { permissions } from '@/engine/clients'
import type { Invoice } from '@/types'

const i18n = useI18n()

interface Events {
  (e: 'on-mark-as-paid', invoice: Invoice.Invoice): void,
  (e: 'on-pagination-change', offset: number): void
}
const emit = defineEmits<Events>()

interface Props {
  invoices: Invoice.Invoice[],
  loading?: boolean,
  pagination?: {
    offset: number,
    total: number,
    limit: number
  }
}
const props = defineProps<Props>()

const data = reactive({
  options: {
    offset: '0 9%',
    stickyHead: 'var(--header-height)',
    pagination: props.pagination,
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    },
    columns: [
      { name: i18n.t('mywb.common.date'), align: 'left', minWidth: '100px', width: 'auto' },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: '100px', width: 'auto' },
      {
        name: i18n.t('mywb.common.type'),
        align: 'left',
        minWidth: '150px',
        width: 'auto',
        lineBehaviour: 'multiline'
      },
      {
        name: i18n.t('mywb.common.user'),
        align: 'left',
        minWidth: '150px',
        width: 'auto',
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.common.total-amount'), align: 'left', minWidth: '150px', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: '300px', width: 'auto' }
    ] as TableProps['options']['columns']
  }
})

const compute = reactive({
  invoicesParsed: computed(() => {
    return props.invoices.map(invoice => ({
      ...invoice,
      userName: invoice.customer_data?.name ?? '',
      ...methods.getStatusInvoice(invoice.status),
      typeTranslated: invoice.type === 'post-payment'
        ? i18n.t('mywb.charger.pay-per-month')
        : i18n.t('mywb.charger.pay-per-charge'),
      totalLocalized: numbers.toLocaleCurrencySymbol(
        invoice.total / 100,
        invoice.currency_code,
        i18n.locale.value)
    }))
  })
})

const methods = {
  getStatusInvoice (status: Invoice.InvoiceStatus) {
    switch (status) {
      case ('open'): return { statusColor: 'warning', statusLabel: i18n.t('mywb.common.pending') } as const
      case ('paid'): return { statusColor: 'success', statusLabel: i18n.t('mywb.payments.paid') }as const
      case ('void'): return { statusColor: 'success', statusLabel: i18n.t('mywb.payments.paid') } as const
      case ('pending'): return { statusColor: 'warning', statusLabel: i18n.t('mywb.common.pending') } as const
      case ('uncollectible'): return { statusColor: 'warning', statusLabel: i18n.t('mywb.common.pending') } as const
      default: return { statusColor: 'warning', statusLabel: i18n.t('mywb.common.pending') } as const
    }
  },

  getDate: dates.toDate,

  getInvoiceOptions (invoice: Invoice.Invoice) {
    const options = []

    options.push({
      id: 'view-invoice',
      dataTestId: 'view-invoice',
      icon: 'visibility',
      label: i18n.t('mywb.common.view')
    } as const)

    if (permissions.canMarkAsPaid && invoice.status === 'open') {
      options.push({
        id: 'mark-as-paid-invoice',
        dataTestId: 'mark-as-paid-invoice',
        icon: 'download_done',
        label: i18n.t('mywb.common.mark-as-paid')
      } as const)
    }
    return options
  },

  handleClick (invoice: Invoice.Invoice, option: 'view-invoice' | 'mark-as-paid-invoice') {
    if (option === 'view-invoice') {
      window.open(invoice.invoice_html, '_blank', 'noopener')
    }

    if (option === 'mark-as-paid-invoice') {
      emit('on-mark-as-paid', invoice)
    }
  },

  handlePaginationChange (offset: number) {
    emit('on-pagination-change', offset)
  }
}
</script>

<style lang="postcss" scoped>
.table {
  height: 100%;
}
</style>
