import type { Support } from '../../types/data/support.data.types'

const support: Support[] = [
  {
    countryIso2: 'GB',
    lang: ['en, en-gb'],
    supportPhone: '+44 20 3885 7072',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    countryIso2: 'IE',
    lang: ['en, en-gb'],
    supportPhone: '+353 1 912 4273',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'zh',
    countryIso2: 'CN',
    supportPhone: '+86 4008808106',
    mail: 'service@wallbox-fawsn.com',
    from: '上午 8:00',
    to: '晚上 6:00',
    timeZone: '(+08:00)'
  },
  {
    lang: 'da',
    countryIso2: 'DK',
    supportPhone: '+45 70 89 01 17',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'nl',
    countryIso2: 'NL',
    supportPhone: '+31 20 808 0847',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'cs',
    countryIso2: 'CZ',
    supportPhone: '+41 43 508 06 75',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'fr',
    countryIso2: 'FR',
    supportPhone: '+33 1 76 46 09 15',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'ff',
    countryIso2: 'BE',
    supportPhone: '+32 2 880 86 36',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'de',
    countryIso2: 'DE',
    supportPhone: '+49 6181 7064000',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'eo',
    countryIso2: 'AT',
    supportPhone: '+43 720882116',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'is',
    countryIso2: 'IS',
    supportPhone: '+34 932 20 95 75',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'it',
    countryIso2: 'IT',
    supportPhone: '+39 02 8148 0341',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'nn',
    countryIso2: 'NO',
    supportPhone: '+47 52 99 33 38 ',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'pt',
    countryIso2: 'PT',
    supportPhone: '+351 300 600 571',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'ro',
    countryIso2: 'RO',
    supportPhone: '+34 932 20 95 75',
    mail: 'office@evconnect.ro',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: ['es', 'es-es'],
    countryIso2: 'ES',
    supportPhone: '+34 932 20 95 75',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'sv',
    countryIso2: 'SE',
    supportPhone: '+46 10 334 03 63',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '8:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'en-us',
    countryIso2: 'US',
    supportPhone: '+1-888-787-5780',
    mail: 'service.na@wallbox.com',
    from: '6:00 AM',
    to: '8:00 PM',
    timeZone: '(Pacific Time)',
    timeService: 'working-days'
  },
  {
    lang: 'es-mx',
    countryIso2: 'MX',
    supportPhone: '+1-888-787-5780',
    mail: 'service.na@wallbox.com',
    from: '8:00 AM',
    to: '6:00 PM',
    timeZone: '(Pacific Time)',
    timeService: 'working-days'
  },
  {
    lang: 'en-ca',
    countryIso2: 'CA',
    supportPhone: '+1-888-787-5780',
    mail: 'service.na@wallbox.com',
    from: '8:00 AM',
    to: '6:00 PM',
    timeZone: '(Pacific Time)',
    timeService: 'working-days'
  },
  {
    lang: 'fi',
    countryIso2: 'FI',
    supportPhone: '+358 2 48858273',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '6:00 PM',
    timeZone: '(+02:00)'
  },
  {
    lang: 'pl',
    countryIso2: 'PL',
    supportPhone: '+48 12 881 58 27',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '6:00 PM',
    timeZone: '(+01:00)'
  },
  {
    lang: 'de-ch',
    countryIso2: 'CH',
    supportPhone: '+41 43 508 06 75',
    mail: 'service@wallbox.com',
    from: '8:00 AM',
    to: '6:00 PM',
    timeZone: '(+02:00)'
  }
]

export default support
