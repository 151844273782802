<template>
  <wb-modal
    width="45"
    @close="emit('on-close')"
  >
    <template v-if="isCurrentState('createOrganization')" #title>
      {{ i18n.t('mywb.common.create-organization') }}
    </template>

    <template v-if="isCurrentState('createOrganization')">
      <p
        v-t="'mywb.common.create-organization-description'"
        class="is-size-300 mb-16"
      />

      <wb-form>
        <wb-input
          v-model="field.name"
          data-test-id="inputName"
          name="name"
          type="text"
          :label="i18n.t('mywb.common.name')"
          :error="errors.name"
        />

        <country-and-state-select-form
          :ref="setRef('country')"
          v-model:countryCode="field.countryCode"
          style="width: 100%;"
          country-key="iso2"
          is-state-hidden
        />

        <organization-type-select
          v-model="field.type"
          data-test-id="organizationType"
          :error="errors.type"
        />
      </wb-form>
    </template>

    <div
      v-if="isCurrentState('addChargersOrganization')"
      class="is-centered"
    >
      <client-img-component
        inline
        src="car_charging"
        class="has-margin-auto has-offset"
        width="140"
      />

      <div class="is-size-700 is-font-weight-500">
        {{ i18n.t('mywb.common.start-adding-chargers') }}
      </div>
      <div class="is-size-400">
        {{ i18n.t('mywb.common.add-chargers-into-new-organization') }}
        <div class="mt-16 is-font-weight-500">
          {{ field.name }}
        </div>
      </div>
    </div>

    <template #actions>
      <wb-button
        v-if="isCurrentState('createOrganization')"
        data-test-id="createOrganizationBtn"
        size="block"
        :loading="data.loading"
        :disabled="!field.name || !field.countryCode || !field.type"
        :label="i18n.t('mywb.common.create-organization')"
        @click="validate(methods.createOrganization)"
      />

      <wb-button
        v-if="isCurrentState('addChargersOrganization')"
        data-test-id="addChargersOrganizationBtn"
        size="block"
        :label="i18n.t('mywb.common.add-chargers')"
        @click="methods.handleRedirectToAddChargersRoute"
      />

      <wb-button
        v-if="isCurrentState('addChargersOrganization')"
        data-test-id="addChargersLaterOrganizationBtn"
        size="block"
        class="mt-16"
        :label="i18n.t('mywb.common.maybe-later')"
        type="white"
        @click="methods.handleRedirectToDefaultRoute"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import ClientImgComponent from '@/components/ClientImgComponent.vue'

import state from '@/state'
import api from '@/api'
import { reactive } from 'vue'
import { useStateMachine } from '@/hooks'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useNotify, promises, useTemplateRef } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'
import type { Organizations } from '@/types'
import { useValidator } from '@/hooks/useValidator.hook'
import OrganizationTypeSelect from '@/components/forms/OrganizationTypeSelect.vue'
import type { OrganizationTypeEnum } from '@/utilities/organizationTypes'

const notify = useNotify()
const i18n = useI18n()
const router = useRouter()
const { refs, setRef } = useTemplateRef()
const { yup, errors, defineSchema, validate } = useValidator()

interface PropsType {
  organizationsCount: number
}

const props = withDefaults(defineProps<PropsType>(), {
  organizationsCount: 0
})

const { isCurrentState, send } = useStateMachine({
  initial: 'createOrganization',
  states: {
    createOrganization: {
      transitions: {
        addChargersOrganization: 'addChargersOrganization'
      }
    },
    addChargersOrganization: {
      transitions: {
        back: 'createOrganization'
      }
    }
  }
})

interface Data {
  loading: boolean
  organizations: Organizations.Organization[]
}

const data: Data = reactive({
  loading: false,
  organizations: []
})

interface Field {
  name: string,
  countryCode: string
  type?: OrganizationTypeEnum
}

const field = reactive<Field>({
  name: '',
  countryCode: ''
})

defineSchema(field, {
  name: yup
    .string()
    .required(),

  type: yup
    .string()
    .required()
})

interface Events {
  (e: 'on-create'): void,
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const methods = {
  async createOrganization () {
    const isValid = await refs.country.isValidCountry()
    if (!isValid) return
    if (!field.type) return

    data.loading = true

    try {
      await api.organizations.postOrganization({
        name: field.name,
        type: field.type,
        country_code: field.countryCode
      })

      trackDataAction('organizations-created', {
        name: field.name,
        type: field.type
      })

      await promises.polling(async () => {
        const { data: organizations } = await api.organizations.getOrganizations()
        data.organizations = organizations
      }, {
        validate: () =>
          data.organizations.length > props.organizationsCount,
        max: 10,
        delta: 2,
        timeout: 1000
      })

      state.organizations.setOrganizations(data.organizations)
      notify.success(i18n.t('mywb.common.new-organization-created'))
      send('addChargersOrganization')
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  },

  async setGroupRelatedToOrganization () {
    const organizationSelected = data.organizations.at(data.organizations.length - 1) as Organizations.Organization
    state.organizations.setCurrentOrganization(organizationSelected.id)
    const { result: chargersGroup } = await api.groups.getDataGroup(organizationSelected.group_id)
    state.groups.set('groupRelatedToOrganization', chargersGroup)
  },

  handleRedirectToAddChargersRoute () {
    methods.setGroupRelatedToOrganization()
    router.push({ name: 'chargers', query: { 'add-chargers': 1 } })
  },

  handleRedirectToDefaultRoute () {
    methods.setGroupRelatedToOrganization()
    router.push({ name: state.organizations.defaultHomePage })
  }
}

function created () {
  trackDataScreen('organizations-create-organization')
}

created()
</script>

<style lang="postcss" scoped>
.is-centered {
  text-align: center;
}
</style>
