<template>
  <wb-notification
    v-if="compute.updatesAvailable && permissions.canViewUpdateChargersPending && permissions.hasBulkActions"
    icon="send_to_charger"
    type="info"
    :action="t('mywb.common.update')"
    class="mb-12"
    @on-action-click="methods.openModal"
  >
    <p>
      {{
        t('mywb.charger.num-chargers-with-update-pending', {
          num: compute.updatesAvailable
        })
      }}
    </p>
  </wb-notification>

  <chargers-remote-actions-modal
    v-if="data.isModalRemoteActionsOpen"
    :action="REMOTE_ACTIONS.UPDATE"
    :group="state.groups.groupRelatedToOrganizationWithChargers"
    @close="data.isModalRemoteActionsOpen = false"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { reactive, computed } from 'vue'
import state from '@/state'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { permissions } from '@/engine/clients'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import ChargersRemoteActionsModal from '@/components/modals/ChargersRemoteActionsModal.vue'

const { t } = useI18n()

const data = reactive({
  isModalRemoteActionsOpen: false
})

const compute = reactive({
  updatesAvailable: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.filter(charger => isUpdatesAvailable(charger, charger.software)).length
    })

    return num
  })
})

const methods = {
  openModal () {
    trackDataEvent('dashboard-updates', { widget: 'chargerStatus', type: 'updateAll' })
    data.isModalRemoteActionsOpen = true
  }
}
</script>
