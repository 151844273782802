import { computed, reactive } from 'vue'
import { toTwoDecimalNumber, getNumberPart } from '@/utilities/currency'

export const usePriceValueProxy = (inputValue?: number) => {
  interface Data {
    inputValue?: number
    stringValue?: string
    onModelChange?: ({ parsedValue, rawInput }: { parsedValue?: number, rawInput: string }) => void
  }

  const data: Data = reactive({
    inputValue
  })

  const computedPrice = computed<string | undefined>({
    get () {
      return String(data.inputValue ?? '').replaceAll('.', getNumberPart('decimal'))
    },
    set (value) {
      const numberCleaned = toTwoDecimalNumber(value?.toString?.() ?? '')

      data.inputValue = !isNaN(parseFloat(numberCleaned)) ? parseFloat(numberCleaned) : undefined
      data.stringValue = numberCleaned.replaceAll('.', getNumberPart('decimal'))

      data?.onModelChange?.({
        parsedValue: data.inputValue,
        rawInput: data.stringValue
      })
    }
  })

  function onModelChange (fn: Data['onModelChange'] = () => {}) {
    data.onModelChange = fn
  }

  return {
    onModelChange,
    computedPrice
  }
}
