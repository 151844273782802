<template>
  <wb-modal
    width="80"
    @close="methods.close"
  >
    <template #title>
      {{ i18n.t('mywb.common.payments-settings') }}
    </template>

    <charger-settings-assign-rate
      class="mb-24"
      :charger="props.charger"
      :rates="data.rates"
      :billing-country-iso2="data.billingCountryIso2"
      :loading="data.loading || data.loadingAssignedRates"
      data-test-id="chargerSettingsAssignRatePayPerCharge"
      type="payPerCharge"
      :compatible="compute.enablePayPerCharge"
      :allow-hourly-selection="false"
    />

    <charger-settings-assign-rate
      :charger="props.charger"
      :rates="data.rates"
      :billing-country-iso2="data.billingCountryIso2"
      :loading="data.loading || data.loadingAssignedRates"
      :compatible="compute.enablePayPerMonth"
      data-test-id="chargerSettingsAssignRatePayPerMonth"
      type="payPerMonth"
      :allow-hourly-selection="false"
    />

    <template #actions>
      <wb-button
        data-test-id="locationAssignRateChargerBtn"
        :label="i18n.t('mywb.common.done')"
        :loading="data.saveLoading"
        @click="methods.close"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargerSettingsAssignRate
  from '@/components/charger/ChargerSettingsAssignRate.vue'
import api from '@/api'
import state from '@/state'
import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'
import { isPaymentsCompatible } from '@/utilities/charger/chargerCompatible'
import type { Charger, Currency, Locations, Rate } from '@/types'

const i18n = useI18n()

const emit = defineEmits(['close', 'rates-assigned'])

interface Props {
  charger: Charger.Charger | Charger.ChargerWithRates
  location?: Locations.Location
}
const props = defineProps<Props>()

const {
  getData: getAssignedRates,
  loading: loadingAssignedRates
} = useAssignedRatesApi()

interface Data {
  rates: Rate.Rate[],
  loadingAssignedRates: typeof loadingAssignedRates
  loading: boolean
  billingCountryIso2?: Currency.CountryCodes
  saveLoading: boolean
}
const data = reactive<Data>({
  rates: [],
  loadingAssignedRates,
  loading: false,
  billingCountryIso2: undefined,
  saveLoading: false
})

const methods = {
  close () {
    emit('close')
  }
}

const compute = reactive({
  enablePayPerCharge: computed(() => {
    return (
      isPaymentsCompatible(props.charger, 'pay_per_charge', props.location)
    )
  }),

  enablePayPerMonth: computed(() => {
    return (
      isPaymentsCompatible(props.charger, 'pay_per_month', props.location)
    )
  })
})

async function created () {
  data.loading = true

  const { data: rates } = await api.rates.getRates({
    groupId: state.groups.groupRelatedToOrganization.id
  })
  data.rates = rates

  const { result: billingInfo } = await api.billing.getBillingInfo(state.groups.groupRelatedToOrganization.id)
  data.billingCountryIso2 = billingInfo.country.iso2

  data.loading = false

  getAssignedRates([props.charger])
}

created()
</script>
