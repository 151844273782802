import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import { formatResponseJsonApi } from './config/jsonApi.config'
import { ulid } from 'ulid'
import type { RateApi, Rate } from '@/types'

export default {
  async getRates ({ groupId }: { groupId: number }): Promise<RateApi.GetRates> {
    return await axios.get<unknown, RateApi.GetRates>(
      endpoints.v4.group_rates.replace('{groupId}', groupId.toString())
    ).then(item => formatResponseJsonApi(item))
  },

  async createRate ({ groupId, rate }:
  { groupId: number, rate: Omit<Rate.Rate, 'id' | 'currency_code' | 'type'> }): Promise<string> {
    const id = ulid()

    return await axios.post(
      endpoints.v4.group_rates.replace('{groupId}', groupId.toString()),
      {
        data: {
          id,
          type: 'rate',
          attributes: rate
        }
      }
    ).then(() => id)
  },

  async editRate ({ groupId, rateId, rate }: {
    groupId: number
    rateId: string
    rate: Omit<Rate.Rate, 'id' | 'currency_code' | 'type'>
  }): Promise<void> {
    return await axios.patch(
      endpoints.v4.group_rate
        .replace('{groupId}', groupId.toString())
        .replace('{rateId}', rateId.toString()),
      {
        data: {
          type: 'rate',
          attributes: rate
        }
      }
    )
  },

  async getRate ({ groupId, rateId }: { groupId: number, rateId: string }): Promise<Rate.Rate> {
    return await axios.get(
      endpoints.v4.group_rate
        .replace('{groupId}', groupId.toString())
        .replace('{rateId}', rateId.toString())
    ).then(item => ({ id: item.data.id, ...item.data.attributes }))
  },

  async deleteRate ({ groupId, rateId }: { groupId: number, rateId: string }): Promise<void> {
    return await axios.delete(
      endpoints.v4.group_rate
        .replace('{groupId}', groupId.toString())
        .replace('{rateId}', rateId.toString())
    )
  },

  async getChargerRatesWeekSchedules (chargerUid: string) {
    return await axios.get<unknown, RateApi.GetChargerRatesWeekSchedules>(
      endpoints.v4.chargers_charger_rate_schedules
        .replace('{chargerUid}', chargerUid.toString())
    ).then(formatResponseJsonApi)
  },

  async saveChargerSchedule ({ chargerId, scheduleId = ulid(), payload }:
  { chargerId: string, scheduleId?: string, payload: any }) {
    return await axios.put(
      endpoints.v4.chargers_charger_rate_schedule_id
        .replace('{chargerUid}', chargerId)
        .replace('{scheduleId}', scheduleId),
      {
        data: {
          type: 'charger_rate_schedule',
          attributes: payload
        }
      })
  },

  async getIsRateAssigned (groupUid: string, rateUid: string): Promise<RateApi.GetIsRateAssignedResponse> {
    return await axios
      .get<unknown, RateApi.GetIsRateAssignedResponse['result']>(
      endpoints.v4.organizations_assigned_rates
        .replace('{groupUid}', groupUid)
        .replace('{rateUid}', rateUid)
    ).then(result => ({
      rate: rateUid,
      result
    }))
  }
}
