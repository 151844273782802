<template>
  <wb-table-row-item>
    <wb-popover v-if="compute.canDoAnyAction" menu-position="bottom-end" :distance-offset="16">
      <template #activator>
        <wb-button
          icon="more_horiz"
          type="white"
          outlined
          data-test-id="chargerActionMenu"
          size="small"
        />
      </template>
      <template #container="{ close }">
        <wb-button
          v-if="compute.showPaymentsConfiguration"
          data-test-id="chargerActionMenuPaymentsConfiguration"
          type="white"
          icon="card"
          size="block"
          content-position="left"
          unrounded
          :label="i18n.t('mywb.common.payments-settings')"
          @mousedown="methods.handlePaymentsSettings(close)"
        />

        <wb-button
          v-if="permissions.canSeeLocations"
          data-test-id="chargerActionMenuChangeLocation"
          type="white"
          icon="change_location"
          size="block"
          content-position="left"
          unrounded
          :label="i18n.t('mywb.common.change-location')"
          @mousedown="methods.handleChangeLocation(close)"
        />

        <wb-button
          v-if="compute.hasPermission && compute.softwareUpdate"
          type="white"
          icon="send_to_charger"
          size="block"
          content-position="left"
          unrounded
          :label="i18n.t('mywb.common.update')"
          @mousedown="methods.remoteAction(REMOTE_ACTIONS.UPDATE, close)"
        />

        <wb-button
          v-if="compute.hasPermission"
          type="white"
          icon="refresh"
          size="block"
          content-position="left"
          unrounded
          :label="i18n.t('mywb.common.restart')"
          @mousedown="methods.remoteAction(REMOTE_ACTIONS.RESTART, close)"
        />

        <wb-button
          v-if="permissions.canChargerItemClickeable"
          type="danger"
          data-test-id="unlinkCharger"
          icon="delete"
          size="block"
          content-position="left"
          unrounded
          inverted
          :label="i18n.t('mywb.common.delete')"
          @mousedown="methods.handleRemoveClick(close)"
        />
      </template>
    </wb-popover>
  </wb-table-row-item>

  <location-assign-rate-charger-modal
    v-if="data.isModalAssignRatesSingleChargerOpen && props.charger"
    data-test-id="chargersAssignRatesSingleChargerModal"
    :charger="props.charger"
    :location="props.location"
    @close="data.isModalAssignRatesSingleChargerOpen = false"
    @rates-assigned="emit('rates-changed')"
  />

  <location-change-location-modal
    v-if="data.isModalChangeLocation && props.charger"
    :group-uid="compute.groupUid"
    :charger="props.charger"
    @close="data.isModalChangeLocation = false"
  />

  <shared-confirmation-modal
    v-if="data.isModalChargersUnlinkOpen"
    :title="i18n.t('mywb.common.delete-confirmation', {element: props.charger?.name})"
    :label-confirmation-button="i18n.t('mywb.common.delete')"
    type="danger"
    @on-confirm="methods.unlinkCharger"
    @on-close="data.isModalChargersUnlinkOpen = false"
  />

  <chargers-remote-actions-modal
    v-if="data.isModalRemoteActionsOpen && props.charger"
    :charger="props.charger"
    :software="props.charger.software"
    :action="data.actionSelected"
    @close="data.isModalRemoteActionsOpen = false"
  />
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { isPaymentsCompatible } from '@/utilities/charger/chargerCompatible'

import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'

import state from '@/state'
import api from '@/api'
import { useNotify } from '@wallbox/toolkit-ui'

import LocationAssignRateChargerModal from '@/components/modals/LocationAssignRateChargerModal.vue'
import LocationChangeLocationModal from '@/components/modals/LocationChangeLocationModal.vue'
import ChargersRemoteActionsModal from '@/components/modals/ChargersRemoteActionsModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { Charger, Locations } from '@/types'

const i18n = useI18n()
const notify = useNotify()

interface Events {
  (e: 'rates-changed'): void
}
const emit = defineEmits<Events>()

interface Props {
  charger?: Charger.Charger | Charger.ChargerWithRates
  groupId: number,
  location?: Locations.Location
}
const props = defineProps<Props>()

interface Data {
  isModalAssignRatesSingleChargerOpen: boolean
  isModalChangeLocation: boolean
  isModalChargersUnlinkOpen: boolean
  isModalRemoteActionsOpen: boolean
  actionSelected: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
}

const data = reactive<Data>({
  isModalAssignRatesSingleChargerOpen: false,
  isModalChangeLocation: false,
  isModalChargersUnlinkOpen: false,
  isModalRemoteActionsOpen: false,
  actionSelected: REMOTE_ACTIONS.UPDATE
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.payments_account),

  showRates: computed((): boolean => permissions.showPayments && compute.hasOrganizationPaymentsAccount),

  showPaymentsConfiguration: computed((): boolean => {
    return (
      compute.showRates &&
      !!props.charger && (isPaymentsCompatible(props.charger, 'pay_per_charge', props.location).isCompatible ||
      isPaymentsCompatible(props.charger, 'pay_per_month', props.location).isCompatible)
    )
  }),

  hasPermission: computed((): boolean => {
    return !!props.charger?.id &&
      permissions.hasAuthToRestartAndUpdate &&
      (getChargerStatus(props.charger).code !== STATUSES.DISCONNECTED ||
      getChargerStatus(props.charger).code !== STATUSES.CHARGING ||
      getChargerStatus(props.charger).code !== STATUSES.DISCHARGING)
  }),

  softwareUpdate: computed(() => {
    return !!props.charger?.id && isUpdatesAvailable(props.charger, props.charger.software)
  }),

  canDoAnyAction: computed((): boolean =>
    compute.showPaymentsConfiguration ||
    compute.hasPermission ||
    permissions.canSeeLocations ||
    permissions.canChargerItemClickeable
  ),
  groupUid: computed(() => state.groups.locations.find(group => group.id === props.groupId)?.uid)
})

const methods = {
  handlePaymentsSettings (close: () => void) {
    close()
    data.isModalAssignRatesSingleChargerOpen = true
  },

  handleChangeLocation (close: () => void) {
    close()
    data.isModalChangeLocation = true
  },

  handleRemoveClick (close: () => void) {
    close()
    trackDataAction('unlink-charger', { charger_id: props.charger?.id.toString() })
    data.isModalChargersUnlinkOpen = true
  },

  remoteAction (action: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART, close: () => void) {
    close()
    data.actionSelected = action
    data.isModalRemoteActionsOpen = true
  },

  async unlinkCharger () {
    if (!props.charger) return
    try {
      state.groups.deleteCharger({ groupId: props.groupId, chargerId: props.charger.id })
      notify.success(i18n.t('mywb.common.charger-removed-successfully'))
      await api.chargers.unlinkCharger(props.charger.id)
    } catch (e) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
      throw e
    }
  }
}
</script>
