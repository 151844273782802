<template>
  <div class="is-grid g-16 mb-16">
    <chart-report
      v-for="(category, key) in data.categories"
      :key="key"
      :title="category.title"
      :subtitle="category.subtitle"
      :icon="category.icon"
      :categories="[category.type]"
      :data-test-id="category.dataTestId"
      :visualization="VisualizationEnum.LINE"
      :analysis="AnalysisEnum.LINEAR"
      :labels="data.labels"
      :series="data.series"
      :dates="state.filters.dashboardFilters.dates"
      :calendar="state.filters.dashboardFilters.calendar"
      :periodicity="state.filters.dashboardFilters.periodicity"
      hide-options
      :chart-height="150"
      show-header
      :loading="data.loading"
    />

    <new-co2-savings-dashboard
      :series="data.series"
    />
  </div>

  <chart-report
    :labels="data.labels"
    :series="data.series"
    :dates="state.filters.dashboardFilters.dates"
    :calendar="state.filters.dashboardFilters.calendar"
    :periodicity="state.filters.dashboardFilters.periodicity"
    :loading="data.loading"
    :button-size="mq.current !== 'mobile' ? 'normal' : 'small'"
  />
</template>
<script setup lang="ts">
import NewCo2SavingsDashboard from '@/components/dashboard/NewCo2SavingsDashboard.vue'
import ChartReport from '@/components/charger/ChartReport.vue'
import { ChartTypesEnum, VisualizationEnum, AnalysisEnum } from '@/utilities/chartSessions/chartTypeEnums'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, watch, onMounted } from 'vue'
import state from '@/state'
import { getData } from '@/utilities/chartSessions/chart'
import type { ChartSeries } from '@/utilities/chartSessions/chart.types'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()

interface Data {
  labels: Date[]
  series?: ChartSeries
  loading: boolean
  categories: any[]

}

const data = reactive<Data>({
  labels: [],
  loading: false,
  categories: [
    {
      title: i18n.t('mywb.common.energy-supplied'),
      subtitle: i18n.t('mywb.common.energy-supplied.description'),
      icon: 'transmission_tower',
      type: ChartTypesEnum.ENERGY,
      dataTestId: 'chartEnergy'
    },
    {
      title: i18n.t('mywb.charger.charging-time'),
      subtitle: i18n.t('mywb.common.charging-time.description'),
      icon: 'clock',
      type: ChartTypesEnum.TIME,
      dataTestId: 'chartTime'
    },
    {
      title: i18n.t('mywb.common.sessions'),
      subtitle: i18n.t('mywb.common.sessions.description'),
      icon: 'charger',
      type: ChartTypesEnum.SESSIONS,
      dataTestId: 'chartSessions'
    },
    {
      title: i18n.t('mywb.common.income'),
      subtitle: i18n.t('mywb.common.income.description'),
      icon: 'card',
      type: ChartTypesEnum.INCOME,
      dataTestId: 'chartIncome'
    },
    {
      title: i18n.t('mywb.common.energy-cost'),
      subtitle: i18n.t('mywb.common.energy-cost.description'),
      icon: 'card',
      type: ChartTypesEnum.COST,
      dataTestId: 'chartCost'
    }
  ]
})

const methods = {
  async getSessionsSeries () {
    data.loading = true
    const { labels, series } = await getData(
      state.filters.dashboardFilters.periodicity,
      state.filters.dashboardFilters.calendar.interval,
      state.filters.dashboardFilters.dates,
      undefined,
      state.filters.dashboardFilters.locations
    )

    data.labels = labels
    data.series = series
    data.loading = false

    getData(
      state.filters.dashboardFilters.periodicity,
      state.filters.dashboardFilters.calendar.interval,
      state.filters.dashboardFilters.dates,
      undefined,
      state.filters.dashboardFilters.locations
    ).then(({ labels, series }) => {
      data.labels = labels
      data.series = series
    })
  }
}

watch(async () => [
  state.filters.dashboardFilters.periodicity,
  state.filters.dashboardFilters.calendar.interval,
  state.filters.dashboardFilters.dates,
  state.filters.dashboardFilters.locations], async () => {
  await methods.getSessionsSeries()
})

onMounted(async () => {
  await methods.getSessionsSeries()
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (--desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
