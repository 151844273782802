<template>
  <wb-modal
    width="70"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.add-chargers') }}
    </template>

    <i18n-t
      tag="p"
      class="is-size-400 mb-24"
      keypath="mywb.location.select-existing-chargers"
    >
      <wb-link
        :to="{ name: 'chargers', query: { 'add-chargers': '1', 'location-id': props.group.id.toString() } }"
        @click="methods.trackAddCharger"
      >
        {{ i18n.t("mywb.location.add-new-charger") }}
      </wb-link>
    </i18n-t>

    <locations-accordion
      v-model="data.chargers"
      data-test-id="sharedLocationChargerTree"
      :filter-location="props.group.id"
    />

    <template #actions>
      <wb-button
        data-test-id="acceptBtnModal"
        :label="i18n.t('mywb.common.add-chargers')"
        size="block"
        :loading="data.loading"
        :disabled="!data.chargers.length"
        @click="methods.updateChargers"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import api from '@/api'
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Group } from '@/types'

const i18n = useI18n()

interface Props {
  group: Group.Group
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-close'): void
}

const emit = defineEmits<Events>()

interface Data {
  loading: boolean
  chargers: number[]
}
const data = reactive<Data>({
  loading: false,
  chargers: []
})

const methods = {
  trackAddCharger () {
    trackDataAction('add-charger', { location_id: props.group.uid })
  },

  async updateChargers () {
    data.loading = true

    await Promise.all(data.chargers.map(charger => {
      return api.chargers.updateChargerLocation({
        chargerId: charger,
        config: { group: props.group.id }
      })
    }))

    const { result: { groups } = {} } = await api.groups.getAllDataOrganizations()
    state.groups.set('groups', groups)

    data.loading = false
    emit('on-close')
  }
}
</script>
