import countries from 'i18n-iso-countries'
import { computed, reactive, toRaw } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import i18n from '@/engine/lang'
import type { Currency, Auth } from '@/types'

const WB_STATE_VERSION = 5

export interface GlobalState {
  version: typeof WB_STATE_VERSION
  timezones: string[]
  currencies: Currency.Currency[]
  countries: Currency.Country[]
  auth: Auth.Auth
  session: {
    start: number
    end: number
  }

  organizationsWithModalViewed: Set<string>
}

const initialState = (): GlobalState => ({
  version: WB_STATE_VERSION,
  timezones: [],
  currencies: [],
  countries: [],
  auth: {
    token: '',
    user_id: '-1',
    ttl: -1,
    refresh_token: '',
    refresh_token_ttl: -1
  },

  session: {
    start: 0,
    end: 0
  },

  organizationsWithModalViewed: new Set()
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<GlobalState>) {
    return objects.getProperty(state, path)
  },

  getCurrencyById: (currencyId: Currency.Currency['id']) =>
    state.currencies.find(currency => currency.id === currencyId),

  getCurrency: (code: Currency.Currency['code']) => code && state.currencies.find(currency => currency.code === code),

  getWbState: () => {
    const state = localStorage.getItem('wb-state')

    return state ? JSON.parse(state) : initialState()
  },

  getAuth: computed(() => state.auth),

  getTimezones: computed(() => state.timezones),

  getCurrencies: computed(() => state.currencies),

  getCountries: computed(() => {
    return state.countries.map(country => ({
      ...country,
      name: countries.getName(country.code, i18n.__rootLanguage, { select: 'official' }) ?? country.name
    })).sort((a, b) => a.name.localeCompare(b.name))
  }),

  getSessionDurationInMinutes: computed(() => (state.session.start - state.session.end) / 1000 / 60)
}

const setters = {
  set (path: Paths<GlobalState>, value: unknown) {
    objects.setProperty(state, path, value)
  },

  setTimezones (timezones: string[]) {
    state.timezones = timezones
    setters.setWbState('timezones', state.timezones)
  },

  setCurrencies (currencies: Currency.Currency[]) {
    state.currencies = currencies
    setters.setWbState('currencies', state.currencies)
  },

  setCountries (countries: Currency.Country[]) {
    state.countries = countries
    setters.setWbState('countries', state.countries)
  },

  setAuth (auth: Auth.Auth) {
    state.auth = auth

    setters.setWbState('auth', state.auth)
  },

  setSession (type: keyof GlobalState['session'] = 'start') {
    state.session[type] = new Date().getTime()
    setters.setWbState('session', state.session)
  },

  setAllSession (session = initialState().session) {
    state.session = session

    setters.setWbState('session', state.session)
  },

  setOrganizationsWithModalViewed (organizationsWithModalViewed = initialState().organizationsWithModalViewed) {
    state.organizationsWithModalViewed = organizationsWithModalViewed

    setters.setWbState('organizationsWithModalViewed', Array.from(state.organizationsWithModalViewed))
  },

  setWbState<S extends keyof GlobalState>(key: S, value: any) {
    const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}') ?? initialState()
    wbState[key] = value
    localStorage.setItem('wb-state', JSON.stringify(wbState))
  },

  hasViewedModal (id: string) {
    return state.organizationsWithModalViewed.has(id)
  },

  removeLocalStorageSensitiveInfo () {
    localStorage.removeItem('wb-state')

    const {
      timezones,
      currencies,
      countries,
      organizationsWithModalViewed
    } = toRaw(state)

    state = reactive(initialState())

    this.setTimezones(timezones)
    this.setCurrencies(currencies)
    this.setCountries(countries)
    this.setOrganizationsWithModalViewed(organizationsWithModalViewed)
  },

  setOrganizationTypeModalViewed (id: string) {
    state.organizationsWithModalViewed.add(id)
    this.setOrganizationsWithModalViewed(state.organizationsWithModalViewed)
  }
}

export function loadState () {
  const wbState = getters.getWbState()

  if (wbState && wbState.version === WB_STATE_VERSION) {
    setters.setCountries(wbState.countries ?? [])
    setters.setCurrencies(wbState.currencies ?? [])
    setters.setTimezones(wbState.timezones ?? [])
    setters.setAuth(wbState?.auth ?? {})
    setters.setAllSession(wbState.session)
    setters.setOrganizationsWithModalViewed(new Set(wbState.organizationsWithModalViewed ?? []))
  }

  setters.setWbState('version', WB_STATE_VERSION)
}

export default reactive({
  ...getters,
  ...setters
})
