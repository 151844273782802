<template>
  <shared-header>
    <template v-if="data.user" #title>
      {{ `${data.user.name} ${data.user.surname}` }}
    </template>
  </shared-header>

  <wb-tabs
    v-model="data.tabSelected"
    :tabs="compute.sections"
    @tab-change="(option: any) => router.push({ name: option.key })"
  />

  <router-view v-slot="{ Component }">
    <container-component>
      <wb-cards-loader :loading="data.loading">
        <component
          :is="Component"
          :user-id="props.id"
        />
      </wb-cards-loader>
    </container-component>
  </router-view>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'

import { useI18n } from '@/hooks/useI18n.hook'
import { useRoute, useRouter } from 'vue-router'
import { reactive, computed, watchEffect } from 'vue'
import api from '@/api'
import state from '@/state'
import { hasRoleGreaterThan } from '@/utilities/users'
import type { User } from '@/types'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  id: string | number
}>()

interface Data {
  tabSelected?: { key: string, value: string, dataTestId: string }
  user: User.User,
  loading: boolean
}
const data = reactive<Data>({
  user: {
    id: -1,
    email: '',
    name: '',
    surname: '',
    avatar: '',
    access: [],
    accessConfigs: []
  },
  loading: false
})

const compute = reactive({
  sections: computed(() => [
    {
      key: 'user',
      value: i18n.t('mywb.common.user'),
      dataTestId: 'userTabLabel'
    },

    hasRoleGreaterThan(+props.id) && {
      key: 'user-chargers',
      value: i18n.t('mywb.common.assigned-chargers'),
      dataTestId: 'assignedChargersTabLabel'
    }
  ].filter(Boolean as unknown as ExcludesNullish))
})

watchEffect(() => {
  data.tabSelected = compute.sections.find(tab => tab.key === route.name)
})

async function created () {
  data.loading = true
  const { data: user } = await api.users.getUser(+props.id)
  data.user = user

  state.routes.hydrateCurrentBreadCrumb(`${data.user.name} ${data.user.surname}`)
  data.loading = false
}

created()
</script>
