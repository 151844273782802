<template>
  <wb-card
    class="widget"
    is-full-height
  >
    <template #content>
      <div class="charger-widget">
        <div class="charger-widget_container">
          <div class="charger-widget_container-overlay">
            <client-img-component
              src="wheel"
              class="circle-wheel"
            />
            <div class="charger-widget_container-overlay-text">
              <h1 class="is-font-weight-500">
                <wb-animated-number
                  :from="0"
                  :to="data.chargerAmps.current"
                  :duration="0.1"
                  :watch-change-number="false"
                  data-test-id="currentNumberAmperes"
                />
                <span class="is-size-300">
                  {{ i18n.t('mywb.charger.amps') }}
                </span>
              </h1>
              <h2 data-test-id="chargerStatus">
                {{ i18n.t(compute.chargerStatus.label) }}
              </h2>
            </div>
          </div>

          <div class="charger-widget_graph">
            <wb-circle-slider
              v-if="compute.showAmpManager && permissions.canCharge"
              v-model="data.chargerAmps.current"
              data-test-id="sliderCircleImage"
              circle-color="transparent"
              :progress-color="compute.colors.bgColor"
              :side="200"
              :progress-width="14"
              :knob-color="compute.knobColor"
              :knob-radius="10"
              :min="data.chargerAmps.min"
              :max="data.chargerAmps.max"
              @on-mouse-up="methods.updateChargerAmps"
            />
          </div>
        </div>

        <div v-if="permissions.canCharge" class="charger-widget_actions">
          <wb-popover>
            <template #activator>
              <wb-button
                rounded
                data-test-id="pausePlayChargeButton"
                :loading="data.startStopButtonLoading"
                :disabled="compute.startStopButton.disabled"
                :icon="compute.isChargingOrDischarging ? 'pause' : 'play_arrow'"
                :style="!compute.startStopButton.disabled ? `background: ${compute.knobColor}` : ''"
                @click="methods.setRemoteAction"
              />
              <span
                v-if="compute.startStopButton.disabled && compute.startStopButton.tooltip"
                class="remote-action-icon wb-icons"
                :style="`color: ${compute.knobColor}`"
              >info_filled</span>
            </template>
            <template v-if="compute.startStopButton.tooltip" #tooltip>
              {{ compute.startStopButton.tooltip }}
            </template>
          </wb-popover>

          <wb-popover>
            <template #activator>
              <wb-button
                rounded
                data-test-id="lockUnlockButton"
                :loading="data.lockUnlockButtonLoading"
                :disabled="compute.lockUnlockButton.disabled"
                :icon="compute.chargerUnlocked ? 'lock_open' : 'lock'"
                :style="!compute.lockUnlockButton.disabled ? `background: ${compute.knobColor}` : ''"
                @click="methods.lockUnlockCharger"
              />
              <span
                v-if="compute.lockUnlockButton.disabled && compute.lockUnlockButton.tooltip"
                class="remote-action-icon wb-icons"
                :style="`color: ${compute.knobColor}`"
              >info_filled</span>
            </template>
            <template v-if="compute.lockUnlockButton.tooltip" #tooltip>
              {{ compute.lockUnlockButton.tooltip }}
            </template>
          </wb-popover>
        </div>
      </div>
    </template>

    <template #modal>
      <shared-confirmation-modal
        v-if="data.isModalMidErrorCommunication"
        :title="i18n.t('mywb.common.warning')"
        :subtitle="i18n.t('mywb.charger.non-mid-session')"
        :label-confirmation-button="i18n.t('mywb.common.initiate')"
        @on-confirm="methods.addChargerRemoteAction(1)"
        @on-close="data.isModalMidErrorCommunication = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed, watch } from 'vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'

import api from '@/api'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import { trackDataEvent, trackDataAction } from '@/engine/metrics/trackDataManager'
import { hasMidErrorCommunication } from '@/utilities/charger/midInformation'
import { getChargerAmps } from '@/utilities/charger/chargerAmps'
import { isPulsar } from '@/utilities/charger/chargerTypes'
import { isOcpp } from '@/utilities/charger/chargerOcppSettings'
import { STATUSES, getChargerStatus, getColorByStatus } from '@/utilities/charger/chargerStatuses'

import { REMOTE_ACTIONS, LOCK_STATUS } from '@/utilities/charger/chargerActions'

const i18n = useI18n()

const emit = defineEmits([
  'on-update'
])

const data = reactive({
  chargerAmps: getChargerAmps(state.charger.getCurrentCharger.chargerData),
  lockUnlockButtonLoading: false,
  isModalMidErrorCommunication: false,
  startStopButtonLoading: false,
  loading: false,
  remoteActionLoading: false
})

const compute = reactive({
  chargerStatus: computed(() => {
    return getChargerStatus(state.charger.getCurrentCharger)
  }),

  isDisconnected: computed((): boolean => compute.chargerStatus.code === STATUSES.DISCONNECTED),

  isChargingOrDischarging: computed((): boolean => {
    return compute.chargerStatus.code === STATUSES.CHARGING || compute.chargerStatus.code === STATUSES.DISCHARGING
  }),

  chargerUnlocked: computed((): boolean => !state.charger.getCurrentCharger.chargerData.locked),

  showAmpManager: computed((): boolean => {
    const hiddenStatus = [STATUSES.DISCONNECTED, STATUSES.UPDATING, STATUSES.ERROR]
    return !hiddenStatus.includes(compute.chargerStatus.code)
  }),

  hasRemoteActionRunning: computed((): boolean =>
    state.charger.getCurrentCharger.config.remote_action === REMOTE_ACTIONS.UPDATE ||
    state.charger.getCurrentCharger.config.remote_action === REMOTE_ACTIONS.RESTART
  ),

  knobColor: computed(() => {
    switch (compute.chargerStatus.code) {
      case STATUSES.LOCKED: return '#bc9e25'
      case STATUSES.RESERVED: return '#bc9e25'
      case STATUSES.READY: return '#165c4a'
      case STATUSES.CHARGING: return '#041ddd'
      case STATUSES.DISCHARGING: return '#a102c9'
      case STATUSES.SCHEDULED: return '#255859'
      case STATUSES.PAUSED: return '#0ca7d6'
      case STATUSES.WAITING: return '#0ca7d6'
      case STATUSES.UPDATING: return '#A430FF'
      case STATUSES.NOT_CONFIGURED: return '#99231D'
      default: return '#A0A0A0'
    }
  }),

  isOcpp: computed((): boolean => isOcpp(state.charger.getCurrentCharger.config)),

  colors: computed(() => {
    const colors = getColorByStatus(compute.chargerStatus.code)

    return {
      bgColor: colors.bg,
      textColor: colors.text
    }
  }),

  isPrivateCharge: computed((): boolean => state.charger.getCurrentCharger.chargerData.accessType === 'private'),

  isMultiUser: computed(():boolean =>
    (state.charger.getCurrentCharger.users || []).filter(user => user.assigned).length > 1),

  lockUnlockButton: computed((): { disabled: boolean, tooltip: string } => {
    let tooltip = ''

    const disabled = data.lockUnlockButtonLoading ||
      compute.hasRemoteActionRunning ||
      compute.isDisconnected ||
      compute.isOcpp ||
      (compute.isMultiUser && !compute.isPrivateCharge && compute.chargerStatus.code !== STATUSES.LOCKED) ||
      isPulsar(state.charger.getCurrentCharger.chargerData)

    if (!disabled) {
      tooltip = compute.chargerUnlocked ? i18n.t('mywb.common.lock') : i18n.t('mywb.common.unlock')
    } else {
      if (compute.isOcpp) {
        tooltip = i18n.t('mywb.error.ocpp-configured')
      } else if (isPulsar(state.charger.getCurrentCharger.chargerData)) {
        tooltip = i18n.t('mywb.error.not-compatible')
      } else if (compute.isMultiUser && !compute.isPrivateCharge && compute.chargerStatus.code !== STATUSES.LOCKED) {
        tooltip = i18n.t('mywb.charger.not-compatible-is-multi-user')
      }
    }

    return {
      disabled,
      tooltip
    }
  }),

  startStopButton: computed((): { disabled: boolean, tooltip: string } => {
    let tooltip = ''

    const disabled = data.startStopButtonLoading ||
      compute.hasRemoteActionRunning ||
      compute.isDisconnected ||
      compute.isOcpp ||
      compute.chargerStatus.code === STATUSES.LOCKED ||
      isPulsar(state.charger.getCurrentCharger.chargerData)

    if (!disabled) {
      tooltip = compute.isChargingOrDischarging ? i18n.t('mywb.common.pause') : i18n.t('mywb.common.resume')
    } else {
      if (compute.isOcpp) {
        tooltip = i18n.t('mywb.error.ocpp-configured')
      } else if (isPulsar(state.charger.getCurrentCharger.chargerData)) {
        tooltip = i18n.t('mywb.error.not-compatible')
      }
    }

    return {
      disabled,
      tooltip
    }
  })
})

watch(() => state.charger.getCurrentCharger, (charger, oldCharger) => {
  data.chargerAmps = getChargerAmps(charger.chargerData)

  if (charger.chargerData.status !== oldCharger.chargerData.status) {
    data.startStopButtonLoading = false
    data.lockUnlockButtonLoading = false
  }

  setTimeout(() => {
    data.startStopButtonLoading = false
    data.lockUnlockButtonLoading = false
  }, 60000)
})

const methods = {
  async lockUnlockCharger () {
    data.lockUnlockButtonLoading = true
    const lockValue = compute.chargerUnlocked ? 'lock' : 'unlock'

    const params = {
      locked: (lockValue === 'lock') ? LOCK_STATUS.LOCK : LOCK_STATUS.UNLOCK
    }

    await api.chargers.updateCharger({
      params,
      chargerId: state.charger.getCurrentCharger.chargerData.id
    })

    const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
    state.charger.setCurrentCharger(charger)

    emit('on-update')
    trackDataAction(`${lockValue}-charger`)
  },

  async setRemoteAction () {
    data.startStopButtonLoading = true
    const type = compute.isChargingOrDischarging ? STATUSES.PAUSED : STATUSES.CHARGING

    const action = type === STATUSES.PAUSED ? REMOTE_ACTIONS.PAUSE : REMOTE_ACTIONS.CHARGE

    const idStatus = state.charger.getCurrentCharger.chargerData.status

    if (hasMidErrorCommunication(idStatus) && action === 1) {
      data.isModalMidErrorCommunication = true
      return
    }

    methods.addChargerRemoteAction(action)
    emit('on-update')
    trackDataAction(`${type}-charger`)
  },

  async addChargerRemoteAction (action: REMOTE_ACTIONS) {
    const chargerRemoteAction = await api.chargers.setChargerRemoteAction({
      action,
      chargerId: state.charger.getCurrentCharger.chargerData.id
    })

    state.charger.set('currentCharger.config.remote_action', chargerRemoteAction)
  },

  async updateChargerAmps () {
    const { data: { chargerData } } = await api.chargers.updateCharger({
      chargerId: state.charger.getCurrentCharger.chargerData.id,
      params: {
        maxChargingCurrent: data.chargerAmps.current
      }
    })
    state.charger.set('currentCharger.chargerData', chargerData)

    trackDataEvent('update-amp-charger', { 'max-charging-current': data.chargerAmps.current })
  }
}
</script>

<style lang="postcss" scoped>
.charger-widget {
  & .charger-widget_graph {
    z-index: 10;
    position: relative;
    top: 3px;
  }

  & .charger-widget_actions {
    display: flex;
    justify-content: space-around;
    width: 20rem;
    margin: 0 auto;
    z-index: 50;
  }

  & .circle-wheel {
    color: v-bind(compute.colors.bgColor);
  }

  & .charger-widget_container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    height: 20rem;

    & .charger-widget_container-overlay {
      position: absolute;
      text-align: center;
      width: 20rem;
      height: 20rem;

      & img {
        width: 41rem;
        z-index: -99;
      }

      & h1 {
        font-size: 4rem;
        z-index: 2;
        margin-top: 9rem;
      }

      & h2 {
        font-weight: 500;
        font-size: 1.2rem;
        z-index: 2;
        text-transform: uppercase;
      }

      & .charger-widget_container-overlay-text {
        position: absolute;
        left: 4rem;
        display: flex;
        flex-direction: column;
        width: 12rem;
        height: 16rem;
        top: 5rem;
        color: v-bind(compute.colors.textColor);
      }
    }
  }
}

.remote-action-icon {
  position: absolute;
  top: -3px;
  left: -3px;
  font-size: 14px !important;
}
</style>
