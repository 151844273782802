export default function (rawRfid: string) {
  let flag = false

  if (typeof rawRfid === 'string' && rawRfid.length > 0) {
    const value = rawRfid.toLowerCase()

    // Hexadecimal value of 8 or 14 characters
    flag = /^([\da-f]{8}|[\da-f]{14})$/g.test(value)

    // Numeric value of 10 or 17 digits
    flag = flag || /^(\d{10}|\d{17})$/g.test(value)
  }

  return flag
}
