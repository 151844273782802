<template>
  <wb-table-row>
    <wb-table-row-item data-test-id="name">
      <wb-link
        type="text"
        class="has-text-grey-700 is-size-300 is-font-weight-500"
        @click="emit('on-change-organization', props.organization)"
      >
        {{ props.organization.name }}
        <span
          v-if="props.organization.is_active"
          class="wb-icons is-size-400 ml-8 has-text-primary-500"
        >check</span>
      </wb-link>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="owner">
      {{ props.organization.owner_email }}
    </wb-table-row-item>
    <wb-table-row-item data-test-id="role">
      <wb-label
        is-active
        is-invert
        disabled
        color="grey"
      >
        {{ props.organization.role }}
      </wb-label>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="plan">
      <wb-label
        is-active
        is-invert
        disabled
        color="grey"
      >
        {{ props.organization.plan }}
      </wb-label>
    </wb-table-row-item>
    <wb-table-row-item>
      <wb-button-groups type="primary">
        <wb-button
          type="primary"
          size="small"
          :label="i18n.t('mywb.common.go-to-organization')"
          data-test-id="goToOrganizationButton"
          class="group-item"
          @click="emit('on-change-organization', props.organization)"
        />

        <wb-button
          icon="edit"
          size="small"
          type="primary"
          inverted
          :disabled="!compute.canEditOrganization"
          data-test-id="editButton"
          class="group-item"
          @click="methods.handleOrganizationDetail"
        />
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import state from '@/state'
import type { Organizations } from '@/types'

const i18n = useI18n()
const router = useRouter()

const props = defineProps<{
  organization: Organizations.Organization
}>()

const emit = defineEmits(['on-change-organization'])

const compute = reactive({
  hasOperatorPlanInRootGroup: computed((): boolean => state.organizations.allOrganizations
    .find(organization => organization.profile_id === EnumRoles['super-admin'])?.product_id === EnumPlans.OPERATOR),

  canEditOrganization: computed((): boolean => {
    return [EnumRoles['super-admin'], EnumRoles.operator].includes(props.organization.profile_id) ||
      ((compute.hasOperatorPlanInRootGroup && props.organization.profile_id === EnumRoles.operator) ||
        props.organization.profile_id === EnumRoles['super-admin'])
  })
})

const methods = {
  handleOrganizationDetail () {
    router.push({ name: 'organizationDetail', params: { organizationUid: props.organization.group_uid } })
  }
}
</script>
