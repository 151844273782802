import { computed, reactive } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import type { Group } from '@/types'

interface GroupState {
  groupRelatedToOrganization: Group.Group
  groups: Group.GroupChargersTree[]
}

const DEFAULT_GROUP: Group.Group = {
  currency: {
    id: 0,
    name: '',
    symbol: '',
    code: 'USD'
  },
  energyCost: {
    value: 0,
    inheritedGroupId: null
  },
  id: 0,
  name: '',
  parent: null,
  payments_account: false,
  plan: {
    product_id: EnumPlans.BASIC,
    name: '',
    label: '',
    description: ''
  },
  subscription: null,
  uid: '',
  profile: {
    id: EnumRoles['super-admin']
  }
}

const initialState = (): GroupState => ({
  groupRelatedToOrganization: DEFAULT_GROUP,
  groups: []
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<GroupState>) {
    return objects.getProperty(state, path)
  },

  locations: computed(() => state.groups),

  groupRelatedToOrganization: computed(() => {
    return state.groupRelatedToOrganization
  }),

  groupRelatedToOrganizationWithChargers: computed(() => {
    const groupsByParent = objects.groupBy(state.groups || [], 'parent') as Record<number | 'null', typeof state.groups>
    const rootGroups = groupsByParent.null || []
    function addSubgroups (group: Group.GroupChargersTree): Group.GroupChargersTree {
      return {
        ...group,
        subgroups: [
          group,
          ...(groupsByParent[group.id] || []).map(addSubgroups)
        ]
      }
    }

    const groupTree = rootGroups.map(addSubgroups)

    const defaultGroupCharger: Group.GroupChargersTree = {
      ...DEFAULT_GROUP,
      chargers: [],
      subgroups: []
    } as unknown as Group.GroupChargersTree

    return groupTree.find((group) => group.id === state.groupRelatedToOrganization.id) ?? defaultGroupCharger
  }),

  hasFreeProduct: computed(() => {
    return [EnumPlans.BUSINESS, EnumPlans.OPERATOR].includes(state.groupRelatedToOrganization?.plan?.product_id) &&
      !state.groupRelatedToOrganization.subscription
  })
}

const setters = {
  set (path: Paths<GroupState>, value: any) {
    objects.setProperty(state, path, value)
    if (path === 'groupRelatedToOrganization') setWbStateProperty('groupRelatedToOrganization')
  },

  setInitialState () {
    state = reactive(initialState())
  },

  deleteCharger ({ groupId, chargerId }: { groupId: number, chargerId: number }) {
    const group = state.groups.find(group => group.id === groupId)

    if (group) {
      group.chargers = group.chargers.filter(charger => charger.id !== chargerId)
    }
  }
}

function setWbStateProperty (path: keyof GroupState) {
  const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}')

  if (wbState) {
    wbState[path] = state[path]
    localStorage.setItem('wb-state', JSON.stringify(wbState))
  }
}

export function loadState () {
  const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}')

  if (wbState) {
    setters.set('groupRelatedToOrganization', wbState.groupRelatedToOrganization)
  }
}

export default reactive({
  ...getters,
  ...setters
})
