<template>
  <wb-select
    v-model="compute.timezoneId"
    uid="_timezone"
    name="timezone"
    option-label="name"
    data-test-id="timezoneSelect"
    :reduce="(option: any) => option.id"
    :options="compute.timezones"
    :label="i18n.t('mywb.common.timezone')"
    :disabled="!state.global.getTimezones.length"
  />
</template>

<script setup lang="ts" >
import { computed, reactive } from 'vue'

import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface PropsType {
  timezoneId?: string
}

const props = defineProps<PropsType>()

interface Events {
  (e: 'update:timezoneId', timezoneId?: string): void,
}

const emit = defineEmits<Events>()

const compute = reactive({
  timezones: computed(() => state.global.getTimezones.map(methods.formatTimezone)),

  timezoneId: computed({
    get () {
      if (props.timezoneId) {
        return props.timezoneId
      }
      emit('update:timezoneId', state.user.userLogged.time_zone)
      return state.user.userLogged.time_zone
    },

    set (value: string) {
      emit('update:timezoneId', value)
    }
  })
})

const methods = {
  formatTimezone (timezone: string) {
    return {
      id: timezone,
      name: timezone.replaceAll('_', ' ')
    }
  }
}

</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr auto;
  }
}

.grid-2-columns {
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.is-label-icon {
  font-size: 1.1rem;
  margin-left: 1rem;
  top: 2px;
  position: relative;
}
</style>
