<template>
  <wb-card
    class="mb-24"
    :headline="i18n.t('mywb.common.charge-with-contract-subscription')"
    :subhead="i18n.t('mywb.common.charge-with-contract-subscription.description')"
    data-test-id="subscriptionSection"
  >
    <template #content>
      <wb-label
        v-if="compute.subscriptionStatus.type && compute.subscriptionStatus.date"
        is-invert
        :color="compute.subscriptionStatus.type"
        data-test-id="subscriptionDate"
      >
        {{ compute.subscriptionStatus.date }}
      </wb-label>

      <wb-notification
        v-if="data.isChecked"
        type="info"
        icon="info_filled"
        class="mt-8"
      >
        <p v-t="'mywb.common.charge-with-contract-subscription.title'" />
      </wb-notification>
    </template>

    <template #place-upper-right>
      <wb-switch
        v-model="data.isChecked"
        name="subscribe"
        @input="methods.handleSubscription"
      />
    </template>
  </wb-card>

  <shared-confirmation-modal
    v-if="data.isModalSettingsSubscribeConfirmationOpen"
    :title="!data.isChecked
      ? i18n.t('mywb.user.unsubscribe-charge-with-contract', { username: props.user.name })
      : i18n.t('mywb.user.subscribe-charge-with-contract', { username: props.user.name })"
    :label-confirmation-button="!data.isChecked
      ? i18n.t('mywb.common.unsubscribe')
      : i18n.t('mywb.common.subscribe')"
    :type="!data.isChecked ? 'danger' : 'primary'"
    :loading="data.loading"
    @on-confirm="methods.confirmAction(!data.isChecked)"
    @on-cancel="methods.cancelAction"
    @on-close="data.isModalSettingsSubscribeConfirmationOpen = false"
  />

  <shared-confirmation-modal
    v-if="data.isModalSettingsUnpaidBillsOpen"
    :title="i18n.t('mywb.user.has-unpaid-bills', { username: props.user.name })"
    :label-confirmation-button="i18n.t('mywb.common.show-pending-invoices')"
    @on-confirm="methods.showPendingBills"
    @on-cancel="methods.cancelAction"
    @on-close="data.isModalSettingsUnpaidBillsOpen = false"
  />
</template>

<script setup lang=ts>
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import api from '@/api'
import state from '@/state'
import { getServerError } from '@/utilities/errorMessages'
import { trackDataAction, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { getRoleById } from '@/utilities/user-roles'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { useNotify, dates } from '@wallbox/toolkit-ui'
import type { User } from '@/types'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const notify = useNotify()
const router = useRouter()
const i18n = useI18n()

interface Props {
  user: User.User & User.UserDetail,
  subscribe?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-update'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  isChecked: false,
  isModalSettingsSubscribeConfirmationOpen: false,
  isModalSettingsUnpaidBillsOpen: false,
  loading: false
})

const compute = reactive({
  accessConfig: computed(() => {
    return props.user.accessConfigs
      .find(accessConfig => state.organizations.getCurrentOrganization.group_id === accessConfig.group)
  }),

  hasUnpaidBills: computed((): boolean => compute.accessConfig?.contract?.status === 'unpaid'),

  subscriptionStatus: computed(() => {
    let subscriptionStatus: { type: 'warning' | 'success', date: string} = { type: 'warning', date: '' }

    if (compute.accessConfig?.contract) {
      const { contract } = compute.accessConfig

      subscriptionStatus = {
        type: 'warning',
        date: i18n.t('mywb.common.pending')
      }

      if (contract.signed && contract.status === 'active') {
        const subscriptionDateTranslate = i18n.t('mywb.common.active')
        const dateFormatSubscription = dates.toLongRedableDate(contract.createdAt, i18n.locale.value)

        subscriptionStatus = {
          type: 'success',
          date: `${subscriptionDateTranslate}: ${dateFormatSubscription}`
        }
      }
    }

    return subscriptionStatus
  })
})

const methods = {
  cancelAction () {
    data.isChecked = !data.isChecked
  },

  showPendingBills () {
    state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'status', value: 'pending' })
    router.push({ name: 'payments-invoices' })
  },

  handleSubscription () {
    if (!compute.hasUnpaidBills) {
      data.isModalSettingsSubscribeConfirmationOpen = true
    } else {
      data.isModalSettingsUnpaidBillsOpen = true
    }
  },

  async confirmAction (checked: boolean) {
    data.loading = true
    checked ? await methods.unsubscribeUser() : await methods.subscribeUser()
    data.loading = false
    trackDataEvent('user-subscription-invited')
  },

  async subscribeUser () {
    if (!compute.accessConfig?.id) return

    try {
      await api.accessConfig.addUserToAccessConfig({
        accessConfigId: compute.accessConfig.id,
        userId: props.user.id,
        subscribed: data.isChecked
      })
      emit('on-update')

      trackDataAction('user-subscription', {
        pay_per_month: data.isChecked,
        user_type: getRoleById(props.user.profile_id).name
      })

      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
      } else {
        throw error
      }
    }
  },

  async unsubscribeUser () {
    try {
      if (compute.accessConfig?.contract) {
        await api.users.deleteContract(compute.accessConfig.contract.id)
      }
      emit('on-update')
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
      } else {
        throw error
      }
    }
  }
}

async function created () {
  data.isChecked = !!compute.accessConfig?.contract
}

created()
</script>
