<template>
  <div>
    <user-list
      id="user-list"
      :ref="setRef('userListTable')"
      data-test-id="userList"
      :group="compute.rootGroup"
      :user-invitations="data.userInvitations"
      :loading="data.loading"
      :user-id="state.user.userLogged.id"
      :user-profile="compute.currentGroupUserProfile"
      @add-user="methods.addUser"
      @remove-user="methods.openRemoveUser"
      @on-click="methods.openUserProfile"
      @revoke-invitation="methods.revokeInvitation"
      @resend-invitation="methods.resendInvitation"
    />

    <invite-users-modal
      v-if="data.isModalUserListAddUserOpen"
      :group="compute.rootGroup"
      :user-invitations="data.userInvitations"
      @on-users-invited="methods.getUserInvitations"
      @close="data.isModalUserListAddUserOpen = false"
    />

    <user-delete-modal
      v-if="data.isModalUserListUserDeleteOpen
        && data.selectedUser
        && data.selectedGroupId"
      :user="data.selectedUser"
      :group-id="data.selectedGroupId"
      :access-config-id="data.selectedAccessConfigId"
      @close="methods.onDeleteUser"
    />

    <shared-confirmation-modal
      v-if="data.isModalSettingsUnpaidBillsOpen"
      :title="i18n.t('mywb.user.has-unpaid-bills', { username: data.selectedUser?.name })"
      :label-confirmation-button="i18n.t('mywb.common.show-pending-invoices')"
      @on-confirm="methods.showPendingBills"
      @on-close="data.isModalSettingsUnpaidBillsOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import UserList from '@/components/users/UserList.vue'
import InviteUsersModal from '@/components/modals/InviteUsersModal.vue'
import UserDeleteModal from '@/components/modals/UserDeleteModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import { clientConfig } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify, useTemplateRef } from '@wallbox/toolkit-ui'
import { useRouter } from 'vue-router'
import userRoles, { EnumRoles } from '@/utilities/user-roles'
import state from '@/state'
import api from '@/api'
import lang from '@/engine/lang'
import type { Invitation, User } from '@/types'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const { refs, setRef } = useTemplateRef()
const router = useRouter()
const notify = useNotify()
const i18n = useI18n()

interface Data {
  selectedAccessConfigId? : number,
  selectedGroupId?: number
  selectedUser?: User.UserGroup
  isModalUserListUserDeleteOpen: boolean
  isModalSettingsUnpaidBillsOpen: boolean
  isModalUserListAddUserOpen: boolean
  loading: boolean
  userInvitations: Invitation.Invitation[]
}

const data = reactive<Data>({
  isModalUserListUserDeleteOpen: false,
  isModalSettingsUnpaidBillsOpen: false,
  isModalUserListAddUserOpen: false,
  loading: false,
  userInvitations: []
})

const compute = reactive({
  rootGroup: computed((): User.UsersGroupWithUserExpanded => {
    const group = state.user.getGroupsByAccessConfig && state.user.getGroupsByAccessConfig
      .find(group => state.groups.groupRelatedToOrganization.id === group.id) as User.UsersGroupWithUserExpanded

    return {
      ...group,
      ...state.groups.groupRelatedToOrganization
    }
  }),

  currentGroupUserProfile: computed((): EnumRoles => {
    const group = state.user.getCurrentUserGroupsMap[compute.rootGroup.id]
    const userInGroup = group && group.users?.find(user => user.id === state.user.userLogged.id)

    return userInGroup ? userInGroup.profile : userRoles.user as EnumRoles
  })
})

const methods = {
  async getAllUsers (withLoading = true) {
    data.loading = withLoading
    state.user.set('usersGroup', await api.users.getUserGroups())

    await methods.loadUserInvitations()
    await refs.userListTable?.loadAccessConfig()

    const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
    state.groups.set('groups', result.groups)
    data.loading = false

    api.groups.getAllDataOrganizations().then(({ result: { groups } = {} }) => state.groups.set('groups', groups))
  },

  async loadUserInvitations () {
    data.userInvitations = (await api.invitations.getUsersInvitations(compute.rootGroup.id)).data
  },

  getUserInvitations () {
    data.isModalUserListAddUserOpen = false
    methods.loadUserInvitations()
  },

  openUserProfile ({ userId }: { userId: number }) {
    trackDataAction('user', { user_id: userId })
    router.push({ name: 'user', params: { id: userId } })
  },

  hasUnpaidBills (user: User.UserGroup) {
    const { contract } = user
    return contract?.status === 'unpaid'
  },

  openRemoveUser ({ groupId, user }: { groupId: number, user: User.UserGroup }) {
    data.selectedUser = user
    data.selectedGroupId = groupId
    data.selectedAccessConfigId = methods.getAccessConfig(groupId)

    if (!methods.hasUnpaidBills(user)) {
      trackDataAction('delete-user', { user_id: user.id })
      data.isModalUserListUserDeleteOpen = true
    } else {
      data.isModalSettingsUnpaidBillsOpen = true
    }
  },

  showPendingBills () {
    state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'status', value: 'pending' })
    router.push({ name: 'payments-invoices' })
  },

  getAccessConfig (groupId: number) {
    return compute.rootGroup.accessConfigs.find(accessConfig => accessConfig.id === groupId)?.id
  },

  addUser (groupId: number) {
    data.selectedAccessConfigId = methods.getAccessConfig(groupId)
    trackDataAction('add-user', { group_id: groupId })
    data.isModalUserListAddUserOpen = true
  },

  async revokeInvitation ({ invitationId }: { invitationId: number }) {
    try {
      await api.invitations.deleteUsersInvitations({ groupId: compute.rootGroup.id, invitationId })
      notify.success(i18n.t('mywb.common.invitation-has-been-revoked'))
      setTimeout(() => { methods.loadUserInvitations() }, 300)
      trackDataAction('revoke-invitation', { group_id: compute.rootGroup.id })
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async resendInvitation ({ invitationId }: { invitationId: number }) {
    try {
      const params = {
        lang: lang.__rootLanguage,
        branding_id: clientConfig.brandingId
      }
      await api.invitations.resendUsersInvitations({ groupId: compute.rootGroup.id, invitationId, params })
      notify.success(i18n.t('mywb.common.invitation-sent'))
      trackDataAction('resend-invitation', { group_id: compute.rootGroup.id })
    } catch (error) {
      if (error instanceof HttpError && error?.code === 403) {
        notify.error(i18n.t('mywb.error.group-invitation-limit-exceeded'))
      } else {
        notify.error(i18n.t('mywb.error.unexpected-error'))
      }
    }
  },

  resetFilters () {
    state.filters.resetFilters({ filter: 'accessConfigFilters' })
  },

  onDeleteUser () {
    data.isModalUserListUserDeleteOpen = false
    methods.resetFilters()
  }
}

function created () {
  methods.getAllUsers()
}

created()

defineExpose({ inviteUser: () => methods.addUser(compute.rootGroup.id) })
</script>
