<template>
  <footer id="footer" class="footer">
    <div class="footer-width-constrain">
      <div v-if="compute.terms" class="terms">
        {{ clientConfig.footerRights }}
        <a
          v-for="term in compute.terms"
          :key="term.url"
          :href="term.url"
          class="footer-policy-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ term.text }}
        </a>
      </div>

      <div class="social">
        <div v-if="compute.socials" class="footer-social social-items">
          <a
            v-for="network in compute.socials"
            :key="network.url"
            :href="network.url"
            class="footer-social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="wb-icons footer-social-icon">{{ network.icon }}</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { clientConfig } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'

const i18n = useI18n()

interface DataType {
  privacy: string
  terms: string
}

interface ItemType {
  url: string
  icon: string
  text?: string
}

const data: DataType = reactive({
  privacy: i18n.t('mywb.common.data-privacy-detail'),
  terms: i18n.t('mywb.common.use-terms')
})

const compute = reactive({
  socials: computed((): ItemType[] => {
    if (!clientConfig.footerSocialMedia) return []

    return Object.entries(clientConfig.footerSocialMedia).reduce((objCarry, [icon, url]) => ({
      ...objCarry,
      [icon]: { url, icon }
    }), [])
  }),

  terms: computed((): ItemType[] => {
    if (!clientConfig.docs) return []

    return Object.entries(clientConfig.docs).reduce((objCarry, [key, entry]) => ({
      ...objCarry,
      [key]: {
        url: entry,
        text: data[key as keyof DataType]
      }
    }), [])
  })
})
</script>

<style lang="postcss" scoped>
.footer {
  display: flex;
  place-items: center;
  backface-visibility: hidden;
  margin: 8px 0;
}

.footer-width-constrain {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    "terms"
    "social";
  align-items: center;
  max-width: var(--max-width-content);
  justify-content: center;

  @media (--tablet) {
    grid-template-columns: max-content auto min-content;
    grid-template-areas: "terms . social";
    justify-content: space-between;
  }
}

.terms {
  grid-area: terms;
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}

.social {
  grid-area: social;
}

.social-items > * + * {
  margin-left: 12px;
}

.footer-social {
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;

  @media (--tablet) {
    justify-content: right;
  }

  & .footer-social-link {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    padding: 0.5rem;
    width: 2rem;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }
  }

  & .footer-social-icon {
    color: var(--grey-700);
    font-size: 1.8rem !important;
    max-height: 100%;
    transition: filter 0.2s ease-in;
    width: 100%;
  }
}

.footer-policy-link {
  color: var(--black);
  text-decoration: none;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 0;

  &:hover {
    text-decoration: underline;
  }
}
</style>
