
import countries from 'i18n-iso-countries'
import caCountry from 'i18n-iso-countries/langs/ca.json'
import deCountry from 'i18n-iso-countries/langs/de.json'
import enCountry from 'i18n-iso-countries/langs/en.json'
import esCountry from 'i18n-iso-countries/langs/es.json'
import frCountry from 'i18n-iso-countries/langs/fr.json'
import itCountry from 'i18n-iso-countries/langs/it.json'
import nlCountry from 'i18n-iso-countries/langs/nl.json'
import noCountry from 'i18n-iso-countries/langs/no.json'
import zhCountry from 'i18n-iso-countries/langs/zh.json'
import ptCountry from 'i18n-iso-countries/langs/pt.json'
import svCountry from 'i18n-iso-countries/langs/sv.json'

import { createI18n } from 'vue-i18n'
import ca from './ca.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import no from './no.json'
import zh from './zh.json'
import pt from './pt.json'
import sv from './sv.json'
import enUS from './en_US.json'

countries.registerLocale(caCountry)
countries.registerLocale(deCountry)
countries.registerLocale(enCountry)
countries.registerLocale(esCountry)
countries.registerLocale(frCountry)
countries.registerLocale(itCountry)
countries.registerLocale(nlCountry)
countries.registerLocale(noCountry)
countries.registerLocale(zhCountry)
countries.registerLocale(ptCountry)
countries.registerLocale(svCountry)

export type Langs = 'ca' | 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'no' | 'zh' | 'pt' | 'sv' | 'enUS'
type MessageSchema = typeof enUS

const i18n = createI18n<[MessageSchema], Langs>({
  locale: navigator?.language,
  fallbackLocale: {
    nb: ['no'],
    nn: ['no'],
    default: ['en'],
    'en-US': ['enUS']
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  warnHtmlInMessage: 'off',
  warnHtmlMessage: false,
  messages: {
    ca,
    de,
    en,
    es,
    fr,
    it,
    nl,
    no,
    zh,
    pt,
    sv,
    enUS
  }
})

export default {
  ...i18n,
  get __rootLanguage () {
    const locale = i18n.global.locale?.substring(0, 2).toLowerCase()

    return (locale === 'nb' || locale === 'nn') ? 'no' : locale as Langs
  }
}
