<template>
  <div
    class="locations"
    :class="{'is-open': data.isOpen}"
  >
    <wb-checkbox
      v-model="data.isLocationChecked"
      class="location-charger-tree-item "
      data-test-id="chargerLocationCheckbox"
      :indeterminate="data.isLocationIndeterminate"
      :name="props.group.name"
      :disabled="props.disabled"
      v-bind="$attrs"
      @update:model-value="methods.handleAll"
    />

    <div
      class="has-cursor-pointer is-fullwidth"
      @click="data.isOpen = !data.isOpen"
    >
      <div class="g-8 button-content">
        <span
          data-test-id="chargerlocation"
          class="is-size-400 ml-16"
        >
          {{ props.group.name }}
        </span>
        <div>
          <wb-label
            is-active
            disabled
            color="grey"
            class="ml-8"
          >
            {{ props.group.chargers?.length ?? 0 }}
          </wb-label>
        </div>

        <span
          class="text-right wb-icons mr-16"
        >
          {{ data.isOpen ? 'expand_less' : 'expand_more' }}
        </span>
      </div>
    </div>
  </div>

  <div
    v-if="data.isOpen"
    class="chargers"
  >
    <locations-accordion-charger
      v-for="charger in props.group.chargers"
      :key="charger.id"
      :model-value="data.checkedChargersModel.get(charger.id) ?? false"
      :charger="charger"
      :disabled="props.disabled"
      v-bind="$attrs"
      @update:model-value="value => methods.setChargerValue(charger, value)"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'
import LocationsAccordionCharger from '@/components/locations/LocationsAccordionCharger.vue'
import type { Charger, Group } from '@/types'

interface Props {
  disabled?: boolean
  group: Group.GroupChargersTree
  modelValue: number[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', chargers: number[]): void
}
const emit = defineEmits<Events>()

interface Data {
  isOpen: boolean
  isLocationChecked: boolean
  isLocationIndeterminate: boolean
  checkedChargersModel: Map<number, boolean>
  selectedChargers: Set<number>
}
const data = reactive<Data>({
  isOpen: false,
  isLocationChecked: false,
  isLocationIndeterminate: false,
  checkedChargersModel: new Map(),
  selectedChargers: new Set()
})

const methods = {
  setChargerValue (charger: Charger.Charger, value: boolean) {
    data.checkedChargersModel.set(charger.id, value)

    if (value) {
      data.selectedChargers.add(charger.id)
    } else {
      data.selectedChargers.delete(charger.id)
    }

    emit('update:modelValue', [...data.selectedChargers])
    methods.checkLocationCheckbox()
  },

  checkLocationCheckbox () {
    let allChecked = true
    let someChecked = false

    data.checkedChargersModel.forEach(value => {
      allChecked = allChecked && value
      someChecked = someChecked || value
    })

    data.isLocationChecked = allChecked && data.checkedChargersModel.size > 0
    data.isLocationIndeterminate = someChecked && !allChecked
  },

  handleAll (newValue: boolean) {
    data.isLocationIndeterminate = false
    data.checkedChargersModel.forEach((value, key) => {
      data.checkedChargersModel.set(key, newValue)

      if (newValue) {
        data.selectedChargers.add(key)
      } else {
        data.selectedChargers.delete(key)
      }
    })

    emit('update:modelValue', [...data.selectedChargers])
  }
}

watch([
  () => props.group.chargers,
  () => props.modelValue
],
() => {
  data.selectedChargers = new Set(props.modelValue)
  data.checkedChargersModel = new Map()

  props.group.chargers.forEach((charger) =>
    data.checkedChargersModel.set(charger.id, data.selectedChargers.has(charger.id))
  )

  methods.checkLocationCheckbox()
}, { immediate: true })
</script>

<style lang="postcss" scoped>
.location-charger-tree-item {
  display: flex;
}

.chargers {
  display: grid;
  grid-template-columns: 1fr;
}

.locations {
  background: var(--white);
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  padding: 8px 0 8px 16px;
  border: 1px solid var(--grey-200);
  border-bottom: none;
  z-index: 10;

  &.is-open,
  &:last-of-type {
    border: 1px solid var(--grey-200);

    & ~ .chargers {
      border-bottom: 1px solid var(--grey-200);
    }
  }

  & ~ .locations {
    border-radius: 0;

    &:last-of-type {
      border-radius: 0 0 0.4rem 0.4rem;
    }
  }
}

.button-content {
  display: grid;
  width: 100%;
  grid-template-columns: max-content max-content auto;
  text-align: left;
  align-items: center;
}

.text-right {
  text-align: right;
}

</style>
