<template>
  <wb-table
    :options="compute.options"
    :loading="props.loading"
  >
    <user-invitation-table-row
      v-for="(invitation, key) in props.userInvitations"
      :key="`invitation-${key}`"
      :invitation="invitation"
      :has-permision-to-actions="permissions.canRevokeOrResendUserInvitation"
      :data-test-id="`invitation-${invitation.id}`"
      @on-resend="emit('on-resend-invitation', { invitationId: invitation.id })"
      @on-revoke="emit('on-revoke-invitation', { invitationId: invitation.id })"
    />

    <access-configs-table-row
      v-for="(accessConfig, key) in props.accessConfigs"
      :key="`access-config-${key}`"
      :access-config="accessConfig"
      :has-permision-to-actions="methods.hasPermisionToActions(accessConfig)"
      :data-test-id="`user-${accessConfig.user.id}`"
      @on-click="emit('on-click', { userId: accessConfig.user.id })"
      @on-remove="emit('remove-user', { groupId: accessConfig.group.id, user: accessConfig.user })"
    />
  </wb-table>
</template>

<script setup lang="ts">
import AccessConfigsTableRow from '@/components/tables/AccessConfigsTableRow.vue'
import UserInvitationTableRow from '@/components/tables/UserInvitationTableRow.vue'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { hasRoleGreaterThan } from '@/utilities/users'
import state from '@/state'
import type { User } from '@/types'
import type { EnumRoles } from '@/utilities/user-roles'
import type { TableProps, ExcludesNullish } from '@wallbox/toolkit-ui'
const i18n = useI18n()

interface Props {
  accessConfigs: User.UserList[]
  userInvitations: User.UserList[]
  userId: number
  userProfile: EnumRoles,
  loading?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'remove-user', payload: { groupId: number, user: User.UserList['user'] }): void,
  (e: 'on-click', payload: { userId?: number }): void,
  (e: 'on-resend-invitation', payload: { invitationId: number }): void,
  (e: 'on-revoke-invitation', payload: { invitationId: number }): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  options: computed((): TableProps['options'] => {
    return {
      offset: '0 8px',

      columns: [
        { name: i18n.t('mywb.common.user'), align: 'left', minWidth: '380px', width: 'auto' },
        { name: i18n.t('mywb.common.access-group'), align: 'left', minWidth: '180px', width: 'auto' },
        { name: i18n.t('mywb.common.type'), align: 'left', minWidth: '110px', width: 'auto' },
        permissions.showPayments && {
          name: i18n.t('mywb.charger.pay-per-month'),
          align: 'center',
          minWidth: 'auto',
          width: '100px'
        },
        { name: i18n.t('mywb.common.status'), align: 'center', minWidth: '100px', width: 'auto' },
        {
          name: i18n.t('mywb.common.actions'),
          align: 'left',
          minWidth: '300px',
          width: 'auto',
          lineBehaviour: 'multiline'
        }
      ].filter(Boolean as unknown as ExcludesNullish) as TableProps['options']['columns'],

      stickyHead: '215px',

      empty: {
        icon: 'table_rows',
        title: i18n.t('mywb.common.table-empty')
      }
    }
  })
})

const methods = {
  hasPermisionToActions (accessConfig: User.UserList) {
    const isOwner = props.userId === state.organizations.getCurrentOrganization.owner_id
    return isOwner || hasRoleGreaterThan(accessConfig.user.id)
  }
}
</script>
