<template>
  <div class="grid-actions-content g-8" v-bind="$attrs">
    <shared-filter
      v-model="data.filterByLocation"
      multiple
      with-search
      uid="_location"
      label="name"
      icon="locations"
      class="filter-button"
      :name="i18n.t('mywb.common.locations')"
      :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
      :empty-text="i18n.t('mywb.locations.empty')"
      data-test-id="groupsFilter"
      :options="compute.locationsOptions"
    >
      <template #option="{ option }">
        <div class="grid g-8">
          {{ option.name }}
          <wb-label
            v-if="option.chargers?.length"
            is-active
            disabled
            color="grey"
            class="ml-8"
          >
            {{ option.chargers.length }}
          </wb-label>
        </div>
      </template>
    </shared-filter>

    <shared-filter
      v-model="data.filterByCharger"
      multiple
      with-search
      label="name"
      icon="charger"
      class="filter-button"
      :name="i18n.t('mywb.common.chargers')"
      :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
      :empty-text="i18n.t('mywb.chargers.empty')"
      data-test-id="chargersFilter"
      :options="state.charger.getChargers ?? []"
    >
      <template #option="{ option }">
        <div class="grid g-8">
          <img
            :src="option.image"
            class="charger-img"
            :alt="option.name "
          >
          {{ option.name }}
        </div>
      </template>
    </shared-filter>
  </div>

  <div v-if="compute.filteredLocations.length > 0">
    <locations-accordion-location
      v-for="group in compute.filteredLocations"
      :key="group.id"
      v-model="compute.chargers"
      v-bind="$attrs"
      :group="group"
      :disabled="props.disabled"
    />
  </div>
  <wb-empty-state
    v-else
    class="empty-state-height"
    icon="product-pulsar"
    :title="i18n.t('mywb.chargers.empty')"
  >
    <wb-link type="primary" class="is-size-300" @click="methods.clearFilters">
      {{ i18n.t('mywb.common.clear-filters') }}
    </wb-Link>
  </wb-empty-state>
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import LocationsAccordionLocation from '@/components/locations/LocationsAccordionLocation.vue'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import type { Charger, Group } from '@/types'

const i18n = useI18n()

interface Props {
  modelValue: number[]
  disabled?: boolean
  filterLocation?: number
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', chargers: number[]): void
}
const emit = defineEmits<Events>()

interface Data {
  filterByCharger: Charger.Charger[],
  filterByLocation: Group.Group[]
}
const data = reactive<Data>({
  filterByCharger: [],
  filterByLocation: []
})

const compute = reactive({
  chargers: computed({
    get () {
      return props.modelValue
    },

    set (value) {
      emit('update:modelValue', value)
    }
  }),

  locationsOptions: computed(() => {
    return state.groups.groupRelatedToOrganizationWithChargers.subgroups
      .filter(location => location.chargers?.length) || []
  }),

  filteredLocations: computed(() => {
    let locations = state.groups.groupRelatedToOrganizationWithChargers.subgroups
    if (!locations) return []

    if (data.filterByLocation.length > 0) {
      locations = [...locations.filter(location =>
        data.filterByLocation.map(filteredLocation => filteredLocation.id).indexOf(location.id) !== -1)
      ]
    }

    if (props.filterLocation) {
      locations = locations.filter((location) => location.id !== props.filterLocation)
    }

    if (data.filterByCharger.length > 0) {
      locations = locations.map(location => ({
        ...location,
        chargers: location.chargers.filter(charger => {
          return data.filterByCharger.map(filteredCharger => {
            return filteredCharger.id
          }).indexOf(charger.id) !== -1
        })
      }))
    }

    locations = locations.filter(location => location.chargers.length > 0)

    return locations
  })
})

const methods = {
  clearFilters: () => {
    data.filterByCharger = []
    data.filterByLocation = []
  }
}

</script>

<style lang="postcss" scoped>
.grid-actions-content {
  display: grid;
  grid-template-columns: auto;
  background: var(--grey-50);
  border-top: 1px solid var(--grey-200);
  border-left: 1px solid var(--grey-200);
  border-right: 1px solid var(--grey-200);
  padding: 1rem;
  border-radius: 0.6rem 0.6rem 0 0;

  @media (--tablet) {
    grid-template-columns: min-content min-content;
  }
}

.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}

:deep(.input-search) {
  background: white;
}

.charger-img {
  width: 32px;
  object-fit: cover;
}

.empty-state-height {
  height: 50% !important;
}

.filter-button {
  & :deep(.button) {
    width: 100%;
  }
}
</style>
