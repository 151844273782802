import { useField as useFieldlibrary } from 'vee-validate'
import { computed } from 'vue'

type params<T> = Parameters<typeof useFieldlibrary<T>>

export const useField = <T>(name: params<T>[0], rules?: params<T>[1], options?: params<T>[2]) => {
  const { errorMessage, value, handleChange, ...rest } = useFieldlibrary<T>(name, rules, {
    validateOnValueUpdate: false,
    ...options
  })

  const validationListeners = computed(() => {
    if (!errorMessage.value) {
      return {
        blur: (e: unknown) => handleChange(e, false),
        change: (e: unknown) => handleChange(e, false),
        input: (e: unknown) => handleChange(e, false)
      }
    }
    return {
      blur: handleChange,
      change: handleChange,
      input: handleChange
    }
  })

  return {
    errorMessage,
    value,
    validationListeners,
    ...rest
  }
}
