import { Userpilot } from 'userpilot'
import ENV from '@/engine/env/web.env'
import type { RouteRecordName } from 'vue-router'

const experiences = [
  {
    name: 'dashboard',
    token: '1619610332tBxe1649'
  },
  {
    name: 'chargers',
    token: '1620221947bZlt2325'
  },
  {
    name: 'sessions',
    token: '1620222277xGkb3739'
  },
  {
    name: 'users',
    token: '1620222682gHqo6219'
  }
]

export const hasExperience = (name?: RouteRecordName | null) => {
  return experiences.some(experience => experience.name === name)
}

export const getExperienceToken = (name?: RouteRecordName | null) => {
  const experience = experiences.find(experience => experience.name === name)

  return experience?.token
}

export default function prepareUserPilot () {
  ENV.userpilot.active && ENV.userpilot.token && Userpilot.initialize(ENV.userpilot.token)
}
