<template>
  <wb-popover
    v-if="compute.showOcppBadge"
  >
    <template #activator>
      <span
        class="wb-icons ocpp-icon"
        :data-status="compute.ocppStatus.code"
      >
        ocpp_circle
      </span>
    </template>
    <template #tooltip>
      {{ compute.ocppStatus.label }}
    </template>
  </wb-popover>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import {
  ocppStatus,
  supportsOcpp,
  OCPP,
  type ocppStatuses
} from '@/utilities/charger/chargerOcppSettings'

import { STATUSES, getChargerStatus, type Status } from '@/utilities/charger/chargerStatuses'
import type { Charger } from '@/types'

interface Props {
  charger: Charger.AnyTypeOfCharger
}
const props = defineProps<Props>()

const compute = reactive({
  ocppStatus: computed((): ReturnType<typeof ocppStatuses>[number] => ocppStatus(props.charger)),

  supportsOcpp: computed(():boolean => supportsOcpp(props.charger)),

  chargerStatus: computed((): Status => getChargerStatus(props.charger)),

  showOcppBadge: computed((): boolean => {
    return (
      compute.supportsOcpp &&
      compute.ocppStatus.code !== OCPP.DISCONNECTED &&
      compute.chargerStatus.code !== STATUSES.DISCONNECTED
    )
  })
})
</script>

<style lang="postcss" scoped>
.ocpp-icon {
  color: var(--grey-200);

  &[data-status="connected"] {
    color: #232451;
  }

  &[data-status="connecting"] {
    color: #d7c74b;
  }

  &[data-status="disconnected"] {
    color: #b8b8b8;
  }

  &[data-status="error"] {
    color: #bf5d58;
  }
}
</style>
