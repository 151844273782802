<template>
  <wb-card
    :headline="i18n.t('mywb.account.face-id-title')"
    :subhead="i18n.t('mywb.account.face-id-description')"
    alignment="center"
    filled
  >
    <template v-if="errors.fileSize" #content>
      <div class="has-text-danger-500">
        {{ errors.fileSize }}
      </div>
    </template>

    <template #actions>
      <wb-user-avatar
        class="has-cursor-pointer"
        type="square"
        size="large"
        data-test-id="avatarExtraLarge"
        :initials="compute.initials"
        :src="data.avatar"
        @on-click="methods.openInput"
      />
      <input
        id="file-input"
        ref="fileInput"
        data-test-id="file-input"
        type="file"
        accept="image/*"
        class="is-hidden"
        @change="methods.validateImage"
      >
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="methods.openInput"
      />
    </template>
  </wb-card>
</template>

<script lang="ts">
export default {
  name: 'AccountPersonalDataAvatar'
}
</script>

<script setup lang="ts">
import { getServerError } from '@/utilities/errorMessages'
import api from '@/api'
import state from '@/state'
import { userInitials } from '@/utilities/users'
import { computed, reactive, ref } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const notify = useNotify()
const i18n = useI18n()
const fileInput = ref<HTMLElement>()
const { yup, defineSchema, errors, validate } = useValidator()

const data = reactive({
  avatar: '',
  fileSize: 0
})

defineSchema(data, {
  fileSize: yup.number().max(200000, i18n.t('mywb.error.image-too-large'))
})

const compute = reactive({
  initials: computed(() => userInitials(state.user.userLogged))
})

const methods = {
  async validateImage (event: Event) {
    const target = event.target as HTMLInputElement
    data.fileSize = target?.files?.[0].size ?? 0
    validate(() => target.files?.[0] && methods.onSelectFile(target.files[0]))
  },

  openInput () {
    fileInput.value?.click()
  },

  readUploadedFileAsBinary (inputFile: File) {
    const reader = new FileReader()
    return new Promise<ProgressEvent<FileReader>>(resolve => {
      reader.onload = file => resolve(file)
      reader.readAsDataURL(inputFile)
    })
  },

  getExtension (filename: string) {
    return ((/[.]/.exec(filename)) && /[^.]+$/.exec(filename)?.[0]) ?? ''
  },

  getImageBytes (fileContents: ProgressEvent<FileReader>) {
    const result = fileContents?.target?.result as string

    return result.split?.(',')?.[1]
  },

  async onSelectFile (file: File) {
    try {
      if (file && !errors.fileSize) {
        const fileContents = await methods.readUploadedFileAsBinary(file)
        await api.chargerAccess.updateUserImage({
          name: file.name,
          extension: methods.getExtension(file.name),
          bytes: methods.getImageBytes(fileContents),
          size: file.size
        })

        const userDetail = await api.users.getUserDetail(state.user.userLogged.id)
        state.user.setUserLogged(userDetail)
        data.avatar = userDetail.avatar
        notify.success(i18n.t('mywb.common.changes-saved'))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
      } else {
        throw error
      }
    }
  }
}

function created () {
  data.avatar = state.user.userLogged.avatar
}

created()
</script>
