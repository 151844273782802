<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.chargers') }}
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canAddCharger"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.add-charger') : ''"
        data-test-id="addChargerButton"
        icon="add"
        type="primary"
        class="ml-12"
        @click="emit('on-add-charger')"
      />
    </template>

    <template #filters>
      <chargers-filters
        :loading="props.loading"
      />
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargersFilters from '@/components/filters/ChargersFilters.vue'
import { useMq } from 'vue3-mq'

const mq = useMq()
const i18n = useI18n()
const emit = defineEmits([
  'on-add-charger'
])

interface Props {
  loading? : boolean
}

const props = defineProps<Props>()
</script>
