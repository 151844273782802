import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import type { InvoicesApi } from '@/types'

export default {
  async getPlanInvoices (groupId: number): Promise<InvoicesApi.apiGroupsInvoices> {
    return await axios.get(endpoints.v3.groups_groupId_invoices.replace('{groupId}', groupId?.toString()))
  },

  async payInvoice (invoiceId: number): Promise<void> {
    return await axios.post(endpoints.v3.invoices_invoiceId_payments.replace('{invoiceId}', invoiceId.toString()))
  },

  feeInvoiceDetailsLink (organizationUid: string, invoiceNumber: string) {
    return axios.getUri() + endpoints.v4.fee_invoice_details
      .replace('{organizationUid}', organizationUid)
      .replace('{invoiceNumber}', invoiceNumber)
  },

  async getFeesInvoices (organizationUid: string): Promise<InvoicesApi.apiWallboxFeeInvoices> {
    return await axios.get(endpoints.v4.fee_invoices.replace('{organizationUid}', organizationUid))
  }
}
