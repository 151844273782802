<template>
  <shared-header>
    <template #title>
      <span v-if="!data.loading">
        {{ data.location?.name }}
      </span>
      <wb-spinner v-else type="grey" />
    </template>

    <template #actions>
      <wb-popover>
        <template #activator>
          <wb-button
            v-if="permissions.showPublishOnElectromaps && compute.chargers.length"
            data-test-id="publishCta"
            type="white"
            outlined
            icon="electromaps"
            :label="i18n.t('mywb.location.publish-electromaps')"
            @click="send('publishChargers')"
          />
        </template>
        <template #tooltip>
          {{ i18n.t('mywb.common.promote-your-chargers') }}
        </template>
      </wb-popover>

      <action-menu-location
        @on-edit="methods.editLocation"
        @on-delete="methods.deleteGroup"
      />
    </template>
  </shared-header>

  <div
    class="location-layout g-32"
    :class="{ 'with-eletromaps-banner': permissions.showPublishOnElectromaps }"
  >
    <publish-on-electromaps-component
      v-if="permissions.showPublishOnElectromaps"
      class="electromaps"
      :has-actions="compute.chargers.length > 0"
      @publish-on-electromaps="send('publishChargers')"
    />

    <location-settings-overview
      data-test-id="summaryLocation"
      :location="data.location"
      class="summary"
      show-map
    />

    <location-chargers-table
      v-if="!data.loading && data.location"
      :group-id="compute.groupId"
      :chargers="compute.chargers"
      :location="data.location"
      :loading-chargers="data.loadingChargers"
      @update-chargers="created()"
      @add-chargers="send('addChargers')"
    />
  </div>

  <shared-confirmation-modal
    v-if="isCurrentState('modalDeleteWithoutChargers')"
    data-test-id="chargersGroupDeleteModal"
    :title="i18n.t('mywb.common.delete-confirmation', { element: data.location?.name })"
    :label-confirmation-button="i18n.t('mywb.common.delete')"
    type="danger"
    @on-confirm="methods.handleLocationDelete"
    @on-close="send('close')"
  />

  <location-create-modal
    v-if="isCurrentState('modalEditLocation')"
    data-test-id="chargersGroupEditModal"
    :group-id="data.group?.id"
    @on-close="send('close')"
    @on-edit-finish="created()"
  />

  <location-publish-electromaps-modal
    v-if="isCurrentState('modalPublishChargers') && data.location"
    :location="data.location"
    :chargers="compute.chargers"
    @on-close="send('close')"
  />

  <location-assign-chargers-modal
    v-if="isCurrentState('modalAddChargers') && data.group"
    :group="data.group"
    @on-close="send('close')"
  />

  <location-with-chargers-delete-modal
    v-if="isCurrentState('modalDeleteWithChargers') && data.location"
    :location="data.location"
    :chargers="compute.chargers"
    @on-close="send('close')"
    @on-delete="router.push({ name: 'locations' })"
  />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, watch } from 'vue'

import api from '@/api'
import LocationChargersTable from '@/components/tables/LocationChargersTable.vue'
import LocationPublishElectromapsModal from '@/components/modals/LocationPublishElectromapsModal.vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import LocationAssignChargersModal from '@/components/modals/LocationAssignChargersModal.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import PublishOnElectromapsComponent from '@/components/PublishOnElectromapsComponent.vue'
import LocationCreateModal from '@/components/modals/LocationCreateModal.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import LocationSettingsOverview from '@/components/locations/LocationSettingsOverview.vue'
import LocationWithChargersDeleteModal from '@/components/modals/LocationWithChargersDeleteModal.vue'
import ActionMenuLocation from '@/components/locations/ActionMenuLocation.vue'
import { useStateMachine } from '@/hooks'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import { useNotify } from '@wallbox/toolkit-ui'
import type { Charger, Group, Locations } from '@/types'

const i18n = useI18n()
const router = useRouter()
const notify = useNotify()

interface Props {
  groupId: string
}
const props = defineProps<Props>()

const { isCurrentState, send } = useStateMachine({
  initial: 'layout',
  states: {
    layout: {
      transitions: {
        deleteWithChargers: 'modalDeleteWithChargers',
        deleteWithoutChargers: 'modalDeleteWithoutChargers',
        editLocation: 'modalEditLocation',
        publishChargers: 'modalPublishChargers',
        addChargers: 'modalAddChargers'
      },
      on: {
        publishChargers: () => trackDataAction('account-menu', { source: 'location-button' })
      }
    },

    modalDeleteWithChargers: {
      transitions: {
        close: 'layout'
      }
    },

    modalDeleteWithoutChargers: {
      transitions: {
        close: 'layout'
      }
    },

    modalEditLocation: {
      transitions: {
        close: 'layout'
      }
    },

    modalPublishChargers: {
      transitions: {
        close: 'layout'
      }
    },

    modalAddChargers: {
      transitions: {
        close: 'layout'
      }
    }
  }
})

interface Data {
  loading: boolean
  group?: Group.Group
  location?: Locations.Location
  loadingChargers?: boolean
}

const data = reactive<Data>({
  loading: false,
  loadingChargers: false
})

const compute = reactive({
  groupId: computed((): number => parseInt(props.groupId, 10)),

  chargers: computed((): Charger.Charger[] => {
    return (state.groups.locations ?? []).find(({ id }) => id === compute.groupId)?.chargers ?? []
  })
})

const methods = {
  editLocation (close: () => void) {
    close()

    send('editLocation')
  },

  deleteGroup (close: () => void) {
    close()

    if (compute.chargers.length > 0) {
      send('deleteWithChargers')
    } else {
      send('deleteWithoutChargers')
    }
  },

  async getLocation (group: Group.Group) {
    const filters = [{
      field: 'group_uid',
      operator: 'eq',
      value: group.uid
    }]

    const locations = await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid, filters })

    return locations.data[0]
  },

  async handleLocationDelete () {
    try {
      router.push({ name: 'locations' })
      await api.locations.deleteLocation({ id: data.location?.id ?? '-1' })
      notify.success(i18n.t('mywb.common.location-removed-successfully'))
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

async function created () {
  data.loading = true
  data.loadingChargers = true
  const { result } = await api.locations.getGroup(compute.groupId)
  data.group = result
  data.location = await methods.getLocation(data.group)

  data.loading = false

  const { result: { groups } = {} } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
  state.groups.set('groups', groups)

  api.groups.getAllDataOrganizations().then(({ result: { groups } = {} }) => state.groups.set('groups', groups))
  data.loadingChargers = false
}

created()

watch(() => data.group, () => state.routes.hydrateCurrentBreadCrumb(data.group?.name ?? ''))
</script>

<style lang="postcss" scoped>
:deep(.is-normal.is-icon) {
  padding: 18px;
}

.delete-button.button-menu {
  color: var(--danger-500);
}

.location-layout {
  display: grid;
  grid-auto-flow: dense;

  @media (--desktop) {
    grid-template-columns: 2fr 1fr;
  }

  &.with-eletromaps-banner {
    grid-template-rows: auto 1fr;
  }
}

.summary {
  @media (--desktop) {
    position: sticky !important;
    top: var(--header-height);
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: start;
  }
}
</style>
