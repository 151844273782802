import ENV from '@/engine/env/web.env'
import axios from 'axios'
import requestInterceptors from '@/api/config/interceptors/request'
import responseInterceptors from '@/api/config/interceptors/response'

const axiosInstance = axios.create({
  baseURL: ENV.api.baseURL
})

requestInterceptors(axiosInstance)
responseInterceptors(axiosInstance)

export default axiosInstance
