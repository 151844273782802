<template>
  <dashboard-card
    title="mywb.common.users"
    subtitle="mywb.common.manage-users"
    icon-color="var(--grey-400)"
    icon="person"
    :number="compute.totalUsers"
    :loading="props.loading || data.loading"
  >
    <div class="is-grid mt-16">
      <wb-button
        icon="add"
        data-test-id="addUsersBtn"
        :label="t('mywb.common.add-user')"
        type="white"
        outlined
        :to="{
          name: 'users',
          query: {
            'add-users': '1'
          }
        }"
      />

      <wb-button
        data-test-id="viewAllSettings"
        :label="t('mywb.common.view-all')"
        type="white"
        :to="{ name: 'users' }"
      />
    </div>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'
import { numbers } from '@wallbox/toolkit-ui'
import api from '@/api'

const { t, locale } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

const data = reactive({
  loading: false
})

const compute = reactive({
  totalUsers: computed((): string => {
    return numbers.toLocaleNumber(compute.users, locale.value)
  }),

  users: computed(() => {
    let { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    if (state.filters.dashboardFilters.locations.length > 0) {
      subgroups = subgroups.filter(group => state.filters
        .dashboardFilters.locations.some(location => location.group_uid === group.uid))
    }

    const chargers = new Set(subgroups.flatMap(group => group.chargers.map(charger => charger.id)))

    const users = state.user.getGroupsByAccessConfig.flatMap(group => {
      return group.accessConfigs.flatMap(accessConfig => {
        if (accessConfig.chargers.some(charger => chargers.has(charger))) {
          return accessConfig.users
        }

        return []
      })
    })

    const uniqUsers = new Set(users.map(user => user.id)).size

    return uniqUsers
  })
})

async function created () {
  data.loading = true

  const users = await api.users.getUserGroups()
  state.user.set('usersGroup', users)

  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  align-items: center;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 8px;
}
</style>
