import type { Charger, ChargerApi } from '@/types'
import { STATUSES, getChargerStatus } from '@/utilities/charger/chargerStatuses'

export const getChargerVersion = (
  charger: any
) => {
  return charger.softwareVersion || charger.software?.currentVersion
}

export const isUpdatesAvailable = (
  charger: Charger.AnyTypeOfCharger | ChargerApi.GetChargerData['data'],
  software: Charger.Software
) => {
  const updateAvailable = software?.updateAvailable ?? false

  const status = getChargerStatus(charger)?.code

  return (
    updateAvailable &&
    status !== STATUSES.DISCONNECTED &&
    status !== STATUSES.CHARGING &&
    status !== STATUSES.DISCHARGING)
}
