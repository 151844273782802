<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.location') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.location.address-description') }}
    </div>

    <edit-location-form
      :ref="setRef('editLocation')"
      :group-id="props.groupId"
      @on-edit="emit('on-edit', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import EditLocationForm from '@/components/forms/EditLocationForm.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify, useTemplateRef } from '@wallbox/toolkit-ui'

const { refs, setRef } = useTemplateRef()

const i18n = useI18n()
const notify = useNotify()

interface PropsType {
  groupId?: number
}

const props = defineProps<PropsType>()

type Events = {
  (e: 'on-edit', created: { groupId: number, country?: string, timezoneId?: string}): void
}

const emit = defineEmits<Events>()

const methods = {
  async isValidateForm () {
    if (await refs.editLocation.validateForm()) {
      try {
        await refs.editLocation.submitForm()
        return true
      } catch (error) {
        notify.error(i18n.t('mywb.error.unexpected-error'))
      }
    }
    return false
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
