import { trackDataApiErrorResponse } from '@/engine/metrics/trackDataManager'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'
import GenericError from '@/api/config/interceptors/errorResponse/genericError'
import type { AxiosError } from 'axios'
import type { ApiErrorResponse } from '@/types/api/clientApi.api.types'

export default async function (error: AxiosError<ApiErrorResponse>) {
  if (error.response) {
    trackDataApiErrorResponse(error)

    if (error.response.data) {
      return await Promise.reject(new HttpError(error.response.data, error.response.status))
    }
  }

  return await Promise.reject(error instanceof Error ? error : new GenericError(error))
}
