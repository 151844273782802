<template>
  <wb-input
    v-model="compute.filteredText"
    data-test-id="searchButton"
    :disabled="props.loading"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.rates') })"
    icon="search"
    type="search"
  />

  <shared-filter
    v-model="compute.filteredFixedFee"
    :name="i18n.t('mywb.common.fixed-fee')"
    :options="data.fixedFeeOptions"
    :reduce="(item: typeof data.fixedFeeOptions[number]) => item.id"
    :loading="props.loading"
    data-test-id="fixedFeeFilter"
    multiple
    @on-error="state.filters.resetFilterValue({ filter: 'ratesFilters', key: 'fixedFee' })"
  />

  <shared-filter
    v-model="compute.filteredVariableType"
    :name="i18n.t('mywb.rates.variable-fee')"
    :options="data.variableTypeOptions"
    :reduce="(item: typeof data.variableTypeOptions[number]) => item.id"
    :loading="props.loading"
    data-test-id="variableFeeFilter"
    multiple
    @on-error="state.filters.resetFilterValue({ filter: 'ratesFilters', key: 'variableType' })"
  />

  <shared-filter
    v-model="compute.filteredAssigned"
    :name="i18n.t('mywb.common.assigned')"
    :options="data.assignedOptions"
    :reduce="(item: typeof data.assignedOptions[number]) => item?.id"
    :loading="props.loading"
    data-test-id="assignedFilter"
    multiple
    @on-error="state.filters.resetFilterValue({ filter: 'ratesFilters', key: 'assigned' })"
  />

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'ratesFilters' })"
  />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'

const i18n = useI18n()

interface Props {
  loading?: boolean
}

const props = defineProps<Props>()

const data = reactive({
  fixedFeeOptions: [
    {
      label: i18n.t('mywb.rates.with-fixed-fee'),
      id: true
    },

    {
      label: i18n.t('mywb.rates.without-fixed-fee'),
      id: false
    }
  ],

  variableTypeOptions: [
    {
      label: i18n.t('mywb.rates.without-variable-fee'),
      id: 'access'
    },

    {
      label: i18n.t('mywb.common.charging-time.fee'),
      id: 'time'
    },

    {
      label: i18n.t('mywb.common.consumed-energy.fee'),
      id: 'energy'
    }
  ],

  assignedOptions: [
    {
      label: i18n.t('mywb.common.assigned'),
      id: true
    },

    {
      label: i18n.t('mywb.rates.unassigned'),
      id: false
    }
  ]
})

const compute = reactive({
  filteredText: computed({
    get () {
      return state.filters.ratesFilters?.text
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'ratesFilters', key: 'text', value })
    }
  }),

  filteredFixedFee: computed({
    get () {
      return state.filters.ratesFilters?.fixedFee ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'ratesFilters', key: 'fixedFee', value })
    }
  }),

  filteredVariableType: computed({
    get () {
      return state.filters.ratesFilters?.variableType ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'ratesFilters', key: 'variableType', value })
    }
  }),

  filteredAssigned: computed({
    get () {
      return state.filters.ratesFilters?.assigned ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'ratesFilters', key: 'assigned', value })
    }
  })
})
</script>
