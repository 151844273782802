import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import type { GlobalApi } from '@/types'

export default {
  async getCurrencies (): Promise<GlobalApi.getCurrenciesResponse> {
    return await axios.get(endpoints.v2.currencies)
  },

  async getCountries (): Promise<GlobalApi.getCountriesResponse> {
    return await axios.get(endpoints.v2.countries)
  },

  async getStatesByCountry (countryCode: string): Promise<GlobalApi.getStatesResponse> {
    return await axios.get<unknown, { states: Array<{ name: string, iso2: string }> }>(
      endpoints.v2.countries_countryCode_states.replace('{countryCode}', countryCode))
      .then(response => {
        return response.states.map(({ name, iso2 }) => ({ label: name, name, iso2 }))
      })
  },

  async getTimezones (): Promise<GlobalApi.getTimezonesResponse> {
    return await axios.get(endpoints.v4.chargers_timezones)
  }
}
