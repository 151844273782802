<template>
  <wb-modal
    width="60"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.edit-billing-information') }}
    </template>

    <wb-form>
      <div class="is-grid-center g-16">
        <p class="is-size-400">
          {{ i18n.t('mywb.user.is-business-purchase') }}
        </p>
        <wb-radio-card
          v-model="data.company"
          :value="true"
        >
          <p class="is-size-400 has-text-grey-900" data-test-id="commercialYesRadioBtn">
            {{ i18n.t('mywb.common.yes') }}
          </p>
        </wb-radio-card>
        <wb-radio-card
          v-model="data.company"
          :value="false"
        >
          <p class="is-size-400 has-text-grey-900" data-test-id="commercialNoRadioBtn">
            {{ i18n.t('mywb.common.no') }}
          </p>
        </wb-radio-card>
      </div>

      <wb-notification
        v-if="data.vatError && data.company"
        type="info"
        icon="info_filled"
      >
        {{ i18n.t('mywb.error.company-number-not-verified') }}
      </wb-notification>

      <template v-if="data.company">
        <wb-input
          v-model="data.name"
          type="text"
          data-test-id="companyNameInput"
          :label="i18n.t('mywb.common.enterprise')"
          :error="errors.name"
        />
        <wb-input
          v-model="data.cif"
          type="text"
          data-test-id="cifInput"
          :label="!state.organizations.isRegionUS ? i18n.t('mywb.common.vat-number') : i18n.t('mywb.common.tax-id')"
          :hint="!state.organizations.isRegionUS
            ? i18n.t('mywb.common.vat-number-hint') : i18n.t('mywb.common.tax-id-number-hint')"
          persistent-hint
          :error="errors.cif || data.errors.cif"
        />
      </template>
      <template v-else>
        <wb-input
          v-model="data.name"
          type="text"
          data-test-id="nameInput"
          :label="i18n.t('mywb.common.name')"
          :error="errors.name"
        />
        <wb-input
          v-model="data.surname"
          type="text"
          data-test-id="surnameInput"
          :label="i18n.t('mywb.user.surname')"
          :error="errors.surname"
        />
      </template>
      <wb-input
        v-model="data.address"
        type="text"
        data-test-id="addressInput"
        :label="i18n.t('mywb.common.address')"
        :error="errors.address"
      />
      <wb-input
        v-model="data.city"
        type="text"
        class="span-2"
        data-test-id="cityInput"
        :label="i18n.t('mywb.common.city')"
        :error="errors.city"
      />
      <wb-input
        v-model="data.zipCode"
        type="text"
        class="span-2"
        data-test-id="zipCodeInput"
        :label="i18n.t('mywb.common.postal-code')"
        :error="(errors.zipCode || data.errors.zipCode)"
      />

      <country-and-state-select-form
        :ref="setRef('countryState')"
        v-model:countryId="data.countryId"
        v-model:countryCode="data.countryCode"
        v-model:stateCode="data.stateCode"
        is-country-disabled
        class="is-fullwidth"
        :state-error="(errors.state || data.errors.state)"
      />

      <currency-select-form
        :country-code="data.countryCode"
        data-test-id="currency"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
          :loading="data.loading"
          @click="validate(methods.clickSaveBillingInfo)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'
import CurrencySelectForm from '@/components/forms/CurrencySelectForm.vue'

import { reactive, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { useNotify, useTemplateRef } from '@wallbox/toolkit-ui'
import api from '@/api'
import { getRegionByCountry } from '@/utilities/regions'
import state from '@/state'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'
import type { BillingInfo } from '@/types'

const i18n = useI18n()
const notify = useNotify()
const { refs, setRef } = useTemplateRef()
const { yup, errors, defineSchema, validate } = useValidator()

interface Props {
  billingInfo: BillingInfo.BillingInfo
  groupId: number
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-updated'): void,
  (e: 'on-close'): void
}
const emit = defineEmits<Events>()

const country = reactive({
  nonEuRegion: () => {
    const region = getRegionByCountry(state.organizations.getCurrentOrganization.country_code)?.key || ''
    return region !== 'europeanUnion' && region !== 'spain'
  }
})

const methods = {
  getCountryFromOrganization (obtain: 'id' | 'code') {
    const countryCode = state.organizations.getCurrentOrganization.country_code
    return state.global.getCountries.find(country => country.iso2 === countryCode)?.[obtain]
  },

  async clickSaveBillingInfo () {
    data.loading = true
    try {
      await methods.saveBillingInfo()
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 1001) {
          data.vatError = true
          data.errors.cif = i18n.t('mywb.error.invalid-company-number')
        } else if (error?.code === 6014) {
          data.errors.state = i18n.t('mywb.error.invalid-state')
          data.errors.zipCode = i18n.t('mywb.error.invalid-postal-code')
        } else {
          notify.error(i18n.t('mywb.error.unexpected-error'))
        }
      } else {
        throw error
      }
    }
    data.loading = false
  },

  async saveBillingInfo () {
    if (await refs.countryState.isValidCountryState()) {
      const billingInfo = {
        company: data.company,
        name: data.name,
        surname: data.company ? undefined : data.surname,
        address: data.address,
        city: data.city,
        zipCode: data.zipCode,
        country: data.countryId,
        state: data.stateCode,
        cif: data.company ? data.cif : null
      }
      await api.billing.putBillingInfo({ billingInfo, groupId: props.groupId })

      data.vatError = false
      notify.success(i18n.t('mywb.common.changes-saved'))
      emit('on-close')
      emit('on-updated')
    }
  }
}

const data = reactive({
  loading: false,
  vatError: false,
  errors: {
    cif: '',
    state: '',
    zipCode: ''
  },
  company: !!props.billingInfo?.company,
  name: props.billingInfo?.name || '',
  surname: props.billingInfo?.surname || '',
  cif: props.billingInfo?.cif || '',
  address: props.billingInfo?.address || '',
  city: props.billingInfo?.city || '',
  zipCode: props.billingInfo?.zipCode || '',
  countryId: props.billingInfo?.country?.id || methods.getCountryFromOrganization('id') as number,
  countryCode: props.billingInfo?.country?.code || methods.getCountryFromOrganization('code') as string,
  stateCode: props.billingInfo?.state || ''
})

watchEffect(() => {
  defineSchema(data, {
    company: yup.boolean(),
    cif: data.company && !country.nonEuRegion()
      ? yup.string().required()
      : yup.string().nullable(),
    name: yup.string().required(),
    surname: yup.string().when('company', {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
    address: yup.string().required(),
    city: yup.string().required(),
    zipCode: yup.string().required()
  })
})

</script>

<style lang="postcss" scoped>
.is-grid-center {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: max-content min-content min-content;
  }
}

</style>
