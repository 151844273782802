import { reactive, computed, toRefs, toRef } from 'vue'
import { numbers } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'

import api from '@/api'
import state from '@/state'
import { isPaymentsCompatible } from '@/utilities/charger/chargerCompatible'
import type { Charger, Locations, Rate } from '@/types'

export const useAssignedRatesApi = (location?: Locations.Location) => {
  interface Data {
    loading: boolean
    rates: Record<string, Rate.Rate>
    chargers?: Array<Charger.Charger | Charger.ChargerWithRates>
    schedules: Record<string, Rate.ScheduleCharger[]>
  }

  const data = reactive<Data>({
    loading: false,
    chargers: [],
    schedules: {},
    rates: {}
  })

  const compute = reactive({
    chargersWithRates: computed((): Charger.ChargerWithRates[] => data.chargers?.map(charger => ({
      ...charger,
      payPerChargeSchedule: methods.getScheduleInfo(charger, 'pay_per_charge'),
      payPerMonthSchedule: methods.getScheduleInfo(charger, 'pay_per_month'),
      isPayPerChargeCompatible: isPaymentsCompatible(charger, 'pay_per_charge', location),
      isPayPerMonthCompatible: isPaymentsCompatible(charger, 'pay_per_month', location)
    })) ?? [])
  })

  const methods = {
    getScheduleInfo (charger: Charger.AnyTypeOfCharger, type: 'pay_per_charge' | 'pay_per_month'):
    { status?: 'active' | 'frozen', rate?: Rate.Rate, isSchedule: boolean } {
      const schedule = data.schedules[charger.uid]?.find(schedule => schedule.paymentType === type)
      if (!schedule) {
        return {
          status: undefined,
          rate: undefined,
          isSchedule: false
        }
      }

      const rates: Set<string> = new Set(Object.values(schedule.schedule).flat())

      if (rates.size === 1) {
        const rateId = schedule.schedule.monday[0]

        return {
          status: schedule.status,
          isSchedule: false,
          rate: data.rates[rateId]
        }
      } else {
        return {
          status: schedule.status,
          isSchedule: true,
          rate: undefined
        }
      }
    },

    setChargers (chargers: Charger.Charger[]) {
      data.chargers = chargers
    },

    async getData (chargers: Array<Charger.Charger | Charger.ChargerWithRates>) {
      data.chargers = chargers
      data.loading = true

      const apiCalls = chargers.map(async charger => await api.rates.getChargerRatesWeekSchedules(charger.uid))
      try {
        data.schedules = await Promise.all(apiCalls).then(results => results.reduce((acum, result, index) => ({
          ...acum,
          [chargers[index].uid]: result.data
        }), {}))

        const rates: Set<string> = new Set(Object.values(data.schedules)
          .flat().map(schedule => Object.values(schedule.schedule).flat()).flat())

        const apiRatesCalls = [...rates].map(async rateId =>
          await api.rates.getRate({ groupId: state.groups.groupRelatedToOrganization.id, rateId }))

        data.rates = await Promise.all(apiRatesCalls).then(results => results.reduce((acum, result) => ({
          ...acum,
          [result.id]: result
        }), {}))
      } catch (error) {
        return []
      } finally {
        data.loading = false
      }
    }
  }

  return {
    ...toRefs(data),
    chargersWithRates: toRef(compute, 'chargersWithRates'),
    getData: methods.getData,
    setChargers: methods.setChargers
  }
}

interface Props {
  rate?: Rate.Rate
}

export const useRateInfoParser = (props: Props) => {
  const i18n = useI18n()

  const methods = {
    getLabel (rate?: Rate.Rate) {
      if (rate?.variable_fee_type === 'energy') {
        return i18n.t('mywb.common.cost-per-kwh')
      } else if (rate?.variable_fee_type === 'time') {
        return i18n.t('mywb.common.cost-per-hour')
      }
      return ''
    }
  }

  const compute = reactive({
    fixedFeePrice: computed(() => numbers
      .toLocaleCurrencySymbol(props.rate?.fixed_fee, props.rate?.currency_code, i18n.locale.value)),
    variableFeePrice: computed(() => numbers
      .toLocaleCurrencySymbol(props.rate?.variable_fee_price, props.rate?.currency_code, i18n.locale.value)),
    variableLabel: computed(() => {
      return methods.getLabel(props.rate)
    })
  })

  return { compute }
}
