import state from '@/state'
import type { GlobalState } from '@/state'
import AuthActions from '@/utilities/authActions'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  const valuesAuthActions = Object.values(AuthActions)
  const authAction = valuesAuthActions.find(value => value.action === to.query.action)?.hasToLogout

  const hasToLogout = to.query.forgot ?? authAction

  if (hasToLogout) {
    state.global.removeLocalStorageSensitiveInfo()
    state.user.setInitialState()
    state.groups.setInitialState()
  }

  const { token } = state.global.get('auth') as GlobalState['auth']

  let route: Partial<NavigationGuardNext> | null = null

  if (requiresAuth && !token) {
    route = { name: 'login', query: to.query }
    next(route)
  } else if (token && to.name === 'login') {
    route = { name: 'chargers', query: to.query }
    next(route)
  }

  next()
}
