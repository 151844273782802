<template>
  <div class="wrapper-grid g-32" data-test-id="chargerDetailSettings">
    <div class="menu-sticky">
      <vertical-menu-component
        v-model="data.selected"
        :title="i18n.t('mywb.common.general')"
        :menu="data.generalMenu"
      />

      <vertical-menu-component
        v-if="compute.supportsOcpp"
        v-model="data.selected"
        :title="i18n.t('mywb.charger.connectivity')"
        :menu="data.connectivityMenu"
      />

      <vertical-menu-component
        v-model="data.selected"
        :title="i18n.t('mywb.common.security')"
        :menu="compute.securityMenu"
      />

      <vertical-menu-component
        v-model="data.selected"
        :title="i18n.t('mywb.common.payments')"
        :menu="data.paymentsMenu"
      />
    </div>

    <div>
      <h2 v-t="'mywb.common.general'" class="mb-16 is-header-text" />
      <div class="is-flex g-8 general-settings">
        <charger-edit-name-card
          id="name"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'name'}"
        />
        <charger-edit-location-card
          id="location"
          :loading="props.loading"
          :location="data.location"
          :class="{ 'selected-blink': data.selected === 'location'}"
        />
        <charger-power-limit-card
          id="current"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'current'}"
        />
        <charger-edit-timezone-card
          id="timezone"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'timezone'}"
        />
      </div>

      <template v-if="compute.supportsOcpp">
        <h2 v-t="'mywb.charger.connectivity'" class="mt-32 mb-16 is-header-text" />
        <charger-edit-ocpp-card
          id="ocpp"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'ocpp'}"
        />
      </template>

      <h2 v-t="'mywb.common.security'" class="mt-32 mb-16 is-header-text" />
      <charger-settings-charge-mode-card
        :loading="props.loading"
        :selected="data.selected"
      />
      <charger-lock-gun
        v-if="compute.supportsGunLock"
        id="gunLock"
        :loading="props.loading"
        :class="{ 'selected-blink': data.selected === 'gunLock'}"
      />

      <h2 v-t="'mywb.common.payments'" class="mt-32 mb-16 is-header-text" />
      <charger-settings-payments-card
        :loading="props.loading || data.loading"
        :selected="data.selected ?? ''"
        :location="data.location"
      />

      <charger-settings-energy-cost-card
        v-if="permissions.canWriteEnergyCost"
        id="energyCost"
        :loading="props.loading || data.loading"
        :location="data.location"
        :class="{ 'selected-blink': data.selected === 'energyCost'}"
        class="mt-8"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ChargerLockGun from '@/components/charger/ChargerLockGun.vue'
import ChargerEditNameCard from '@/components/charger/ChargerEditNameCard.vue'
import ChargerPowerLimitCard from '@/components/charger/ChargerPowerLimitCard.vue'
import ChargerEditTimezoneCard from '@/components/charger/ChargerEditTimezoneCard.vue'
import ChargerEditLocationCard from '@/components/charger/ChargerEditLocationCard.vue'
import ChargerEditOcppCard from '@/components/charger/ChargerEditOcppCard.vue'
import ChargerSettingsPaymentsCard from '@/components/charger/ChargerSettingsPaymentsCard.vue'
import ChargerSettingsChargeModeCard from '@/components/charger/ChargerSettingsChargeModeCard.vue'
import VerticalMenuComponent from '@/components/VerticalMenuComponent.vue'
import { reactive, computed, watchEffect, onMounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { isGunLockCompatible } from '@/utilities/charger/chargerCompatible'
import { supportsOcpp } from '@/utilities/charger/chargerOcppSettings'
import ChargerSettingsEnergyCostCard from '@/components/charger/ChargerSettingsEnergyCostCard.vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api'
import type { Locations, Rate } from '@/types'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})

interface Menu {
  key: string
  icon: string
  text: string
}

interface Data {
  selected?: string
  generalMenu: Menu[]
  connectivityMenu: Menu[]
  paymentsMenu: Menu[]
  assignedRates: Rate.AssignedRate[]
  location?: Locations.Location
  loading?: boolean
}

const data: Data = reactive({
  generalMenu: [
    {
      key: 'name',
      icon: 'charger',
      text: i18n.t('mywb.charger.charger-name')
    },
    {
      key: 'location',
      icon: 'location',
      text: i18n.t('mywb.common.location')
    },
    {
      key: 'current',
      icon: 'bolt',
      text: i18n.t('mywb.common.power-limit')
    },
    {
      key: 'timezone',
      icon: 'clock',
      text: i18n.t('mywb.common.timezone')
    }
  ],

  connectivityMenu: [
    {
      key: 'ocpp',
      icon: 'ocpp',
      text: i18n.t('mywb.charger.ocpp')
    }
  ],

  paymentsMenu: [
    permissions.showPayments && {
      key: 'payPerCharge',
      icon: 'card',
      text: i18n.t('mywb.charger.pay-per-charge')
    },
    permissions.showPayments && {
      key: 'payPerMonth',
      icon: 'calendar',
      text: i18n.t('mywb.charger.pay-per-month')
    },
    permissions.canWriteEnergyCost && {
      key: 'energyCost',
      icon: 'ev_station',
      text: i18n.t('mywb.common.energy-cost')
    }
  ].filter(Boolean as unknown as ExcludesNullish),

  assignedRates: []
})

const compute = reactive({
  supportsGunLock: computed(() => {
    const gunCompatible = isGunLockCompatible(state.charger.getCurrentCharger.chargerData)
    return ['update', 'compatible', 'ocpp'].includes(gunCompatible.code)
  }),

  supportsOcpp: computed(() => {
    return supportsOcpp(state.charger.getCurrentCharger.chargerData)
  }),

  securityMenu: computed((): Menu[] => [
    {
      key: 'access',
      icon: 'key',
      text: i18n.t('mywb.common.access')
    },
    {
      key: 'lock',
      icon: 'lock',
      text: i18n.t('mywb.common.auto-lock')
    },
    compute.supportsGunLock && {
      key: 'gunLock',
      icon: 'electrical_services',
      text: i18n.t('mywb.charger.gun-lock')
    }
  ].filter(Boolean as unknown as ExcludesNullish))
})

const methods = {
  async getLocation () {
    const filters = [{
      field: 'group_uid',
      operator: 'eq',
      value: state.charger.getCurrentCharger?.chargerData.groupUid
    }]

    const locations = await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid, filters })

    return locations.data[0]
  }
}

watchEffect(async () => {
  if (!props.loading) {
    data.loading = true

    data.location = await methods.getLocation()

    data.loading = false
  }
})

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView?.({
    behavior: 'smooth',
    block: 'center'
  })
})

onMounted(() => {
  if (route.query?.selected) {
    data.selected = route.query.selected?.toString() ?? ''
    router.push({ query: {} }).catch(() => {})
    setTimeout(() => {
      data.selected && document.getElementById(data.selected)?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center'
      })
    }, 300)
  }
})

</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.general-settings {
  flex-direction: column;
}

.menu-sticky {
  display: none;

  @media (--tablet) {
    display: block;
    position: sticky;
    top: 70px;
    height: calc(100vh - 125px);
    overflow-y: auto;
  }
}

.selected-blink {
  animation: blink 900ms linear;
  border-radius: 0.8rem;
}

@keyframes blink {
  0% { box-shadow: none; }
  50% { box-shadow: 0 0 10px var(--primary-500); }
  100% { box-shadow: none; }
}

</style>
