import type { VariableFeeType } from '@/types/data/rate.data.types'
import { useForm, useField } from 'vee-validate'
import { computed, reactive, type Ref } from 'vue'

export function useFormValidator (variableActivated: Ref<boolean>, fixedActivated: Ref<boolean>) {
  const validationSchema = computed(() => {
    return {
      name: 'required',
      fixedFee: {
        required: fixedActivated.value,
        min_value: 0,
        not_one_of: 0,
        not_exceeds_price_upper_limit: fixedActivated.value
      },
      variableType: { required: variableActivated.value },
      variablePrice: {
        required: variableActivated.value,
        min_value: 0,
        not_one_of: 0,
        not_exceeds_price_upper_limit: variableActivated.value
      }
    }
  })

  return useForm({ validationSchema })
}

export function useValidatedRate () {
  return reactive({
    name: { ...useField<string>('name') },
    fixedFee: { ...useField<number>('fixedFee') },
    variableType: { ...useField<VariableFeeType>('variableType') },
    variablePrice: { ...useField<number>('variablePrice') }
  })
}
