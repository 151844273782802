<template>
  <wb-table
    :options="data.options"
    :loading="props.loading"
    data-test-id="tableChargersGroups"
  >
    <locations-table-row
      v-for="location in props.locations"
      :key="location.id"
      :location="location"
      :data-test-id="`chargersGroup${location.name}`"
      @on-edit="emit('on-edit', $event)"
      @on-delete="emit('on-delete', $event)"
    />
  </wb-table>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import LocationsTableRow from '@/components/tables/LocationsTableRow.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useMq } from 'vue3-mq'
import type { Locations } from '@/types'
import type { TableProps } from '@wallbox/toolkit-ui'

const mq = useMq()
const i18n = useI18n()

interface PropsType {
  loading?: boolean
  locations: Locations.Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  loading: false,
  locations: () => []
})

type Events = {
  (e: 'on-edit', location?: Locations.Location): void,
  (e: 'on-delete', location?: Locations.Location): void,
}

const emit = defineEmits<Events>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: i18n.t('mywb.common.name'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.chargers'), align: 'center', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'right', minWidth: '200px', width: 'auto' }
    ],
    stickyHead: mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})
</script>
