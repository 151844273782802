interface JsonApi<T> {
  data: T[]
  relationships?: relationships
  pagination: {
    count: number
  }
}

type relationships = Record<string, {
  data: {
    id: string | number
    type: string
  }
}>

interface Data<T> {
  type: string
  id: string | number
  relationships?: relationships
  attributes?: T
}

interface DataEntry<T> {
  data: Array<Data<T>>
  included?: Omit<Array<Data<any>>, 'relationships'>
  meta?: {
    count: number
  }
}

export function formatResponseJsonApi<T> (dataJson: DataEntry<T>): JsonApi<T> {
  if (!dataJson.data) {
    return {
      data: [],
      pagination: { count: 0 }
    }
  }

  const formattedData = dataJson.data.map(item => {
    const relations = Object.values(item.relationships ?? { data: {} }).reduce((acc, relation) => {
      const entity = dataJson.included
        ?.find(subject => (subject.id === relation.data.id && subject.type === relation.data.type))
      return { ...acc, [relation.data?.type]: entity?.attributes }
    }, {})

    const { attributes, ...info } = item

    return { ...relations, ...info, ...attributes }
  })

  return {
    data: formattedData,
    pagination: dataJson.meta ?? { count: 0 }
  }
}
