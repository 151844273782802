<template>
  <content-component>
    <wb-cards-loader :loading="data.loading" class="wrapper">
      <locations-accordion
        v-model="data.chargers"
        :disabled="!compute.canUserAssignChargers"
        @update:model-value="methods.saveInBackend"
      />
    </wb-cards-loader>
  </content-component>
</template>

<script setup lang="ts">
import ContentComponent from '@/components/ContentComponent.vue'
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'

import { reactive, computed } from 'vue'
import { useNotify, dom } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import api from '@/api'
import state from '@/state'
import { hasRoleGreaterThan } from '@/utilities/users'

const i18n = useI18n()
const notify = useNotify()

const props = defineProps<{
  userId: string | number
}>()

interface DataType {
  user: any
  loading: boolean
  chargers: number[]
  accessConfigId: number,
  accessConfigName: string | null
}

const data: DataType = reactive({
  user: {},
  loading: false,
  chargers: [],
  accessConfigId: -1,
  accessConfigName: null
})

const compute = reactive({
  isUserGroup: computed((): boolean => {
    return data.user.accessConfigs
      ?.some?.((accessConfig: any) =>
        accessConfig.name && state.organizations.getCurrentOrganization.group_id === accessConfig.group)
  }),

  canUserAssignChargers: computed((): boolean => {
    return !compute.isUserGroup && hasRoleGreaterThan(+props.userId)
  })
})

const methods = {
  saveInBackend: dom.debounce(async (chargers: number[]) => {
    try {
      await api.accessConfig.updateAccessConfig({
        id: data.accessConfigId,
        chargers
      })
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  }, 1000)
}

async function created () {
  data.loading = true
  const { data: user } = await api.users.getUser(+props.userId)
  data.user = user

  const { result: chargerGroups } = await api.groups.getAllDataOrganizations()
  state.groups.set('groups', chargerGroups.groups)

  const accessConfig = user.accessConfigs
    .find(accessConfig => accessConfig.group === state.organizations.getCurrentOrganization.group_id)

  if (accessConfig) {
    data.accessConfigId = accessConfig.id
    data.accessConfigName = accessConfig.name || null
    data.chargers = accessConfig.chargers
  }

  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
.wrapper {
  @media (--desktop) {
    width: 50%;
  }
}
</style>
