<template>
  <rate-type-component
    v-model:activated="data.isActivated"
    v-model:schedule="data.schedule"
    :charger-schedule-id="data.chargerScheduleId"
    data-test-id="rateTypeCard"
    :rates="props.rates"
    :loading="props.loading"
    v-bind="compute.rateInfo"
    :charger="props.charger"
    :billing-country-iso2="props.billingCountryIso2"
    :compatible="props.compatible"
    choose-rate
    :allow-hourly-selection="props.allowHourlySelection"
    @update:activated="methods.checkRateAssignActive"
  >
    <slot v-if="data.isActivated && (data.schedule)" />
  </rate-type-component>
</template>

<script setup lang="ts">
import { reactive, watch, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import api from '@/api'
import state from '@/state'
import RateTypeComponent from '@/components/RateTypeComponent.vue'
import type { Charger, Rate } from '@/types'
import type { CountryCodes } from '@/types/data/currency.data.types'
import { moveScheduleFromUTCToLocal } from '@/utilities/rates/normalizeScheduleInTimezone'
import { trackDataAction, trackDataError } from '@/engine/metrics/trackDataManager'

const i18n = useI18n()

interface PropsType {
  charger: Charger.AnyTypeOfCharger
  rates: Rate.Rate[]
  type: 'payPerCharge' | 'payPerMonth'
  schedule?: Rate.ScheduleCharger
  loading?: boolean
  billingCountryIso2?: CountryCodes
  hasAutolock?: 0 | 1
  activated?: boolean
  title?: string
  subtitle?: string
  error?: string
  chooseRate?: boolean
  compatible: {
    isCompatible: boolean
    reason: string
  }
  allowHourlySelection?: boolean
}

const props = defineProps<PropsType>()

const emit = defineEmits([
  'schedule-updated'
])

interface Data {
  guestAccessModal: boolean
  isActivated: boolean
  types: {
    payPerCharge: {
      title: string
      subtitle: string
      type: 'pay_per_charge'
    },

    payPerMonth: {
      title: string
      subtitle: string
      type: 'pay_per_month'
    }
  },

  schedule?: Rate.Schedule
  chargerScheduleId?: string
}

const data: Data = reactive({
  guestAccessModal: false,
  isActivated: false,
  types: {
    payPerCharge: {
      title: i18n.t('mywb.charger.pay-per-charge'),
      subtitle: i18n.t('mywb.charger.pay-per-charge-assign-description'),
      type: 'pay_per_charge'
    },

    payPerMonth: {
      title: i18n.t('mywb.charger.pay-per-month'),
      subtitle: i18n.t('mywb.charger.pay-per-month-assign-description'),
      type: 'pay_per_month'
    }
  }
})

const compute = reactive({
  rateInfo: computed(() => data.types[props.type])
})

const methods = {
  async checkRateAssignActive () {
    if (props.schedule) {
      const payload = {
        chargerId: props.charger.uid,
        scheduleId: data.chargerScheduleId,
        payload: {
          status: data.isActivated ? 'active' : 'frozen',
          type: data.types[props.type].type,
          schedule: data.schedule
        }
      }
      try {
        api.rates.saveChargerSchedule(payload)

        trackDataAction('charger-schedule-updated', payload)

        if (data.isActivated) {
          await api.chargers.updateChargerConfig({
            id: props.charger.id,
            config: {
              auto_lock: 1,
              auto_lock_time: 60
            }
          })
        }
      } catch (error) {
        trackDataError('charger-schedule-error', { error })
        throw error
      }

      methods.scheduleUpdated(data.isActivated)
    }
  },

  async updateCharger () {
    const charger = await api.chargers.getCharger(props.charger.id)
    state.charger.setCurrentCharger(charger)
  },

  scheduleUpdated (isActive = true) {
    data.isActivated = isActive

    emit('schedule-updated', {
      paymentType: data.types[props.type].type,
      status: data.isActivated ? 'active' : 'frozen',
      schedule: data.schedule
    })
  },

  loadSchedule () {
    data.isActivated = props.schedule?.status === 'active'
    data.schedule = props.schedule?.schedule
      ? moveScheduleFromUTCToLocal(props.schedule?.schedule, state.charger.getCurrentCharger.config.timezone)
      : undefined

    data.chargerScheduleId = props.schedule?.id
  }
}

const watcherSchedule = watch(() => [props.schedule, props.rates], async () => {
  methods.loadSchedule()

  if (props.schedule && props.rates) {
    watcherSchedule?.()
  }
})

watch(() => state.charger.getCurrentCharger.config.timezone, async (value, oldValue) => {
  if (oldValue && value !== oldValue) {
    methods.loadSchedule()
  }
})
</script>
