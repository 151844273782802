<template>
  <auth-check-invite-user-modal
    v-if="data.isModalCheckInviteUserOpen"
    data-test-id="AuthCheckInviteUser"
    @close="data.isModalCheckInviteUserOpen=false"
  />

  <auth-activation-account-modal
    v-if="data.isModalActivacionAccountOpen"
    data-test-id="AuthActivationAccount"
    @close="data.isModalActivacionAccountOpen = false"
  />

  <auth-login-type-selector
    v-if="isCurrentState('typeSelector')"
    :play-animation="data.playAnimation"
    data-test-id="AuthLoginTypeSelector"
    @continue-email="send('next')"
  />

  <auth-login-continue-with-email
    v-else-if="isCurrentState('emailSelector')"
    data-test-id="AuthContinueWithEmail"
    @continue-password="methods.toPassword"
    @back="send('back')"
  />

  <auth-login-continue-with-password
    v-else-if="isCurrentState('passwordSelector')"
    :email="data.email"
    @back="send('back')"
  />
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import AuthActions from '@/utilities/authActions'
import AuthActivationAccountModal from '@/components/modals/AuthActivationAccountModal.vue'
import AuthCheckInviteUserModal from '@/components/modals/AuthCheckInviteUserModal.vue'
import AuthLoginTypeSelector from '@/components/auth/AuthLoginTypeSelector.vue'
import AuthLoginContinueWithEmail from '@/components/auth/AuthLoginContinueWithEmail.vue'
import AuthLoginContinueWithPassword from '@/components/auth/AuthLoginContinueWithPassword.vue'

import { trackScreen } from '@/engine/metrics/metricsManager'
import { useStateMachine } from '@/hooks'
import { permissions } from '@/engine/clients'

const router = useRouter()
const route = useRoute()

interface DataType {
  email: string,
  isModalCheckInviteUserOpen: boolean
  isModalActivacionAccountOpen: boolean
  loading: boolean
  playAnimation: boolean
}

const data: DataType = reactive({
  email: '',
  isModalCheckInviteUserOpen: false,
  isModalActivacionAccountOpen: false,
  loading: false,
  playAnimation: true
})

const { isCurrentState, send } = useStateMachine({
  initial: 'typeSelector',
  states: {
    typeSelector: {
      transitions: {
        next: 'emailSelector'
      },
      on: {
        next: () => {
          data.playAnimation = false
        }
      }
    },
    emailSelector: {
      transitions: {
        back: 'typeSelector',
        next: 'passwordSelector'
      }
    },
    passwordSelector: {
      transitions: {
        back: 'emailSelector'
      }
    }
  }
})

const methods = {
  openAction ({ forgot, action }: { forgot?: string, action: string }) {
    const selectedAction = forgot ? AuthActions.changePassword.action : action
    const actions = {
      [AuthActions.changePassword.action]: () => (router.push({ name: 'change-password', query: route.query })),
      [AuthActions.validateUserAccount.action]: () => (data.isModalActivacionAccountOpen = true),
      [AuthActions.inviteNewUser.action]: () => (router.push({ name: 'register', query: route.query })),
      [AuthActions.validateTemporalUserInvitation.action]: () => (data.isModalCheckInviteUserOpen = true),
      [AuthActions.validateUserInvitation.action]: () => (data.isModalCheckInviteUserOpen = true),
      [AuthActions.acceptOperatorRequest.action]: () => (methods.redirectTo('organizations'))
    }
    actions[selectedAction]?.()
  },

  toPassword (email: string) {
    data.email = email
    send('next')
  },

  redirectTo (name: string) {
    localStorage.setItem('wb-redirection', JSON.stringify({ name, query: route.query }))
  }
}

function created () {
  methods.openAction(route.query as { forgot?: string, action: string })
  window.history.pushState(null, document.title, window.location.pathname)
  trackScreen('welcome')

  if (!permissions.hasSocialSingin) {
    send('next')
  }
}

created()
</script>
