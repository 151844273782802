import { ulid } from 'ulid'
import axios from './config/axios.config'
import ENV from '@/engine/env/web.env'
import { endpoints } from './config/endpoints.config'
import { clientConfig } from '@/engine/clients'
import type { AuthApi } from '@/types'

export default {
  async isUserEmailRegistered (email: string): Promise<AuthApi.IsUserEmailRegisteredResponse> {
    return await axios.get(
      endpoints.userApi.userEmail.replace('{email}', email),
      {
        baseURL: ENV.api.userApiBaseUrl,
        headers: {
          Partner: clientConfig.partner
        }
      }
    )
  },

  async signIn (auth: AuthApi.SignInPayload): Promise<AuthApi.SignInResponse> {
    return await axios.get(endpoints.userApi.signIn, {
      auth,
      headers: {
        Partner: clientConfig.partner,
        'X-Request-ID': `Portal-${ulid()}`
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async create (body: AuthApi.CreatePayload): Promise<AuthApi.IdResponse> {
    const id = ulid()

    return await axios.post(endpoints.userApi.create, {
      data: {
        type: 'user',
        id,
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    }).then(() => ({ id }))
  },

  async passwordResetRequest (body: AuthApi.PasswordResetRequestPayload): Promise<void> {
    return await axios.post(endpoints.userApi.passwordResetRequest, {
      data: {
        type: 'user',
        id: ulid(),
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async passwordReset ({ token, ...body }: AuthApi.PasswordResetPayload) {
    return await axios.post(endpoints.userApi.passwordReset, {
      data: {
        type: 'reset_password',
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner,
        Authorization: `Bearer ${token}`
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async googleSignup (body: AuthApi.OauthSignUpPayload): Promise<AuthApi.IdResponse> {
    const id = ulid()

    return await axios.post(endpoints.userApi.googleSignup, {
      data: {
        type: 'user',
        id,
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    }).then(() => ({ id }))
  },

  async googleSignin (body: AuthApi.OauthSignInPayload): Promise<AuthApi.SignInResponse> {
    return await axios.post(endpoints.userApi.googleSignin, {
      data: {
        type: 'social-google-signin',
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async appleSignup (body: AuthApi.OauthSignUpPayload): Promise<AuthApi.IdResponse> {
    const id = ulid()
    return await axios.post(endpoints.userApi.appleSignup, {
      data: {
        type: 'user',
        id,
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    }).then(() => ({ id }))
  },

  async appleSignin (body: AuthApi.OauthSignInPayload): Promise<AuthApi.SignInResponse> {
    return await axios.post(endpoints.userApi.appleSignin, {
      data: {
        type: 'social-apple-signin',
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async activateUser (body: AuthApi.ActivateUserPayload): Promise<void> {
    return await axios.post(endpoints.userApi.activateUser, {
      data: {
        type: 'user_activation',
        attributes: {
          ...body
        }
      }
    }, {
      headers: {
        Partner: clientConfig.partner
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  },

  async refreshToken ({ refreshToken }: AuthApi.RefreshTokenPayload): Promise<AuthApi.SignInResponse> {
    return await axios.get(endpoints.userApi.refreshToken, {
      headers: {
        Partner: clientConfig.partner,
        Authorization: `Bearer ${refreshToken}`
      },
      baseURL: ENV.api.userApiBaseUrl
    })
  }
}
