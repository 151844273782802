<template>
  <dashboard-card
    title="mywb.common.chargers"
    subtitle="mywb.common.total-installed-chargers"
    icon-color="var(--grey-400)"
    icon="charger"
    :number="compute.totalChargersLocale"
    :loading="props.loading"
  >
    <div class="is-grid mt-16">
      <wb-button
        data-test-id="addChargerBtn"
        icon="add"
        :label="t('mywb.common.add-charger')"
        type="white"
        outlined
        :to="{
          name: 'chargers',
          query: {
            'add-chargers': '1',
            'location-id': state.groups.groupRelatedToOrganizationWithChargers.id.toString()
          }
        }"
      />

      <wb-button
        data-test-id="viewAllChargers"
        :label="t('mywb.common.view-all')"
        type="white"
        :to="{ name: 'chargers' }"
      />
    </div>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'
import { numbers } from '@wallbox/toolkit-ui'
import type { Group } from '@/types'

const { t, locale } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

const compute = reactive({
  totalChargersLocale: computed((): string => {
    return numbers.toLocaleNumber(compute.totalChargers, locale.value)
  }),

  groupsFiltered: computed((): Group.GroupCharger[] => {
    let { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    if (state.filters.dashboardFilters.locations.length > 0) {
      subgroups = subgroups.filter(group => state.filters
        .dashboardFilters.locations.some(location => location.group_uid === group.uid))
    }

    return subgroups
  }),

  totalChargers: computed((): number => {
    return compute.groupsFiltered.flatMap(group => group.chargers).length
  })
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  align-items: center;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 8px;
}
</style>
