import type { Charger } from '@/types'

export const getChargerAmps = (charger: Charger.ChargerData) => {
  // chargers type AC - min amp 6 / max amp equal to max available current property.
  // chargers type DC - min and max amps equal to positive and negative max available current property.
  return {
    current: charger.maxChargingCurrent || 0,
    max: charger.maxAvailableCurrent,
    min: charger.chargingType === 'AC' ? 6 : -charger.maxAvailableCurrent
  }
}
