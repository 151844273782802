import PaymentsView from '@/views/PaymentsView.vue'
import PaymentsConfigurationView from '@/views/PaymentsConfigurationView.vue'
import PaymentsInvoicesView from '@/views/PaymentsInvoicesView.vue'
import RateCreateView from '@/views/RateCreateView.vue'
import RatesView from '@/views/RatesView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'payments',
    component: PaymentsView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'payments',
        text: 'mywb.common.payments'
      }
    },
    children: [
      {
        path: 'rates',
        name: 'payments-rates',
        component: RatesView
      },
      {
        path: 'invoices',
        name: 'payments-invoices',
        component: PaymentsInvoicesView
      }
    ]
  },
  {
    path: 'payments-configuration',
    name: 'payments-configuration',
    component: PaymentsConfigurationView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'payments-configuration',
        text: 'mywb.common.payments-configuration'
      }
    }
  },
  {
    path: 'create-rate',
    name: 'payments-new-rates-create',
    component: RateCreateView,
    props: true,
    meta: {
      breadcrumb: {
        name: 'payments-new-rates-create',
        text: 'mywb.common.create-rate'
      }
    }
  },
  {
    path: 'edit-rate/:rateId',
    name: 'payments-new-rates-edit',
    component: RateCreateView,
    props: true,
    meta: {
      breadcrumb: {
        name: 'payments-new-rates-edit',
        text: 'mywb.common.edit-rate'
      }
    }
  }
] as RouteRecordRaw[]
