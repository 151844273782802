import { createRouter, createWebHistory } from 'vue-router'

import hasAuth from './guards/hasAuth'
import hasAccess from './guards/hasAccess'

import LayoutView from '@/views/LayoutView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import DashboardView from '@/views/DashboardView.vue'
import NewDashboardView from '@/views/NewDashboardView.vue'
import SessionsView from '@/views/SessionsView.vue'
import SupportView from '@/views/SupportView.vue'
import UserSubscriptionConfirmView from '@/views/UserSubscriptionConfirmView.vue'
import AuthLoginView from '@/views/AuthLoginView.vue'
import AuthRegisterView from '@/views/AuthRegisterView.vue'
import AuthPasswordChangeView from '@/views/AuthPasswordChangeView.vue'
import AuthPasswordResetView from '@/views/AuthPasswordResetView.vue'
import AuthView from '@/views/AuthView.vue'
import OcppView from '@/views/OcppView.vue'

import ChargerRoutes from './charger.routes'
import OrganizationsRoutes from './organizations.routes'
import PaymentsRoutes from './payments.routes'
import UserRoutes from './user.routes'
import AccountRoutes from './account.routes'
import PlanRoutes from './plan.routes'
import locationRoutes from './location.routes'

export default function () {
  const router = createRouter({
    history: createWebHistory('/'),
    routes: [
      { path: '/', name: 'home', redirect: { name: 'chargers' } },
      {
        path: '/auth',
        name: 'auth',
        redirect: 'login',
        component: AuthView,
        children: [
          {
            path: 'login',
            name: 'login',
            alias: '/login',
            component: AuthLoginView
          },
          {
            path: 'register',
            name: 'register',
            alias: '/register',
            component: AuthRegisterView
          },
          {
            path: 'forgot-password',
            name: 'forgot-password',
            alias: '/forgot-password',
            component: AuthPasswordResetView
          },
          {
            path: 'password-change',
            name: 'change-password',
            alias: '/password-change',
            component: AuthPasswordChangeView
          }
        ]
      },

      { path: '/subscription', name: 'subscription', component: UserSubscriptionConfirmView },
      {
        path: '/',
        component: LayoutView,
        meta: { requiresAuth: true },
        children: [
          ...OrganizationsRoutes,
          {
            path: 'dashboard',
            name: 'dashboard',
            component: DashboardView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: 'dashboard',
                text: 'mywb.common.dashboard'
              }
            }
          },
          {
            path: 'new-dashboard',
            name: 'new-dashboard',
            component: NewDashboardView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: 'dashboard',
                text: 'mywb.common.dashboard'
              }
            }
          },
          ...ChargerRoutes,
          {
            path: 'ocpp',
            name: 'ocpp',
            component: OcppView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: 'ocpp',
                text: 'mywb.charger.ocpp'
              }
            }
          },
          ...AccountRoutes,
          ...UserRoutes,
          ...PaymentsRoutes,
          ...PlanRoutes,
          ...locationRoutes,
          {
            path: 'sessions',
            name: 'sessions',
            component: SessionsView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: 'sessions',
                text: 'mywb.common.sessions'
              }
            }
          },
          {
            path: 'support',
            name: 'support',
            component: SupportView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: { name: 'support', text: 'mywb.support.title' }
            }
          },
          { path: 'not-found', name: 'not-found', component: NotFoundView },
          { path: '/:pathMatch(.*)*', redirect: 'not-found' }
        ]
      }
    ],

    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return { ...savedPosition, behavior: 'smooth' }
      } else {
        return { x: 0, y: 0, behavior: 'smooth' }
      }
    }
  })

  router.beforeEach(hasAuth)
  router.beforeEach(hasAccess)

  return router
}
