<template>
  <wb-input
    v-model="compute.filteredText"
    data-test-id="searchButton"
    :disabled="props.loading"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
    icon="search"
    type="search"
  />

  <shared-filter
    v-model="compute.filteredChargers"
    multiple
    with-search
    label="name"
    icon="charger"
    :name="i18n.t('mywb.common.chargers')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
    :empty-text="i18n.t('mywb.chargers.empty')"
    data-test-id="chargersFilter"
    :options="state.charger.getChargers ?? []"
    :reduce="(item: typeof state.charger.getChargers[number]) => item.id"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'chargers' })"
  >
    <template #option="{ option }">
      <div class="grid g-8">
        <img
          :src="option.image"
          class="charger-img"
          :alt="option.name"
        >
        {{ option.name }}
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-model="compute.filteredGroups"
    multiple
    with-search
    label="name"
    icon="locations"
    :name="i18n.t('mywb.common.locations')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    data-test-id="groupsFilter"
    :options="compute.groups"
    :reduce="(item: typeof compute.groups[number]) => item.id"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'groups' })"
  >
    <template #option="{ option }">
      <div class="grid g-8">
        {{ option.name }}
        <wb-label
          v-if="option.chargers.length"
          is-active
          disabled
          color="grey"
          class="ml-8"
        >
          {{ option.chargers.length }}
        </wb-label>
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-model="compute.filteredStatuses"
    multiple
    option-key="code"
    :name="i18n.t('mywb.common.status')"
    :options="compute.statuses"
    :reduce="(item: typeof compute.statuses[number]) => item.code"
    :loading="props.loading"
    data-test-id="statusFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'statuses' })"
  >
    <template #input-text="{ option, label }">
      <div class="grid g-8">
        <span
          v-if="option?.code"
          :style="option.color"
          class="bullet wb-icons is-size-300"
        >lens_filled</span>
        {{ label }}
      </div>
    </template>

    <template #option="{ option }">
      <div class="grid g-8">
        <span
          v-if="option.code"
          :style="option.color"
          class="bullet wb-icons is-size-300"
        >lens_filled</span>
        {{ option.label }}
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-model="compute.filteredChargerTypes"
    multiple
    label="name"
    :name="i18n.t('mywb.charger.type')"
    data-test-id="chargersTypeFilter"
    :options="compute.chargerTypes ?? []"
    option-key="code"
    :reduce="(item: typeof compute.chargerTypes[number]) => item.code"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'chargerTypes' })"
  >
    <template #input-text="{ option, label }">
      <div class="grid g-8">
        <img
          v-if="option?.image"
          :src="option.image"
          class="charger-img"
          :alt="label"
        >
        {{ label }}
      </div>
    </template>

    <template #option="{ option }">
      <div class="grid g-8">
        <img
          :src="option.image"
          class="charger-img"
          :alt="option.name"
        >
        {{ option.name }}
      </div>
    </template>
  </shared-filter>

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'chargersFilters' })"
  />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { dom } from '@wallbox/toolkit-ui'

import state from '@/state'
import { chargerStatuses, getColorByStatus } from '@/utilities/charger/chargerStatuses'
import { chargerTypes, isChargerType } from '@/utilities/charger/chargerTypes'

import SharedFilter from '@/components/filters/SharedFilter.vue'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const i18n = useI18n()

interface Props {
  loading?: boolean
}

const props = defineProps<Props>()

const compute = reactive({
  filteredText: computed({
    get () {
      return state.filters.chargersFilters?.text
    },

    set: dom.debounce((value: string) => {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'text', value })
    }, 300)
  }),

  filteredChargers: computed({
    get () {
      return state.filters.chargersFilters?.chargers ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'chargers', value })
    }
  }),

  filteredChargerTypes: computed({
    get () {
      return state.filters.chargersFilters?.chargerTypes ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'chargerTypes', value })
    }
  }),

  filteredGroups: computed({
    get () {
      return state.filters.chargersFilters?.groups ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'groups', value })
    }
  }),

  filteredStatuses: computed({
    get () {
      return state.filters.chargersFilters?.statuses ?? []
    },
    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'statuses', value })
    }
  }),

  statuses: computed(() => {
    return chargerStatuses
      .filter(status => status.filter)
      .map(status => ({
        ...status,
        color: { '--bullet-color': getColorByStatus(status.code).bg },
        label: i18n.t(status.label)
      }))
  }),

  chargerTypes: computed(() => {
    return chargerTypes
      .map(chargerType => {
        const chargers = state.charger.getChargers.filter(charger => isChargerType(charger, [chargerType.code]))
        if (!chargers.length) return null

        return {
          ...chargerType,
          image: chargers[0].image
        }
      })
      .filter(Boolean as unknown as ExcludesNullish)
  }),

  groups: computed(() => {
    return state.groups.locations
      .filter((group) => state.charger.getChargers.some(charger => charger.groupId === group.id))
  })
})
</script>

<style lang="postcss" scoped>
.charger-img {
  width: 32px;
  object-fit: cover;
}

.bullet {
  color: var(--bullet-color, var(--white));
  font-size: 12px;
}

.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}
</style>
