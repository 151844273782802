<template>
  <wb-card
    class="mb-24"
    :headline="i18n.t('mywb.common.settings')"
    :loading="data.loading"
    actions-position="bottom"
  >
    <template #content>
      <user-role-select-form
        v-if="data.accessConfigSelected?.profile"
        :profile="data.accessConfigSelected?.profile"
        :disabled="compute.isDisabled || data.submitLoading"
        class="mb-12"
        @on-change="methods.setProfile"
      />

      <div class="is-flex g-8">
        <wb-select
          v-model="compute.groupSelected"
          data-test-id="groupSelect"
          uid="_user_group"
          class="is-capitalized"
          :label="i18n.t('mywb.common.access-group')"
          option-label="name"
          name="subgroup"
          :placeholder="i18n.t('mywb.common.select-group')"
          :options="compute.accessConfigsOptions"
          :disabled="compute.isDisabled || data.submitLoading"
        />
        <wb-button
          v-if="compute.groupSelected.name"
          type="white"
          outlined
          data-test-id="removeAccessConfigGroupBtn"
          icon="delete"
          class="has-margin-bottom"
          :disabled="data.loading"
          @click="methods.removeAccessConfigGroup"
        />
      </div>
    </template>

    <template #actions>
      <wb-button
        type="primary"
        data-test-id="addAccessConfigGroupBtn"
        :label="!data.submitLoading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.submitLoading"
        :disabled="data.loading || !compute.touched"
        @click="methods.addAccessConfigGroup"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import UserRoleSelectForm from '@/components/forms/UserRoleSelectForm.vue'
import state from '@/state'
import api from '@/api'
import { hasRoleGreaterThan } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import type { AccessConfig, User } from '@/types'
import type { EnumRoles } from '@/utilities/user-roles'

const notify = useNotify()
const i18n = useI18n()

interface Props {
  user: User.User
}
const props = defineProps<Props>()

interface Data {
  accessConfigSelected?: Partial<AccessConfig.AccessConfigInDetail>
  accessConfigOld?: Partial<AccessConfig.AccessConfigInDetail>
  loading: boolean
  submitLoading: boolean
}

const data = reactive<Data>({
  loading: false,
  submitLoading: false
})

const compute = reactive({
  isDisabled: computed(() => !(hasRoleGreaterThan(props.user.id) ||
    state.user.userLogged.id === state.organizations.getCurrentOrganization.owner_id)),

  touched: computed(() => JSON.stringify(data.accessConfigOld) !== JSON.stringify(data.accessConfigSelected)),

  groupSelected: computed({
    get () {
      return {
        id: data.accessConfigSelected?.id || -1,
        name: data.accessConfigSelected?.name || ''
      }
    },
    set (accessConfig) {
      data.accessConfigSelected = {
        ...(data.accessConfigSelected ?? {}),
        ...accessConfig
      }
    }
  }),

  accessConfigsOptions: computed(() => {
    const group = state.user.getGroupsByAccessConfig
      .find(group => state.organizations.getCurrentOrganization.group_id === group.id)

    return group?.accessConfigs.map(({ id, name }) => ({ id, name })) ?? []
  })
})

const methods = {
  setProfile (profile: EnumRoles) {
    data.accessConfigSelected = {
      ...(data.accessConfigSelected ?? {}),
      profile
    }
  },

  async removeAccessConfigGroup () {
    if (!data.accessConfigSelected?.id) return
    data.accessConfigSelected.name = ''
  },

  async addAccessConfigGroup () {
    if (!data.accessConfigSelected?.id || !data.accessConfigSelected?.profile) return

    data.submitLoading = true
    try {
      if (data.accessConfigOld?.name && !data.accessConfigSelected.name) {
        await api.accessConfig.deleteUserFromAccessConfig({
          accessConfigId: data.accessConfigSelected.id,
          userId: props.user.id,
          action: 'change'
        })
      } else {
        await api.accessConfig.addUserToAccessConfig({
          accessConfigId: data.accessConfigSelected.id,
          userId: props.user.id,
          profile: data.accessConfigSelected.profile
        })
      }
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.submitLoading = false
    }
  }
}

async function created () {
  data.loading = true

  state.user.set('usersGroup', await api.users.getUserGroups())

  data.accessConfigSelected = props.user?.accessConfigs?.find(accessConfig =>
    accessConfig.group === state.organizations.getCurrentOrganization.group_id)

  data.accessConfigOld = Object.assign({}, data.accessConfigSelected)

  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
.is-flex {
  display: flex;
  align-items: flex-end;
}

.has-margin-bottom {
  margin-bottom: 4px;
}
</style>
