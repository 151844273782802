import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import { formatResponseJsonApi } from './config/jsonApi.config'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import type { Locations, LocationsApi, Filters, Group } from '@/types'

export default {
  async postGroup (group: Omit<Locations.Group, 'id'>): Promise<{ result: Group.Group }> {
    trackDataEvent('add-location')
    return await axios.post(endpoints.v3.groups, group)
  },

  async putLocation ({ id, ...location }: Partial<Locations.Location>): Promise<void> {
    if (!id) throw new Error('Id needed')

    return await axios.put(endpoints.v4.location.replace('{locationUid}', id), {
      data: {
        type: 'location',
        attributes: location
      }
    })
  },

  async getLocations ({ organizationUuid, filters }: { organizationUuid: string, filters?: Filters.Filters[] }) {
    return await axios.get<unknown, LocationsApi.ApiLocationsLocationApiResponse>(
      endpoints.v4.get_locations.replace('{organizationUid}', organizationUuid), {
        params: filters && { filters: JSON.stringify({ filters }) }
      }).then(formatResponseJsonApi)
  },

  async deleteLocation ({ id }: { id: string }): Promise<void> {
    return await axios.delete(endpoints.v4.location.replace('{locationUid}', id))
  },

  async getLocationId (groupUid: string): Promise<LocationsApi.ApiGetLocationIdResponse> {
    return await axios.get(endpoints.v4.group_id.replace('{groupUid}', groupUid))
  },

  async getGroup (groupId: number): Promise<LocationsApi.ApiLocationsGroupApiResponse> {
    return await axios.get(endpoints.v3.groups_groupId.replace('{groupId}', groupId.toString()))
  }
}
