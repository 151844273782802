/*
| -------------------------------------------------------------------------------------------------
|  Validator
| -------------------------------------------------------------------------------------------------
|
|  that validator is a yup wrapper for manage vue 3 reactivity, more info please visit the
|  official yup documentation https://github.com/jquense/yup#table-of-contents
|
*/
import * as yup from 'yup'
import { watch, reactive } from 'vue'
import i18n from '@/engine/lang'

type Errors = Record<string, string>
type Schema = Record<string, any>

interface Error {
  errors: string[]
  path: string
}

/*
| -------------------------------------------------------------------------------------------------
|  Custom validator rules
| -------------------------------------------------------------------------------------------------
|
|  Section for new rules that yup does not have support yet, yup could be extended by addMethod.
|  more info: https://github.com/jquense/yup#addmethodschematype-schema-name-string-method--schema-void
|
*/
yup.setLocale({
  mixed: {
    required: i18n.global.t('mywb.error.field-required'),
    oneOf: i18n.global.t('mywb.error.passwords-must-match'),
    notOneOf: i18n.global.t('mywb.error.cannot-match-password')
  },
  string: {
    email: i18n.global.t('mywb.error.email-required')
  }
})

yup.addMethod(yup.string, 'passwordValidFormat', function passwordValidFormat () {
  return this.test({
    message: i18n.global.t('mywb.error.invalid-format-password'),
    name: 'passwordValidFormat',
    test (value) {
      const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,30}$/
      return strongRegex.test(value ?? '')
    }
  })
})

yup.addMethod(yup.string, 'rfidValidFormat', function rfidValidFormat () {
  return this.test({
    message: i18n.global.t('mywb.error.invalid-format-rfid'),
    name: 'rfidValidFormat',
    test (value) {
      let flag = false
      if (typeof value === 'string' && value.length > 0) {
        const valueParsed = value.toLowerCase()
        flag = /^([\da-f]{8}|[\da-f]{14})$/g.test(valueParsed) // Hexadecimal value of 8 or 14 characters
        flag = flag || /^(\d{10}|\d{17})$/g.test(valueParsed) // Numeric value of 10 or 17 digits
      }
      return flag
    }
  })
})

/*
| -------------------------------------------------------------------------------------------------
|  Validator Hook
| -------------------------------------------------------------------------------------------------
|
|  The validator with the main functionality.
|
*/
export function useValidator () {
  const errors: Errors = reactive({})
  let schemaData = reactive({})
  let schema: Schema
  let automaticValidation = false
  let stopAutomaticValidation: () => void

  /*
  | -------------------------------------------------------------------------------------------------
  |  Reset validation errors
  | -------------------------------------------------------------------------------------------------
  |
  |  Reset validation errors without lost vue reactivity.
  |  NOTE: that is a trick maybe that could be improved.
  |
  */
  function resetErrors () {
    /* eslint-disable-next-line */
    for (const key in errors) delete errors[key]
  }

  /*
  | -------------------------------------------------------------------------------------------------
  |  Reset validation
  | -------------------------------------------------------------------------------------------------
  |
  |  That includes unwatch validation schema and reset errors, that forces to start watcher calling
  |  validation method again.
  |
  */
  function reset () {
    resetErrors()
    automaticValidation = false
    stopAutomaticValidation()
  }

  /*
  | -------------------------------------------------------------------------------------------------
  |  Validate schema
  | -------------------------------------------------------------------------------------------------
  |
  |  Validation by default is forced to not aborted early that means show all errors instead of one.
  |  on the other hand error list is managed with yup path as key name. More info about yup validate
  |  https://github.com/jquense/yup#schemavalidatevalue-any-options-object-promiseinfertypeschema-validationerror
  |
  */
  async function validateSchema () {
    let validatedSchema = false

    try {
      validatedSchema = !!await schema.validate(schemaData, { abortEarly: false })
      resetErrors()
    } catch (error: any) {
      resetErrors()
      error.inner?.forEach((message: Error) => {
        errors[message.path] = message.errors[0]
      })
    }

    return !!validatedSchema
  }

  /*
  | -------------------------------------------------------------------------------------------------
  |  Define schema
  | -------------------------------------------------------------------------------------------------
  |
  | By default every schema is an object, more schema properties and examples.
  | https://github.com/jquense/yup#schema
  |
  */
  function defineSchema (data: Schema, newSchema: Schema) {
    schema = yup.object(newSchema)
    schemaData = data
  }

  /*
  | -------------------------------------------------------------------------------------------------
  |  Run validation manually
  | -------------------------------------------------------------------------------------------------
  |
  | Method for run yup validations, if validator run at the first time or was reset that activate
  | the automatic validation that means every change you are in the validation schema will activate
  | the yup validator.
  |
  | On the other hand, that method receives a callback as param, which means if the validation is success
  | the callback will be executed. that is usefully for cases when the user needs to call some actions
  | if validation was a success.
  |
  */
  async function validate (callback?: () => void) {
    const isValid = await validateSchema()

    isValid && callback && callback()

    if (!automaticValidation) {
      stopAutomaticValidation = watch(schemaData, async () => await validateSchema(), { deep: true })
      automaticValidation = true
    }

    return isValid
  }

  return {
    errors,
    yup,
    defineSchema,
    validate,
    reset
  }
}
