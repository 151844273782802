<template>
  <wb-modal
    width="74"
    class="charger-remote-actions"
    @close="methods.close"
  >
    <template #title>
      {{ compute.modalTitle }}
    </template>

    <wb-notification
      v-if="compute.accesibleChargers.length > 0"
      icon="info_filled"
      class="mb-24"
    >
      {{ compute.unaccessibleChargersExplication }}
      <dl v-if="compute.chargersComposedList.length !== compute.accesibleChargers.length">
        <dt class="reason-title" @click="data.showExplanation = !data.showExplanation">
          <span
            class="arrow wb-icons mr-8"
            :class="{ rotate: data.showExplanation }"
          >
            expand_more
          </span>

          <span>
            {{ compute.unaccessibleChargersExplicationWhy }}
          </span>
        </dt>
        <dd v-if="data.showExplanation" class="ml-32">
          <ul class="reason-list">
            <li v-t="'mywb.charger.unsuported-action-explain-1'" />
            <li v-t="'mywb.charger.unsuported-action-explain-2'" />
            <li v-t="'mywb.charger.unsuported-action-explain-3'" />
          </ul>
        </dd>
      </dl>
    </wb-notification>

    <chargers-remote-actions-table
      :action="props.action"
      :accesible-chargers="compute.accesibleChargers"
      @on-delete-charger="methods.deleteCharger"
    />

    <template #actions>
      <div class="actions">
        <div class="button-actions">
          <wb-button
            data-test-id="cancelButton"
            type="white"
            outlined
            size="block"
            :label="i18n.t('mywb.common.cancel')"
            @click="methods.close"
          />
          <wb-button
            data-test-id="confirmUpdateOrRestartButton"
            size="block"
            :label="compute.labelUpdateOrRestart"
            :loading="data.loading"
            :disabled="!compute.accesibleChargers.length"
            @click="methods.handleClickRemoteActions"
          />
        </div>
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import ChargersRemoteActionsTable from '@/components/tables/ChargersRemoteActionsTable.vue'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { trackDataScreen, trackDataAction } from '@/engine/metrics/trackDataManager'
import { objects, useNotify } from '@wallbox/toolkit-ui'
import api from '@/api'
import state from '@/state'
import { computed, reactive, watch } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS, hasActionsAvailable } from '@/utilities/charger/chargerActions'
import type { Charger } from '@/types'

const i18n = useI18n()
const notify = useNotify()

interface Props {
  charger?: Charger.AnyTypeOfCharger
  software?: Charger.Software
  action: REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
  forceAllChargers?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits([
  'close',
  'load-component',
  'charger-updated'
])

interface Data {
  loading: boolean,
  chargerList: Charger.Charger[]
  showExplanation: boolean
}

const data = reactive<Data>({
  loading: false,
  chargerList: [],
  showExplanation: false
})

interface ChargerRemoteAction {
  id: number
  name: string
  software: {
    currentVersion: string,
    latestVersion: string
  }
  hasActionsSupport: boolean
  hasUpdate: boolean
}

const compute = reactive({
  groupChargersTree: computed((): boolean => !props.charger),

  labelUpdateOrRestart: computed((): string => {
    return props.action === REMOTE_ACTIONS.UPDATE
      ? i18n.t('mywb.common.update')
      : i18n.t('mywb.common.restart')
  }),

  updatableOrganizationChargers: computed(() => {
    return state.charger.getChargers.filter(charger => isUpdatesAvailable(charger, charger.software))
  }),

  modalTitle: computed((): string => {
    if (compute.groupChargersTree) {
      return props.action === REMOTE_ACTIONS.UPDATE
        ? i18n.t('mywb.charger.update-chargers-detail')
        : i18n.t('mywb.charger.restart-chargers-detail')
    }

    return props.action === REMOTE_ACTIONS.UPDATE
      ? i18n.t('mywb.charger.update-detail')
      : i18n.t('mywb.charger.restart-detail')
  }),

  chargersComposedList: computed((): ChargerRemoteAction[] => {
    if (compute.groupChargersTree) {
      return data.chargerList.map(charger => ({
        id: charger.id,
        name: charger.name,
        software: charger.software,
        hasActionsSupport: hasActionsAvailable(charger),
        hasUpdate: isUpdatesAvailable(charger, charger.software)
      }))
    } else {
      if (!props.charger || !props.software) return []
      return [
        {
          id: props.charger.id ?? -1,
          name: props.charger.name ?? '',
          software: {
            currentVersion: props.software?.currentVersion ?? '',
            latestVersion: props.software?.latestVersion ?? ''
          },
          hasActionsSupport: true,
          hasUpdate: isUpdatesAvailable(props.charger, props.software)
        }
      ]
    }
  }),

  accesibleChargers: computed((): ChargerRemoteAction[] => {
    const filterFunc = {
      [REMOTE_ACTIONS.UPDATE]: (charger: ChargerRemoteAction) => charger.hasActionsSupport && charger.hasUpdate,
      [REMOTE_ACTIONS.RESTART]: (charger: ChargerRemoteAction) => charger.hasActionsSupport
    }[props.action]

    return compute.chargersComposedList.filter(filterFunc)
  }),

  unaccessibleChargersExplication: computed(():string => {
    const text1 = i18n.t(
      'mywb.charger.unsuported-action',
      [
        compute.labelUpdateOrRestart,
        compute.accesibleChargers.length,
        compute.chargersComposedList.length
      ])

    const text2 = i18n.t(
      'mywb.charger.touch-warning',
      [
        compute.labelUpdateOrRestart,
        compute.accesibleChargers.length
      ])

    return [
      compute.chargersComposedList.length !== compute.accesibleChargers.length && text1,
      text2
    ].filter(Boolean).join(' ')
  }),

  unaccessibleChargersExplicationWhy: computed((): string => {
    return i18n.t(
      'mywb.charger.unsuported-action-why',
      [
        props.action === REMOTE_ACTIONS.UPDATE ? i18n.t('mywb.common.updated') : i18n.t('mywb.common.restarted')
      ])
  })
})

watch(() => props.charger, (charger, oldCharger) => {
  if (JSON.stringify(charger) !== JSON.stringify(oldCharger)) {
    props.charger?.id && emit('load-component')
  }
})

const methods = {
  handleClickRemoteActions () {
    compute.groupChargersTree
      ? methods.bulkRemoteAction()
      : methods.updateRemoteAction()

    trackDataAction(`${props.action}-charger`, {
      chargers: compute.groupChargersTree ? data.chargerList : ''
    })
  },

  bulkRemoteAction () {
    try {
      data.chargerList.length && api.chargers.bulkRemoteActions({
        chargers: compute.accesibleChargers.map(charger => charger.id),
        data: {
          remoteAction: props.action
        }
      })
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      methods.close()
    }
  },

  deleteCharger (charger: ChargerRemoteAction) {
    data.chargerList = data.chargerList.filter(({ id }) => id !== charger.id)
  },

  async updateRemoteAction () {
    if (!props.charger) return

    data.loading = true
    try {
      await api.chargers.setChargerRemoteAction({
        action: props.action,
        chargerId: props.charger.id
      })

      const charger = await api.chargers.getCharger(props.charger.id)
      state.charger.setCurrentCharger(charger)
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
      methods.close()
    }
  },

  close () {
    emit('close')
  }
}

function created () {
  if (compute.groupChargersTree) {
    if (props.forceAllChargers ||
      (!state.charger.getCheckedChargers.length && props.action === REMOTE_ACTIONS.UPDATE)) {
      data.chargerList = compute.updatableOrganizationChargers
    } else {
      data.chargerList = objects.deepClone(state.charger.getCheckedChargers) as Charger.Charger[]
    }
  } else {
    props.action === REMOTE_ACTIONS.UPDATE
      ? trackDataScreen('chargers-remote-actions-update')
      : trackDataScreen('chargers-remote-actions-restart')
  }
}

created()

</script>

<style lang="postcss" scoped>
.reason-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.reason-list {
  list-style-type: "- ";
  list-style-position: inside;
}

.arrow {
  transition: transform 300ms;

  &.rotate {
    transform: rotateX(0.5turn);
  }
}

.charger-remote-actions {
  & .old-version {
    color: var(--danger-500);
    text-decoration: line-through;
  }

  & .new-version {
    color: var(--primary-500);
  }
}
</style>
