<template>
  <wb-table-row
    class="grid-table-row"
    :data-test-id="`invitation-${props.invitation.id}`"
  >
    <wb-table-row-item>
      <div class="is-flex has-align-items">
        <wb-user-avatar
          class="mr-16"
          data-test-id="userPicture"
          size="small"
          :src="props.invitation.user.avatar"
          :initials="compute.initials"
        />
        <div class="is-name has-text-grey-500">
          <p data-test-id="userEmail">
            {{ props.invitation.user.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userGroup">
      {{ props.invitation.group?.name || '' }}
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userProfile">
      <user-role-item :profile="props.invitation.profile" />
    </wb-table-row-item>

    <wb-table-row-item
      v-if="permissions.showPayments"
      class="has-text-centered"
    >
      <div data-test-id="stateSubscription">
        -
      </div>
    </wb-table-row-item>

    <wb-table-row-item class="has-text-centered">
      <wb-label
        is-active
        disabled
        icon="lens_filled"
        color="warning"
        data-test-id="userActivationStatus"
      >
        {{ i18n.t('mywb.common.invitation-sent') }}
      </wb-label>
    </wb-table-row-item>

    <wb-table-row-item>
      <wb-button-groups v-if="props.hasPermisionToActions" data-test-id="userActionsDropdown">
        <wb-popover class="group-item">
          <template #activator>
            <wb-button
              type="white"
              outlined
              size="small"
              icon="email"
              data-test-id="userResendInvitation"
              @click="methods.handleResendInvitation"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.resend') }}
          </template>
        </wb-popover>
        <wb-popover class="group-item">
          <template #activator>
            <wb-button
              type="danger"
              outlined
              size="small"
              icon="person_remove"
              class="ml-8"
              data-test-id="userRevokeInvitation"
              @click="methods.handleRevokeInvitation"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.revoke') }}
          </template>
        </wb-popover>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import UserRoleItem from '@/components/users/UserRoleItem.vue'
import { permissions } from '@/engine/clients'
import { userInitials } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { User } from '@/types'

const i18n = useI18n()

interface Props {
  invitation: User.UserList,
  hasPermisionToActions?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-revoke'): void
  (e: 'on-resend'): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  initials: computed(() => userInitials(props.invitation.user))
})

const methods = {
  handleRevokeInvitation () {
    emit('on-revoke')
  },

  handleResendInvitation () {
    emit('on-resend')
  }
}
</script>

<style lang="postcss" scoped>
.grid-table-row {
  cursor: default;
}

.has-align-items {
  align-items: center;
}
</style>
