<template>
  <wb-cards-loader>
    <new-dashboard-header
      :loading="data.loading"
      :locations="data.locations"
    />

    <enable-auto-refresh-component
      v-if="data.showActivateInterval"
      data-test-id="sharedEnableAutoRefresh"
      class="mb-16"
      @enable-auto-refresh="methods.enableAutoRefresh"
    />

    <update-chargers-notification />

    <div class="is-grid-3 g-16 mb-16">
      <new-chargers-overview-dashboard
        :loading="data.loading"
      />
    </div>

    <div class="is-grid-4 g-16 mb-16">
      <organization-card :loading="data.loading" />

      <charger-card :loading="data.loading" />

      <locations-card
        :loading="data.loading"
        :locations="data.locations"
      />

      <users-card :loading="data.loading" />
    </div>

    <active-chargers-table
      class="table"
      data-test-id="dashboardChargersActiveSessions"
      :organization="state.organizations.getCurrentOrganization"
      :has-real-time-information="!data.showActivateInterval"
    />

    <new-dashboard-header-date-intervals />

    <infrastucture-performance-charts />
  </wb-cards-loader>
</template>

<script setup lang="ts">
import NewChargersOverviewDashboard from '@/components/dashboard/NewChargersOverviewDashboard.vue'
import ActiveChargersTable from '@/components/tables/ActiveChargersTable.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import UpdateChargersNotification from '@/components/UpdateChargersNotification.vue'
import NewDashboardHeader from '@/components/headers/NewDashboardHeader.vue'
import NewDashboardHeaderDateIntervals from '@/components/headers/NewDashboardHeaderDateIntervals.vue'
import InfrastucturePerformanceCharts from '@/components/dashboard/InfrastucturePerformanceCharts.vue'
import state from '@/state'
import { reactive } from 'vue'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import { useRouter } from 'vue-router'
import api from '@/api'
import ChargerCard from '@/components/dashboard/ChargerCard.vue'
import OrganizationCard from '@/components/dashboard/OrganizationCard.vue'
import LocationsCard from '@/components/dashboard/LocationsCard.vue'
import UsersCard from '@/components/dashboard/UsersCard.vue'
import type { Locations } from '@/types'

const router = useRouter()

interface Data {
  locations: Locations.Location[]
  showActivateInterval: boolean
  loading: boolean
}

const data = reactive<Data>({
  showActivateInterval: false,
  loading: false,
  locations: []
})

const methods = {
  enableAutoRefresh () {
    data.showActivateInterval = false
  },

  async setGroup () {
    data.loading = true
    const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
    state.groups.set('groups', result.groups)
    data.loading = false
  },

  async getLocations () {
    const locations = await api.locations.getLocations({
      organizationUuid: state.groups.groupRelatedToOrganization.uid
    })

    data.locations = locations.data
  }
}

const created = async () => {
  if (!hasFeatureFlagActive('new-dashboard')) {
    router.push({ name: 'dashboard' })
  }

  await Promise.all([
    methods.getLocations(),
    methods.setGroup()
  ])

  setTimeout(() => {
    data.showActivateInterval = true
  }, 300000)
}

created()
</script>

<style lang="postcss" scoped>
.is-grid-3 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (--desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.is-grid-4 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (min-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1250px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.table {
  grid-column: 1/ span 1;

  @media (--tablet) {
    grid-column: 1 / span 2;
  }

  @media (--widescreen) {
    grid-column: 1 / span 3;
  }
}
</style>
