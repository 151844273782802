<template>
  <wb-card
    :headline="i18n.t('mywb.common.rfid-card')"
    :subhead="i18n.t('mywb.account.rfid-description')"
    alignment="center"
    filled
  >
    <template v-if="permissions.editRfid" #actions>
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="data.editRfid = true"
      />
    </template>

    <template #content>
      <div class="is-flex">
        <p class="is-size-500" data-test-id="rfidView">
          <span v-if="permissions.canSeeRfid">
            {{ compute.rfidProxy }}
          </span>
          <span v-else>
            * * * * * * * *
          </span>
        </p>

        <wb-button
          v-if="compute.rfidProxy && permissions.canSeeRfid && permissions.editRfid"
          type="danger"
          outlined
          size="small"
          icon="delete"
          class="ml-8"
          data-test-id="deleteBtn"
          @click="data.showRfidConfirmModal = true"
        />
      </div>
    </template>

    <template #modal>
      <wb-modal
        v-if="data.editRfid"
        width="50"
        @close="data.editRfid = false"
      >
        <template #title>
          {{ i18n.t('mywb.common.rfid-card') }}
        </template>

        <wb-input
          v-model="compute.rfidProxy"
          data-test-id="rfidInput"
          class="card-rfid-input mb-24"
          name="rfidName"
          type="text"
          :error="errors.rfid || data.errorApiMessage"
          autocomplete="__away"
          :placeholder="i18n.t('mywb.common.add-rfid-code')"
          @keyup="data.errorApiMessage = ''"
        />

        <template #actions>
          <div class="button-actions">
            <wb-button
              data-test-id="cancelButton"
              type="white"
              outlined
              :label="i18n.t('mywb.common.cancel')"
              size="block"
              @click="data.editRfid = false"
            />

            <wb-button
              type="primary"
              :label="!data.saving ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
              :loading="data.saving"
              data-test-id="saveBtn"
              size="block"
              @click.stop="validate(methods.saveRfid)"
            />
          </div>
        </template>
      </wb-modal>

      <shared-confirmation-modal
        v-if="data.showRfidConfirmModal"
        :title="i18n.t('mywb.common.rfid-card.delete-confirm')"
        :label-confirmation-button="i18n.t('mywb.common.delete')"
        type="danger"
        @on-confirm="methods.deleteRfid"
        @on-close="data.showRfidConfirmModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { useValidator } from '@/hooks/useValidator.hook'
import { hasRoleGreaterThan, checkIfUserIsSameAs } from '@/utilities/users'
import { permissions } from '@/engine/clients'
import { computed, reactive } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import api from '@/api'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const i18n = useI18n()
const notify = useNotify()
const { yup, errors, defineSchema, validate } = useValidator()

interface Data {
  rfid?: string
  saving: boolean,
  showRfidConfirmModal: boolean,
  editRfid: boolean,
  errorApiMessage?: string
}
const data = reactive<Data>({
  saving: false,
  showRfidConfirmModal: false,
  editRfid: false
})

defineSchema(data, {
  rfid: yup
    .string()
    .rfidValidFormat()
    .required()
})

const compute = reactive({
  hasPermissions: computed((): boolean => (
    hasRoleGreaterThan(state.user.userLogged.id) ||
    checkIfUserIsSameAs(state.user.userLogged.id) ||
    permissions.canAssignedRfid
  )),

  rfidProxy: computed({
    get (): string | undefined {
      const assignedRfid = compute.hasPermissions ? compute.assignedRfid : '*****************'
      return data.rfid !== null ? data.rfid : assignedRfid
    },
    set (value) {
      data.rfid = value
    }
  }),

  assignedRfid: computed(() => {
    let currentGroupAccess

    if (state.user.userLogged.access) {
      const rootGroup = state.organizations.getCurrentOrganization.group_id
      currentGroupAccess = state.user.userLogged.access
        .filter(item => item.type === 'rfid')
        .find(item => item.group === rootGroup)
    }

    return currentGroupAccess?.authentication
  })
})

const methods = {
  async deleteRfid () {
    const dataAccess = {
      user: state.user.userLogged.id,
      group: state.organizations.getCurrentOrganization.group_id,
      type: 'rfid'
    }

    await api.chargerAccess.deleteRfidAccess(dataAccess)
    compute.rfidProxy = ''
    const user = await api.users.getUser(state.user.userLogged.id)
    state.user.setUserLogged(user.data)
  },

  async saveRfid () {
    const dataAccess = {
      user: state.user.userLogged.id,
      type: 'rfid',
      group: state.organizations.getCurrentOrganization.group_id,
      authentication: compute.rfidProxy ?? ''
    }

    try {
      data.saving = true
      data.errorApiMessage = undefined
      await api.chargerAccess.updateRfidAccess(dataAccess)
      const user = await api.users.getUser(state.user.userLogged.id)
      state.user.setUserLogged(user.data)
      notify.success(i18n.t('mywb.common.rfid-saved'))
      data.editRfid = false
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 2418) {
          data.errorApiMessage = i18n.t('mywb.error.duplicated-rfid')
        } else {
          notify.error(i18n.t('mywb.error.unexpected-error'))
        }
      } else {
        throw error
      }
    } finally {
      data.saving = false
    }
  }
}

function created () {
  data.rfid = compute.assignedRfid
}

created()
</script>
