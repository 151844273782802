<template>
  <wb-modal
    width="54"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.charger.ocpp-socket') }}
    </template>

    <wb-form>
      <wb-input
        v-model="data.ocpp.address"
        :label="i18n.t('mywb.common.url')"
        class="mb-16"
        :error="data.errors.address"
        name="address"
        data-test-id="urlInput"
      />
      <wb-input
        v-model="data.ocpp.chargePointIdentity"
        :label="i18n.t('mywb.charger.ocpp.charge-point-identity')"
        class="mb-16"
        :error="data.errors.chargePointIdentity"
        name="user"
        data-test-id="chargePointInput"
      />
      <wb-input
        v-model="data.ocpp.password"
        :label="i18n.t('mywb.common.password')"
        class="mb-16"
        name="user"
        data-test-id="passwordInput"
      />

      <wb-checkbox
        v-model="data.ocpp.acceptedConditions"
        :error="!!data.errors.acceptedConditions"
        name="terms"
        data-test-id="termsAndConditionsCheckbox"
      >
        {{ i18n.t('mywb.common.accepted-privacy-policy') }}
        <wb-link
          target="_blank"
          :href="compute.termsAndConditions"
        >
          {{ i18n.t('mywb.common.terms-and-conditions') }}
        </wb-link>
      </wb-checkbox>
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :disabled="props.loading"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="props.loading"
          @click="methods.saveOcpp"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed, type Ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import { useI18n } from '@/hooks/useI18n.hook'
import lang from '@/engine/lang'
import type { Ocpp } from '@/types'

const i18n = useI18n()

interface Props {
  ocppSettings: Partial<Ocpp.OcppSettings>
  loading?: boolean
}
const props = defineProps<Props>()

interface OcppData {
  type: 'wallbox' | 'ocpp'
  address: string | Ref<string>,
  chargePointIdentity: string | Ref<string>
  acceptedConditions: string | Ref<string>
  password?: string
}

interface Events {
  (e: 'on-close'): void
  (e: 'on-save', ocpp: OcppData): void
}
const emit = defineEmits<Events>()

const { errors, handleSubmit } = useForm({
  validationSchema: {
    address: 'required',
    chargePointIdentity: 'required',
    acceptedConditions: 'required'
  },

  initialValues: {
    acceptedConditions: !!props.ocppSettings.address,
    address: props.ocppSettings.address,
    chargePointIdentity: props.ocppSettings.chargePointIdentity
  }
})

const { value: address } = useField<string>('address')
const { value: chargePointIdentity } = useField<string>('chargePointIdentity')
const { value: acceptedConditions } = useField<string>('acceptedConditions')

interface Data {
  ocpp: OcppData
  errors: typeof errors
}
const data = reactive<Data>({
  ocpp: {
    type: 'wallbox',
    address,
    chargePointIdentity,
    acceptedConditions,
    password: props.ocppSettings.password
  },

  errors
})

const compute = reactive({
  termsAndConditions: computed(() => {
    return lang.__rootLanguage === 'es'
      ? '/docs/terms_conditions_OCPP_es.pdf'
      : '/docs/terms_conditions_OCPP_en.pdf'
  })
})

const methods = {
  saveOcpp: handleSubmit(async () => {
    data.ocpp.type = 'ocpp'
    emit('on-save', data.ocpp)
  })
}
</script>
