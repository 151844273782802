<template>
  <wb-card
    :headline="i18n.t('mywb.common.password')"
    :subhead="i18n.t('mywb.account.password-description')"
    data-test-id="securityInformation"
  >
    <template #actions>
      <wb-button
        type="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="data.editPassword = true"
      />
    </template>

    <template #modal>
      <wb-modal
        v-if="data.editPassword"
        width="50"
        @close="methods.onClose()"
      >
        <template #title>
          {{ i18n.t('mywb.account.password-description') }}
        </template>

        <wb-form>
          <wb-input
            v-model="data.oldPassword"
            type="password"
            name="oldPassword"
            :label="i18n.t('mywb.user.current-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="errors.oldPassword || data.errorApiOldPassword"
            data-test-id="inputPassword"
            @keyup="data.errorApiOldPassword = ''"
          />

          <wb-input
            v-model="data.newPassword"
            type="password"
            name="newPassword"
            :label="i18n.t('mywb.user.new-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="errors.newPassword || data.errorApiNewPassword"
            data-test-id="inputNewPassword"
            @keyup="data.errorApiNewPassword = ''"
          />

          <wb-input
            v-model="data.confirmPassword"
            type="password"
            name="confirmNewPassword"
            :label="i18n.t('mywb.user.confirm-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="errors.confirmPassword"
            data-test-id="inputNewPasswordConfirm"
          />

          <auth-password-hint :value="data.newPassword || ''" />
        </wb-form>

        <template #actions>
          <div class="button-actions">
            <wb-button
              data-test-id="cancelButton"
              type="white"
              outlined
              :label="i18n.t('mywb.common.cancel')"
              size="block"
              @click="methods.onClose()"
            />
            <wb-button
              type="primary"
              size="block"
              :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
              :loading="data.loading"
              data-test-id="buttonConfirm"
              @click.prevent="validate(methods.updatePassword)"
            />
          </div>
        </template>
      </wb-modal>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import { reactive } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const i18n = useI18n()
const notify = useNotify()
const { yup, errors, defineSchema, validate } = useValidator()

const data = reactive({
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  loading: false,
  editPassword: false,
  errorApiOldPassword: '',
  errorApiNewPassword: ''
})

defineSchema(data, {
  oldPassword: yup
    .string()
    .required(),
  newPassword: yup
    .string()
    .passwordValidFormat()
    .required()
    .notOneOf([yup.ref('oldPassword'), null]),
  confirmPassword: yup
    .string()
    .passwordValidFormat()
    .required()
    .oneOf([yup.ref('newPassword'), null])
})

const methods = {
  trackDataScreen,

  onClose () {
    data.editPassword = false
    methods.resetForm()
  },

  resetForm () {
    data.editPassword = false
    data.oldPassword = ''
    data.newPassword = ''
    data.confirmPassword = ''
    data.errorApiNewPassword = ''
    data.errorApiOldPassword = ''
  },

  async updatePassword () {
    data.loading = true
    data.errorApiNewPassword = ''
    data.errorApiOldPassword = ''
    try {
      await api.users.updateUserPassword({
        current_password: data.oldPassword,
        new_password: data.newPassword
      })
      methods.resetForm()
      notify.success(i18n.t('mywb.user.changed-password'))
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 1002) {
          data.errorApiOldPassword = i18n.t('mywb.error.invalid-password')
        }
        notify.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }
    }
    data.loading = false
  }
}
</script>
