<template>
  <wb-cards-loader :loading="data.loading">
    <wb-form class="form mt-24">
      <wb-input
        v-model="data.location.name"
        name="location"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="data.errors.name"
      />

      <wb-notification
        icon="info_filled"
        :type="data.onLocationError ? 'danger' : 'info'"
        class="is-fullwidth"
      >
        {{ data.onLocationError
          ? i18n.t('mywb.error.invalid-address', { country: data.organizationCountryName })
          : i18n.t('mywb.location.address-info', { country: data.organizationCountryName })
        }}
      </wb-notification>

      <google-maps-and-address-input-form
        v-model:address="data.location.place.address"
        v-model:latitude="data.location.place.latitude"
        v-model:longitude="data.location.place.longitude"
        :component-restrictions="{ country: data.location.place.country ?? null }"
        :address-error="data.errors.address"
        data-test-id="googleMapsAddressInput"
        @on-place-update="methods.saveAddress"
      />

      <country-and-state-select-form
        :ref="setRef('countryState')"
        v-model:countryCode="data.location.place.country"
        v-model:stateCode="data.location.place.state"
        is-country-disabled
        country-key="iso2"
        class="is-fullwidth"
      />

      <wb-input
        v-model="data.location.place.city"
        :error="data.errors.city"
        data-test-id="locationCity"
        :label="i18n.t('mywb.common.city')"
      />

      <wb-input
        v-model="data.location.place.zipCode"
        data-test-id="locationZipCode"
        :error="data.errors.zipCode"
        :label="i18n.t('mywb.common.postal-code')"
      />

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.energy-type'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.energy-type-description'"
          class="is-size-400 mb-16"
        />
        <div class="energy-grid g-12">
          <checkbox-card-form
            v-model="data.location.energyType"
            value="renewable"
            icon="leaf"
            data-test-id="renewableOption"
            :title="i18n.t('mywb.common.renewable')"
          />
          <checkbox-card-form
            v-model="data.location.energyType"
            value="nonrenewable"
            icon="bolt"
            data-test-id="nonRenewableOption"
            :title="i18n.t('mywb.common.non-renewable')"
          />
          <checkbox-card-form
            v-model="data.location.energyType"
            value="unknown"
            data-test-id="unknownOption"
            :title="i18n.t('mywb.common.unknown')"
          />
        </div>
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.common.energy-cost'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.location-cost-description'"
          class="is-size-400 mb-16"
        />
        <wb-input
          v-model="data.location.energyCost"
          name="energyCost"
          data-test-id="energyCostInput"
          :label-right="`${compute.getCurrencyCode} / ${i18n.t('mywb.common.kwh')}`"
          type="number"
          min="0"
          step="0.01"
          :error="data.errors.energyCost"
        />
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.location-type'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.location-type-description'"
          class="is-size-400 mb-16"
        />
        <wb-select
          v-model="data.location.locationTypes"
          uid="_location_types"
          data-test-id="locationTypesSelector"
          :options="data.options"
          :close-on-select="false"
          option-label="value"
          :reduce="(item: LocationTypeArray) => item.key"
          clearable
          multiple
        />
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.instructions'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.instructions-description'"
          class="is-size-400 mb-16"
        />
        <wb-input
          v-model="data.location.instructions"
          is-textarea
          :rows="5"
          data-test-id="locationInstructions"
        />
      </div>
    </wb-form>
  </wb-cards-loader>
</template>

<script setup lang="ts">
import { useForm, useField } from 'vee-validate'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import GoogleMapsAndAddressInputForm from '@/components/forms/GoogleMapsAndAddressInputForm.vue'
import CheckboxCardForm from '@/components/forms/CheckboxCardForm.vue'
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'

import state from '@/state'
import api from '@/api'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { locationTypesArray, type LocationTypeArray } from '@/utilities/locationTypesEnum'
import { getCurrencyCode } from '@/utilities/currency'
import type { Group, Locations } from '@/types'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { useTimezonesGoogleMaps } from '@/hooks/useTimezonesGoogleMaps'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()
const { getTimezoneId } = useTimezonesGoogleMaps()

interface PropsType {
  groupId?: number
}

const props = defineProps<PropsType>()

const { errors, validate, setValues, setFieldValue } = useForm({
  validationSchema: {
    name: 'required',
    energyCost: 'required',
    address: 'required',
    latitude: 'required',
    longitude: 'required',
    city: 'required',
    zipCode: 'required'
  }
})

const { value: name } = useField<string>('name')
const { value: energyCost } = useField<number>('energyCost')
const { value: address } = useField<string>('address')
const { value: latitude } = useField<number>('latitude')
const { value: longitude } = useField<number>('longitude')
const { value: city } = useField<string>('city')
const { value: zipCode } = useField<string>('zipCode')

interface DataType {
  location: {
    name: string
    energyCost: number
    energyType?: string,
    locationTypes: LocationTypeArray[]
    instructions: string,
    place: Partial<Locations.Place>
  }
  options: LocationTypeArray[]
  errors: Record<string, string | undefined>
  onLocationError: boolean
  groupUid?: string
  organizationCountryName: string
  loading: boolean
  group?: Group.Group
}

const data: DataType = reactive({
  location: {
    name,
    energyCost,
    locationTypes: [],
    instructions: '',
    place: {
      address,
      latitude,
      longitude,
      city,
      zipCode
    }
  },
  options: locationTypesArray,
  errors,
  onLocationError: false,
  loading: false,
  organizationCountryName: ''
})

type Events = {
  (e: 'on-edit', created: { groupId: number, country?: string, timezoneId?: string }): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  getCurrencyCode: computed(getCurrencyCode)
})

const methods = {
  saveAddress (place: Locations.Place) {
    setFieldValue('address', place.address)
    setFieldValue('latitude', place.latitude)
    setFieldValue('longitude', place.longitude)
    setFieldValue('city', place.city)
    place.zipCode && setFieldValue('zipCode', place.zipCode)
    data.location.place.country = place.country
  },

  async createGroup () {
    const group = {
      name: data.location.name,
      energyPrice: data.location.energyCost,
      parent: state.groups.groupRelatedToOrganization.id
    }

    return await api.locations.postGroup(group)
  },

  async getLocation () {
    const filters = [
      data.groupUid && {
        field: 'group_uid',
        operator: 'eq',
        value: data.groupUid
      }
    ].filter(Boolean as unknown as ExcludesNullish)

    const locations = await api.locations
      .getLocations({ organizationUuid: state.groups.groupRelatedToOrganization.uid, filters })

    return locations.data[0]
  },

  async editLocation (locationUid: string) {
    const location = {
      name: data.location.name,
      address: data.location.place.address,
      energy_type: data.location.energyType ?? 'unknown',
      location_types: data.location.locationTypes.length > 0 ? data.location.locationTypes.join(',') : 'unknown',
      instructions: data.location.instructions ?? '',
      city: data.location.place.city,
      state: data.location.place.state ?? '',
      zip_code: data.location.place.zipCode,
      country: data.location.place.country,
      latitude: data.location.place.latitude,
      longitude: data.location.place.longitude,
      energy_cost: data.location.energyCost
    }

    try {
      await api.locations.putLocation({ id: locationUid, ...location })
    } catch (error) {
      throw new Error(error as string)
    }
  },

  async isValidateForm () {
    data.onLocationError = false
    const validCountryState = await refs.countryState.isValidCountryState()
    const { valid } = await validate()

    return (validCountryState && valid)
  },

  async isSubmitForm () {
    try {
      if (!props.groupId) {
        data.group = (await methods.createGroup()).result
        data.groupUid = data.group.uid
      }

      let timezoneId = ''
      if (data.location.place.latitude && data.location.place.longitude) {
        timezoneId = await getTimezoneId(data.location.place.latitude, data.location.place.longitude)
      }

      emit('on-edit', {
        groupId: data.group?.id || props.groupId || 0,
        country: state.global.getCountries.find(country => country.iso2 === data.location.place.country)?.code,
        timezoneId
      })

      const location = await methods.getLocation()
      await methods.editLocation(location.id)
      return location
    } catch (error) {
      data.onLocationError = true
      throw new Error(error as string)
    }
  }
}

async function created () {
  data.loading = true
  trackDataScreen(!props.groupId ? 'create-location' : 'edit-location')

  data.location.place.country = state.organizations.getCurrentOrganization.country_code

  if (props.groupId) {
    const { result } = await api.locations.getGroup(props.groupId)
    data.groupUid = result.uid
    const location = await methods.getLocation()

    setValues({
      name: location.name,
      energyCost: location.energy_cost,
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude,
      city: location.city,
      zipCode: location.zip_code
    })

    data.location.energyType = location.energy_type
    data.location.locationTypes = (location.location_types ?? '')
      .split(',').filter(Boolean) as unknown as typeof locationTypesArray

    data.location.instructions = location.instructions

    data.location.place.state = location.state

    data.location.place.country ??= location.country
  }

  data.organizationCountryName = state.global.getCountries.find(
    country => country.iso2 === data.location.place.country)?.name || ''

  data.loading = false
}

created()

defineExpose({
  validateForm: () => methods.isValidateForm(),
  submitForm: () => methods.isSubmitForm(),
  isLoading: () => data.loading
})
</script>

<style lang="postcss" scoped>
.form {
  grid-template-columns: 100%;
}

.energy-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
