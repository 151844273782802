import { ref } from 'vue'
import ENV from '@/engine/env/web.env'
import noirTheme from '@/hooks/googleMapsNoirTheme'

export function useLoadGoogleMaps ({
  withInitialGeolocation = false,
  center = { lat: 40.4637, lng: 3.7492 },
  zoom = 5
} = {}) {
  interface Data {
    googleUrl: string
    queries: string
    googleScript?: HTMLScriptElement
    el?: HTMLElement
  }
  const data: Data = {
    googleUrl: 'https://maps.googleapis.com/maps/api/js',
    queries: `?key=${ENV.google.maps}&libraries=places&v=weekly&solution_channel=GMP_QB_addressselection_v1_cABC`
  }

  const map = ref<google.maps.Map>()

  const methods = {
    createMap (resolve: () => void) {
      if (!data.el) return resolve()

      map.value = new window.google.maps.Map(data.el, {
        center: { lat: center.lat ?? 40.4637, lng: center.lng ?? 3.7492 },
        zoom,
        minZoom: 5,
        maxZoom: 18,
        styles: noirTheme
      })

      if (withInitialGeolocation && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            map.value?.setCenter?.({ lat, lng })
            map.value?.setZoom(11)
          }
        )
      }

      resolve()
    },

    async initMap ({ el } = { el: HTMLElement.prototype }) {
      return await new Promise<void>((resolve) => {
        data.el ??= el

        data.googleScript = document.getElementById('gmaps-script') as HTMLScriptElement

        if (data.googleScript) {
          methods.createMap(resolve)
          return
        }

        data.googleScript = document.createElement('script')
        data.googleScript.setAttribute('src', `${data.googleUrl}${data.queries}`)
        data.googleScript.setAttribute('id', 'gmaps-script')
        document.head.appendChild(data.googleScript)

        data.googleScript.onload = () => {
          methods.createMap(resolve)
        }
      })
    },

    createCustomMarkerIcon () {
      const svg = window.btoa(`
        <svg fill="#52B89E" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
        </svg>`
      )

      return {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(45, 45)
      }
    },

    createCustomCluster () {
      return {
        render ({ count, position }: { count: number, position: any }) {
          const svg = window.btoa(`
          <svg fill="#52B89E" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
          <circle cx="120" cy="120" opacity=".1" r="130" />
          </svg>`)

          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(45, 45)
            },
            label: {
              text: String(count),
              color: 'rgba(255,255,255,0.9)',
              fontSize: '12px'
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count
          })
        }
      }
    },

    async geocode (address: google.maps.GeocoderRequest) {
      const geocoder = new window.google.maps.Geocoder()
      const result = await geocoder.geocode(address)

      return result
    }
  }

  return {
    map,
    initMap: methods.initMap,
    createCustomCluster: methods.createCustomCluster,
    createCustomMarkerIcon: methods.createCustomMarkerIcon,
    geocode: methods.geocode
  }
}
