interface State {
  isRefreshing: boolean
  requestCount: number
  pendingRequests: Array<() => void>
}

const state: State = {
  isRefreshing: false,
  requestCount: 0,
  pendingRequests: []
}

export const addPendingRequest = (request: () => void) => {
  state.pendingRequests.push(request)
}
export const addRequestCount = (number: number) => {
  state.requestCount += number

  setTimeout(() => {
    document.body.setAttribute('pending-request', (state.requestCount > 0).toString())
  }, 500)
}

export const getPendingRequests = () => state.pendingRequests
export const clearPendingRequest = () => (state.pendingRequests = [])

export const setIsRefreshing = (bool: boolean) => (state.isRefreshing = bool)
export const getIsRefreshing = () => state.isRefreshing
