import type { Charger } from '@/types'
import chargerTypesData, { CHARGER_TYPES } from '@/utilities/charger/chargerTypesData'

export const chargerTypes = chargerTypesData

export const findChargerType = (charger: Charger.AnyTypeOfCharger) => {
  if (!charger?.chargerType) return null
  const code = charger.chargerType.replaceAll(' ', '-').toLowerCase()
  return chargerTypes.find(type => type.code.includes(code))
}

export const isChargerType = <E extends { chargerType: string }> (charger: E, types: string[] = []) => {
  return types.includes(charger?.chargerType?.toLowerCase().replaceAll(' ', '-'))
}

export const isPulsar = (charger: Charger.ChargerData | Charger.Charger) => {
  return isChargerType(charger, [
    CHARGER_TYPES.PULSAR,
    CHARGER_TYPES.PULSAR_CHINA,
    CHARGER_TYPES.PULSAR_UP
  ])
}

export const hasPaymentsSupport = (charger: Charger.AnyTypeOfCharger) => {
  const chargerType = findChargerType(charger)
  return !!chargerType?.payments
}

export const supportsPowerBoost = (charger: Charger.ChargerData) => {
  return isChargerType(charger, [
    CHARGER_TYPES.PULSAR_PLUS,
    CHARGER_TYPES.PULSAR_PLUS_US,
    CHARGER_TYPES.COMMANDER_2,
    CHARGER_TYPES.COPPER,
    CHARGER_TYPES.COPPER_BUSINESS,
    CHARGER_TYPES.QUASAR,
    CHARGER_TYPES.QUASAR_BUSINESS
  ])
}

export const supportsPowerSharing = (charger: Charger.ChargerData) => {
  return isChargerType(charger, [
    CHARGER_TYPES.PULSAR,
    CHARGER_TYPES.PULSAR_CHINA,
    CHARGER_TYPES.PULSAR_UP,
    CHARGER_TYPES.PULSAR_PLUS,
    CHARGER_TYPES.PULSAR_PLUS_US,
    CHARGER_TYPES.PULSAR_MAX,
    CHARGER_TYPES.COMMANDER_2,
    CHARGER_TYPES.COPPER,
    CHARGER_TYPES.COPPER_BUSINESS
  ])
}

export const supportsDynamicPowerSharing = (charger: Charger.ChargerData) => {
  return isChargerType(charger, [
    CHARGER_TYPES.PULSAR,
    CHARGER_TYPES.PULSAR_CHINA,
    CHARGER_TYPES.PULSAR_UP,
    CHARGER_TYPES.PULSAR_PLUS,
    CHARGER_TYPES.PULSAR_PLUS_US,
    CHARGER_TYPES.COMMANDER_2,
    CHARGER_TYPES.COPPER,
    CHARGER_TYPES.COPPER_BUSINESS
  ])
}

export const supportsEcoSmart = (charger: Charger.ChargerData) => {
  return isChargerType(charger, [
    CHARGER_TYPES.PULSAR_PLUS,
    CHARGER_TYPES.PULSAR_PLUS_US,
    CHARGER_TYPES.PULSAR_MAX,
    CHARGER_TYPES.COMMANDER_2,
    CHARGER_TYPES.COPPER,
    CHARGER_TYPES.COPPER_BUSINESS
  ])
}
