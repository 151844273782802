import i18n from '@/engine/lang'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import {
  hasRole,
  hasPlan,
  hasFreeProduct,
  and,
  or,
  isOwner
} from '../validators'
import supportInfoJson from '@/utilities/support/supportInfo'
import { numbers } from '@wallbox/toolkit-ui'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import type { Client } from './client'

const wallbox: Client = {
  name: 'wallbox',
  partner: 'wallbox',
  brandingId: 'com.wallbox',
  actions: {
    showCommanderPin: true,
    showRfid: true,
    editRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showSelectedChargers: true,
    canChangePlan: false,
    showPayments: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showPaymentsAsideMenu: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showBilling: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canEditChargerName: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showAutoReporting: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    hasBulkActions: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasAuthToRestartAndUpdate: hasRole(EnumRoles['super-admin'], EnumRoles.admin, EnumRoles.operator),
    canMarkAsPaid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canAssignedRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canSeeRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCharge: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canChargerItemClickeable: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddChargerFromEmptyState: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddCharger: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canWriteEnergyCost: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeLocations: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canViewUpdateChargersPending: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCreateUserGroups: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    isDisabledSelectAccessGroup: hasRole(EnumRoles.admin, EnumRoles.user),
    showUserGroupOptions: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showDashboardAsideMenu: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showUserAsideMenu: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canPaymentsAsideMenu: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canAddUser: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canEditAndDeleteUserGroupOption: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canAddRfidInInvitations: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canUserListRfidEdit: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    canUserListRfidActions: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    canRevokeOrResendUserInvitation: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    hasSocialSingin: true,
    showPaymentsEngage: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showUpSellingToPlanBusiness: false,
    canConnectStripe: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showPublishOnElectromaps: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canOperateOrganizations: or(
      hasPlan(EnumPlans.OPERATOR),
      hasRole(EnumRoles.operator)
    ),
    showCarouselLogin: true,
    canSeeOcppChargers: () => hasFeatureFlagActive('ocpp'),
    canAddOperators: and(
      isOwner(),
      hasRole(EnumRoles.operator)
    ),
    canSeeModalEditOrganization: and(
      isOwner()
    )
  },

  footer: {
    rights: i18n.global.t('mywb.common.wallbox-rigths', { year: new Date().getFullYear() }),
    social: {
      twitter: 'https://twitter.com/wallboxchargers',
      linkedin: 'https://es.linkedin.com/company/wallbox-chargers',
      facebook: 'https://www.facebook.com/wallboxchargers'
    }
  },

  academy: 'https://support.wallbox.com/',

  energyManagmentSupport: 'https://support.wallbox.com/en/knowledge-base/energy-management-solutions/',

  ecommerceUrl: 'https://wallbox.com/',

  docs: {
    terms: '/docs/wallbox_terms_conditions_en_uk.pdf',
    privacy: '/docs/wallbox_privacy_policy_en.pdf',
    business: '/docs/terms_conditions_business_en.pdf',
    standard: '/docs/terms_conditions_standard_en.pdf'
  },

  logo: {
    login: {
      lottie: true,
      src: 'logos/wallbox_logo.svg',
      alt: 'myWallbox',
      style: 'width: 75%; max-width: 300px'
    },
    aside: { src: 'logos/wallbox-logo-inverted.svg', alt: 'Wallbox' },
    asideCollapse: { src: 'logos/logo-wallbox-icon-inverted.svg', alt: 'Wallbox' },
    header: { src: 'logos/wallbox_logo.svg', style: 'height: 56px;', alt: 'wallbox-logo' }
  },

  units: {
    volume: {
      unit: 'L',
      name: 'liters',
      formula: liters => `${numbers.toLocaleNumber(liters, i18n.global.locale, 2, 2)} L`
    },
    mass: {
      unit: 'Kg',
      name: 'Kilograms',
      formula: kilogram => `${numbers.toLocaleNumber(kilogram, i18n.global.locale, 2, 2)} Kg`
    }
  },

  supportInfo: supportInfoJson,

  regions: {
    usa: {
      name: 'wallbox-usa',

      actions: {
        showCommanderPin: false,
        showRfid: false,
        canChangePlan: and(
          () => hasFeatureFlagActive('us_payments'),
          hasRole(EnumRoles['super-admin']),
          hasFreeProduct(false)
        ),
        showPayments: and(
          () => hasFeatureFlagActive('us_payments'),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR),
          hasRole(EnumRoles['super-admin'], EnumRoles.operator)
        ),
        canSeeLocations: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        canCreateUserGroups: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        isDisabledSelectAccessGroup: or(
          hasRole(EnumRoles.admin, EnumRoles.user),
          hasPlan(EnumPlans.BASIC)
        ),
        showAutoReporting: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        hasBulkActions: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        canEditAndDeleteUserGroupOption: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        hasAuthToRestartAndUpdate: or(
          and(
            hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
            hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
          ),
          and(
            hasRole(EnumRoles['super-admin']),
            hasPlan(EnumPlans.BASIC)
          )
        ),
        showPaymentsAsideMenu: or(
          and(
            () => hasFeatureFlagActive('us_payments'),
            hasRole(EnumRoles['super-admin']),
            hasFreeProduct(false)
          ),
          and(
            () => hasFeatureFlagActive('us_payments'),
            hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR),
            hasRole(EnumRoles['super-admin']),
            hasFreeProduct(true)
          ),
          and(
            () => hasFeatureFlagActive('us_payments'),
            hasRole(EnumRoles.operator)
          )
        ),
        showPaymentsEngage: and(
          () => hasFeatureFlagActive('us_payments'),
          hasRole(EnumRoles['super-admin'], EnumRoles.operator)
        ),
        canConnectStripe: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        showPublishOnElectromaps: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        )
      },
      footer: {
        social: {
          twitter: 'https://twitter.com/WallboxNA',
          instagram: 'https://www.instagram.com/wallboxna/',
          facebook: 'https://www.facebook.com/WallboxNA'
        }
      },

      academy: 'https://support.wallbox.com/na/',

      docs: {
        terms: '/docs/wallbox_terms_conditions_usa_en_05_04_20.pdf',
        privacy: '/docs/wallbox_privacy_policy_usa_en_08_27_20.pdf'
      },

      units: {
        volume: {
          unit: 'gal',
          name: 'gallons ',
          formula: liters => `${(liters / 3.785).toFixed(2)} gal`
        },
        mass: {
          unit: 'lb/oz',
          name: 'Pounds and ounces',
          formula: kilogram => {
            const poundsTotal = kilogram / 0.45359237
            const intPart = Math.trunc(poundsTotal)
            const floatPart = Number((poundsTotal - intPart))
            const ounces = (floatPart * 16).toFixed(2)
            return `${intPart} lb ${ounces} oz`
          }
        }
      }
    },

    spain: {
      name: 'wallbox-spain',
      docs: {
        privacy: '/docs/wallbox_privacy_policy_es.pdf',
        terms: '/docs/wallbox_terms_conditions_es.pdf',
        standard: '/docs/terms_conditions_standard_es.pdf',
        business: '/docs/terms_conditions_business_es.pdf'
      }
    },

    europeanUnion: {
      docs: {
        terms: '/docs/wallbox_terms_conditions_en.pdf',
        standard: '/docs/terms_conditions_standard_en.pdf',
        business: '/docs/terms_conditions_business_en.pdf'
      }
    }
  }
}

export default wallbox
