<template>
  <wb-card
    id="access"
    :headline="i18n.t('mywb.common.open-access')"
    :subhead="i18n.t('mywb.common.open-access.description')"
    data-test-id="securityOpenAccess"
    :class="{ 'selected-blink': props.selected === 'access'}"
  >
    <template #place-upper-right>
      <div class="is-flex">
        <wb-label
          v-if="!data.chargerConfig.auto_lock"
          is-invert
          type="primary"
          class="label"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-label>
        <wb-radio
          v-model="data.chargerConfig.auto_lock"
          :value="0"
          position="right"
          :disabled="data.loading"
          @update:model-value="methods.checkUnassignRates"
        />
      </div>
    </template>
  </wb-card>

  <wb-card
    id="lock"
    class="my-8"
    :headline="i18n.t('mywb.common.restricted-or-paid-access')"
    :subhead="i18n.t('mywb.common.restricted-or-paid-access.description')"
    data-test-id="securityRestrictedOrPaidAccess"
    :class="{ 'selected-blink': props.selected === 'lock'}"
    actions-position="bottom"
  >
    <template #actions>
      <wb-button
        v-if="!compute.isOcpp"
        class="mt-16"
        :label="i18n.t('mywb.charger.view-assigned-users')"
        type="white"
        outlined
        :to="{ name: 'charger-users' }"
        data-test-id="editMaxCurrentButton"
      />
    </template>

    <template #content>
      <p class="is-size-300 is-font-weight-500 has-text-black">
        {{ i18n.t('mywb.common.auto-lock') }}
      </p>

      <span class="is-size-300">{{ i18n.t('mywb.common.auto-lock.description') }}</span>

      <i18n-t
        v-if="data.chargerConfig.auto_lock"
        tag="p"
        keypath="mywb.charger.protection.charger-locks-after"
        class="is-size-300 mt-8"
      >
        <span
          class="is-font-weight-500"
          data-test-id="autoLockTime"
        >
          {{ Math.round(data.chargerConfig.auto_lock_time / data.autolock.interval) }} {{ i18n.t('mywb.common.min') }}
        </span>
      </i18n-t>

      <wb-range-slider
        v-if="compute.compatible.isCompatible && data.chargerConfig.auto_lock"
        v-model="data.chargerConfig.auto_lock_time"
        data-test-id="autoLockSliderBar"
        :interval="data.autolock.interval"
        :min="data.autolock.min"
        :max="data.autolock.max"
        :tooltip-formatter="tooltip => `${tooltip}${i18n.t('mywb.common.min')}`"
        @on-mouseup="methods.saveChargerConfig"
      />
    </template>

    <template #place-upper-right>
      <div class="is-flex">
        <wb-label
          v-if="data.chargerConfig.auto_lock"
          is-invert
          type="primary"
          class="label"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-label>

        <wb-radio
          v-model="data.chargerConfig.auto_lock"
          :value="1"
          class="radio"
          position="right"
          :disabled="data.loading"
          @update:model-value="methods.saveChargerConfig"
        />
      </div>
    </template>
  </wb-card>

  <shared-confirmation-modal
    v-if="data.frozeSchedules"
    :title="i18n.t('mywb.common.change-mode-charging-confirmation')"
    :subtitle="i18n.t('mywb.common.free-charging.description')"
    type="danger"
    :label-confirmation-button="i18n.t('mywb.common.disable-payments')"
    blocked
    hide-close-button
    @on-confirm="methods.deleteRatesAndSave"
    @on-cancel="methods.cancelDeleteRates"
  >
    <wb-notification
      type="info"
      icon="info_filled"
      class="mb-12"
    >
      <p v-t="'mywb.common.payments-configuration-will-be-disable'" />
    </wb-notification>
    <p class="is-size-400 mb-12">
      {{ i18n.t('mywb.common.change-mode-charging-confirmation.description') }}
    </p>
  </shared-confirmation-modal>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import api from '@/api'
import state from '@/state'
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { isOcpp } from '@/utilities/charger/chargerOcppSettings'

import { useNotify } from '@wallbox/toolkit-ui'
import { isAutolockCompatible } from '@/utilities/charger/chargerCompatible'

const notify = useNotify()
const i18n = useI18n()

interface Props {
  loading?: boolean,
  selected?: string
}
const props = defineProps<Props>()

interface Data {
  frozeSchedules: boolean,
  autolock: {
    min: 1,
    max: 60,
    interval: 60
  },
  chargerConfig: {
    auto_lock: 0 | 1,
    auto_lock_time: number
  },
  loading: boolean,
  schedules: Awaited<ReturnType<typeof api.rates.getChargerRatesWeekSchedules>>['data']
}
const data = reactive<Data>({
  frozeSchedules: false,
  autolock: {
    min: 1,
    max: 60,
    interval: 60
  },
  chargerConfig: {
    auto_lock: 0,
    auto_lock_time: 60
  },
  loading: false,
  schedules: []
})

const compute = reactive({
  compatible: computed(() => {
    if (!state.charger.getCurrentCharger.chargerData) return { isCompatible: true }
    return isAutolockCompatible(state.charger.getCurrentCharger.chargerData)
  }),

  isOcpp: computed(() => isOcpp(state.charger.getCurrentCharger.config))
})

watchEffect(() => {
  if (!props.loading) {
    data.chargerConfig.auto_lock = state.charger.getCurrentCharger.config.auto_lock
    data.chargerConfig.auto_lock_time = state.charger.getCurrentCharger.config.auto_lock_time
  }
})

const methods = {
  cancelDeleteRates () {
    data.chargerConfig.auto_lock = 1
    data.frozeSchedules = false
  },

  async deleteRatesAndSave () {
    const apiCalls = data.schedules.map(schedule => {
      return () => api.rates.saveChargerSchedule({
        chargerId: state.charger.getCurrentCharger.chargerData.uid,
        scheduleId: schedule.id,
        payload: {
          type: schedule.paymentType,
          status: 'frozen',
          schedule: schedule.schedule

        }
      })
    })

    await Promise.all(apiCalls.map(call => call()))
    data.frozeSchedules = false
    await methods.saveChargerConfig()
  },

  async checkUnassignRates () {
    data.schedules =
      (await api.rates.getChargerRatesWeekSchedules(state.charger.getCurrentCharger.chargerData.uid ?? '')).data

    if (data.schedules.length > 0) {
      data.frozeSchedules = true
    } else {
      methods.saveChargerConfig()
    }
  },

  async saveChargerConfig () {
    data.loading = true
    try {
      await api.chargers.updateChargerConfig({
        id: state.charger.getCurrentCharger.chargerData.id,
        config: data.chargerConfig
      })

      const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
      state.charger.setCurrentCharger(charger)
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.is-flex {
  display: flex;
  align-items: center;

  & .label {
    padding: 0 8px;
    margin-right: 8px;
  }
}

.separator {
  border: 1px solid var(--grey-200);
}

.selected-blink {
  animation: blink 900ms linear;
  border-radius: 0.8rem;
}

@keyframes blink {
  0% { box-shadow: none; }
  50% { box-shadow: 0 0 10px var(--primary-500); }
  100% { box-shadow: none; }
}

</style>
