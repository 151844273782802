<template>
  <wb-modal
    width="33"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.power-limit') }}
    </template>

    <p class="is-size-400 mb-24">
      {{ i18n.t('mywb.common.power-limit.description') }}
    </p>

    <!-- eslint-disable-next-line -->
    <p class="is-size-400 mb-32" v-html="i18n.t('mywb.common.current-per-phase', [field.currentAmps])" />

    <wb-range-slider
      v-model="field.currentAmps"
      name="currentAmps"
      :min="chargerAmps.min"
      :max="chargerAmps.max"
      :tooltip-formatter="methods.ampsParsed"
    />

    <div class="is-flex-elements mt-24">
      <div class="is-size-300 is-font-weight-500">
        {{ chargerAmps.min }}{{ i18n.t('mywb.charger.amps') }}
      </div>
      <div class="is-size-300 is-font-weight-500">
        {{ chargerAmps.max }}{{ i18n.t('mywb.charger.amps') }}
      </div>
    </div>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          size="block"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />

        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="i18n.t('mywb.common.set-limit')"
          :loading="data.loading"
          @click="validate(methods.editMaxCurrent)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import state from '@/state'
import api from '@/api'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { getChargerAmps } from '@/utilities/charger/chargerAmps'
import { useValidator } from '@/hooks/useValidator.hook'

const notify = useNotify()
const i18n = useI18n()
const { yup, defineSchema, validate } = useValidator()

const chargerAmps = getChargerAmps(state.charger.getCurrentCharger.chargerData)

interface Events {
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const field = reactive({
  currentAmps: chargerAmps.current
})

defineSchema(field, {
  currentAmps: yup
    .number()
    .min(chargerAmps.min, i18n.t('mywb.error.min.max-current', [chargerAmps.min]))
    .max(chargerAmps.max, i18n.t('mywb.error.max.max-current', [chargerAmps.max]))
    .required()
})

interface DataType {
  loading: boolean
}

const data: DataType = reactive({
  loading: false
})

const methods = {
  ampsParsed (value: string | number) {
    return value + i18n.t('mywb.charger.amps')
  },

  async editMaxCurrent  () {
    data.loading = true
    try {
      const { data: { chargerData } } = await api.chargers.updateCharger({
        chargerId: state.charger.getCurrentCharger.chargerData?.id,
        params: {
          maxChargingCurrent: field.currentAmps
        }
      })
      state.charger.set('currentCharger.chargerData', chargerData)

      trackDataEvent('update-amp-charger', { 'max-charging-current': field.currentAmps })
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('on-close')
    }
  }
}
</script>

<style lang="postcss" scoped>
.is-flex-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
