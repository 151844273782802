<template>
  <div>
    <invoices-header />

    <payments-invoices-table
      :invoices="data.invoices"
      :loading="data.loading"
      :pagination="data.pagination"
      @on-mark-as-paid="methods.handleMarkAsPaid"
      @on-pagination-change="methods.getInvoicesData"
    />

    <payments-invoices-mark-as-paid-modal
      v-if="data.isModalMarkAsPaidOpen && data.invoiceSelected"
      :invoice="data.invoiceSelected"
      :loading="data.markAsPaidLoading"
      @on-mark-as-paid="methods.markAsPaid"
      @close="data.isModalMarkAsPaidOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import PaymentsInvoicesMarkAsPaidModal from '@/components/modals/PaymentsInvoicesMarkAsPaidModal.vue'
import PaymentsInvoicesTable from '@/components/tables/PaymentsInvoicesTable.vue'
import InvoicesHeader from '@/components/headers/InvoicesHeader.vue'
import api from '@/api'
import state from '@/state'
import { useNotify } from '@wallbox/toolkit-ui'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive, watch } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import type { Filters, Invoice } from '@/types'

const router = useRouter()
const i18n = useI18n()
const notify = useNotify()

interface Data {
  loading: boolean,
  invoices: Invoice.Invoice[],
  filtersParams: Array<Filters.Filters>
  isModalMarkAsPaidOpen: boolean
  markAsPaidLoading: boolean,
  invoiceSelected?: Invoice.Invoice
  pagination: {
    offset: number,
    limit: number
    total: number
  }
}
const data = reactive<Data>({
  loading: true,
  invoices: [],
  filtersParams: [],
  isModalMarkAsPaidOpen: false,
  markAsPaidLoading: false,
  pagination: {
    offset: 0,
    limit: 25,
    total: 0
  }
})

async function created () {
  router.push({ query: {} }).catch(() => {})
}

const methods = {
  async getInvoicesData (offset = 0) {
    data.loading = true
    data.invoices = []

    const groupUid = state.organizations.getCurrentOrganization.group_uid
    data.pagination.offset = offset
    const params = {
      ...data.pagination,
      filters: { filters: state.filters.invoicesBackendFilters }
    }
    const invoices = await api.payments.getInvoices({ params, groupUid })

    data.invoices = invoices.data
    data.pagination.total = +invoices.pagination.count
    data.loading = false
  },

  handleMarkAsPaid (invoice: Invoice.Invoice) {
    data.invoiceSelected = invoice
    data.isModalMarkAsPaidOpen = true
  },

  async markAsPaid (invoice: Invoice.Invoice) {
    data.markAsPaidLoading = true
    try {
      await api.payments.postVoidInvoice({
        invoiceId: invoice.id
      })
      await methods.getInvoicesData()
      notify.success(i18n.t('mywb.common.changes-saved'))
      trackDataAction('invoice-paid')
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.markAsPaidLoading = false
      data.isModalMarkAsPaidOpen = false
    }
  }
}

created()

watch(() => state.filters.invoicesBackendFilters, () => {
  data.pagination.offset = 0
  methods.getInvoicesData()
}, { immediate: true, deep: true })
</script>
