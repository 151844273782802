<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.users') }}
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canCreateUserGroups"
        id="add-group"
        icon="create_new_folder"
        data-test-id="createGroupBtn"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.create-access-group') : ''"
        type="primary"
        outlined
        @click="methods.onCreateGroup"
      />
      <wb-button
        v-if="permissions.canAddUser"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.user.add-user') : ''"
        data-test-id="addUserButton"
        icon="person_add"
        type="primary"
        @click="methods.onInviteUser"
      />
    </template>

    <template v-if="route.name === 'users'" #filters>
      <access-config-filters
        :loading="data.loading"
      />
    </template>

    <template #tabs>
      <wb-tabs
        v-model="data.tabSelected"
        :tabs="compute.sections"
        @tab-change="(option: any) => router.push({ name: option.key })"
      />
    </template>
  </shared-header>

  <router-view
    v-slot="{ Component }"
    v-bind="$attrs"
  >
    <container-component>
      <component
        :is="Component"
        :ref="setRef('routerComponent')"
      />
    </container-component>
  </router-view>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import accessConfigFilters from '@/components/filters/accessConfigFilters.vue'

import { computed, reactive, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { useMq } from 'vue3-mq'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const mq = useMq()

const compute = reactive({
  sections: computed(() => {
    return [
      { key: 'users', value: i18n.t('mywb.common.users'), dataTestId: 'usersTab' },
      { key: 'access-configs-groups', value: i18n.t('mywb.common.access-groups'), dataTestId: 'userGroupsTab' }
    ]
  })
})

interface Data {
  tabSelected?: typeof compute.sections[number],
  loading?: boolean
}
const data = reactive<Data>({
  tabSelected: undefined,
  loading: false
})

watchEffect(() => {
  data.tabSelected = compute.sections.find(tab => tab.key === route.name?.toString())
})

const methods = {
  onInviteUser () {
    router.push({ name: 'users' })
    setTimeout(() => { refs.routerComponent?.inviteUser() }, 300)
  },

  onCreateGroup () {
    router.push({ name: 'access-configs-groups' })
    setTimeout(() => { refs.routerComponent?.handleAddGroup() }, 300)
  }
}

</script>
