import { numbers } from '@wallbox/toolkit-ui'
import i18n from '@/engine/lang'
import { getPriceAmountUpperLimit, getCurrencyCode } from '@/utilities/currency'

export function notExceedsPriceUpperLimit (amount: number) {
  const amountUpperLimit = getPriceAmountUpperLimit()
  return amount <= amountUpperLimit
}

export function priceAmountUpperLimit () {
  return numbers.toLocaleCurrencySymbol(
    getPriceAmountUpperLimit(),
    getCurrencyCode(),
    i18n.global.locale
  )
}
