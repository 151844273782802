<template>
  <wb-select
    v-model="compute.groupId"
    option-label="name"
    uid="_group"
    :options="compute.groupsOptions"
    :reduce="(group: GroupChargersTree) => group.id"
    data-test-id="selectLocation"
    placeholder="select location"
    :loading="props.loading || data.loading || !compute.groupFounded"
    :disabled="props.loading || data.loading || props.disabled"
    :error="props.error"
    placeholder-icon="location"
    @change="$emit('on-change')"
  >
    <template #selected-option="{ option }">
      <span
        class="wb-icons mr-12"
      > location</span>
      {{ i18n.t(option.name) }}
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import type { GroupChargersTree } from '@/types/data/group.data.types'

const i18n = useI18n()

interface Props {
  modelValue?: number | string
  loading?: boolean
  disabled?: boolean
  error?: string
  filterGroup?: string
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', value?: number | string): void,
  (e: 'on-change'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const compute = reactive({
  groupFounded: computed((): boolean => {
    return !props.modelValue ? true : compute.groupsOptions.some(group => group.id === props.modelValue)
  }),

  groupsOptions: computed((): GroupChargersTree[] => {
    return (state.groups.groupRelatedToOrganizationWithChargers?.subgroups ?? [])
      .filter((group) => group.uid !== props.filterGroup)
  }),

  groupId: computed({
    get (): number | string | undefined {
      return !data.loading && compute.groupFounded ? props.modelValue : i18n.t('mywb.common.loading')
    },
    set (groupId) {
      emit('update:modelValue', groupId)
    }
  })
})
</script>

<style lang="postcss" scoped>
:deep(.wbSelect .v-select .vs__search) {
  padding-left: 34px;
}
</style>
