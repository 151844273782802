import ChargersView from '@/views/ChargersView.vue'
import ChargerView from '@/views/ChargerView.vue'
import ChargerOverviewView from '@/views/ChargerOverviewView.vue'
import ChargerUsersView from '@/views/ChargerUsersView.vue'
import ChargerSessionsView from '@/views/ChargerSessionsView.vue'
import ChargerSettingsView from '@/views/ChargerSettingsView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '',
    redirect: 'chargers'
  },
  {
    path: 'chargers',
    name: 'chargers',
    component: ChargersView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'chargers',
        text: 'mywb.common.chargers'
      }
    }
  },
  {
    path: 'chargers/:chargerId',
    component: ChargerView,
    props: true,
    meta: {
      breadcrumb: {
        name: 'charger'
      }
    },
    children: [
      {
        path: '',
        name: 'charger',
        component: ChargerOverviewView
      },
      {
        path: 'users',
        name: 'charger-users',
        component: ChargerUsersView
      },
      {
        path: 'sessions',
        name: 'charger-sessions',
        component: ChargerSessionsView
      },
      {
        path: 'settings',
        name: 'charger-settings',
        component: ChargerSettingsView
      }
    ]
  }
] as RouteRecordRaw[]
