<template>
  <div
    v-if="props.charger.id"
    v-t="compute.status.label"
    class="status-label"
  />
</template>

<script setup lang="ts">
import type { Charger } from '@/types'
import { getChargerStatus, getColorByStatus, type Status } from '@/utilities/charger/chargerStatuses'

import { computed, reactive } from 'vue'

interface Props {
  charger: Charger.AnyTypeOfCharger
}
const props = defineProps<Props>()

const compute = reactive({
  status: computed((): Status => getChargerStatus(props.charger)),

  colors: computed((): { bg: string, text: string } => getColorByStatus(compute.status?.code))
})
</script>

<style lang="postcss" scoped>
.status-label {
  border-radius: 26px;
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 11px;
  background: v-bind(compute.colors.bg);
  color: v-bind(compute.colors.text);
  letter-spacing: 0.08rem;
}
</style>
