import type { CountryCodes } from '@/types/data/currency.data.types'
import { computed, reactive } from 'vue'

interface State {
  midWithCountriesNeeded: CountryCodes[]
}

const initialState = (): State => ({
  midWithCountriesNeeded: []
})

const state: State = reactive(initialState())

const getters = {
  countriesWithMidNeeded: computed(() => {
    return state.midWithCountriesNeeded
  })
}
const setters = {
  setConfig ({ mid_countries }: { mid_countries: CountryCodes[] }) {
    state.midWithCountriesNeeded = mid_countries
  }
}

export default reactive({
  ...getters,
  ...setters
})
