import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import ENV from '@/engine/env/web.env'
import Tracker from './tracker'

export default class DatadogTracker extends Tracker {
  constructor () {
    super()

    if (!ENV.datadog.projectId || !ENV.datadog.clientToken) return

    datadogRum.init({
      applicationId: ENV.datadog.projectId,
      clientToken: ENV.datadog.clientToken,
      site: 'datadoghq.com',
      service: 'portal',
      sampleRate: 10,
      replaySampleRate: 10,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      env: ENV.datadog.env,
      version: ENV.datadog.version,
      allowedTracingOrigins: ['https://api-pre.wall-box.com', 'https://api.wall-box.com']
    })

    datadogLogs.init({
      clientToken: ENV.datadog.clientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'portal',
      version: ENV.datadog.version
    })

    datadogRum.startSessionReplayRecording()
  }

  trackScreen (name: string, params: object) {
    datadogLogs.logger.log(name, params)
  }

  trackAction (name: string, params: object) {
    datadogLogs.logger.log(name, params)
  }

  trackEvent (name: string, params: object) {
    datadogLogs.logger.log(name, params)
  }

  trackPurchase (name: string, params: object) {
    datadogLogs.logger.log(name, params)
  }

  trackError (name: string, params: object) {
    datadogLogs.logger.error(name, params)
  }

  trackIdentify () {
    return false
  }
}
