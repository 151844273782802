<template>
  <div>
    <access-configs-table
      v-if="compute.filteredAccessConfigs.length || compute.filteredUserInvitations.length || props.loading"
      :access-configs="compute.filteredAccessConfigs"
      :user-invitations="compute.filteredUserInvitations"
      :user-id="props.userId"
      :user-profile="props.userProfile"
      :loading="props.loading"
      @remove-user="emit('remove-user', $event)"
      @on-click="emit('on-click', $event)"
      @on-resend-invitation="emit('resend-invitation', $event)"
      @on-revoke-invitation="emit('revoke-invitation', $event)"
    />
    <user-empty-state v-else @add-user="emit('add-user')" />
  </div>
</template>

<script setup lang="ts">
import AccessConfigsTable from '@/components/tables/AccessConfigsTable.vue'
import UserEmptyState from '@/components/emptyStates/UserEmptyState.vue'
import { reactive, watch, computed } from 'vue'

import state from '@/state'
import type { User, Invitation } from '@/types'
import type { EnumRoles } from '@/utilities/user-roles'

interface Props {
  group?: User.UsersGroupWithUserExpanded,
  userInvitations: Invitation.Invitation[]
  userProfile: EnumRoles
  userId: number
  loading?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-edit-group'): void,
  (e: 'on-delete-group'): void,
  (e: 'remove-user', payload: { groupId: number, user: User.UserList['user'] }): void,
  (e: 'on-click', payload: { userId?: number }): void,
  (e: 'resend-invitation', payload: { invitationId: number }): void,
  (e: 'revoke-invitation', payload: { invitationId: number }): void,
  (e: 'add-user'): void,
}
const emit = defineEmits<Events>()

interface Data {
  accessConfigs: User.UserList[]
  userInvitations: User.UserList[]
}
const data = reactive<Data>({
  accessConfigs: [],
  userInvitations: []
})

watch(() => props.userInvitations, async (object, oldObject) => {
  if (JSON.stringify(object) !== JSON.stringify(oldObject)) {
    data.userInvitations = await methods.getUserInvitations()
  }
})

const compute = reactive({
  filteredAccessConfigs: computed(() => {
    const reducers = {
      groups: methods.filterByGroup,
      role: methods.filterByProfile,
      users: methods.filterByUsers,
      text: methods.filterByText,
      status: methods.filterByStatus
    }

    const entries = Object.entries(state.filters.accessConfigFilters) as
      Array<[keyof typeof reducers, never]>

    return entries.reduce((accessConfigFilters, [type, value]) =>
      reducers[type](accessConfigFilters, value), data.accessConfigs
    )
  }),

  filteredUserInvitations: computed(() => {
    const reducers = {
      groups: methods.filterByGroup,
      role: methods.filterByProfile,
      users: methods.filterByUsers,
      text: methods.filterByText,
      status: methods.filterByStatus
    }

    const entries = Object.entries(state.filters.accessConfigFilters) as
      Array<[keyof typeof reducers, never]>

    return entries.reduce((accessConfigFilters, [type, value]) =>
      reducers[type](accessConfigFilters, value), data.userInvitations
    )
  })
})

const methods = {
  getAllAccessConfig () {
    const rootGroupUsers = (props.group?.users ?? [])
      .filter(user => user.id !== state.organizations.getCurrentOrganization.owner_id)

    const accessConfigsRootUsers = rootGroupUsers?.map(user => ({
      id: user.accessConfig,
      status: 'active',
      user: {
        id: user.id,
        name: user.name ?? '',
        lastname: user.lastname,
        email: user.email,
        avatar: user.avatar,
        registerDate: user.registerDate
      },
      group: {
        id: props.group?.id ?? -1
      },
      profile: user.profile,
      contract: user.contract,
      contractStatus: user.contractStatus
    })) ?? []

    const accessConfigsGroupUsers: User.UserList[] = [];

    (props.group?.accessConfigs ?? []).forEach((group) => {
      if (!group.users.length) return
      group.users.forEach(user => {
        accessConfigsGroupUsers.push({
          id: user.accessConfig,
          status: 'active',
          user: {
            id: user.id,
            name: user.name ?? '',
            lastname: user.lastname,
            email: user.email,
            avatar: user.avatar,
            registerDate: user.registerDate
          },
          profile: user.profile,
          contract: user.contract,
          group: {
            id: group.id,
            name: group.name
          },
          contractStatus: user.contractStatus
        })
      })
    })
    return [...accessConfigsRootUsers, ...accessConfigsGroupUsers]
  },

  getUserInvitations () {
    return props.userInvitations.map(invitation => ({
      id: invitation.id,
      status: 'pending',
      user: {
        id: -1,
        name: '',
        email: invitation.email
      },
      profile: invitation.profile,
      group: {
        id: invitation.access_config_id,
        name: props.group?.accessConfigs.find(acc => acc.id === invitation.access_config_id)?.name
      }
    }))
  },

  loadAccessConfig () {
    data.accessConfigs = methods.getAllAccessConfig()
    data.userInvitations = methods.getUserInvitations()
  },

  filterByProfile (accessConfigs: User.UserList[], value: EnumRoles) {
    if (!value) return accessConfigs

    return accessConfigs.filter(accessConfig => accessConfig.profile === value)
  },

  filterByUsers (accessConfigs: User.UserList[], users: number[]) {
    if (!users.length) return accessConfigs

    return accessConfigs.filter(accessConfig => users.includes(accessConfig.user.id ?? -1))
  },

  filterByStatus (accessConfigs: User.UserList[], value: string) {
    if (!value) return accessConfigs

    return accessConfigs.filter(
      accessConfig => accessConfig.status === value
    )
  },

  filterByGroup (accessConfigs: User.UserList[], filterGroups: number[]) {
    if (!filterGroups.length) return accessConfigs

    return accessConfigs.filter(accessConfig => filterGroups.includes(accessConfig.group.id))
  },

  filterByText (accessConfigs: User.UserList[], text: string) {
    if (!text) return accessConfigs

    return accessConfigs.filter(accessConfig => {
      return (
        accessConfig.user.name?.toLowerCase().includes(text) ||
        accessConfig.user.lastname?.toLowerCase().includes(text) ||
        accessConfig.user.email.toLowerCase().includes(text)
      )
    })
  }
}

watch(() => props.group, () => {
  methods.loadAccessConfig()
})

function created () {
  methods.loadAccessConfig()
}

created()

defineExpose({ loadAccessConfig: methods.loadAccessConfig })
</script>
