<template>
  <dashboard-card
    title="mywb.common.locations"
    subtitle="mywb.common.organise-chargers-locations"
    icon-color="var(--grey-400)"
    icon="location"
    :number="compute.locationsLength"
    :loading="props.loading"
  >
    <div class="is-grid mt-16">
      <wb-button
        icon="add"
        data-test-id="addLocationBtn"
        :label="t('mywb.charger.add-location')"
        type="white"
        outlined
        :to="{
          name: 'locations',
          query: {
            'add-location': '1'
          }
        }"
      />

      <wb-button
        data-test-id="viewAllSettings"
        :label="t('mywb.common.view-all')"
        type="white"
        :to="{ name: 'locations' }"
      />
    </div>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import { numbers } from '@wallbox/toolkit-ui'
import type { Locations } from '@/types'
import state from '@/state'

const { t, locale } = useI18n()

interface Props {
  loading: boolean,
  locations: Locations.Location[]
}

const props = defineProps<Props>()

const compute = reactive({
  locationsFiltered: computed((): Locations.Location[] => {
    let locations = props.locations ?? []

    if (state.filters.dashboardFilters.locations.length > 0) {
      locations = locations.filter(group => state.filters
        .dashboardFilters.locations.some(location => location.id === group.id))
    }

    return locations
  }),

  locationsLength: computed((): string => {
    return numbers.toLocaleNumber(compute.locationsFiltered.length, locale.value)
  })
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  align-items: center;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 8px;
}
</style>
