<template>
  <wb-modal
    width="33"
    @close="emit('on-close')"
  >
    <wb-form>
      <wb-input
        v-model="field.name"
        type="text"
        name="name"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="data.loading"
          @click="validate(methods.editName)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import state from '@/state'
import api from '@/api'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useValidator } from '@/hooks/useValidator.hook'

const notify = useNotify()
const i18n = useI18n()
const { yup, errors, defineSchema, validate } = useValidator()

interface Events {
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const field = reactive({
  name: state.charger.getCurrentCharger.chargerData.name
})

defineSchema(field, {
  name: yup
    .string()
    .required()
})

interface DataType {
  loading: boolean
}

const data: DataType = reactive({
  loading: false
})

const methods = {
  async editName () {
    data.loading = true
    try {
      const { data: { users, chargerData } } = await api.chargers.updateCharger({
        chargerId: state.charger.getCurrentCharger.chargerData?.id,
        params: {
          name: field.name
        }
      })

      state.charger.set('currentCharger.chargerData.name', chargerData.name)
      state.charger.set('currentCharger.users', users)
      trackDataEvent('change-name-charger', { charger_id: state.charger.getCurrentCharger.chargerData?.id })
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('on-close')
    }
  }
}
</script>
