import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import { formatResponseJsonApi } from './config/jsonApi.config'
import type { SessionApi, Session } from '@/types'

export default {
  async getSessionFilters (filters: object): Promise<SessionApi.getSessionsFilterApi> {
    return await axios.get(endpoints.v1.sessions_filters, { params: filters })
  },

  async getSystemFormats (): Promise<Session.SessionSystemFormat[]> {
    return await axios.get<unknown, SessionApi.apiSessionsFormat>(endpoints.v3.systemFormats)
      .then(({ result }) => result)
  },

  async getGroupChargingSessions ({
    groupId,
    filters,
    limit,
    offset,
    cacheType = 'network'
  }: SessionApi.apiGroupChargingSessions) {
    const url = endpoints.v4.groups_groupId_charger_charging_sessions
      .replace('{groupId}', groupId?.toString())

    const result = await axios.get<unknown, SessionApi.getGroupChargingSessions>(url, {
      headers: {
        'workbox-cache-type': cacheType
      },
      params: {
        filters: JSON.stringify(filters),
        limit,
        offset
      },
      data: {}
    })

    return formatResponseJsonApi(result)
  },
  async getGroupChargingSessionsCSV ({
    groupId,
    filters,
    limit
  }: SessionApi.apiGroupChargingSessions) {
    const url = endpoints.v4.groups_groupId_charger_charging_sessions
      .replace('{groupId}', groupId?.toString())

    const result = await axios.get<string, string>(url, {
      headers: {
        'content-type': 'text/csv'
      },
      params: {
        filters: JSON.stringify(filters),
        limit
      },
      data: {}
    })

    return result
  }
}
