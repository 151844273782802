<template>
  <wb-modal
    width="40"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', { element: props.accessConfig.name }) }}
    </template>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          type="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          size="block"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          type="danger"
          :label="i18n.t('mywb.common.delete')"
          :loading="data.isLoading"
          size="block"
          @click="methods.deleteAccessConfigFromGroup"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import state from '@/state'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify } from '@wallbox/toolkit-ui'
import type { AccessConfig } from '@/types'

const i18n = useI18n()
const notify = useNotify()

interface Props {
  accessConfig: AccessConfig.AccessConfigGroupWithUsersExpanded
}
const props = defineProps<Props>()

const emit = defineEmits(['close'])

const data = reactive({
  isLoading: false
})

const methods = {
  async deleteAccessConfigFromGroup () {
    try {
      data.isLoading = true
      await api.accessConfig.deleteAccessConfig(props.accessConfig.id)
      state.user.set('usersGroup', await api.users.getUserGroups())
      trackDataEvent('delete-group', { group_id: props.accessConfig.group })
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.isLoading = false
    emit('close')
  }
}

function created () {
  trackDataScreen('users-list-access-config-delete')
}

created()
</script>
