import { endpoints } from './config/endpoints.config'
import axios from './config/axios.config'
import type { SupportApi } from '@/types'

interface Report {
  country: number
  description: string
  email: string
  images: Array<{
    name: string
    bytes: string
    size: string
    extension: string
  }>
  language: string
  platform: string
  topicId: string
}

export default {
  async postReport (payload: Report): Promise<void> {
    return await axios.post(endpoints.v3.contact_reportIssue, payload)
  },

  async getTopics (lang: string): Promise<SupportApi.ApiTopics> {
    return await axios.get(endpoints.v3.contact_reportIssue_topics, { params: { lang } })
  }
}
