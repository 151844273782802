<template>
  <div class="not-found-container">
    <client-img-component inline src="not-found" />
    <p
      v-t="'mywb.error.not-page-found.title'"
      class="is-size-900 has-text-primary-500 mb-24"
    />
    <p
      v-t="'mywb.error.not-page-found.description'"
      class="is-size-400 has-text-grey-400 mb-24"
    />
    <div class="actions">
      <wb-button
        :label="i18n.t('mywb.common.home-page')"
        class="mr-16"
        size="block"
        :to="{ name:'home'}"
      />
      <wb-button
        :label="i18n.t('mywb.common.support')"
        outlined
        size="block"
        :to="{ name: 'support' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

</script>

<style lang="postcss" scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  max-width: 40rem;
  margin: 0 auto;

  & .actions {
    justify-content: space-around;
    display: flex;
  }
}
</style>
