<template>
  <div>
    <wb-card
      :headline="i18n.t('mywb.common.chargers')"
      :subhead="i18n.t('mywb.common.total-installed-chargers')"
      loding
    >
      <template #content>
        <wb-card-loader
          :loading="!state.groups.groupRelatedToOrganizationWithChargers.id"
        >
          <span
            data-test-id="chargersTotal"
            class="is-size-1000 has-cursor-pointer has-text-underline-hover"
            @click="methods.goToChargers"
          >{{ compute.totalChargers }}</span>
          <div data-test-id="chargers-overview-content" class="is-flex g-24">
            <div>
              {{ i18n.t('mywb.common.online') }}
              (<span data-test-id="chargersOnline">{{ compute.onlineChargers }}</span>)
            </div>

            <div
              class="has-cursor-pointer has-text-underline-hover"
              data-test-id="chargerStatusErrorLink"
              @click="methods.goToChargers('error')"
            >
              {{ i18n.t('mywb.common.error') }}
              (<span data-test-id="chargersError">{{ compute.errorChargers }}</span>)
            </div>

            <div>
              {{ i18n.t('mywb.common.offline') }}
              (<span data-test-id="chargersOffline">{{ compute.offlineChargers }}</span>)
            </div>
          </div>
        </wb-card-loader>

        <wb-notification
          v-if="compute.updatesAvailable && permissions.canViewUpdateChargersPending && permissions.hasBulkActions"
          icon="send_to_charger"
          class="mt-24"
          :action="i18n.t('mywb.common.update')"
          @on-action-click="methods.openModal"
        >
          <p>
            {{
              i18n.t('mywb.charger.num-chargers-with-update-pending', {
                num: compute.updatesAvailable
              })
            }}
          </p>
        </wb-notification>
        <wb-button
          data-test-id="addChargerBtn"
          icon="add"
          :label="i18n.t('mywb.common.add-charger')"
          type="white"
          class="mt-24"
          outlined
          :to="{
            name: 'chargers',
            query: {
              'add-chargers': '1',
              'location-id': state.groups.groupRelatedToOrganizationWithChargers.id.toString()
            }
          }"
        />
      </template>
    </wb-card>

    <chargers-remote-actions-modal
      v-if="data.isModalRemoteActionsOpen"
      :action="REMOTE_ACTIONS.UPDATE"
      :group="state.groups.groupRelatedToOrganizationWithChargers"
      @close="data.isModalRemoteActionsOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import ChargersRemoteActionsModal from '@/components/modals/ChargersRemoteActionsModal.vue'

import { permissions, clientConfig } from '@/engine/clients'
import state from '@/state'
import { trackDataEvent, trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import { useRouter } from 'vue-router'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { isOnlineStatus, isOfflineStatus, isErrorStatus } from '@/utilities/charger/chargerStatuses'

const i18n = useI18n()
const router = useRouter()

type DataType = {
  isModalRemoteActionsOpen: boolean
}

const data: DataType = reactive({
  isModalRemoteActionsOpen: false
})

const compute = reactive({
  totalChargers: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.length
    })

    return num
  }),

  updatesAvailable: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.filter(charger => isUpdatesAvailable(charger, charger.software)).length
    })

    return num
  }),

  onlineChargers: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.filter(charger => isOnlineStatus(charger)).length
    })

    return num
  }),

  offlineChargers: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.filter(charger => isOfflineStatus(charger)).length
    })

    return num
  }),

  errorChargers: computed((): number => {
    const { subgroups = [] } = state.groups.groupRelatedToOrganizationWithChargers

    let num = 0
    subgroups.forEach(group => {
      num += group.chargers.filter(charger => isErrorStatus(charger)).length
    })

    return num
  })

})

const methods = {
  goToChargers (type: 'allChargers' | 'error' = 'allChargers') {
    if (type === 'error') {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'statuses', value: ['error'] })
    }
    router.push('chargers')
    trackDataAction('dashboard-viewmore', { widget: 'chargerStatus', type })
  },

  openModal () {
    trackDataEvent('dashboard-updates', { widget: 'chargerStatus', type: 'updateAll' })
    data.isModalRemoteActionsOpen = true
  },

  goToWallboxEcommerce () {
    trackDataAction('link_ecommerce', { source: 'dashboard' })
    window.open(clientConfig.ecommerceUrl, '_blank')
  }
}
</script>
