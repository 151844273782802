import PlansView from '@/views/PlansView.vue'
import PlanBusinessView from '@/views/PlanBusinessView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'plan-business',
    name: 'plan-business',
    component: PlanBusinessView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'plan-business',
        text: 'mywb.common.plan-business'
      }
    }
  },
  {
    path: 'plans',
    name: 'plans',
    component: PlansView,
    meta: {
      breadcrumb: {
        name: 'plans',
        text: 'mywb.common.plans'
      }
    }
  }
] as RouteRecordRaw[]
