import i18n from '@/engine/lang'
import { compareVersionGreaterOrEqual } from '@/utilities/compare'
import { ocppStatus } from '@/utilities/charger/chargerOcppSettings'
import { findChargerType, hasPaymentsSupport, isChargerType } from '@/utilities/charger/chargerTypes'
import { getChargerVersion } from '@/utilities/charger/chargerSoftware'
import { hasActionsSupport } from '@/utilities/charger/chargerActions'
import type { Charger, Locations } from '@/types'

export const hasMinVersion = (charger: Charger.AnyTypeOfCharger, type: 'pay_per_charge' | 'pay_per_month') => {
  const chargerType = findChargerType(charger)
  let minVersion
  if (type === 'pay_per_charge') {
    minVersion = chargerType?.payments?.ppc?.minVersion
  }

  if (type === 'pay_per_month') {
    minVersion = chargerType?.payments?.ppm?.minVersion
  }

  return !minVersion ? false : compareVersionGreaterOrEqual(getChargerVersion(charger), minVersion)
}

export const isPaymentsCompatible = (
  charger: Charger.AnyTypeOfCharger,
  type: 'pay_per_charge' | 'pay_per_month',
  location?: Locations.Location
) => {
  if (!charger) return { isCompatible: true, reason: '' }

  if (ocppStatus(charger).code !== 'disconnected') {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.ocpp-configured')
    }
  } else if (!hasActionsSupport(charger) || !hasMinVersion(charger, type)) {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.charger-needs-update')
    }
  } else if (!hasPaymentsSupport(charger)) {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.charger-model-not-compatible')
    }
  } else if (location && !location.address) {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.location-not-complete', [location.name])
    }
  } else {
    return {
      isCompatible: true,
      reason: ''
    }
  }
}

export const isAutolockCompatible = (charger: Charger.ChargerData) => {
  if (ocppStatus(charger).code !== 'disconnected') {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.ocpp-configured')
    }
  } else if (!hasActionsSupport(charger)) {
    return {
      isCompatible: false,
      reason: i18n.global.t('mywb.error.charger-model-not-compatible')
    }
  } else {
    return {
      isCompatible: true,
      reason: null
    }
  }
}

export const isGunLockCompatible = (charger: Charger.ChargerData) => {
  const chargerType = findChargerType(charger)
  if (!isChargerType(charger, ['copper', 'copper-business'])) {
    return {
      isCompatible: false,
      code: 'chargerType',
      reason: i18n.global.t('mywb.error.not-compatible')
    }
  } else if (ocppStatus(charger).code !== 'disconnected') {
    return {
      isCompatible: false,
      code: 'ocpp',
      reason: i18n.global.t('mywb.error.ocpp-configured')
    }
  } else if (
    chargerType?.gunLock?.minVersion &&
    !compareVersionGreaterOrEqual(getChargerVersion(charger), chargerType?.gunLock?.minVersion)
  ) {
    return {
      isCompatible: false,
      code: 'update',
      reason: i18n.global.t('mywb.error.charger-needs-update')
    }
  } else {
    return {
      isCompatible: true,
      code: 'compatible',
      reason: null
    }
  }
}
