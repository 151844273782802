<template>
  <div>
    <div class="grid-plan g-32">
      <article class="item g-16">
        <client-img-component class="img" inline src="monetize" />
        <!-- eslint-disable-next-line -->
        <p class="is-size-400" v-html="i18n.t('mywb.plan.payments-description-1.html')" />
      </article>
      <article class="item g-16">
        <client-img-component class="img" inline src="monthly_subscription" />
        <!-- eslint-disable-next-line -->
        <p class="is-size-400" v-html="i18n.t('mywb.plan.payments-description-2.html')" />
      </article>
      <article class="item g-16">
        <client-img-component class="img" inline src="semipublic_charge" />
        <!-- eslint-disable-next-line -->
        <p class="is-size-400" v-html="i18n.t('mywb.plan.payments-description-3.html')" />
      </article>
    </div>
  </div>
</template>

<script setup lang="ts">
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
</script>

<style lang="postcss" scoped>
.title {
  font-size: 3.4rem;
  font-weight: 600;
}

.item {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;

  & .img {
    flex: 1 0 auto;
    height: 50px;
    width: 50px;
    fill: var(--grey-700);
  }
}

.grid-plan {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 3rem;
}
</style>
