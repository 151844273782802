<template>
  <auth-layout>
    <template
      v-if="!data.passwordChangeFinished"
      #title
    >
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.account.password-description') }}
      </p>
    </template>

    <template
      v-else
      #title
    >
      <wb-check-icon />
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.user.changed-password') }}
      </p>
    </template>
    <wb-form v-if="!data.passwordChangeFinished">
      <wb-input
        ref="input"
        v-model="data.form.password"
        name="password"
        autocomplete="new-password"
        data-test-id="newPasswordInputModal"
        :label="i18n.t('mywb.user.new-password')"
        :placeholder="i18n.t('mywb.user.enter-password')"
        not-error-message
        type="password"
        :error="errors.password"
        v-on="validationListenersPassword"
      />
      <auth-password-hint :value="data.form.password" />

      <wb-input
        v-model="data.form.passwordConfirm"
        name="passwordConfirm"
        data-test-id="confirmNewPasswordInputModal"
        :label="i18n.t('mywb.user.confirm-password')"
        :placeholder="i18n.t('mywb.user.enter-password')"
        type="password"
        :error="errors.passwordConfirm"
        v-on="validationListenersPasswordRepeat"
        @on-enter.prevent="methods.updatePassword"
      />

      <wb-button
        data-test-id="confirmBtnModal"
        size="large"
        :loading="data.loading"
        :label="i18n.t('mywb.common.accept')"
        @click="methods.updatePassword"
      />
    </wb-form>

    <div v-else>
      <wb-button
        class="mb-24"
        :label="i18n.t('mywb.common.done')"
        data-test-id="loginBtn"
        size="large"
        :to="{ name: 'login' }"
      />
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useForm } from 'vee-validate'
import { useField, useWbInputAutofocus } from '@/hooks'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRoute, useRouter } from 'vue-router'
import { useNotify } from '@wallbox/toolkit-ui'
import { trackScreen, trackAction, trackEvent } from '@/engine/metrics/metricsManager'
import { getServerError } from '@/utilities/errorMessages'
import api from '@/api'
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { parseJwt } from '@/utilities/jwt'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const router = useRouter()
const route = useRoute()
const notify = useNotify()
const i18n = useI18n()

const { errors, handleSubmit } = useForm({
  validationSchema: {
    password: 'required|verifyPassword',
    passwordConfirm: 'required|confirmed:@password'
  },
  initialTouched: {
    password: true,
    passwordConfirm: false
  },
  initialValues: {
    password: '',
    passwordConfirm: ''
  }
})

const { value: password, validationListeners: validationListenersPassword } = useField<string>('password')
const { value: passwordConfirm, validationListeners: validationListenersPasswordRepeat } =
  useField<string>('passwordConfirm')

const input = ref()
useWbInputAutofocus(input)

const data = reactive({
  passwordChangeFinished: false,
  loading: false,
  token: route.query.forgot?.toString() ?? '',
  form: {
    password,
    passwordConfirm
  }
})

onMounted(() => {
  const token = data.token ? parseJwt(data.token) as { username: string, exp: number } : null
  if (!data.token || (token && token.exp < Math.floor(Date.now() / 1000))) {
    router.push({ name: 'forgot-password', query: { 'token-expired': 'true', email: token?.username || '' } })
    trackEvent('password-change-error', { error: 'token-expired' })
  } else {
    trackScreen('password-change')
  }
})

const methods = {
  updatePassword: handleSubmit(async () => {
    data.loading = true
    trackAction('password-change')
    try {
      await api.auth.passwordReset({
        password: data.form.password,
        confirm_password: data.form.passwordConfirm,
        token: data.token
      })
      trackEvent('password-change-success')
      data.passwordChangeFinished = true
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
        trackEvent('password-change-error', { error })
      } else {
        throw error
      }
    }

    data.loading = false
  })
}
</script>
