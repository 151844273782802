<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-access.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-access.description') }}
    </div>

    <wb-radio-card
      v-model="compute.invitationToAccessGroup"
      :value="1"
      :image="usersIcon"
      class="mb-16"
      :disabled="permissions.isDisabledSelectAccessGroup"
      :error="data.error"
      @click="data.error = false"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="accessExistingAccess">
        {{ i18n.t('mywb.common.users-access.assign-access-config.title') }}
        <wb-label
          v-if="permissions.showUpSellingToPlanBusiness"
          is-invert
          disabled
          color="grey"
          icon="lock"
          class="has-position-relative"
        >
          {{ i18n.t('mywb.plans.business') }}
        </wb-label>
      </p>
      <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
        {{ i18n.t('mywb.common.users-access.assign-access-config.subtitle') }}
      </p>
    </wb-radio-card>

    <wb-radio-card
      v-model="compute.invitationToAccessGroup"
      :value="0"
      :image="chargerCommanderIcon"
      :error="data.error"
      @click="data.error = false"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="assignNewAccess">
        {{ i18n.t('mywb.common.users-access.assign-new-access-config.title') }}
      </p>
      <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
        {{ i18n.t('mywb.common.users-access.assign-new-access-config.subtitle') }}
      </p>
    </wb-radio-card>
  </div>
</template>

<script setup lang="ts">
import usersIcon from '@/assets/inline/common/users-icon.svg'
import chargerCommanderIcon from '@/assets/inline/common/charger-commander-icon.svg'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Events {
  (e: 'update:modelValue', model: number): void
}
const emit = defineEmits<Events>()

const data = reactive({
  error: false
})

interface Props {
  modelValue: number
}

const props = defineProps<Props>()

const compute = reactive({
  invitationToAccessGroup: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})

const methods = {
  isValidateForm () {
    data.error = props.modelValue === -1
    return !data.error
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
:deep(.radio) {
  padding: 2.4rem !important;
}
</style>
