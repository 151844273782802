import { useRegisterSW } from 'virtual:pwa-register/vue'
import { watch } from 'vue'
import ENV from '@/engine/env/web.env'
import api from '@/api'
import state from '@/state'
import { dom } from '@wallbox/toolkit-ui'

export default function initPwa () {
  if (!ENV.pwa.active || !navigator.serviceWorker) {
    return
  }

  const intervalMS = 60 * 1000

  const {
    needRefresh,
    updateServiceWorker
  } = useRegisterSW({
    onRegistered (r) {
      r && setInterval(() => {
        void r.update()
      }, intervalMS)
    }
  })

  watch(needRefresh, () => {
    needRefresh && updateServiceWorker()
  })

  const db = dom.debounce(async (event: MessageEvent<any>) => {
    if (event.data.type === 'SW-401-API-ERROR') {
      const { refresh_token } = state.global.getAuth
      const { data } = await api.auth.refreshToken({ refreshToken: refresh_token })

      state.global.setAuth(data.attributes)
    }
  }, 500)

  navigator.serviceWorker?.addEventListener?.('message', db)
}
