<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.add-charger-by-sn.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.add-charger-by-sn.description') }}
    </div>

    <template v-if="permissions.hasBulkActions">
      <div class="label">
        {{ i18n.t('mywb.common.import-chargers-using-csv-file') }}
        <wb-link
          target="_blank"
          href="files/template_add_chargers.csv"
        >
          {{ i18n.t('mywb.common.download-csv-template') }}
        </wb-link>
      </div>
      <wb-file-uploader
        v-model="data.csvFile"
        v-model:errors="data.filesErrors"
        :title-label="i18n.t('mywb.common.browse-or-drag-and-drop-files')"
        :description-label="i18n.t('mywb.common.only-accept-csv-file')"
        format="file"
        accept="
          text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :error="data.csvFilesError"
        :show-previews="false"
      />
    </template>

    <div v-if="mq.current !== 'mobile'" class="label">
      <div class="grid g-24">
        <div> {{ i18n.t('mywb.charger.serial-number') }}</div>
        <div>{{ i18n.t('mywb.charger.uid-or-puk') }}</div>
      </div>
    </div>

    <add-chargers-form
      v-for="(charger, index) in data.chargers"
      :key="index"
      v-model:row="data.chargers[index]"
      :row-index="index"
      :row-total="data.chargers.length || 0"
      :loading="data.loading"
      @delete-row="methods.deleteRow"
      @update-column="emit('update:modelValue', data.chargers)"
    />

    <wb-button
      v-if="permissions.hasBulkActions"
      type="primary"
      inverted
      :label="i18n.t('mywb.common.add-one-more-charger')"
      icon="add"
      :loading="data.loading"
      data-test-id="addChargerRow"
      class="mt-16"
      @click="methods.addRow"
    />
  </div>
</template>

<script setup lang="ts">
import AddChargersForm from '@/components/forms/AddChargersForm.vue'

import { reactive, onMounted, watch } from 'vue'
import { validate } from 'vee-validate'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import api from '@/api'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import type { Charger, ChargerApi, Locations } from '@/types'
import { csvToArray } from '@/utilities/csv'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()

const emit = defineEmits(['update:modelValue'])

interface Props {
  modelValue: Charger.ChargerToAdd[]
  location: Locations.LocationAddingChargers
}

const props = defineProps<Props>()

interface Data {
  chargers: Charger.ChargerToAdd[],
  loading: boolean,
  chargerErrors: boolean,
  csvFile: File[],
  filesErrors: string[],
  csvFilesError: string
}
const data = reactive<Data>({
  chargers: [],
  loading: false,
  chargerErrors: false,
  csvFile: [],
  filesErrors: [],
  csvFilesError: ''
})

watch(() => data.csvFile, (csvFile) => {
  if (csvFile[0]) {
    data.csvFilesError = ''
    const reader = new FileReader()

    reader.onloadend = e => {
      const csvParsed = csvToArray(e.target?.result as string)
      methods.loadChargers(csvParsed)
    }

    reader.onerror = () => {
      data.csvFilesError = i18n.t('mywb.error.unexpected-error')
    }

    reader.readAsText(csvFile[0])
  }
}, { deep: true })

const methods = {
  async addRow () {
    const templateRow = {
      serial: {
        value: undefined
      },
      puk: {
        value: ''
      },
      added: false
    }
    data.chargers.push(templateRow)

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)
  },

  scrollToBottom () {
    const objDiv = document.getElementsByClassName('modal-content')
    objDiv[0].scrollTo({
      top: objDiv[0].scrollHeight,
      behavior: 'smooth'
    })
  },

  deleteRow (index: number) {
    data.chargers.splice(index, 1)
  },

  async setChargerErrors (chargers: Charger.ChargerToAdd[]) {
    await Promise.all(chargers.map(async (charger, index) => {
      const { valid, errors } = await validate(charger.serial.value, `${index === 0 ? 'required' : ''}|numeric`)
      chargers[index].serial.error = undefined

      if (!valid) {
        chargers[index].serial.error = errors[0]
      }
    }))

    await Promise.all(chargers.map(async (charger, index) => {
      const { valid, errors } = await validate(charger.puk.value, `${index === 0 ? 'required' : ''}`)
      chargers[index].puk.error = undefined

      if (!valid) {
        chargers[index].puk.error = errors[0]
      }
    }))

    return chargers
  },

  handleResponse (chargers: ChargerApi.CreateChargersResponse) {
    if (chargers.chargersAdded.length) {
      chargers.chargersAdded.forEach(chargerAdded => {
        const indexCharger = data.chargers.findIndex(charger => chargerAdded === +(charger.serial?.value ?? -1))
        data.chargers[indexCharger].added = true
      })

      trackDataEvent('add-charger', {
        chargers: chargers.chargersAdded,
        chargers_unsuccessful: chargers.errors.map(({ serial }) => serial)
      })
    }

    if (chargers.errors.length) {
      chargers.errors.forEach(error => {
        const index = data.chargers.findIndex(charger => charger.serial.value === error.serial)
        if (index === -1) return

        let serialErrorMsg = ''
        let pukErrorMsg = i18n.t('mywb.error.invalid-puk-number')

        if (error.code === 2304) {
          serialErrorMsg = i18n.t('mywb.error.charger-already-has-owner')
        } else if (error.code === 2307) {
          serialErrorMsg = i18n.t('mywb.error.invalid-charger-serial')
          pukErrorMsg = ''
        } else {
          serialErrorMsg = i18n.t('mywb.error.invalid-charger')
        }

        data.chargers[index].serial.error = serialErrorMsg
        data.chargers[index].puk.error = pukErrorMsg
      })

      emit('update:modelValue', data.chargers)
    }
  },

  loadChargers (csvParsed: Record<string, string>[]) {
    data.chargers = data.chargers.filter(charger => charger.serial.value !== undefined && charger.puk.value !== '')

    csvParsed.forEach(chargerCsv => {
      if (!data.chargers.some(charger => charger.serial.value === chargerCsv.serial)) {
        data.chargers.push({
          added: false,
          serial: {
            value: chargerCsv.serial
          },
          puk: {
            value: chargerCsv.puk
          }
        })
      }
    })

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)

    emit('update:modelValue', data.chargers)
  },

  async addChargers () {
    data.chargerErrors = false

    try {
      const chargers = data.chargers
        .filter(charger => !charger.added)
        .map(charger => ({
          serial: charger.serial.value ?? 0,
          puk: charger.puk.value,
          group: props.location.groupId ?? 1,
          country: props.location.country ?? 'ES',
          timezone: props.location.timezoneId ?? '1'
        }))

      const chargersResponse = await api.chargers.createChargers(chargers)

      data.chargerErrors = !!chargersResponse.errors.length

      methods.handleResponse(chargersResponse)
    } catch {
      data.chargerErrors = true
    } finally {
      data.loading = false
    }
  },

  async isValidateForm () {
    data.loading = true

    data.chargers = await methods.setChargerErrors(data.chargers)
    const isValid = !data.chargers.some(charger => charger.serial.error || charger.puk.error)

    if (isValid) {
      data.chargers.filter(charger => charger.serial.value !== undefined && charger.puk.value !== '')
      await methods.addChargers()
    }
    data.loading = false
    return isValid && !data.chargerErrors
  }
}

onMounted(async () => {
  data.chargers = props.modelValue
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 250px 250px auto;
  }
}
</style>
