<template>
  <div class="table-wrapper">
    <chargers-table-header
      v-bind="attrs"
      :chargers="compute.chargersFiltered"
      @on-bulk-action="methods.handleBulkAction"
    />

    <wb-table
      v-model:selected="data.chargerSelected"
      :options="compute.options"
      :loading="data.loadingFilters"
      @on-checked-all-rows="methods.selectAllChargers"
    >
      <chargers-table-row
        v-for="charger in compute.chargersFiltered"
        :key="charger.id"
        :charger="charger"
      />
    </wb-table>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import ChargersTableHeader from '@/components/tables/ChargersTableHeader.vue'
import { useMq } from 'vue3-mq'
import state from '@/state'
import { getChargerStatus } from '@/utilities/charger/chargerStatuses'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, watch, nextTick, useAttrs } from 'vue'
import ChargersTableRow from '@/components/tables/ChargersTableRow.vue'
import { isChargerType } from '@/utilities/charger/chargerTypes'
import type { Charger, Group } from '@/types'
import type { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { TableProps } from '@wallbox/toolkit-ui'

const attrs = useAttrs()
const mq = useMq()
const i18n = useI18n()

interface Events {
  (e: 'on-bulk-action', action: REMOTE_ACTIONS): void
  (e: 'remove-charger'): void
}
const emit = defineEmits<Events>()

interface Data {
  groups: Group.Group[],
  loadingFilters: boolean,
  chargerSelected: Record<number, boolean>
}
const data = reactive<Data>({
  groups: [],
  loadingFilters: false,
  chargerSelected: {}
})

type ChargerParsed = typeof state.charger.getChargers[number] & {
  checked: boolean
}

const compute = reactive({
  chargersArray: computed((): ChargerParsed[] => {
    return state.charger.getChargers.map(charger => ({
      ...charger,
      checked: false
    }))
  }),

  chargersFiltered: computed((): ChargerParsed[] => {
    const reducers = {
      groups: methods.filterByGroup,
      statuses: methods.filterByStatus,
      chargers: methods.filterByChargers,
      text: methods.filterByText,
      chargerTypes: methods.filterByChargerType
    }

    const entries = Object.entries(state.filters.chargersFilters) as
      Array<[keyof typeof reducers, never]>

    return entries.reduce((chargersFiltered, [type, value]) =>
      reducers[type](chargersFiltered, value), compute.chargersArray
    )
  }),

  options: computed((): TableProps['options'] => ({
    selectable: true,
    offset: '0 8%',
    stickyFirstColumn: true,
    columns: [
      { name: i18n.t('mywb.common.name'), align: 'left', minWidth: '250px', width: 'auto' },
      { name: i18n.t('mywb.common.location'), align: 'left', minWidth: '150px', width: 'auto' },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: '150px', width: 'auto' },
      { name: '', align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.connection'), align: 'left', minWidth: '200px', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'right', minWidth: '100px', width: '100px' }
    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    },
    stickyHead: mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)'
  }))
})

watch(() => state.filters.chargersFilters, async () => {
  data.loadingFilters = true
  await nextTick()
  data.loadingFilters = false
}, { deep: true })

const methods = {
  filterByText (chargers: ChargerParsed[], text: string) {
    if (!text) return chargers

    return chargers.filter(charger => {
      return (
        charger.name.toLowerCase().includes(text.toLowerCase()) ||
        charger.groupName.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  filterByChargerType (chargers: ChargerParsed[], chargerTypes: string[]) {
    if (!chargerTypes?.length) return chargers

    return chargers.filter(charger => isChargerType(charger, chargerTypes))
  },

  filterByStatus (chargers: ChargerParsed[], statuses: string[]) {
    if (!statuses?.length) return chargers

    return chargers.filter(charger => statuses.includes(getChargerStatus(charger).code))
  },

  filterByChargers (chargers: ChargerParsed[], chargersToShow: number[]) {
    if (!chargersToShow?.length) return chargers

    return chargers.filter(charger => chargersToShow.includes(charger.id))
  },

  filterByGroup (chargers: ChargerParsed[], groups: number[]) {
    if (!groups?.length) return chargers

    return chargers.filter(charger => groups.includes(charger.groupId))
  },

  selectAllChargers (checked: boolean) {
    state.charger.setCheckChargersGroup({
      status: checked,
      chargers: compute.chargersFiltered
    })
  },

  handleBulkAction (action: REMOTE_ACTIONS) {
    const chargersSelected: Array<Charger.Charger | Charger.ChargerData> = []
    compute.chargersFiltered.forEach(charger => {
      const chargerFound = state.charger.getCheckedChargers.find(checkedCharger => checkedCharger.id === charger.id)
      if (chargerFound) chargersSelected.push(chargerFound)
    })

    state.charger.resetCheckedChargers()
    state.charger.setCheckChargersGroup({
      status: true,
      chargers: chargersSelected
    })

    emit('on-bulk-action', action)
  }
}

watch(() => state.charger.getCheckedChargers, () => {
  const checkedChargers = Object.values(data.chargerSelected ?? {}).filter(Boolean)

  if (state.charger.getCheckedChargers.length !== checkedChargers.length) {
    data.chargerSelected = {}
  }
})

function created () {
  state.charger.resetCheckedChargers()
}

created()
</script>

<style lang="postcss" scoped>
.table-wrapper {
  position: relative;
}
</style>
