import OrganizationsView from '@/views/OrganizationsView.vue'
import OrganizationDetailView from '@/views/OrganizationDetailView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'organizations',
    name: 'organizations',
    component: OrganizationsView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'organizations',
        text: 'mywb.common.organizations'
      }
    }
  },
  {
    path: 'organizations/:organizationUid',
    name: 'organizationDetail',
    component: OrganizationDetailView,
    meta: {
      breadcrumb: {
        name: 'Organization'
      }
    }
  }
] as RouteRecordRaw[]
