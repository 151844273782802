<template>
  <wb-card
    :headline="i18n.t(compute.chargerStatus?.label || '')"
    :subhead="i18n.t(compute.chargerStatus?.description || '')"
  >
    <template #content>
      <div class="bar-status mt-8 mb-8">
        <div v-if="compute.isChargerCharging" class="bar-status-animated" />
      </div>

      <div class="is-grid mt-16">
        <div class="power">
          <div class="font-power is-font-weight-400">
            <span data-test-id="power">
              {{ compute.power }}
            </span>
            <span class="is-size-700 ml-4">{{ i18n.t('mywb.common.kw') }}</span>
          </div>
        </div>

        <template v-if="compute.isChargerActive">
          <div class="time">
            <div class="is-size-200">
              {{ i18n.t('mywb.common.duration') }}
            </div>
            <div class="is-size-400 is-font-weight-500">
              <span data-test-id="time">
                {{ time.getTimeDurationString(state.charger.currentChargerEnergy.chargingTime, ['h', 'm']) }}
              </span>
            </div>
          </div>

          <div class="energy">
            <div class="is-size-200">
              {{ i18n.t('mywb.common.energy') }}
            </div>
            <div class="is-size-400 is-font-weight-500">
              <span data-test-id="energy">{{ state.charger.currentChargerEnergy.chargingEnergy }}</span>
              <span class="is-size-300">{{ i18n.t('mywb.common.kwh') }}</span>
            </div>
          </div>

          <div class="cost">
            <div class="is-size-200">
              {{ i18n.t('mywb.common.energy-cost') }}
            </div>
            <div class="is-size-400 is-font-weight-500">
              <span data-test-id="cost">{{ compute.energyCost }}</span>
            </div>
          </div>
        </template>
      </div>

      <template v-if="compute.isChargerActive && data.user">
        <div class="is-size-300 is-font-weight-500 mt-16">
          {{ i18n.t('mywb.common.user') }}
        </div>

        <wb-button-card
          data-test-id="user"
          :title="data.user.name + ' ' + data.user.surname"
          :subtitle="data.user.email"
          status="enabled"
          filled
          :action="data.user.id && data.user.profile !== 'super-admin' ? 'emit' : undefined"
          @on-click="data.user?.id &&
            data.user.profile !== 'super-admin' && router.push({ name: 'user', params: { id: data.user.id } })"
        >
          <template #icon>
            <wb-user-avatar
              size="small"
              :src="data.user.avatar"
              :initials="userInitials(data.user)"
            />
          </template>
        </wb-button-card>
      </template>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed, watch, onBeforeUnmount } from 'vue'
import { numbers, time, useNotify } from '@wallbox/toolkit-ui'
import { getChargerStatus, getColorByStatus, STATUSES } from '@/utilities/charger/chargerStatuses'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import api from '@/api'
import { userInitials } from '@/utilities/users'
import { useRouter } from 'vue-router'
import type { User } from '@/types'
import { getCurrencyCode } from '@/utilities/currency'

const i18n = useI18n()
const notify = useNotify()
const router = useRouter()

interface Status {
  code: STATUSES
  label: string
  description: string
}

const props = defineProps({
  hasRealTimeInformation: {
    type: Boolean,
    default: true
  }
})

interface DataType {
  user: User.UserCharger | undefined
  chargingEnergy: number
  chargingPower: number
  chargingCost: number
  chargingTime: number
  unmounted: boolean
  reloadChargersInterval?: ReturnType<typeof setInterval>
}

const data: DataType = reactive({
  user: {
    assigned: false,
    avatar: '',
    createdByUser: '',
    email: '',
    id: 0,
    name: '',
    profile: 'user',
    surname: ''
  },
  chargingPower: 0,
  chargingEnergy: 0,
  chargingCost: 0,
  chargingTime: 0,
  unmounted: false,
  reloadChargersInterval: undefined
})

const compute = reactive({
  power: computed(() => {
    const energy = state.charger.currentChargerEnergy.chargingPower
    const decilams = energy % 1 !== 0 ? 3 : 0
    return numbers.toDecimal(state.charger.currentChargerEnergy.chargingPower, i18n.locale.value, decilams, decilams)
  }),

  isChargerActive: computed((): boolean => {
    if (!compute.chargerStatus?.code) return false
    return [
      STATUSES.WAITING,
      STATUSES.SCHEDULED,
      STATUSES.CHARGING,
      STATUSES.DISCHARGING,
      STATUSES.PAUSED
    ].includes(compute.chargerStatus.code)
  }),

  isChargerCharging: computed((): boolean => {
    if (!compute.chargerStatus?.code) return false
    return [
      STATUSES.CHARGING,
      STATUSES.DISCHARGING
    ].includes(compute.chargerStatus.code)
  }),

  chargerStatus: computed((): Partial<Status> | undefined =>
    getChargerStatus(state.charger.getCurrentCharger.chargerData)),

  chargerStatusColor: computed((): any => {
    if (!compute.chargerStatus?.code) return {}
    return getColorByStatus(compute.chargerStatus.code)
  }),

  energyCost: computed(() => {
    return numbers.toLocaleCurrencySymbol(
      state.charger.currentChargerEnergy.chargingCost, getCurrencyCode(), i18n.locale.value)
  })
})

const methods = {
  enableAutoRefresh () {
    if (compute.isChargerActive) {
      methods.getChargingData()
      methods.getUserData()

      if (!data.unmounted) {
        data.reloadChargersInterval = setInterval(() => {
          methods.getChargingData()
          methods.getUserData()
        }, 5000)
      }
    }
  },

  cancelAutoRefresh () {
    clearInterval(data.reloadChargersInterval)
  },

  async getUserData () {
    try {
      const filters = [{ field: 'group_id', operator: 'eq', value: state.groups.groupRelatedToOrganization.id }]
      const { data: chargerLastSession } = await api.dashboard.getChargersLastSessions({ filters })

      const userId = chargerLastSession
        .find(session => session.attributes.charger_id === state.charger.getCurrentCharger.chargerData.id)
        ?.attributes.user_id

      data.user = state.charger.getCurrentCharger.users.find(user => user.id === userId)
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async getChargingData () {
    try {
      const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
      state.groups.set('groups', result.groups)

      const charger = state.groups.groupRelatedToOrganizationWithChargers.subgroups
        .flatMap(group => group.chargers)
        .find(charger => charger.id === state.charger.getCurrentCharger.chargerData.id)

      state.charger.set('currentChargerEnergy.chargingPower', charger?.chargingPower || 0)
      state.charger.set('currentChargerEnergy.chargingEnergy', charger?.addedEnergy || 0)
      state.charger.set('currentChargerEnergy.chargingCost',
        (charger?.addedEnergy || 0) * (charger?.energyPrice || 0))
      state.charger.set('currentChargerEnergy.chargingTime', charger?.chargingTime || 0)
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

onBeforeUnmount(() => {
  data.unmounted = true
  clearInterval(data.reloadChargersInterval)
})

watch(() => compute.isChargerActive, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
}, { immediate: true })

watch(() => props.hasRealTimeInformation, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
})
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-areas: "power time energy cost";
  gap: 8px;
  align-items: end;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content max-content;
    grid-template-areas:
      "power power ·"
      "energy cost time";
  }
}

.power {
  grid-area: power;
}

.time {
  grid-area: time;

  @media (max-width: 1500px) {
    text-align: right;
  }
}

.energy {
  grid-area: energy;
}

.cost {
  grid-area: cost;
}

.font-power {
  font-size: 36px;
  line-height: 24px;
}

.bar-status {
  height: 3px;
  background: v-bind(compute.chargerStatusColor.bg);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.bar-status-animated {
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: white;
  width: 0;
  opacity: 0.2;
  animation: animation-bar 2s linear infinite;
}

@keyframes animation-bar {
  0% {
    left: 0%;
    right: 100%;
    opacity: 0.2;
    width: 0%;
  }

  80% {
    left: 75%;
    right: 0%;
    width: 20%;
    opacity: 0.7;
  }

  100% {
    left: 100%;
    right: 0%;
    opacity: 0;
    width: 100%;
  }
}
</style>
