
<template>
  <wb-table-row
    :id="props.charger.id"
    :track-by="props.charger.id"
    class="charger-row"
    data-test-id="chargerItem"
    @on-checked-row="methods.handleChargerCheck"
  >
    <wb-table-row-item>
      <div class="charger-name g-8">
        <img :src="props.charger.image" :alt="props.charger.name">
        <wb-link
          v-if="permissions.canChargerItemClickeable"
          :to="{ name: 'charger', params: { chargerId: props.charger.id.toString() } }"
          type="text"
          data-test-id="chargerName"
          class="u-block is-font-weight-500 has-text-overflow"
        >
          {{ props.charger.name }}
        </wb-link>

        <p v-else data-test-id="chargerName" class="is-font-weight-500 has-text-overflow">
          {{ props.charger.name }}
        </p>
      </div>
    </wb-table-row-item>

    <wb-table-row-item>
      <wb-link
        v-if="permissions.canSeeLocations"
        type="text"
        data-test-id="locationName"
        :to="{ name: 'location-detail', params: { groupId: props.charger.groupId.toString() } }"
      >
        {{ props.charger.groupName }}
      </wb-link>

      <p v-else data-test-id="locationName" class="has-text-overflow">
        {{ props.charger.groupName }}
      </p>
    </wb-table-row-item>

    <wb-table-row-item>
      <charger-status :charger="props.charger" />
    </wb-table-row-item>

    <wb-table-row-item>
      <charger-device-network
        :charger="props.charger"
        :software="props.charger.software"
      />
    </wb-table-row-item>

    <wb-table-row-item>
      <div
        v-if="compute.isDisconnected && compute.lastConnection"
        class="is-size-200 is-hidden-mobile"
        data-test-id="lastConnectionDate"
      >
        <p> {{ compute.lastConnectionLabel }} </p>
        {{ compute.lastConnectionDateTime }}
      </div>
      <div
        v-if="!compute.isDisconnected && props.charger.maxChargingCurrent && !compute.isOnError"
      >
        <wb-label disabled is-active color="black">
          {{ props.charger.maxChargingCurrent }} A
        </wb-label>
      </div>
    </wb-table-row-item>

    <charger-actions-table-row-item
      :group-id="props.charger.groupId"
      :charger="props.charger"
    />
  </wb-table-row>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { STATUSES, getChargerStatus, getColorByStatus, type Status } from '@/utilities/charger/chargerStatuses'

import { isPulsar } from '@/utilities/charger/chargerTypes'
import { permissions } from '@/engine/clients'
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import ChargerDeviceNetwork from '@/components/charger/ChargerDeviceNetwork.vue'
import ChargerActionsTableRowItem from '@/components/tables/ChargerActionsTableRowItem.vue'
import state from '@/state'

const i18n = useI18n()

interface Props {
  charger: typeof state.charger.getChargers[number]
}
const props = defineProps<Props>()

const compute = reactive({
  status: computed((): Status => getChargerStatus(props.charger)),

  lastConnectionDateTime: computed((): string => {
    return new Intl.DateTimeFormat(i18n.locale.value, {
      dateStyle: 'short',
      timeStyle: 'short'
    }).format(compute.lastConnection * 1000)
  }),

  lastConnection: computed((): number => {
    return isPulsar(props.charger)
      ? props.charger.lastSync
      : props.charger.lastConnection
  }),

  lastConnectionLabel: computed(() => {
    return isPulsar(props.charger)
      ? i18n.t('mywb.charger.last-sync')
      : i18n.t('mywb.charger.last-connection')
  }),

  colors: computed(() => {
    const colors = getColorByStatus(compute.status.code)

    return {
      bgColor: colors.bg,
      textColor: colors.text
    }
  }),

  isDisconnected: computed((): boolean => {
    return compute.status.code === STATUSES.DISCONNECTED
  }),

  isOnError: computed((): boolean => {
    return compute.status.code === STATUSES.ERROR
  })
})

const methods = {
  handleChargerCheck (value: boolean) {
    state.charger.setCheckCharger({
      ...props.charger
    }, value)
  }
}
</script>

<style lang="postcss" scoped>
.charger-row :deep(.wallbox-row-item-wrapper:first-child) {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background: v-bind("compute.colors.bgColor");
    color: v-bind("compute.colors.textColor");
    z-index: 6;
  }
}

.charger-row:last-child :deep(.wallbox-row-item-wrapper:first-child)::before {
  border-radius: 0 0 0 0.8rem;
}

.charger-row:last-child :deep(.wallbox-row-item-wrapper:last-child) {
  border-radius: 0 0 0.8rem;
}

.charger-name {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
}
</style>
