<template>
  <wb-filter
    v-model="compute.profile"
    :name="i18n.t('mywb.common.role')"
    icon="people_filled"
    data-test-id="filterProfile"
    :options="compute.rolesOptions"
    :reduce="(item: typeof compute.rolesOptions[number]) => item.id"
    multiple
    @on-error="state.filters.resetFilterValue({ filter: 'organizationsFilters', key: 'profile' })"
  />
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { getRoleById, EnumRoles } from '@/utilities/user-roles'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'

const i18n = useI18n()

const compute = reactive({
  rolesOptions: computed(() => {
    return [
      { label: i18n.t(getRoleById(EnumRoles.operator).key), id: getRoleById(EnumRoles.operator).id },
      { label: i18n.t(getRoleById(EnumRoles['super-admin']).key), id: getRoleById(EnumRoles['super-admin']).id },
      { label: i18n.t(getRoleById(EnumRoles.admin).key), id: getRoleById(EnumRoles.admin).id },
      { label: i18n.t(getRoleById(EnumRoles.user).key), id: getRoleById(EnumRoles.user).id }
    ]
  }),

  profile: computed({
    get () {
      return state.filters.organizationsFilters?.profile ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'organizationsFilters', key: 'profile', value })
    }
  })
})
</script>
