<template>
  <wb-card
    :headline="i18n.t('mywb.common.rfid-card')"
    :subhead="i18n.t('mywb.common.rfid-card.description')"
    actions-position="bottom"
  >
    <template #content>
      <wb-notification
        v-if="data.rfidActivated"
        type="info"
        icon="info_filled"
      >
        <p v-t="'mywb.user.rfid-detail'" />
      </wb-notification>

      <wb-form v-if="data.rfidActivated" class="mt-8">
        <wb-input
          v-model="data.rfid"
          data-test-id="rfidInput"
          :disabled="!compute.hasPermissions || !permissions.canUserListRfidEdit || data.saving"
          type="text"
          :error="errors.rfid || data.errorApiMessage"
          autocomplete="__away"
          :label="i18n.t('mywb.common.code')"
          :placeholder="i18n.t('mywb.common.add-rfid-code')"
          :hint="i18n.t('mywb.rfid.only-visible-to-users')"
          persistent-hint
          @keyup="data.errorApiMessage = ''"
        />
      </wb-form>
    </template>

    <template #actions>
      <wb-button
        v-if="compute.hasPermissions && permissions.canUserListRfidActions && data.rfidActivated"
        data-test-id="saveBtn"
        :loading="data.saving"
        :disabled="!data.rfid"
        :label="i18n.t('mywb.common.save')"
        @click.stop="validate(methods.saveRfid)"
      />
    </template>

    <template #place-upper-right>
      <wb-switch
        id="type"
        v-model="data.rfidActivated"
        data-test-id="switchRfid"
        name="type"
        :disabled="!compute.hasPermissions || !permissions.canUserListRfidEdit"
        @input="methods.switchRfid"
      />
    </template>

    <template #modal>
      <shared-confirmation-modal
        v-if="data.showRfidConfirmModal"
        :title="i18n.t('mywb.common.rfid-card.delete-confirm')"
        :label-confirmation-button="i18n.t('mywb.common.delete')"
        type="danger"
        @on-confirm="methods.handleConfirm"
        @on-close="methods.handleCancel"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { hasRoleGreaterThan, checkIfUserIsSameAs } from '@/utilities/users'
import api from '@/api'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import type { User } from '@/types'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const notify = useNotify()
const i18n = useI18n()
const { yup, errors, defineSchema, validate } = useValidator()

interface Props {
  user: User.User
}
const props = defineProps<Props>()

interface Data {
  rfid?: string
  saving: boolean
  showRfidConfirmModal: boolean
  rfidActivated: boolean
  errorApiMessage?: string
}

const data = reactive<Data>({
  saving: false,
  showRfidConfirmModal: false,
  rfidActivated: false
})

defineSchema(data, {
  rfid: yup
    .string()
    .rfidValidFormat()
    .required()
})

const compute = reactive({
  hasPermissions: computed(() => {
    return (
      hasRoleGreaterThan(props.user.id) ||
      checkIfUserIsSameAs(props.user.id) ||
      permissions.canAssignedRfid
    )
  })
})

const methods = {
  getRfidAssigned () {
    if (!compute.hasPermissions) return '*****************'

    let currentGroupAccess

    if (props.user.access) {
      const rootGroup = state.organizations.getCurrentOrganization.group_id
      currentGroupAccess = props.user.access
        .filter(item => item.type === 'rfid')
        .find(item => item.group === rootGroup)
    }
    return currentGroupAccess?.authentication
  },

  async switchRfid (checked: boolean) {
    if (!checked) {
      data.showRfidConfirmModal = true
    } else {
      data.rfidActivated = checked
    }
  },

  handleCancel () {
    data.showRfidConfirmModal = false
    data.rfidActivated = true
  },

  async saveRfid () {
    if (!data.rfid) return

    const userData = {
      user: props.user.id,
      type: 'rfid',
      group: state.organizations.getCurrentOrganization.group_id,
      authentication: data.rfid
    }

    try {
      data.saving = true
      data.errorApiMessage = undefined

      await api.chargerAccess.updateRfidAccess(userData)
      if (props.user.id === state.user.userLogged.id) {
        const user = await api.users.getUser(state.user.userLogged.id)
        state.user.setUserLogged(user.data)
      }
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError && error?.code === 409) {
        data.errorApiMessage = i18n.t('mywb.error.duplicated-rfid')
      } else {
        notify.error(i18n.t('mywb.error.unexpected-error'))
      }
    } finally {
      data.saving = false
    }
  },

  async handleConfirm () {
    const userData = {
      group: state.organizations.getCurrentOrganization.group_id,
      user: props.user.id,
      type: 'rfid'
    }
    await api.chargerAccess.deleteRfidAccess(userData)
    data.rfid = ''
    data.rfidActivated = false
  }
}

function created () {
  data.rfid = methods.getRfidAssigned()
  data.rfidActivated = !!data.rfid
}

created()
</script>
