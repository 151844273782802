
import { computed, reactive } from 'vue'
import ENV from '@/engine/env/web.env'
import { objects } from '@wallbox/toolkit-ui'
import { getRegionByCountry } from '@/utilities/regions'
import wallbox from '@/engine/clients/settings/wallbox'
import iberdrola from '@/engine/clients/settings/iberdrola'
import dynamicImg from '@/utilities/dynamicImg'
import type { Client } from './settings/client'

export const initialConfig = () => ENV.portal.client === 'iberdrola' ? iberdrola : wallbox

let clientLoaded = wallbox
let config: Client = reactive({ ...initialConfig() })

const hasAction = (name: keyof Client['actions']) => () => {
  const verifications = config.actions[name]
  if (!verifications) return false
  if (typeof verifications === 'boolean') return verifications
  return verifications()
}

export const setRegion = (region: string) => {
  const configToMerge = config.regions?.[region]
  if (!configToMerge) return

  const configMerged = objects.deepMerge(initialConfig(), configToMerge) as Client

  Object.assign(config, configMerged)
}

export const setRegionByCountry = (country: string) => {
  const region = getRegionByCountry(country)
  if (region) {
    setRegion(region.key)
  }
}

export const reset = () => (config = initialConfig())

export const getLogo = (name: keyof Client['logo']) => {
  if (!config.logo[name]) return null
  const logo = { ...config.logo[name] }
  logo.src = dynamicImg(logo.src)
  return logo
}

export const clientConfig = reactive({
  academy: computed(() => config.academy),
  energyManagmentSupport: computed(() => config.energyManagmentSupport),
  ecommerceUrl: computed(() => config.ecommerceUrl),
  footerRights: computed(() => config.footer?.rights),
  supportInfo: computed(() => config.supportInfo),
  brandingId: computed(() => config.brandingId),
  name: computed(() => config.name),
  partner: computed(() => config.partner),
  units: computed(() => config.units),
  docs: computed(() => config.docs),
  footerSocialMedia: computed(() => config.footer?.social),
  loginLogo: computed(() => getLogo('login')),
  asideLogo: computed(() => getLogo('aside')),
  asideLogoCollpase: computed(() => getLogo('asideCollapse')),
  headerLogo: computed(() => getLogo('header'))
})

export const permissions: Record<keyof Client['actions'], boolean> = reactive({
  showCommanderPin: computed(hasAction('showCommanderPin')),
  showRfid: computed(hasAction('showRfid')),
  editRfid: computed(hasAction('editRfid')),
  canChangePlan: computed(hasAction('canChangePlan')),
  showSelectedChargers: computed(hasAction('showSelectedChargers')),
  showPayments: computed(hasAction('showPayments')),
  showPaymentsAsideMenu: computed(hasAction('showPaymentsAsideMenu')),
  showAutoReporting: computed(hasAction('showAutoReporting')),
  showBilling: computed(hasAction('showBilling')),
  canEditChargerName: computed(hasAction('canEditChargerName')),
  hasBulkActions: computed(hasAction('hasBulkActions')),
  hasAuthToRestartAndUpdate: computed(hasAction('hasAuthToRestartAndUpdate')),
  canMarkAsPaid: computed(hasAction('canMarkAsPaid')),
  canAssignedRfid: computed(hasAction('canAssignedRfid')),
  canSeeRfid: computed(hasAction('canSeeRfid')),
  canCharge: computed(hasAction('canCharge')),
  canChargerItemClickeable: computed(hasAction('canChargerItemClickeable')),
  canAddChargerFromEmptyState: computed(hasAction('canAddChargerFromEmptyState')),
  canAddCharger: computed(hasAction('canAddCharger')),
  canWriteEnergyCost: computed(hasAction('canWriteEnergyCost')),
  canSeeLocations: computed(hasAction('canSeeLocations')),
  canViewUpdateChargersPending: computed(hasAction('canViewUpdateChargersPending')),
  canCreateUserGroups: computed(hasAction('canCreateUserGroups')),
  isDisabledSelectAccessGroup: computed(hasAction('isDisabledSelectAccessGroup')),
  showUserGroupOptions: computed(hasAction('showUserGroupOptions')),
  showDashboardAsideMenu: computed(hasAction('showDashboardAsideMenu')),
  showUserAsideMenu: computed(hasAction('showUserAsideMenu')),
  canPaymentsAsideMenu: computed(hasAction('canPaymentsAsideMenu')),
  canAddUser: computed(hasAction('canAddUser')),
  canEditAndDeleteUserGroupOption: computed(hasAction('canEditAndDeleteUserGroupOption')),
  canAddRfidInInvitations: computed(hasAction('canAddRfidInInvitations')),
  canUserListRfidEdit: computed(hasAction('canUserListRfidEdit')),
  canUserListRfidActions: computed(hasAction('canUserListRfidActions')),
  canRevokeOrResendUserInvitation: computed(hasAction('canRevokeOrResendUserInvitation')),
  hasSocialSingin: computed(hasAction('hasSocialSingin')),
  showPaymentsEngage: computed(hasAction('showPaymentsEngage')),
  showUpSellingToPlanBusiness: computed(hasAction('showUpSellingToPlanBusiness')),
  canConnectStripe: computed(hasAction('canConnectStripe')),
  showPublishOnElectromaps: computed(hasAction('showPublishOnElectromaps')),
  canOperateOrganizations: computed(hasAction('canOperateOrganizations')),
  showCarouselLogin: computed(hasAction('showCarouselLogin')),
  canSeeOcppChargers: computed(hasAction('canSeeOcppChargers')),
  canAddOperators: computed(hasAction('canAddOperators')),
  canSeeModalEditOrganization: computed(hasAction('canSeeModalEditOrganization'))
})

export default async function loadClient (client: string = ENV.portal.client) {
  clientLoaded = await import(`./settings/${client}.ts`).then(module => module.default)

  document.body.dataset.theme = client

  Object.assign(config, clientLoaded)
}
