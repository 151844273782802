export interface SessionSystemFormat {
  id: string
  name: 'comma' | 'dot'
}

export enum RateVariableTypeEnum {
  ACCESS = 'access',
  TIME = 'time',
  ENERGY = 'energy',
  RESERVATION_TIME = 'reservation_time'
}

export interface Session {
  id: string
  start_time: number
  end_time: number
  charging_time: number
  user_id: number
  user_name: string
  user_surname: string
  user_email: string
  charger_id: number
  charger_name: string
  group_id: number
  location_id: number
  location_name: string
  energy: number
  mid_energy: number
  energy_price: number
  currency_code: string
  session_type: 'free' | 'pay_per_month' | 'pay_per_charge'
  application_fee_percentage: number
  user_avatar: string
  order_uid: string
  rate_price: number
  rate_variable_type: RateVariableTypeEnum
  order_energy: number
  access_price: number
  fee_amount: number
  total: number
  subtotal: number
  tax_amount: number
  tax_percentage: number
  total_cost: number
  public_charge_uid: string
}

export interface ChargersLastSessions {
  type: 'charger_last_session'
  id: string
  attributes: {
    charger_id: number
    charging_energy: number
    charging_energy_unit: string
    charging_green_energy: number
    charging_green_energy_unit: string
    discharged_energy: number
    discharged_energy_unit: string
    end_time?: number
    start_time?: number
    user_id: number
  }
}
