<template>
  <shared-filter
    v-model="compute.filteredLocations"
    multiple
    with-search
    label="name"
    icon="locations"
    :name="i18n.t('mywb.common.locations')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    data-test-id="groupsFilter"
    :options="props.locations"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'dashboardFilters', key: 'locations' })"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import type { Locations } from '@/types'
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import SharedFilter from '@/components/filters/SharedFilter.vue'

const i18n = useI18n()

interface Props {
  loading?: boolean
  locations: Locations.Location[]
}

const props = defineProps<Props>()

const compute = reactive({
  filteredLocations: computed({
    get () {
      return state.filters.dashboardFilters?.locations ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'locations', value })
    }
  })
})
</script>
