export default function (value: string | number, { decimals = '*', separator = '.' }:
{ decimals?: string | number, separator?: string } = {}) {
  if (Number(decimals) === 0) {
    return /^-?\d*$/.test(value.toString())
  }

  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

  return regex.test(value.toString())
}
