<template>
  <wb-table-row>
    <wb-table-row-item>
      <div class="charger-name g-8">
        <img :src="props.charger?.image" :alt="props.charger?.name">
        <wb-link
          :to="{ name: 'charger', params: { chargerId: props.charger?.id.toString() ?? '-1' } }"
          type="text"
          class="has-text-grey-700 is-size-400 is-font-weight-500 has-text-overflow"
        >
          {{ props.charger?.name }}
        </wb-link>
      </div>
    </wb-table-row-item>

    <wb-table-row-item>
      <charger-status
        v-if="props.charger"
        :charger="props.charger"
        class="label"
      />
    </wb-table-row-item>

    <location-rate-row-item
      v-if="compute.showRates && props.charger?.payPerChargeSchedule"
      :is-compatible="props.charger?.isPayPerChargeCompatible"
      :schedule="props.charger.payPerChargeSchedule"
      :loading="props.loadingRates"
    />

    <location-rate-row-item
      v-if="compute.showRates && props.charger?.payPerMonthSchedule"
      :is-compatible="props.charger?.isPayPerMonthCompatible"
      :schedule="props.charger?.payPerMonthSchedule"
      :loading="props.loadingRates"
    />

    <charger-actions-table-row-item
      :group-id="groupId"
      :charger="charger"
      :location="props.location"
      @rates-changed="emit('rates-changed')"
    />
  </wb-table-row>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { permissions } from '@/engine/clients'
import state from '@/state'

import LocationRateRowItem from '@/components/tables/LocationRateRowItem.vue'
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import ChargerActionsTableRowItem from '@/components/tables/ChargerActionsTableRowItem.vue'
import type { Charger, Locations } from '@/types'

interface Props {
  charger?: Charger.ChargerWithRates
  loadingRates?: boolean
  groupId: number
  location: Locations.Location
}
const props = defineProps<Props>()

const emit = defineEmits(['rates-changed'])

const compute = reactive({
  hasOrganizationPaymentsAccount:
    computed((): boolean => !!state.organizations.getCurrentOrganization.payments_account),

  showRates: computed((): boolean => permissions.showPayments && compute.hasOrganizationPaymentsAccount)
})
</script>

<style lang="postcss" scoped>
.charger-name {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
}
</style>
