<template>
  <wb-modal
    :width="isCurrentState('form') ? '70' : '50'"
    :blocked="false"
    @close="emit('on-close')"
  >
    <template #title>
      <template v-if="isCurrentState('form')">
        {{ i18n.t('mywb.location.publish-electromaps') }}
      </template>

      <template v-if="isCurrentState('success')">
        {{ i18n.t('mywb.common.request-sent') }}
      </template>
    </template>

    <template v-if="isCurrentState('form')">
      <p
        v-t="'mywb.location.publish-electromaps.description'"
        class="is-size-400"
      />

      <wb-form class="form mt-24">
        <wb-input
          v-model="data.form.location"
          data-test-id="locationNameInput"
          name="location"
          :label="i18n.t('mywb.common.name')"
          :error="errors.location"
        />

        <google-maps-and-address-input-form
          v-model:address="data.place.address"
          v-model:latitude="data.place.latitude"
          v-model:longitude="data.place.longitude"
          @on-place-update="methods.saveAddress"
        />

        <div class="is-fullwidth">
          <h3
            v-t="'mywb.location.energy-type'"
            class="is-size-400 is-font-weight-500"
          />
          <p
            v-t="'mywb.location.energy-type-description'"
            class="is-size-400 mb-16"
          />
          <div class="energy-grid g-12">
            <checkbox-card-form
              v-model="data.form.energyType"
              :title="i18n.t('mywb.common.renewable')"
              icon="leaf"
              data-test-id="energyTypeCheckboxRenewable"
              value="renewable"
              :error="errors.energyType"
            />
            <checkbox-card-form
              v-model="data.form.energyType"
              :title="i18n.t('mywb.common.non-renewable')"
              icon="bolt"
              data-test-id="energyTypeCheckboxNonRenewable"
              value="nonrenewable"
              :error="errors.energyType"
            />
            <checkbox-card-form
              v-model="data.form.energyType"
              :title="i18n.t('mywb.common.unknown')"
              data-test-id="energyTypeCheckboxUnknown"
              value="unknown"
              :error="errors.energyType"
            />
          </div>
        </div>

        <div class="is-fullwidth">
          <h3
            v-t="'mywb.location.location-type'"
            class="is-size-400 is-font-weight-500"
          />
          <p
            v-t="'mywb.location.location-type-description'"
            class="is-size-400 mb-16"
          />
          <wb-select
            v-model="data.form.locationType"
            uid="_location_type"
            :options="data.options"
            :close-on-select="false"
            option-label="value"
            :reduce="({ key }) => key"
            :error="errors.locationType"
            data-test-id="locationTypeSelect"
            clearable
            multiple
          />
        </div>

        <div class="is-fullwidth">
          <h3
            v-t="'mywb.location.instructions'"
            class="is-size-400 is-font-weight-500"
          />
          <p
            v-t="'mywb.location.instructions-description'"
            class="is-size-400 mb-16"
          />
          <wb-input
            v-model="data.form.instructions"
            data-test-id="instructionsInput"
            is-textarea
            :rows="5"
          />
        </div>
      </wb-form>
    </template>

    <template v-if="isCurrentState('success')">
      <div class="grid g-32">
        <p class="is-size-300">
          {{ i18n.t('mywb.location.publish-electromaps.request-sent') }}
        </p>
        <img
          class="img"
          :src="ElectromapsApp"
          alt="Electromaps"
        >
      </div>
    </template>

    <template #actions>
      <template v-if="isCurrentState('form')">
        <div class="button-actions">
          <wb-button
            type="white"
            outlined
            :label="i18n.t('mywb.common.cancel')"
            @click="emit('on-close')"
          />

          <wb-button
            :label="i18n.t('mywb.common.send-request')"
            :loading="data.loading"
            data-test-id="publishToElectromapsBtn"
            @click="validate(methods.publishToElectromaps)"
          />
        </div>
      </template>

      <template v-if="isCurrentState('success')">
        <div class="button-actions">
          <wb-button
            type="primary"
            :label="i18n.t('mywb.common.done')"
            @click="emit('on-close')"
          />
        </div>
      </template>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import axiosInstance from '@/api/config/axios.config'
import GoogleMapsAndAddressInputForm from '@/components/forms/GoogleMapsAndAddressInputForm.vue'
import state from '@/state'
import ENV from '@/engine/env/web.env'
import { trackDataEvent, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useStateMachine } from '@/hooks'
import ElectromapsApp from '@/assets/images/Electromaps_app.png'
import { locationTypesArray } from '@/utilities/locationTypesEnum'
import CheckboxCardForm from '@/components/forms/CheckboxCardForm.vue'
import type { Locations, Charger } from '@/types'
import { useValidator } from '@/hooks/useValidator.hook'

const i18n = useI18n()
const notify = useNotify()
const { yup, errors, defineSchema, validate } = useValidator()

interface Props {
  location : Locations.Location,
  chargers: Charger.Charger[]
}
const props = defineProps<Props>()

const data = reactive({
  options: locationTypesArray,
  form: {
    location: props.location.name,
    locationType: (props.location.location_types ?? '').split(','),
    energyType: props.location.energy_type,
    instructions: props.location.instructions
  },

  place: {
    address: props.location.address,
    latitude: props.location.latitude,
    longitude: props.location.longitude
  },
  loading: false
})

defineSchema(data.form, {
  location: yup.string().required(),
  locationType: yup.array().min(1, i18n.t('mywb.error.field-required')).required(),
  energyType: yup.string().required()
})

interface Events {
  (e: 'on-close'): void
}

const emit = defineEmits<Events>()

const { isCurrentState, send } = useStateMachine({
  initial: 'form',
  states: {
    form: {
      transitions: {
        next: 'success'
      }
    },
    success: {
      transitions: {},
      on: {
        next: () => emit('on-close')
      }
    }
  }
})

const methods = {
  saveAddress (place: Locations.Place) {
    data.place = place
  },

  async publishToElectromaps () {
    const payload = {
      ...data.form,
      ...data.place,
      user: state.global.getAuth,
      locationId: props.location.group_uid,
      status: 'WORKING',
      connectors: props.chargers.map(charger => ({
        external_id: charger.uid,
        type: 'IEC_62196_T2',
        kw: 32
      }))
    }

    data.loading = true

    try {
      await axiosInstance.post(`https://formspree.io/f/${ENV.formspree.form}`, payload)
      trackDataEvent('publishelectromaps-success')
      send('next')
    } catch (error) {
      notify.error(i18n.t('mywb.error.unexpected-failed'))
      trackDataEvent('publishelectromaps-error')
    }

    data.loading = false
  }
}

trackDataScreen('publishelectromaps')
</script>

<style lang="postcss" scoped>
.energy-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.form {
  grid-template-columns: 100%;
}

.button-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: auto max-content;
  width: 100%;
  align-items: center;

  & .img {
    width: 120px;
  }
}
</style>
