import groups from '@/state/groups.state'
import global from '@/state/global.state'
import limitsAndRules from '@/utilities/prices/limitsAndRules.json'
import i18n from '@/engine/lang'
import currencyCountry from './currencyCountry'
import type { CountryCodes } from '@/types/data/currency.data.types'

function getCurrency () {
  const rootGroupSelected = groups.groupRelatedToOrganization
  const currencyId = rootGroupSelected?.currency?.id

  return global.getCurrencyById(currencyId)
}

export function getCurrencyCode () {
  const currency = getCurrency()
  return currency ? currency.code : undefined
}

export function getMinimumChargeAmount () {
  const currencyCode = getCurrencyCode()
  if (!currencyCode) return undefined

  return limitsAndRules[currencyCode as keyof typeof limitsAndRules]?.minimum_charge_amount
}

export function getPriceAmountUpperLimit () {
  const currencyCode = getCurrencyCode()
  return limitsAndRules[currencyCode as keyof typeof limitsAndRules]?.rate_amount_upper_limit ?? 9999.99
}

export function toTwoDecimalNumber (value: string) {
  const valueParsed = value.match(/(\d+([.,]\d{0,2})?|[.,]\d{0,2})/)?.[0] ?? ''
  return valueParsed.replace(/,/g, '.')
}

export function getNumberPart (partType = 'decimal') {
  const value = 0.01
  const valueParts = new Intl.NumberFormat(i18n.global.locale).formatToParts(value)

  const part = valueParts.find((part) => {
    return part.type === partType
  })?.value

  return part ?? '.'
}

export function getCurrencyByCountry (iso2: CountryCodes) {
  return currencyCountry[iso2]
}
