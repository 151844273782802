<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.locations') }}
    </template>
  </shared-header>

  <wb-card
    filled
  >
    <template #content>
      <wb-empty-state
        :title="i18n.t('mywb.common.start-creating-locations')"
        :subtitle="i18n.t('mywb.common.locations-empty-state')"
      >
        <template #image>
          <client-img-component
            inline
            src="locations"
            width="180"
          />
        </template>
        <template #subheading>
          <wb-label
            v-if="!permissions.canSeeLocations"
            data-test-id="labelLock"
            is-invert
            disabled
            color="grey"
            icon="lock"
            class="mb-8"
          >
            {{ i18n.t('mywb.plans.business') }}
          </wb-label>
        </template>

        <wb-button
          v-if="permissions.canChangePlan && !permissions.canSeeLocations"
          data-test-id="upgradePlanBtn"
          :label="i18n.t('mywb.common.change-plan')"
          type="primary"
          :to="{ name: 'plans' }"
          @click="methods.goToPlans"
        />
        <wb-button
          v-if="permissions.canSeeLocations"
          icon="add"
          data-test-id="addLocationBtn"
          :label="i18n.t('mywb.charger.add-location')"
          type="primary"
          @click="emit('on-add-group')"
        />
      </wb-empty-state>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'

const i18n = useI18n()

interface Events {
  (e: 'on-add-group'): void
}

const emit = defineEmits<Events>()

const methods = {
  goToPlans () {
    trackDataAction('upgrade_plan', {
      source: 'locations'
    })
  }
}
</script>
