<template>
  <div class="charger-header g-8">
    <h1
      v-if="state.charger.getCurrentCharger.chargerData?.id"
      data-test-id="chargerName"
      class="name is-size-700 is-font-weight-500"
      :class="{'is-size-900': mq.current !== 'mobile'}"
    >
      {{ state.charger.getCurrentCharger.chargerData?.name }}
    </h1>

    <div v-else class="empty-state-charger-name" />
    <div class="status charger-status" data-test-id="chargerStatus">
      <charger-status :charger="state.charger.getCurrentCharger.chargerData" />
    </div>
    <charger-device-network
      class="network"
      :charger="state.charger.getCurrentCharger.chargerData"
      :software="state.charger.getCurrentCharger.config.software"
    />
  </div>
</template>

<script setup lang="ts">
import ChargerDeviceNetwork from '@/components/charger/ChargerDeviceNetwork.vue'
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import state from '@/state'
import { useMq } from 'vue3-mq'

const mq = useMq()
</script>

<style lang="postcss" scoped>
.charger-header {
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  grid-template-areas:
    "name name"
    "status network";

  @media (--tablet) {
    grid-template-columns: max-content min-content min-content;
    grid-template-areas: "name status network";
  }
}

.name {
  grid-area: name;
}

.status {
  grid-area: status;
}

.network {
  grid-area: network;
}

.empty-state-charger-name {
  position: relative;
  top: 0.3rem;
  width: 25rem;
  height: 4.4rem;
  background: var(--grey-300);
  border-radius: 0.4rem;
  opacity: 0.4;
}
</style>
