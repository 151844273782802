<template>
  <div class="googleButton">
    <div
      id="g_id_onload"
      data-test-id="googleButton"
      :data-client_id="data.client_id"
      data-callback="handleCredentialResponse"
    />
    <div
      ref="googleParent"
      class="g_id_signin"
      data-type="standard"
      data-text="continue_with"
      data-logo_alignment="center"
      :data-locale="data.locale"
      :data-width="mq.current === 'mobile' ? '300' : '375'"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'AuthGoogleButton'
}
</script>

<script setup lang="ts">
import { onMounted, reactive, watch, ref, onBeforeUnmount } from 'vue'
import { useMq } from 'vue3-mq'
import ENV from '@/engine/env/web.env'
import { trackAction, trackScreen } from '@/engine/metrics/metricsManager'
import type { Auth } from '@/types'

interface Events {
  (e: 'on-click', googleCredentials: Auth.GoogleOauthResponse['credential']): void,
}

const emit = defineEmits<Events>()

const mq = useMq()

const googleParent = ref<HTMLElement>()

interface Data {
  googleScript?: HTMLScriptElement
  client_id: string
  loaded: boolean
  locale: string
}

const data: Data = reactive({
  client_id: ENV.oauth.google ?? '',
  loaded: false,
  locale: navigator.language
})

const methods = {
  googleOneTap () {
    data.googleScript = document.createElement('script')
    data.googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(data.googleScript)
  },

  handleCredentialResponse (googleUser: Auth.GoogleOauthResponse) {
    trackAction('signin_continue', { provider: 'google' })
    trackScreen('signin_provider', { provider: 'google' })

    emit('on-click', googleUser.credential)
  }
}

watch(mq, () => {
  if (!window.google) return
  const width = mq.current === 'mobile' ? '300' : '375'

  window.google.accounts.id.renderButton(googleParent.value, {
    text: 'continue_with',
    type: 'standard',
    logo_alignment: 'center',
    locale: data.locale,
    width
  })
})

onMounted(() => {
  window.handleCredentialResponse = methods.handleCredentialResponse
  methods.googleOneTap()
})

onBeforeUnmount(() => {
  data.googleScript?.remove?.()
})
</script>

<style lang="postcss" scoped>
.googleButton {
  min-height: 80px;
  margin-bottom: -40px;
}
</style>
