<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.plans') }}
    </template>
  </shared-header>

  <section
    data-test-id="plansSection"
    class="plans-layout-grid g-24"
  >
    <plan-upgrade-card
      v-for="plan in state.plans.plans"
      :key="plan.product_id"
      :product-id="plan.product_id"
    />
  </section>
</template>

<script setup lang="ts">
import PlanUpgradeCard from '@/components/plans/PlanUpgradeCard.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
</script>

<style lang="postcss" scoped>
.plans-layout-grid {
  display: grid;
  grid-template-columns: auto;

  @media (--desktop) {
    grid-template-columns: auto auto;
  }
}
</style>
