<template>
  <wb-modal
    width="34"
    @close="emit('on-close')"
  >
    <div class="has-text-centered">
      <wb-check-icon />
      <p
        v-t="'mywb.common.thank-you'"
        class="is-size-900 has-text-primary-500 mb-32"
      />
      <p
        v-t="'mywb.support.modal-description'"
        class="is-size-400"
      />
    </div>
    <template #actions>
      <wb-button
        size="block"
        data-test-id="confirmBtnModal"
        :label="i18n.t('mywb.common.done')"
        @click="emit('on-close')"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Events {
  (e: 'on-close'): void
}
const emit = defineEmits<Events>()

</script>
