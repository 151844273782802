<template>
  <wb-card
    :headline="i18n.t('mywb.common.fixed-fee')"
    :subhead="i18n.t('mywb.rates.fixed-fee-description')"
  >
    <template #place-upper-right>
      <wb-switch
        v-model="compute.isActiveProxy"
        data-test-id="switch"
      />
    </template>

    <template #content>
      <wb-input
        v-if="compute.isActiveProxy"
        :ref="setRef('input')"
        v-model="compute.computedPrice"
        data-test-id="fixedFeeInput"
        :error="data.rate.fixedFee.errors[0]"
        class="span-2 mt-12"
        :label="i18n.t('mywb.rates.fixe-fee-price')"
        :label-right="compute.currencyCode"
        :hint="i18n.t('mywb.common.tax-not-included')"
        persistent-hint
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useValidatedRate } from '@/utilities/rates/useFormValidation'
import { getCurrencyCode } from '@/utilities/currency'
import { useI18n } from '@/hooks/useI18n.hook'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { usePriceValueProxy } from '@/hooks'

const i18n = useI18n()
const { refs, setRef } = useTemplateRef()

const props = defineProps({
  isActivated: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:isActivated'])

const data = reactive({
  rate: useValidatedRate()
})

const { computedPrice, onModelChange } = usePriceValueProxy(data.rate.fixedFee?.value)

onModelChange(({ parsedValue, rawInput }) => {
  if (parsedValue) data.rate.fixedFee.value = parsedValue
  if (refs.input) refs.input.$el.getElementsByTagName('input')[0].value = rawInput
})

const compute = reactive({
  currencyCode: computed(() => getCurrencyCode()),

  isActiveProxy: computed({
    get () {
      return props.isActivated
    },
    set (value) {
      compute.computedPrice = undefined
      return emit('update:isActivated', value)
    }
  }),

  computedPrice
})
</script>
