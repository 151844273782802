<template>
  <wb-input
    v-model="compute.filteredText"
    data-test-id="searchButton"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    icon="search"
    type="search"
  />

  <shared-filter
    v-model="compute.filteredLocations"
    multiple
    with-search
    label="name"
    icon="locations"
    :name="i18n.t('mywb.common.locations')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    :reduce="(item: Locations.Location) => item.id"
    :options="props.locations"
    data-test-id="locationsFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'locationsFilters', key: 'locations' })"
  />

  <wb-button
    type="white"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'locationsFilters' })"
  />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { dom } from '@wallbox/toolkit-ui'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import type { Locations } from '@/types'

const i18n = useI18n()

interface PropsType {
  showMap?: boolean
  locations: Locations.Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  showMap: true,
  locations: () => []
})

const compute = reactive({
  filteredText: computed({
    get () {
      return state.filters.locationsFilters?.text ?? ''
    },

    set: dom.debounce((value: string) => {
      state.filters.setFilterValue({ filter: 'locationsFilters', key: 'text', value })
    }, 300)
  }),

  filteredLocations: computed({
    get () {
      return state.filters.locationsFilters?.locations ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'locationsFilters', key: 'locations', value })
    }
  })
})
</script>
