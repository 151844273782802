<template>
  <wb-card
    :headline="i18n.t('mywb.common.publish-on-electromaps.title')"
    :subhead="i18n.t('mywb.common.publish-on-electromaps.subtitle')"
    class="card"
    filled
  >
    <template #content>
      <wb-button
        type="primary"
        :label="i18n.t('mywb.location.publish-electromaps')"
        icon="electromaps"
        class="mt-16"
        data-test-id="publish-on-electromaps"
        :disabled="!props.hasActions"
        @click="methods.publishOnElectromaps"
      />
    </template>

    <template #place-upper-right>
      <div class="img">
        <img
          :src="ElectromapsApp"
          alt="electromaps"
          data-test-id="electromapsImg"
        >
      </div>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import ElectromapsApp from '@/assets/images/Electromaps_app.png'
import { trackDataAction } from '@/engine/metrics/trackDataManager'

const i18n = useI18n()
const emit = defineEmits(['publish-on-electromaps'])

interface PropsType {
  hasActions: boolean
}

const props = withDefaults(defineProps<PropsType>(), {
  hasActions: false
})

const methods = {
  publishOnElectromaps () {
    emit('publish-on-electromaps')
    trackDataAction('account-menu', { source: 'location-banner' })
  }
}
</script>

<style lang="postcss" scoped>
.card {
  background: var(--primary-100) !important;
}

.img {
  width: 80px;
  height: 20px;
}
</style>
