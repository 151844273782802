<template>
  <touch-header class="header" @on-handle-menu="data.isOpen = !data.isOpen" />
  <div
    id="main-panel"
    class="wrapper"
    :class="compute.wrapperClasses"
  >
    <aside-menu
      v-model="data.isOpen"
      class="aside"
      :class="{'is-open': data.isOpen}"
      @on-collapse="methods.collapseLayout"
    />

    <router-view v-slot="{ Component }">
      <wb-cards-loader
        class="content"
        :class="{ 'content-loading': data.loading }"
        :loading="data.loading"
      >
        <component :is="Component" />
      </wb-cards-loader>
    </router-view>

    <footer-component class="footer" />

    <organization-edit-modal
      v-if="permissions.canSeeModalEditOrganization && data.showOrganizationEditModal && data.organizationToEdit"
      :organization="data.organizationToEdit"
      show-notification
      @on-close="methods.onCloseModal"
    />
  </div>
</template>

<script setup lang="ts">
import FooterComponent from '@/components/FooterComponent.vue'
import AsideMenu from '@/components/asideMenu/AsideMenu.vue'
import TouchHeader from '@/components/headers/TouchHeader.vue'
import api from '@/api'
import state, { type GlobalState, type FilterState } from '@/state'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { Userpilot } from 'userpilot'
import { useRoute, useRouter } from 'vue-router'
import { computed, watch, reactive, nextTick } from 'vue'
import OrganizationEditModal from '@/components/modals/OrganizationEditModal.vue'
import { getRoleById } from '@/utilities/user-roles'
import type { Organizations } from '@/types'
import { permissions } from '@/engine/clients'

const route = useRoute()
const router = useRouter()

interface Data {
  loading: boolean
  isOpen: boolean
  isCollapsed: boolean
  showOrganizationEditModal: boolean
  organizationToEdit?: Organizations.Organization
}

const data = reactive<Data>({
  loading: false,
  isOpen: false,
  isCollapsed: false,
  showOrganizationEditModal: false
})

watch(() => route.name, (newRoute, oldRoute) => {
  if (newRoute !== oldRoute) {
    Userpilot.reload()
    nextTick(() => {
      data.isOpen = false
      route.name && trackDataScreen(route.name.toString())
    })
  }

  if (route.query.update === 'organizations') {
    router.push({ query: {} }).catch(() => {})
    methods.getOrganizations()
  }
}, { deep: true, immediate: true })

const compute = reactive({
  wrapperClasses: computed(() => {
    return {
      'is-collapsed': data.isCollapsed,
      'is-open': data.isOpen
    }
  })
})

async function created () {
  data.loading = true
  const globalState = state.global.getWbState()

  await Promise.allSettled([
    methods.loadCurrencies(globalState),
    methods.loadTimezones(globalState),
    methods.loadCountries(globalState),
    methods.loadConfig(),
    methods.loadUser()
  ])
  await methods.getOrganizations()
  await methods.setGroupRelatedToOrganization()

  methods.trackActiveSession()

  methods.manageQueryFilters()
  data.loading = false
}

const methods = {
  collapseLayout (value: boolean) {
    data.isCollapsed = value
  },

  async getOrganizations () {
    const { data } = await api.organizations.getOrganizations()
    state.organizations.setOrganizations(data)
  },

  onCloseModal () {
    state.global.setOrganizationTypeModalViewed(state.organizations.getCurrentOrganization.id)
    data.showOrganizationEditModal = false
  },

  async setGroupRelatedToOrganization () {
    const currentOrganizationId = state.organizations.getCurrentOrganization?.id || state.user.getDefaultOrganization
    state.organizations.setCurrentOrganization(currentOrganizationId)

    if (!state.organizations.getCurrentOrganization.group_id) {
      const organizations = state.organizations.allOrganizations
      state.organizations.setCurrentOrganization(organizations[0].id)
    }

    if (!state.organizations.getCurrentOrganization.organization_type) {
      data.organizationToEdit = state.organizations.getCurrentOrganization
      data.showOrganizationEditModal = !state.global.hasViewedModal(state.organizations.getCurrentOrganization.id)
    }

    const group = await api.groups.getDataGroup(state.organizations.getCurrentOrganization.group_id)
    state.groups.set('groupRelatedToOrganization', group.result)
  },

  async loadUser () {
    const info = await api.users.getIdByUlid({ ulid: state.global.getAuth.user_id })
    const id = info.data.attributes.value

    const { data: user } = await api.users.getUser(id)
    const userDetail = await api.users.getUserDetail(id)
    state.user.setUserLogged({ ...userDetail, ...user })

    Userpilot.identify(userDetail.id.toString(), {
      name: userDetail.name,
      email: userDetail.email.includes('wallbox') ? userDetail.email : null,
      country: userDetail.country_code,
      currency: userDetail.currency_code,
      role: getRoleById(userDetail.profile_id)?.name,
      payments_account: userDetail.payments_account,
      plan: userDetail.plan.plan_name,
      created_at: userDetail.register_date
    })
  },

  async loadTimezones (globalState: GlobalState) {
    if (!globalState.timezones?.length) {
      const { data } = await api.global.getTimezones()
      state.global.setTimezones(data.attributes.timezones)
    }
  },

  async loadCurrencies (globalState: GlobalState) {
    if (!globalState.currencies?.length) {
      const { currencies } = await api.global.getCurrencies()
      state.global.setCurrencies(currencies)
    }
  },

  async loadCountries (globalState: GlobalState) {
    if (!globalState.countries?.length) {
      const { countries } = await api.global.getCountries()
      state.global.setCountries(countries)
    }
  },

  async loadConfig () {
    const { data: config } = await api.config.getConfig()
    state.config.setConfig(config.attributes)
  },

  trackActiveSession () {
    state.global.setSession('start')
    if (state.global.getSessionDurationInMinutes > 5) {
      trackDataEvent('portal-session-start')
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        state.global.setSession('end')
      } else if (document.visibilityState === 'visible') {
        state.global.setSession('start')
        if (state.global.getSessionDurationInMinutes > 5) {
          trackDataEvent('portal-session-start')
        }
      }
    })
  },

  manageQueryFilters () {
    function applyFilter <T extends keyof FilterState> (filter: T) {
      try {
        const filters = JSON
          .parse(route.query[filter]?.toString() ?? '{}') as FilterState[T]

        const entries = Object.entries(filters) as Array<[keyof FilterState[T], any]>
        entries.forEach(([key, value]) => {
          if (key === 'dates' && Array.isArray(value)) {
            value = value.map(date => new Date(date))
          }

          state.filters.setFilterValue({ filter, key, value })
        })
      } catch {}
    }

    applyFilter('accessConfigFilters')
    applyFilter('chargersFilters')
    applyFilter('dashboardFilters')
    applyFilter('invoiceFilters')
    applyFilter('locationsFilters')
    applyFilter('organizationsFilters')
    applyFilter('ratesFilters')
    applyFilter('sessionsFilters')
  }
}

created()
</script>

<style lang="postcss">
:root {
  --max-width-content: 1728px;

  --breadcrumb-size: 4.5rem;
  --header-touch-size: 6rem;
  --title-actions-size: 3.6rem;
  --filters-size: 5.4rem;

  --header-breadcrumb: calc(var(--breadcrumb-size) + var(--header-touch-size));
  --header-height: calc(var(--breadcrumb-size) + var(--title-actions-size) + var(--header-touch-size));
}

@media (--tablet) {
  :root {
    --header-breadcrumb: var(--breadcrumb-size);
    --header-height: calc(var(--breadcrumb-size) + var(--title-actions-size));
    --header-filters-height: calc(var(--header-height) + var(--filters-size));
  }
}
</style>

<style lang="postcss" scoped>
.notification {
  margin-top: 4rem;
}

.wrapper {
  display: grid;
  grid-template-columns: auto;
  min-height: 100vh;

  @media (--tablet) {
    grid-template-columns: 24rem auto;
    grid-template-rows: auto 48px;
    grid-template-areas:
      "aside content"
      "aside footer";

    &.is-collapsed {
      grid-template-columns: 7rem auto;
    }
  }
}

.header {
  grid-area: header;
}

.aside {
  grid-column: 1;
  grid-row: 1 / span 3;

  @media (--tablet) {
    grid-area: aside;
  }
}

.content {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  width: 95%;

  @media (--tablet) {
    grid-area: content;
    max-width: var(--max-width-content);
  }
}

.footer {
  grid-column: 1;
  grid-row: 3;
  place-self: center;
  width: 95%;

  @media (--tablet) {
    grid-area: footer;
    max-width: var(--max-width-content);
  }
}
</style>
