import { computed, ref, type UnwrapRef } from 'vue'

interface State<T extends string, A extends string> {
  transitions: Partial<Record<A, T>>
  on?: Partial<Record<A, () => void>>
}

type States<T extends string, A extends string> = Record<T, State<T, A>>

export function useStateMachine
  <T extends string, A extends string> ({ initial, states }: { initial: T, states: States<T, A> }) {
  const machine = states
  const currentStateKey = ref<T>(initial)

  const current = computed(() => machine[currentStateKey.value as keyof typeof states] ?? null)
  const currentStatesCount = computed(() => Object.keys(machine).indexOf(currentStateKey.value))
  const totalStatesCount = Object.keys(machine).length - 1

  function send (state: keyof State<T, A>['transitions']) {
    if (!current.value.transitions[state]) {
      throw new Error('invalid state')
    }

    current.value.on?.[state]?.()
    currentStateKey.value = current.value.transitions[state] as UnwrapRef<T>
  }

  const isFinished = computed(() => !!current.value)

  const isCurrentState = (stateKey: string) => currentStateKey.value === stateKey

  return {
    currentStateKey,
    send,
    isCurrentState,
    currentStatesCount,
    totalStatesCount,
    isFinished
  }
}
