<template>
  <wb-modal
    width="45"
    @close="methods.close"
  >
    <template #title>
      {{ i18n.t('mywb.common.change-location') }}
    </template>

    <location-selector
      v-model="data.groupUid"
      data-test-id="locationSelect"
    />

    <template #actions>
      <wb-button
        data-test-id="locationChangeLocationBtn"
        :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.loading"
        size="block"
        @click="methods.saveLocation"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import LocationSelector from '@/components/locations/LocationSelector.vue'
import api from '@/api'
import { useNotify } from '@wallbox/toolkit-ui'
import state from '@/state'
import type { Charger } from '@/types'

const i18n = useI18n()
const notify = useNotify()

const emit = defineEmits(['update:groupId', 'close', 'on-change-location'])

interface Props {
  groupUid?: string
  charger: Charger.AnyTypeOfCharger
}
const props = defineProps<Props>()

interface Data {
  groupUid?: string
  loading?: boolean
}
const data = reactive<Data>({
  loading: false
})

const methods = {
  async saveLocation () {
    if (!data.groupUid) return
    try {
      data.loading = true
      const locationId = (await api.locations.getLocationId(data.groupUid)).data.attributes.value

      await api.chargers.updateChargerLocation({
        chargerId: props.charger.id,
        config: { group: locationId }
      })

      const charger = await api.chargers.getCharger(props.charger.id)
      state.charger.setCurrentCharger(charger)
      notify.success(i18n.t('mywb.common.changes-saved'))
      emit('on-change-location')
    } catch (e) {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
      methods.close()
    }
  },

  close () {
    emit('close')
  }
}

function created () {
  data.groupUid = props.groupUid
}

created()
</script>
