import userpilotInit from '@/engine/userpilot'
import clientInit from '@/engine/clients'
import i18n from '@/engine/lang'
import initRouter from '@/engine/router'
import initValidator from '@/engine/validator'
import initVendors from '@/engine/vendors'
import featureFlagsInit from '@/engine/featureFlags'
import initPwa from '@/engine/pwa'
import type { App } from 'vue'

import { initClientTrackers } from '@/engine/metrics/metricsManager'
import { loadStates } from '@/state'
export default async function initApp (app: App) {
  initPwa()
  await featureFlagsInit()
  await clientInit()
  userpilotInit()
  initValidator()
  initVendors(app)
  await initClientTrackers()
  loadStates()

  const router = initRouter()

  return { i18n, router }
}
