<template>
  <wb-cards-loader :loading="data.loading">
    <locations-empty-state
      v-if="!permissions.canSeeLocations || permissions.showUpSellingToPlanBusiness || !data.locations.length"
      @on-add-group="methods.addLocation"
    />

    <div v-else class="locations-view">
      <locations-header
        v-model:show-map="data.showMap"
        :locations="data.locations"
        @on-add-group="methods.addLocation"
      />

      <wb-notification
        v-if="compute.locationsWithoutLatOrLng.length > 0"
        icon="info_filled"
        type="info"
        class="mb-24"
      >
        <span
          v-t="'mywb.locations.locations-notification-warn'"
        />&nbsp;

        <template
          v-for="(location, index) in compute.locationsWithoutLatOrLng"
          :key="location.id"
        >
          <span v-if="index != 0">,&nbsp;</span>
          <wb-link
            @click="methods.editLocation(location)"
          >
            {{ location.name }}
          </wb-link>
        </template>
      </wb-notification>

      <maps-location
        v-if="data.showMap"
        class="pb-24"
        :locations="compute.locationsFiltered"
        @on-edit="methods.editLocation"
        @on-delete="methods.handleLocationDeleteConfirmation"
      />

      <locations-table
        :loading="data.loading"
        :locations="compute.locationsFiltered"
        @on-edit="methods.editLocation"
        @on-delete="methods.handleLocationDeleteConfirmation"
      />
    </div>

    <location-create-modal
      v-if="isCurrentState('modalCreateLocation')"
      data-test-id="chargersGroupEditModal"
      :group-id="compute.groupRelatedToSelectedLocation?.id"
      @on-close="send('close')"
      @on-edit-finish="created()"
    />

    <shared-confirmation-modal
      v-if="isCurrentState('modalDeleteWithoutChargers')"
      :title="i18n.t('mywb.common.delete-confirmation', { element: data.currentLocation?.name })"
      :label-confirmation-button="i18n.t('mywb.common.delete')"
      type="danger"
      @on-confirm="methods.handleLocationDelete"
      @on-close="send('close')"
    />

    <location-with-chargers-delete-modal
      v-if="isCurrentState('modalDeleteWithChargers') && compute.groupRelatedToSelectedLocation?.chargers"
      :location="data.currentLocation"
      :chargers="compute.groupRelatedToSelectedLocation.chargers"
      @on-confirm="created"
      @on-close="send('close')"
    />
  </wb-cards-loader>
</template>

<script setup lang="ts">
import LocationsEmptyState from '@/components/emptyStates/LocationsEmptyState.vue'
import LocationsHeader from '@/components/headers/LocationsHeader.vue'
import LocationCreateModal from '@/components/modals/LocationCreateModal.vue'
import LocationsTable from '@/components/tables/LocationsTable.vue'
import MapsLocation from '@/components/locations/MapsLocation.vue'
import LocationWithChargersDeleteModal from '@/components/modals/LocationWithChargersDeleteModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { computed, reactive } from 'vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import api from '@/api'
import { useStateMachine } from '@/hooks'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Group, Locations } from '@/types'

const notify = useNotify()
const i18n = useI18n()

interface DataType {
  loading: boolean
  showMap: boolean
  currentLocation?: Locations.Location
  search: string
  locations: Locations.Location[]
}
const data: DataType = reactive({
  loading: false,
  showMap: true,
  search: '',
  locations: []
})

const { isCurrentState, send } = useStateMachine({
  initial: 'list',
  states: {
    list: {
      transitions: {
        deleteWithChargers: 'modalDeleteWithChargers',
        deleteWithoutChargers: 'modalDeleteWithoutChargers',
        createLocation: 'modalCreateLocation'
      }
    },

    modalDeleteWithChargers: {
      transitions: {
        close: 'list'
      }
    },

    modalDeleteWithoutChargers: {
      transitions: {
        close: 'list'
      }
    },

    modalCreateLocation: {
      transitions: {
        close: 'list'
      }
    }
  }
})

const compute = reactive({
  locationsFiltered: computed((): Locations.Location[] => {
    const reducers = {
      locations: methods.filterByLocation,
      text: methods.filterByText
    }

    const entries = Object.entries(state.filters.locationsFilters) as Array<[keyof typeof reducers, string & number]>

    return entries.reduce((locationsFiltered, [type, value]) =>
      reducers[type](locationsFiltered, value), data.locations
    )
  }),

  locationsWithoutLatOrLng: computed((): Locations.Location[] => {
    return compute.locationsFiltered.filter((location) => !location.latitude || !location.longitude)
  }),

  groupRelatedToSelectedLocation: computed(() => {
    const groups = state.groups.get('groups') as Group.GroupChargersTree[]

    return groups.find((group: Group.GroupChargersTree) => data.currentLocation?.group_uid === group.uid)
  })
})

const methods = {
  filterByText (locations: Locations.Location[], text: string) {
    if (!text) return locations

    return locations.filter(location => {
      return (
        location.name.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  filterByLocation (locations: Locations.Location[], locationsFiltered: string[]) {
    if (!locationsFiltered?.length) return locations

    return locations.filter(location => locationsFiltered.includes(location.id))
  },

  async addLocation () {
    data.currentLocation = undefined

    send('createLocation')
    trackDataAction('add-location')
  },

  async editLocation (location: Locations.Location) {
    send('createLocation')
    data.currentLocation = location
    trackDataAction('edit-location', { location_id: location.id })
  },

  handleLocationDeleteConfirmation (location: Locations.Location) {
    data.currentLocation = location

    if (compute.groupRelatedToSelectedLocation?.chargers.length) {
      send('deleteWithChargers')
    } else {
      send('deleteWithoutChargers')
    }
  },

  async handleLocationDelete () {
    if (!data.currentLocation?.id) return

    try {
      data.locations = data.locations.filter(location => location.id !== data.currentLocation?.id)
      notify.success(i18n.t('mywb.common.location-removed-successfully'))
      trackDataAction('delete-location', { location_id: data.currentLocation?.id })
      api.locations.deleteLocation({ id: data.currentLocation.id })
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

async function created () {
  if (!permissions.canSeeLocations) return

  data.loading = true

  const { result } = await api.groups.getAllDataOrganizations({ cacheType: 'stale' })
  state.groups.set('groups', result.groups)
  data.loading = false

  api.groups.getAllDataOrganizations().then(({ result: { groups } = {} }) => state.groups.set('groups', groups))

  const { data: locations } = await api.locations.getLocations({
    organizationUuid: state.groups.groupRelatedToOrganization.uid
  })

  data.locations = locations

  const showMapStoraged = localStorage.getItem('wb-show-locations-map')
  if (showMapStoraged) {
    data.showMap = localStorage.getItem('wb-show-locations-map') === 'true'
  } else {
    data.showMap = data.locations.some(location => (location.latitude && location.longitude))
  }
}

created()
</script>

<style lang="postcss">
.locations-view {
  display: grid;
  height: 100%;
}
</style>
