import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import type { InvitationsApi, AccessConfig } from '@/types'
import { formatResponseJsonApi } from './config/jsonApi.config'

interface ResendInvitation {
  groupId: number
  invitationId: number
  params: {
    lang: string
    branding_id: string
  }
}

export default {
  async getUsersInvitations (groupId: number) {
    return await axios.get<unknown, InvitationsApi.ApiInvitationsResponse>(
      endpoints.v4.groups_groupId_invitations.replace('{groupId}', groupId.toString()), {
        params: {
          limit: 9999
        }
      }).then(formatResponseJsonApi)
  },

  async deleteUsersInvitations ({ groupId, invitationId }: { groupId: number, invitationId: number }): Promise<void> {
    return await axios.delete(endpoints.v4.groups_groupId_invitations_invitationId
      .replace('{groupId}', groupId.toString())
      .replace('{invitationId}', invitationId.toString())
    )
  },

  async addUsersToAccessConfig (payload: AccessConfig.UserToAccessConfig): Promise<void> {
    return await axios.post(endpoints.v3.add_users_to_access_config, payload)
  },

  async resendUsersInvitations ({ groupId, invitationId, params }: ResendInvitation): Promise<void> {
    return await axios.patch(endpoints.v4.groups_groupId_invitations_invitationId
      .replace('{groupId}', groupId.toString())
      .replace('{invitationId}', invitationId.toString()), {
      lang: params.lang,
      branding_id: params.branding_id
    }
    )
  }
}
