import type { Rate } from '@/types'
import { toRaw } from 'vue'

const directions_right: Record<keyof Rate.Schedule, keyof Rate.Schedule> = {
  monday: 'tuesday',
  tuesday: 'wednesday',
  wednesday: 'thursday',
  thursday: 'friday',
  friday: 'saturday',
  saturday: 'sunday',
  sunday: 'monday'
}

const directions_left: Record<keyof Rate.Schedule, keyof Rate.Schedule> = {
  monday: 'sunday',
  tuesday: 'monday',
  wednesday: 'tuesday',
  thursday: 'wednesday',
  friday: 'thursday',
  saturday: 'friday',
  sunday: 'saturday'
}

export function shiftHours (schedule: Rate.Schedule, amount: number, right: boolean): Rate.Schedule {
  const entries = Object.entries(schedule) as Array<[keyof typeof schedule, string[]]>

  const scheduleCopy = structuredClone(toRaw(schedule))

  const newSchedule = entries.reduce((schedule, [key, hours]) => {
    const remain = [...hours]
    if (right) {
      const rest = remain.splice((hours.length - amount), hours.length)
      schedule[directions_right[key]] = [
        ...rest,
        ...schedule[directions_right[key]].slice(0, schedule[directions_right[key]].length - amount)
      ]
    } else {
      const rest = remain.splice(0, amount)

      schedule[directions_left[key]] = [
        ...schedule[directions_left[key]].slice(amount, schedule[directions_left[key]].length),
        ...rest
      ]
    }
    return schedule
  }, scheduleCopy)

  return newSchedule
}

function moveSchedule (schedule: Rate.Schedule, timezone: string, fromUtcToLocal: boolean) {
  const utcDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(new Date().toLocaleString('en-US', { timeZone: timezone ?? 'UTC' }))

  const offset = (tzDate.getTime() - utcDate.getTime()) / 36e5

  const direction = Math.sign(offset)
  const amount = Math.abs(offset)

  return shiftHours(schedule, amount, fromUtcToLocal ? (direction >= 0) : (direction <= 0))
}

export function moveScheduleFromUTCToLocal (schedule: Rate.Schedule, timezone: string) {
  return moveSchedule(schedule, timezone, true)
}

export function moveScheduleFromLocalToUTC (schedule: Rate.Schedule, timezone: string) {
  return moveSchedule(schedule, timezone, false)
}
