import i18n from '@/engine/lang'
import type { Charger } from '@/types'

export enum OCPP {
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
  CONNECTED = 'connected',
}

export const ocppStatuses = () => [
  {
    code: OCPP.DISCONNECTED,
    label: i18n.global.t('mywb.common.disabled'),
    id: 0
  },
  {
    code: OCPP.CONNECTING,
    label: i18n.global.t('mywb.common.connecting'),
    id: 2
  },
  {
    code: OCPP.ERROR,
    label: i18n.global.t('mywb.common.error'),
    id: 3
  },
  {
    code: OCPP.CONNECTED,
    label: i18n.global.t('mywb.common.enabled'),
    id: 4
  }
]

export const ocppStatus = (charger: Charger.AnyTypeOfCharger) => {
  const statusId = charger.ocppConnectionStatus

  return ocppStatuses().find(status => status.id === statusId) ??
  { id: -1, code: OCPP.DISCONNECTED, label: i18n.global.t('mywb.common.disconnected') }
}

export const supportsOcpp = (charger: Charger.AnyTypeOfCharger) => {
  return !!charger?.ocppReady
}

export const isOcpp = (charger: Charger.ChargerConfig) => {
  return charger?.operation_mode === 'ocpp'
}
