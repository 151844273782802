import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import state from '@/state'
import type { GroupsApi } from '@/types'

export default {
  async getAllDataOrganizations ({ cacheType = 'network' } = {}): Promise<GroupsApi.ApiGroupsResponse> {
    return await axios.get(endpoints.v3.chargers_groups, {
      headers: {
        'workbox-cache-type': cacheType
      },

      params: {
        user: state.user.userLogged.id
      }
    })
  },

  async getDataGroup (groupId: number): Promise<GroupsApi.ApiDataGroupResponse> {
    return await axios.get(endpoints.v3.chargers_group.replace('{groupId}', groupId.toString()))
  }
}
