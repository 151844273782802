<template>
  <wb-modal
    class="charger-remote-actions"
    is-fullscreen
    @close="$emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.charger.charger-rate-unassignment', [state.charger.getCheckedChargers.length]) }}
    </template>

    <div class="content g-64">
      <vertical-menu-component
        v-model="currentStateKey"
        class="menu"
        :menu="compute.menu"
      />

      <div v-if="isCurrentState('settings')">
        <p
          v-t="'mywb.charger.unassign-charger-title'"
          class="is-font-weight-500 mb-24 is-size-400"
        />

        <rate-type-component
          v-model:activated="data.payPerChargeActivated"
          data-test-id="payPerMonthCard"
          class="mb-24"
          v-bind="data.payPerCharge"
        />

        <rate-type-component
          v-model:activated="data.payPerMonthActivated"
          v-bind="data.payPerMonth"
        />
      </div>

      <div v-if="isCurrentState('resume')">
        <p
          v-t="'mywb.charger.unassign-charger-resume'"
          class="is-font-weight-500 mb-24 is-size-400"
        />

        <chargers-rates-table
          data-test-id="tableSummary"
          :loading="data.loadingAssignedRates"
          :unassign-pay-per-charge="data.payPerChargeActivated"
          :unassign-pay-per-month="data.payPerMonthActivated"
          :chargers-with-rates="data.chargersWithRates"
          @on-update="methods.getSelectedChargers"
        />
      </div>
    </div>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          type="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="send('back')"
        />

        <wb-button
          :loading="data.loading"
          data-test-id="nextButton"
          :disabled="!data.payPerChargeActivated && !data.payPerMonthActivated"
          :label="i18n.t('mywb.common.continue')"
          icon="arrow_forward"
          icon-position="right"
          @click="send('next')"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed, type Ref } from 'vue'
import { useNotify } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'

import ChargersRatesTable from '@/components/tables/ChargersRatesTable.vue'
import RateTypeComponent from '@/components/RateTypeComponent.vue'
import VerticalMenuComponent from '@/components/VerticalMenuComponent.vue'

import api from '@/api'
import state from '@/state'

import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'
import { useStateMachine } from '@/hooks'
import type { Charger } from '@/types'

const i18n = useI18n()
const notify = useNotify()
const emit = defineEmits(['close', 'rates-updated', 'restore-chargers'])

const {
  getData: getAssignedRates,
  loading: loadingAssignedRates,
  chargersWithRates
} = useAssignedRatesApi()

interface Data {
  loadingAssignedRates: typeof loadingAssignedRates
  loading: boolean
  chargersWithRates: Ref<Charger.ChargerWithRates[]>
  payPerChargeActivated: boolean
  payPerMonthActivated: boolean

  payPerCharge: {
    type: 'pay_per_charge',
    title: string,
    subtitle: string
  }

  payPerMonth: {
    type: 'pay_per_month',
    title: string,
    subtitle: string
  }
}

const data = reactive<Data>({
  loadingAssignedRates,
  loading: false,
  chargersWithRates,
  payPerChargeActivated: false,
  payPerMonthActivated: false,
  payPerCharge: {
    type: 'pay_per_charge',
    title: i18n.t('mywb.charger.pay-per-charge'),
    subtitle: i18n.t('mywb.charger.pay-per-charge-unassign-description')
  },

  payPerMonth: {
    type: 'pay_per_month',
    title: i18n.t('mywb.charger.pay-per-month'),
    subtitle: i18n.t('mywb.charger.pay-per-month-unassign-description')
  }
})

const compute = reactive({
  menu: computed(() => {
    return [
      {
        key: 'settings',
        text: `1. ${i18n.t('mywb.common.settings')}`
      },
      {
        key: 'resume',
        text: `2. ${i18n.t('mywb.chargers.rate-resume')}`,
        disabled: !data.payPerChargeActivated && !data.payPerMonthActivated
      }
    ]
  })
})

const methods = {
  async unassignRates () {
    data.loading = true

    const apiCalls = state.charger.getCheckedChargers.map(charger => {
      return () => {
        return api.rates.getChargerRatesWeekSchedules(charger.uid).then(schedules => {
          const promises = []

          if (data.payPerChargeActivated) {
            const schedule = schedules.data.find(schedule => schedule.paymentType === 'pay_per_charge')

            if (!schedule) return

            promises.push(api.rates.saveChargerSchedule({
              chargerId: charger.uid,
              scheduleId: schedule.id,
              payload: {
                type: 'pay_per_charge',
                status: 'frozen',
                schedule: schedule.schedule

              }
            }))
          }

          if (data.payPerMonthActivated) {
            const schedule = schedules.data.find(schedule => schedule.paymentType === 'pay_per_month')

            if (!schedule) return

            promises.push(api.rates.saveChargerSchedule({
              chargerId: charger.uid,
              scheduleId: schedule.id,
              payload: {
                type: 'pay_per_month',
                status: 'frozen',
                schedule: schedule.schedule
              }
            }))
          }

          return Promise.all(promises)
        })
      }
    })

    await Promise.all(apiCalls.map(call => call()))

    data.loading = false
    notify.success(i18n.t('mywb.common.changes-saved'))

    emit('close')
    emit('rates-updated')
  },

  async getSelectedChargers () {
    getAssignedRates(state.charger.getCheckedChargers as any)
  }
}

const { currentStateKey, isCurrentState, send } = useStateMachine({
  initial: 'settings',
  states: {
    settings: {
      transitions: {
        next: 'resume',
        back: 'settings'
      },
      on: {
        back: () => emit('close')
      }
    },

    resume: {
      transitions: {
        back: 'settings',
        next: 'resume'
      },

      on: {
        back: () => emit('restore-chargers'),
        next: methods.unassignRates
      }
    }
  }
})

async function created () {
  await methods.getSelectedChargers()
}

created()
</script>

<style lang="postcss" scoped>
.content {
  display: grid;
  grid-template-columns: auto;

  @media (--tablet) {
    grid-template-columns: max-content auto;
  }
}

.menu {
  top: 0;
}

.button-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
