<template>
  <shared-header>
    <template #title>
      {{ i18n.t('mywb.common.payments-configuration') }}
    </template>
  </shared-header>

  <wb-cards-loader key="payments" :loading="data.loadingComponent">
    <container-component>
      <div class="grid-layout g-3">
        <payments-configuration-info />

        <div class="stripe-content g-32 p-32 tablet:pt-16">
          <client-img-component
            src="wallbox_stripe_account"
            class="stripe-img"
            :class="{'is-linked': data.accountLinked === 'confirmed'}"
            data-test-id="stripeImg"
            inline
          />

          <div v-if="data.accountLinked === 'unlinked'">
            <div v-t="'mywb.payments.onboarding-description'" class="is-size-400 mb-12" />
            <div v-t="'mywb.payments.onboarding-description-2'" class="is-size-400 mb-32" />

            <billing-information
              v-model:has-billing-info="data.hasBillingInfo"
              data-test-id="billingInformationSection"
              class="mb-24"
              :group-id="state.organizations.getCurrentOrganization.group_id"
            />

            <div class="has-text-centered">
              <wb-button
                type="primary"
                :disabled="!data.hasBillingInfo"
                :loading="data.loadingExpressButton"
                size="block"
                data-test-id="linkStripeAccountBtn"
                :label="i18n.t('mywb.common.connect-stripe')"
                @click="methods.linkPaymentsAccount"
              />

              <div class="has-text-danger-500 mt-24">
                <p
                  v-if="data.connectionError"
                  v-t="'mywb.payments.stripe-connection-error-detail'"
                  data-test-id="stripeConnectionError"
                />
                <p
                  v-else-if="data.currencyError"
                  v-t="'mywb.payments.stripe-connection-currency-error'"
                  data-test-id="stripeConnectionCurrency"
                />
                <p
                  v-else-if="data.countryError"
                  v-t="'mywb.payments.stripe-connection-country-error'"
                  data-test-id="stripeConnectionCountry"
                />
                <p
                  v-else-if="data.countryExpressError"
                  v-t="'mywb.payments.stripe-connection-country-express-error'"
                  data-test-id="countryExpressError"
                />
              </div>
            </div>
          </div>

          <wb-spinner
            v-if="data.accountLinked === 'pending'"
            class="spinner"
            spinner="Ring"
            width="40px"
          />

          <div v-if="data.accountLinked === 'confirmed'" class="has-text-centered">
            <p class="is-size-500 has-text-primary-500 mb-24" data-test-id="accountLinkedSuccessfully">
              {{ i18n.t('mywb.payments.account-linked-successfully') }}
            </p>

            <wb-button
              type="primary"
              :label="i18n.t('mywb.common.go-to-payments')"
              :to="{ name: 'payments-invoices' }"
              data-test-id="goToPaymentsBtn"
            />
          </div>

          <div v-if="data.accountLinked === 'error'" class="has-text-centered">
            <i18n-t
              tag="p"
              class="is-size-500 has-text-danger-500 mb-24"
              keypath="mywb.payments.error-stripe"
            >
              <wb-link
                href="https://dashboard.stripe.com/login"
                type="text"
                target="_blank"
              >
                Stripe
              </wb-link>
            </i18n-t>
          </div>
        </div>
      </div>
    </container-component>
  </wb-cards-loader>

  <payments-configuration-reset-modal
    v-if="data.openResetAccountModal"
    :loading-continue-linking="data.loadingContinueLinking"
    :loading-reset-linking="data.loadingResetLinking"
    @continue-linking="methods.continueLinking"
    @delete-account="methods.deleteAccount"
    @on-close="methods.closeModal"
  />
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import BillingInformation from '@/components/payments/BillingInformation.vue'
import PaymentsConfigurationInfo from '@/components/payments/PaymentsConfigurationInfo.vue'
import PaymentsConfigurationResetModal from '@/components/modals/PaymentsConfigurationResetModal.vue'
import SharedHeader from '@/components/headers/SharedHeader.vue'

import api from '@/api'
import state from '@/state'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { useNotify, promises } from '@wallbox/toolkit-ui'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const notify = useNotify()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()

interface Data {
  connectionError: boolean
  countryError: boolean
  currencyError: boolean
  countryExpressError: boolean
  loadingComponent: boolean,
  loadingExpressButton: boolean
  accountLinked: 'unlinked' | 'pending' | 'error' | 'confirmed' | 'unlink',
  openResetAccountModal: boolean
  loadingContinueLinking: boolean
  loadingResetLinking: boolean
  pollingFinish: boolean
  hasBillingInfo: boolean
}

const data = reactive<Data>({
  connectionError: false,
  countryError: false,
  currencyError: false,
  countryExpressError: false,
  loadingComponent: true,
  loadingExpressButton: false,
  accountLinked: 'unlinked',
  openResetAccountModal: false,
  loadingContinueLinking: false,
  loadingResetLinking: false,
  pollingFinish: false,
  hasBillingInfo: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.payments_account)
})

const methods = {
  handlePromiseErrors (error: HttpError) {
    if (error.code === 6015) {
      data.countryError = true
    } else if (error.code === 6017) {
      data.currencyError = true
    } else if (error.code === 403) {
      data.countryExpressError = true
    } else {
      data.connectionError = true
    }

    data.loadingContinueLinking = false
    data.loadingResetLinking = false
    data.loadingExpressButton = false
  },

  async linkPaymentsAccount () {
    data.connectionError = false
    data.countryError = false

    data.loadingExpressButton = true
    trackDataAction('connect-stripe-express-account')

    let information = null

    try {
      information = await api.payments.getPaymentsAccount({
        groupId: state.organizations.getCurrentOrganization.group_id
      })
    } catch {}

    data.openResetAccountModal = information?.data?.attributes?.status === 'pending' ?? false
    if (!data.openResetAccountModal) {
      try {
        await methods.setupPayments()
        await methods.continueLinking()
      } catch (error) {
        if (error instanceof HttpError) {
          methods.handlePromiseErrors(error)
        } else {
          throw error
        }
      }
    }
    data.loadingExpressButton = false
  },

  async continueLinking (loading = true) {
    try {
      if (loading) data.loadingContinueLinking = true
      await methods.getLinkPaymentsAccount()
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handlePromiseErrors(error)
      } else {
        throw error
      }
    }
  },

  async deleteAccount () {
    data.loadingResetLinking = true

    try {
      await api.payments.deletePaymentsAccount({
        groupId: state.organizations.getCurrentOrganization.group_id
      })
      await methods.setupPayments()
      await methods.continueLinking(false)
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handlePromiseErrors(error)
      } else {
        throw error
      }
    }
  },

  setupPayments () {
    return api.payments.postPaymentsAccount({
      groupId: state.organizations.getCurrentOrganization.group_id,
      type: 'express'
    })
  },

  async getLinkPaymentsAccount () {
    const { protocol, host, pathname } = location

    const paymentsRoute = router.resolve({
      name: 'payments-configuration',
      query: {
        accountLinked: 'true'
      }
    }).href

    const refreshUrl = `${protocol}//${host}${pathname}`
    const redirectUrl = `${protocol}//${host}${paymentsRoute}`

    const linkStripeOnboarding = await api.payments.getLinkPaymentsAccount({
      groupId: state.organizations.getCurrentOrganization.group_id,
      refreshUrl,
      redirectUrl
    })

    data.loadingContinueLinking = false
    data.loadingResetLinking = false
    data.loadingExpressButton = false
    data.openResetAccountModal = false

    window.location.href = linkStripeOnboarding
  },

  async confirmStripeLink () {
    if (!compute.hasOrganizationPaymentsAccount && route.query.accountLinked) {
      router.push({ query: {} }).catch(() => {})
      data.accountLinked = 'pending'
      data.pollingFinish = false

      try {
        await promises.polling(async () => {
          try {
            const { data: organizations } = await api.organizations.getOrganizations()
            const currentGroupId = state.organizations.getCurrentOrganization.group_id
            const organization = organizations.find(organization => organization.group_id === currentGroupId)
            data.pollingFinish = organization?.payments_account !== null
          } catch (error) {
            if (error instanceof HttpError && error.code === 6018) {
              data.accountLinked = 'error'
            }
          }
        }, { validate: () => data.pollingFinish || data.accountLinked === 'error', max: 20, delta: 2, timeout: 5000 })

        await api.organizations.getOrganizations()
          .then(({ data }) => data)
          .then(state.organizations.setOrganizations)

        if (state.organizations.getCurrentOrganization.payments_account) {
          data.accountLinked = 'confirmed'
        } else {
          data.accountLinked = 'unlinked'
        }
      } catch (e) {
        data.accountLinked = 'unlink'
        notify.error(i18n.t('mywb.error.unexpected-error'))
      }
    }
  },

  closeModal () {
    data.openResetAccountModal = false
    data.loadingExpressButton = false
  },

  async loadInitialData () {
    data.loadingComponent = true

    const group = await api.groups.getDataGroup(state.organizations.getCurrentOrganization.group_id)
    state.groups.set('groupRelatedToOrganization', group.result)

    trackDataScreen('paymentslanding-stripe')
    data.loadingComponent = false

    await methods.confirmStripeLink()
  }
}

async function created () {
  await methods.loadInitialData()
}

created()
</script>

<style lang="postcss" scoped>
.grid-layout {
  display: grid;
  grid-template-columns: 1fr;

  @media (--widescreen) {
    grid-template-columns: 1fr 1fr;
  }
}

.stripe-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content min-content min-content;
  justify-items: center;
  border-radius: 1rem;
  margin-top: 8px;
  border: 1px solid var(--grey-200);
}

.stripe-img {
  max-width: 160px;
  fill: var(--grey-700);

  &.is-linked {
    fill: var(--primary-500);
  }

  :deep(.color-2) {
    fill: var(--grey-100);
  }

  :deep(.color-3) {
    fill: var(--grey-200);
  }
}

.spinner {
  margin: 32px auto;
}
</style>
