<template>
  <div
    id="appleid-signin"
    class="applebutton"
    data-color="white"
    data-border="true"
    data-type="continue"
    @click="methods.trackClick"
  />
</template>
<script lang="ts">
export default {
  name: 'AuthAppleButton'
}
</script>

<script setup lang="ts">
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import ENV from '@/engine/env/web.env'
import { trackAction, trackScreen } from '@/engine/metrics/metricsManager'
import { dom } from '@wallbox/toolkit-ui'
import type { Auth } from '@/types'

interface Events {
  (e: 'on-click', data: Auth.AppleOauthResponse): void,
}
const emit = defineEmits<Events>()

interface DataType {
  timesTryingtoLoadScript: number
  appleScript?: HTMLScriptElement
}

const data: DataType = reactive({
  timesTryingtoLoadScript: 0
})

const methods = {
  trackClick () {
    trackAction('signin_continue', { provider: 'apple' })
    trackScreen('signin_provider', { provider: 'apple' })
  },

  loadScript (locale = 'en_US') {
    data.timesTryingtoLoadScript++
    data.appleScript = document.createElement('script')

    data.appleScript.setAttribute('src',
      `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${locale}/appleid.auth.js`
    )

    document.head.appendChild(data.appleScript)

    const initAppleButton = () => {
      window.AppleID.auth.init({
        clientId: ENV.oauth.apple ?? '',
        scope: 'name email',
        redirectURI: location.origin,
        usePopup: true
      })
    }

    data.appleScript.onload = initAppleButton

    if (data.timesTryingtoLoadScript < 2) {
      data.appleScript.onerror = () => methods.loadScript()
    }
  },

  appleSuccess (e: CustomEvent<Auth.AppleOauthResponse>) {
    dom.throttle(() => {
      emit('on-click', e.detail)
    }, 300)
  }
}

onMounted(() => {
  const navigatorLanguage = navigator.language.split('-')

  const languageProposal = [
    navigatorLanguage[0]?.toLowerCase?.() ?? 'en',
    (navigatorLanguage[1] ?? navigatorLanguage[0])?.toUpperCase?.() ?? 'EN'
  ].join('_')

  methods.loadScript(languageProposal)

  document.addEventListener('AppleIDSignInOnSuccess', methods.appleSuccess as EventListener)
})

onBeforeUnmount(() => {
  data.appleScript?.remove?.()
  document.removeEventListener('AppleIDSignInOnSuccess', methods.appleSuccess as EventListener)
})

</script>

<style scoped lang="postcss">
.applebutton {
  width: 300px;
  height: 40px;
  cursor: pointer;

  @media (--tablet) {
    width: 375px;
  }
}
</style>
