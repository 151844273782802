import UsersListView from '@/views/UsersListView.vue'
import UsersView from '@/views/UsersView.vue'
import UserView from '@/views/UserView.vue'
import UserSettingsView from '@/views/UserSettingsView.vue'
import UserChargersView from '@/views/UserChargersView.vue'
import AccessConfigsGroupsView from '@/views/AccessConfigsGroupsView.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'users',
    component: UsersView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: 'users',
        text: 'mywb.common.users'
      }
    },
    children: [
      {
        path: '',
        name: 'users',
        component: UsersListView,
        props: true
      },
      {
        path: 'groups',
        name: 'access-configs-groups',
        component: AccessConfigsGroupsView,
        props: true
      }
    ]
  },
  {
    path: 'user/:id',
    component: UserView,
    props: true,
    meta: {
      breadcrumb: {
        name: 'user'
      }
    },
    children: [
      {
        path: '',
        name: 'user',
        component: UserSettingsView,
        props: true
      },
      {
        path: 'chargers',
        name: 'user-chargers',
        component: UserChargersView,
        props: true
      }
    ]
  }
] as RouteRecordRaw[]
