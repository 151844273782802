<template>
  <wb-card
    :headline="t(props.title)"
    :subhead="t(props.subtitle)"
  >
    <template #place-upper-right>
      <span class="wb-icons chart-icon">
        {{ props.icon }}
      </span>
    </template>
    <template #content>
      <wb-card-loader class="card-content" :loading="props.loading">
        <p v-if="props.number" class="is-size-1000 is-font-weight-500 has-text-black">
          {{ props.number }}
        </p>

        <slot />
      </wb-card-loader>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  title: string
  subtitle: string
  icon: string
  iconColor: string
  loading: boolean
  number?: number | string
}

const props = defineProps<Props>()
</script>

<style lang="postcss" scoped>
.chart-icon {
  color: v-bind(props.iconColor);
  position: relative;
  font-size: var(--size-700);
  margin: 0.8rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: currentColor;
    opacity: 0.08;
    width: 36px;
    height: 36px;
    border-radius: 99px;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

:deep(.card-content) {
  width: 100% !important;
}
</style>
