import {
  trackScreen,
  trackAction,
  trackError,
  trackEvent
} from '@/engine/metrics/metricsManager'

import state from '@/state'
import type { ApiErrorResponse } from '@/types/api/clientApi.api.types'
import type { AxiosError } from 'axios'
import { getRoleById } from '@/utilities/user-roles'

const getCurrentUserLogged = () => {
  const auth = state.global.getAuth
  const user = state.user.userLogged

  if (user.id && user.profile_id && auth.token) {
    return {
      user_uid: auth.user_id,
      user_id: user.id,
      user_type: getRoleById(user.profile_id)?.name
    }
  }
}

const getCurrentCharger = () => {
  const currentCharger = state.charger.getCurrentCharger

  const charger = {
    ...currentCharger?.chargerData,
    ...currentCharger?.config
  }

  if (charger.name) {
    return {
      charger_id: charger.id,
      charger_name: charger.name,
      charger_serial_number: charger.serialNumber,
      charger_part_number: charger.part_number,
      charger_model: charger.chargerType,
      charger_connector_type: charger.connectorType,
      charger_protocol_communication: charger.protocolCommunication,
      charger_software_version: charger.software?.currentVersion,
      charger_software_update_available: charger.software?.updateAvailable,
      charger_mid: charger.midEnabled,
      charger_power_sharing: charger.powerSharingStatus,
      charger_max_available_current: charger.maxAvailableCurrent,
      charger_max_charging_current: charger.maxChargingCurrent,
      charger_autolock: charger.auto_lock,
      charger_autolock_time: charger.auto_lock_time,
      charger_with_qr: charger.charger_load_type === 'Public',
      charger_monthly_billing: charger.contract_charging_available,
      charger_ocpp: charger.ocpp_ready
    }
  }
}

const getPreviousRoute = () => {
  return {
    previousRoute: state.routes.routes[state.routes.routes.length - 2]?.fullPath
  }
}

const getCurrentOrganization = () => {
  const organization = state.organizations.getCurrentOrganization

  if (organization) {
    return {
      organization_id: organization.id,
      organization_name: organization.name,
      organization_type: organization.organization_type ?? '-',
      plan: state.plans.getPlan(organization.product_id)?.name || ''
    }
  }
}

export const trackDataScreen = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCurrentCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackScreen(eventName, params)
}

export const trackDataAction = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCurrentCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackAction(eventName, params)
}

export const trackDataEvent = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCurrentCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackEvent(eventName, params)
}

export const trackDataError = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCurrentCharger(),
    ...getPreviousRoute(),
    ...params
  }

  trackError(eventName, params)
}

export const trackDataApiErrorResponse = (error: AxiosError<ApiErrorResponse>) => {
  const params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCurrentCharger(),
    ...getPreviousRoute(),
    url: error.response?.config?.url,
    data: error.response?.data,
    status: error.response?.status
  }
  trackError('api', params)
}
