<template>
  <wb-popover
    tooltip-position="right"
    menu-position="right"
    :distance-offset="24"
    :tooltip-distance-offset="16"
    @click="methods.handleDropdown"
    @change="data.isActive = !data.isActive"
  >
    <template #activator>
      <slot name="activator" />
    </template>
    <template v-if="slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
    <template #container="{ close }">
      <wb-button
        type="white"
        data-test-id="logoutMenu"
        icon="logout"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.user.logout')"
        @mousedown="methods.logout(close)"
      />
    </template>
  </wb-popover>
</template>

<script setup lang="ts">
import { trackDataAction, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import state, { loadStates } from '@/state'
import { reactive, useSlots } from 'vue'

const i18n = useI18n()
const slots = useSlots()

interface DataType {
  isActive: boolean
}

const data: DataType = reactive({
  isActive: false
})

const methods = {
  handleDropdown (isActive: boolean) {
    trackDataAction('account-menu', { is_active: isActive })
  },

  logout (close: () => void) {
    close()
    trackDataAction('account-menu-option', { option: 'logout' })
    trackDataEvent('logout')
    state.global.removeLocalStorageSensitiveInfo()
    sessionStorage.removeItem('wb-state')
    window.location.href = '/login'
    loadStates()
  }
}
</script>
