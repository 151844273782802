<template>
  <wb-modal
    width="33"
    @close="emit('close')"
  >
    <wb-form>
      <wb-checkbox
        v-if="!state.organizations.isPlan()([EnumPlans.BASIC])"
        v-model="data.keepEnergyCostFromGroup"
        @update:model-value="methods.switchKeepEnergyCost"
      >
        {{ i18n.t('mywb.common.keep-energy-from') }} {{ props.location.name }}
      </wb-checkbox>

      <wb-input
        :ref="setRef('input')"
        v-model="compute.computedPrice"
        :label="i18n.t('mywb.common.energy-cost')"
        data-test-id="energyCostInput"
        :error="data.errors.energyCost"
        :label-right="`${compute.getCurrencyCode} / ${i18n.t('mywb.common.kwh')}`"
        :placeholder="i18n.t('mywb.common.add-energy-cost')"
        :disabled="data.keepEnergyCostFromGroup"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          type="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          type="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="data.saving"
          @click="methods.editName"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useField, useForm } from 'vee-validate'
import state from '@/state'
import api from '@/api'
import { getCurrencyCode } from '@/utilities/currency'
import { useNotify, useTemplateRef } from '@wallbox/toolkit-ui'
import { EnumPlans } from '@/utilities/plans'
import { usePriceValueProxy } from '@/hooks'
import type { Locations } from '@/types'

const notify = useNotify()
const i18n = useI18n()
const { refs, setRef } = useTemplateRef()

interface Events {
  (e: 'close'): void
}

const emit = defineEmits<Events>()

interface Props {
  location: Locations.Location
}
const props = defineProps<Props>()

const { errors, handleSubmit } = useForm({
  validationSchema: {
    energyCost: 'required',
    keepEnergyCostFromGroup: false
  },

  initialValues: {
    energyCost: state.charger.getCurrentCharger?.config?.energyCost?.inheritedGroupId &&
      !state.organizations.isPlan()([EnumPlans.BASIC])
      ? props.location.energy_cost
      : state.charger.getCurrentCharger?.config?.energyCost?.value,
    keepEnergyCostFromGroup:
      !!state.charger.getCurrentCharger?.config?.energyCost?.inheritedGroupId &&
      !state.organizations.isPlan()([EnumPlans.BASIC])
  }
})

const { value: energyCost } = useField<number | undefined>('energyCost')
const { value: keepEnergyCostFromGroup } = useField<boolean>('keepEnergyCostFromGroup')

const data = reactive({
  energyCost,
  keepEnergyCostFromGroup,
  saving: false,
  errors
})

const { computedPrice, onModelChange } = usePriceValueProxy(data.energyCost)

onModelChange(({ parsedValue, rawInput }) => {
  data.energyCost = parsedValue
  if (refs.input) refs.input.$el.getElementsByTagName('input')[0].value = rawInput
})

const compute = reactive({
  getCurrencyCode: computed(getCurrencyCode),

  computedPrice
})

const methods = {
  editName: handleSubmit(async () => {
    data.saving = true

    try {
      await api.chargers.updateChargerConfig({
        id: state.charger.getCurrentCharger.chargerData.id,
        config: { energyCost: data.keepEnergyCostFromGroup ? undefined : (data.energyCost ?? 0) }
      })
      const charger = await api.chargers.getCharger(state.charger.getCurrentCharger.chargerData.id)
      state.charger.setCurrentCharger(charger)
      notify.success(i18n.t('mywb.common.changes-saved'))
    } catch {
      notify.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.saving = false
    emit('close')
  }),

  switchKeepEnergyCost (checked: boolean) {
    if (checked) {
      compute.computedPrice = props.location?.energy_cost?.toString?.() ?? '0'
    }
  }
}
</script>
