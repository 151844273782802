<template>
  <div class="header-content-card">
    <transition name="header">
      <div
        v-if="permissions.showSelectedChargers && compute.countCheckedChargers"
        class="header-chargers-selected pr-24 g-16"
      >
        <div>
          <div v-if="compute.countCheckedChargers" class="is-flex is-size-500">
            <span class="is-font-weight-500 mx-4">
              {{ compute.countCheckedChargers }}
            </span>
            <span class="has-text-grey-500">
              {{ i18n.t('mywb.charger.charger-selected', compute.countCheckedChargers) }}
            </span>
          </div>

          <div v-if="!compute.countCheckedChargers" class="has-text-grey-500 is-size-500">
            <span class="is-font-weight-500 mx-4">
              {{ props.chargers.length }}
            </span>
            <span class="has-text-grey-500">
              {{ i18n.t('mywb.common.chargers') }}
            </span>
          </div>
        </div>

        <div class="header-chargers-selected-buttons">
          <charger-rates-popover
            v-if="permissions.showPayments && compute.hasOrganizationPaymentsAccount"
            :disabled="!compute.countCheckedChargers"
            @assign-rates="emit('on-bulk-action', 'assign-rates')"
            @unassign-rates="emit('on-bulk-action', 'unassign-rates')"
          />

          <wb-button
            v-if="permissions.hasBulkActions"
            :disabled="!(compute.chargersToUpdate && compute.countCheckedChargers)"
            :label="mq.current !== 'mobile' ? i18n.t('mywb.common.update') : ''"
            size="small"
            data-test-id="updateChargerButton"
            icon="send_to_charger"
            type="white"
            outlined
            @click="emit('on-bulk-action', REMOTE_ACTIONS.UPDATE)"
          />
          <wb-button
            v-if="permissions.hasBulkActions"
            :disabled="!(compute.chargersToRestart && compute.countCheckedChargers)"
            data-test-id="restartChargerButton"
            :label="mq.current !== 'mobile' ? i18n.t('mywb.common.restart') : ''"
            icon="refresh"
            type="white"
            outlined
            size="small"
            @click="emit('on-bulk-action', REMOTE_ACTIONS.RESTART)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { reactive, watch, computed } from 'vue'
import { useMq } from 'vue3-mq'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargerRatesPopover from '@/components/charger/ChargerRatesPopover.vue'
import { REMOTE_ACTIONS, hasActionsAvailable } from '@/utilities/charger/chargerActions'
import type { Charger, Group } from '@/types'

const mq = useMq()
const i18n = useI18n()

interface Props {
  chargers: Charger.Charger[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-bulk-action', action: REMOTE_ACTIONS | 'assign-rates' | 'unassign-rates'): void
}

const emit = defineEmits<Events>()

interface Data {
  groups?: Group.Group[],
  checkedGroupChargersTree: number
}
const data = reactive<Data>({
  groups: undefined,
  checkedGroupChargersTree: 0
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.payments_account),

  selectedChargersByGroup: computed((): Charger.Charger[] => {
    return props.chargers.filter(charger => state.charger.getCheckedChargers.some(item => item.id === charger.id))
  }),

  countCheckedChargers: computed((): number => compute.selectedChargersByGroup.length),

  chargersToUpdate: computed((): number => {
    const chargers = compute.selectedChargersByGroup.filter(charger => {
      return isUpdatesAvailable(charger, charger.software) && hasActionsAvailable(charger)
    })
    return chargers.length
  }),

  chargersToRestart: computed((): number => {
    const chargers = compute.selectedChargersByGroup.filter(hasActionsAvailable)
    return chargers.length
  })
})

watch(() => compute.countCheckedChargers, (chargersLength) => {
  data.checkedGroupChargersTree = chargersLength === props.chargers.length ? 1 : 0
})
</script>

<style lang="postcss" scoped>
.header-content-card {
  position: absolute;
  top: 0;
  left: 50px;
  width: calc(100% - 62px);
  height: 100%;
  background: var(--white);
}

.header-chargers-selected {
  width: 100%;
  height: 36px;
  margin: 1px 0;
  background: var(--grey-50);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: sticky;
  top: var(--header-height);

  @media (--desktop) {
    top: 143px;
  }
}

.header-chargers-selected-buttons {
  & * + * {
    margin-left: 1rem;
  }
}

.header-enter-active,
.header-leave-active {
  transition: all 300ms ease;
}

.header-enter-from,
.header-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
