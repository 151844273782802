<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500">
        <span
          v-t="'mywb.auth.welcome-back'"
          class="is-font-weight-700"
        />
        <br>
        <span v-t="'mywb.auth.password-to-continue'" />
      </p>
    </template>

    <wb-form class="auth-form">
      <wb-input
        ref="input"
        v-model="password"
        :error="errors.password"
        type="password"
        :label="i18n.t('mywb.common.password')"
        data-test-id="passwordInput"
        :disabled="data.loading"
        v-on="validationListenersPassword"
        @on-enter.prevent="methods.login"
      />

      <wb-button
        size="large"
        :label="i18n.t('mywb.common.continue')"
        data-test-id="loginCta"
        :disabled="data.loading"
        @click.prevent="methods.login"
      />

      <div class="is-flex justify-between">
        <wb-link
          data-test-id="backButton"
          class="is-size-400"
          type="text"
          @click="emit('back')"
        >
          <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
          <span>{{ i18n.t('mywb.common.back') }}</span>
        </wb-link>

        <wb-link
          class="is-size-400"
          data-test-id="forgotPasswordLink"
          @click="methods.handleForgotPassword"
        >
          {{ i18n.t('mywb.auth.forgotten-password') }}
        </wb-link>
      </div>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { useField, useWbInputAutofocus } from '@/hooks'
import { useNotify } from '@wallbox/toolkit-ui'
import { trackAction, trackEvent } from '@/engine/metrics/metricsManager'
import { getServerError } from '@/utilities/errorMessages'
import { useI18n } from '@/hooks/useI18n.hook'
import api from '@/api'
import state from '@/state'
import HttpError from '@/api/config/interceptors/errorResponse/httpError'

const i18n = useI18n()
const notify = useNotify()
const router = useRouter()

const props = defineProps({
  email: {
    type: String,
    required: true
  }
})

interface Events {
  (e: 'back'): void
}

const emit = defineEmits<Events>()
const { errors, handleSubmit } = useForm({
  validationSchema: {
    password: 'required'
  }
})

const input = ref()
useWbInputAutofocus(input)

const { value: password, validationListeners: validationListenersPassword } = useField<string>('password')

const data = reactive({
  loading: false
})

const methods = {
  login: handleSubmit(async () => {
    data.loading = true
    trackAction('signin_submit', { provider: 'email', new_user: false })
    try {
      const userLogin = await api.auth.signIn({
        username: props.email,
        password: password.value
      })

      const auth = userLogin.data.attributes

      state.global.setAuth(auth)
      trackEvent('signin_success', { user_id: auth.user_id, provider: 'email', new_user: false })
      methods.handleRedirect()
    } catch (error) {
      if (error instanceof HttpError) {
        notify.error(getServerError(error))
      } else {
        throw error
      }

      trackEvent('login-error', { type: 'email' })
    } finally {
      data.loading = false
    }
  }),

  handleForgotPassword () {
    trackAction('forgot-password')
    router.push({ name: 'forgot-password', query: { email: props.email } })
  },

  handleRedirect () {
    const storage = localStorage.getItem('wb-redirection')
    const wbRedirection = storage && JSON.parse(storage)
    if (wbRedirection) {
      const route = { name: wbRedirection.name, query: wbRedirection.query }
      localStorage.removeItem('wb-redirection')
      router.push(route)
    } else {
      router.push({ name: 'dashboard' })
    }
  }
}
</script>

<style lang="postcss" scoped>
.justify-between {
  width: 100%;
  justify-content: space-between;
}
</style>
