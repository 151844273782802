import axios from './config/axios.config'
import { endpoints } from './config/endpoints.config'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import type { GetChargerData } from '@/types/api/chargers.api.types'
import type { Charger, ChargerApi } from '@/types'

async function deleteChargerCache (chargerId: number) {
  try {
    const urlCharger = endpoints.v2.charger_chargerId.replace('{chargerId}', chargerId.toString())
    const urlChargerConfig = endpoints.v1.chargers_config_chargerId.replace('{chargerId}', chargerId.toString())

    const uriCharger = axios.getUri({ url: urlCharger })
    const uriChargerConfig = axios.getUri({ url: urlChargerConfig })

    const cacheStorage = await caches.open('api-cache')

    await cacheStorage.delete(uriCharger)
    await cacheStorage.delete(uriChargerConfig)
  } catch {}
}

export default {
  async getCharger (chargerId: number, { cacheType = 'network' } = {}) {
    return await Promise.all([
      axios.get<unknown, GetChargerData>(endpoints.v2.charger_chargerId.replace('{chargerId}', chargerId.toString()), {
        headers: {
          'workbox-cache-type': cacheType
        }
      }),
      axios.get<unknown, Charger.ChargerConfig>(endpoints.v1.chargers_config_chargerId
        .replace('{chargerId}', chargerId.toString()), {
        headers: {
          'workbox-cache-type': cacheType
        }
      })
    ]).then(response => ({
      charger: response[0],
      chargerConfig: response[1]
    }))
  },

  async getChargerData (chargerId: number | string): Promise<ChargerApi.GetChargerData> {
    return await axios.get(endpoints.v2.charger_chargerId.replace('{chargerId}', chargerId.toString()))
  },

  async createChargers (chargers: ChargerApi.CreateChargerPayload[]): Promise<ChargerApi.CreateChargersResponse> {
    return await axios.post(endpoints.v1.users_chargers_bulkAdd, chargers)
  },

  async updateCharger ({ chargerId, params }: { chargerId: number, params: Partial<Charger.ChargerData> }) {
    const response = await axios.put(endpoints.v2.charger_chargerId
      .replace('{chargerId}', chargerId.toString()), params)
    await deleteChargerCache(chargerId)
    return response
  },

  async setChargerRemoteAction ({ action, chargerId }: { action: number, chargerId: number }) {
    return await axios.post(endpoints.v3.chargers_chargerId_remoteAction
      .replace('{chargerId}', chargerId.toString()), { action })
  },

  async updateChargerConfig ({ id, config }: { id: number, config: ChargerApi.UpdateChargerConfig }) {
    const response = await axios.post(endpoints.v1.chargers_config_chargerId
      .replace('{chargerId}', id.toString()), config)
    await deleteChargerCache(id)
    return response
  },

  async updateChargerLocation ({ chargerId, config }: { chargerId: number, config: Partial<Charger.ChargerData> }) {
    trackDataEvent('update-charger-settings')
    const response = await axios.put(endpoints.v3.chargers_chargerId
      .replace('{chargerId}', chargerId.toString()), config)
    await deleteChargerCache(chargerId)

    return response
  },

  async bulkRemoteActions (remoteActions: any) {
    return await axios.put(endpoints.v3.chargers, remoteActions)
  },

  async unlinkCharger (chargerId: number) {
    trackDataEvent('unlink-charger', { charger_id: chargerId })
    return await axios.delete(endpoints.v2.charger_chargerId_group.replace('{chargerId}', chargerId.toString()))
  }
}
